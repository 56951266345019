import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Link, Snackbar, Card, CardContent, FormHelperText } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl } from '../helper/ApiAction';
import { FiChevronLeft } from 'react-icons/fi';
import TitleBarWhite from './Layouts/TitleBarWhite';

const INITIAL_SECURITY_PASSWORD_STATE = { old_password: '', security_password: '', security_password_confirmation: '' };

export default function Tutorial() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const [securityPasswordFormState, setSecurityPasswordFormState] = useState(INITIAL_SECURITY_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        old_password: '', password: '', password_confirmation: '',
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });

    const handleSecurityPasswordChange = (event) => {
        const name = event.target.name;
        setSecurityPasswordFormState({ ...securityPasswordFormState, [name]: event.target.value });
    }

    const updateSecurityPassword = () => {
        const { old_password, security_password, security_password_confirmation } = securityPasswordFormState;
        if(!old_password || !security_password || !security_password_confirmation) {
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            putUrl('user/security_password', securityPasswordFormState).then(result => {
                if(result.error) {
                    setState( state => ({ ...state, snackbarShow: true, error: true, message: result.message }) );
                    let allErrorField = [];
                    _.map(result.error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    setState( state => ({ ...state, snackbarShow: true, error: false, message: result.message }) );
                    setSecurityPasswordFormState(INITIAL_SECURITY_PASSWORD_STATE);
                    setErrorFormState({});
                }
            })
        }
    }

    return (
        <Box style={{padding:'0 15px'}}>
            
            <TitleBarWhite title={ t('title.bonus') } back displayInfo={false}/>
            <center><img src="/images/general/tutorial.png" style={{width:300, maxWidth:'80%'}} alt={t('profile.cryptoAddress')}/></center>
            <div className={styles.rowBox}>
                <Link underline='none' to={"/tutorialWithdrawal"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/withdrawal.png" style={{ width: 85, maxWidth: '80%' }} alt={t('tutorial.withdrawal')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.withdrawal')}</p>
                    </div>
                </Link>
                {/* <Link underline='none' to={"/tutorialDeposit"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/deposit.png" style={{ width: 85, maxWidth: '80%' }} alt={t('tutorial.deposit')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.deposit')}</p>
                    </div>
                </Link> */}
                <Link underline='none' to={"/tutorialStakes"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/stakes.png" style={{ width: 85, maxWidth: '80%' }} alt={t('tutorial.deposit')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.stakes')}</p>
                    </div>
                </Link>
            </div>
            <div className={styles.rowBox}>
                <Link underline='none' to={"/tutorialWalletAddress"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/cryptoAddress.png" style={{ width: 85, maxWidth: '80%' }} alt={t('profile.cryptoAddress')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.cryptoAddress')}</p>
                    </div>
                </Link>
                <Link underline='none' to={"/tutorialRegister"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/register.png" style={{ width: 85, maxWidth: '80%' }} alt={t('tutorial.forgetPassword')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.register')}</p>
                    </div>
                </Link>
            </div>
            <div className={styles.rowBox}>
                {/* <Link underline='none' to={"/tutorialSecurityPassword"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/securityPassword.png" style={{ width: 85, maxWidth: '80%' }} alt={t('tutorial.changeSecurityPassword')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.changeSecurityPassword')}</p>
                    </div>
                </Link> */}
                <Link underline='none' to={"/tutorialPassword"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/password.png" style={{ width: 85, maxWidth: '80%' }} alt={t('tutorial.changePassword')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.changePassword')}</p>
                    </div>
                </Link>
                {/* <Link underline='none' to={"/tutorialForgetPassword"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/forgetPassword.png" style={{ width: 85, maxWidth: '80%' }} alt={t('tutorial.forgetPassword')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.forgetPassword')}</p>
                    </div>
                </Link> */}
                
            </div>
            {/* <div className={styles.rowBox}>
                <Link underline='none' to={"/tutorialAuthenticator"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/authenticator.png" style={{ width: 85, maxWidth: '80%' }} alt={t('tutorial.authenticator')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.authenticator')}</p>
                    </div>
                </Link>
                <Link underline='none' to={"/tutorialDisableAuthenticator"} component={RouterLink} className={styles.linkContainer}>
                    <div className={styles.itemBox}>
                        <center><img src="/images/general/tutorial/disableAuthenticator.png" style={{ width: 85, maxWidth: '80%' }} alt={t('tutorial.disableAuthentication')} /></center>
                        <p style={{ textAlign: 'center', paddingTop: 8 }}>{t('tutorial.disableAuthentication')}</p>
                    </div>
                </Link>
            </div> */}
            <div className={styles.rowBox} style={{ paddingBottom: 40 }}>
                
            </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    backIconStyle: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        cursor: 'pointer',
    },
    linkContainer:{
        width:'fit-content',
        padding:7,
        maxWidth:'50%',
        minWidth:'50%',
    },
    link3Container:{
        width:'fit-content',
        padding:7,
        maxWidth:'33.33%',
        minWidth:'33.33%',
    },
    rowBox: {
        width:'100%',
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        padding:'0 7px'
    },
    rowBoxSingle: {
        width:'100%',
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        padding:'0 7px'
    },
    itemBox: {
        maxWidth:'100%',
        height:150,
        borderRadius:10,
        boxShadow:'0 0 6px 0 #0003',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        padding:15
    },
}));