import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TextField, Button, Typography, Grid, Box, Divider, List, Link, ListItem, Snackbar,Card ,CardContent, FormHelperText, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl,postUrl } from '../helper/ApiAction';
import { FiChevronLeft } from 'react-icons/fi';

import TitleBarWhite from './Layouts/TitleBarWhite';

import useNotificationLoading from '../helper/useNotificationLoading';

const INITIAL_PASSWORD_STATE = { old_password: '', password: '', password_confirmation: '', authentication_code: '' };

export default function ProfilePassword() {
    const { t } = useTranslation();
    const { setLoading } = useNotificationLoading();
    const theme = useTheme();
    const { two_factor_auth } = useSelector(state => ({
        two_factor_auth: state.user.two_factor_auth,
    }));
    const [state, setState] = useState({
        modalShow: false,
        mode: null,
        deleteAddressId: null,
        editAddressId: null,
        snackbarShow: false,
        deleteAddressShow: false,
        error: false,
        message: '',
    });

    const [passwordFormState, setPasswordFormState] = useState(INITIAL_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        old_password: '', password: '', password_confirmation: '', authentication_code: ''
    })
    
    const styles = useStyles();

    const handlePasswordChange = (event) => {
        const name = event.target.name;
        setPasswordFormState({ ...passwordFormState, [name]: event.target.value });
    }

    const updatePassword = () => {
        setLoading(true);
        setErrorFormState({});
        const { old_password, password, password_confirmation } = passwordFormState;
        if (!old_password || !password || !password_confirmation) {
            setLoading(false);
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            postUrl('auth/repassword', passwordFormState).then(result => {
                if (result.code != 0) {
                    setLoading(false);
                    setState(state => ({ ...state, snackbarShow: true, error: true, message: result.msg }));
                    let allErrorField = [];
                    _.map(result.error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    setLoading(false);
                    setState(state => ({ ...state, snackbarShow: true, error: false, message: result.msg }));
                    setPasswordFormState(INITIAL_PASSWORD_STATE);
                    setErrorFormState({});
                }
            })
        }
    }

    const [viewAnnouncement, setViewAnnouncement] = useState(true);
    const closeAnnouncement = (event, reason) => {
        if (reason && (reason == "backdropClick" || reason == "escapeKeyDown")) 
        return;
        setViewAnnouncement(false);
    }
    const [agree, setAgree] = useState(false);
    const checkboxHandler = () => {
        setAgree(!agree);
    }
    const btnHandler = () => {
        setViewAnnouncement(false);
    };    

    return (
        <Box>
            {/* <Link underline='none' to="/setting" component={RouterLink} style={{ zIndex: 3, lineHeight: 0 }} >
                <div className={styles.backIconStyle}>
                    <FiChevronLeft style={{ marginRight: 6, fontSize: 21 }} /><p>{t('button.back')}</p>
                </div>
            </Link> */}

            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'0 10px'}}>
            <TitleBarWhite title={ t('profile.cryptoAddress') } back displayInfo={false}/>
                <Link underline='none' to="/TutorialPassword" component={RouterLink} >
                <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff' }}>
                    <p>{t('tutorial.tutorial')}</p>
                </div>
                </Link>
            </div>

            <Box style={{ padding:'0 20px' }}>
                <Typography variant="h6">
                    {t('profile.changePassword')}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    {t('profile.passwordSubtitle')}
                </Typography>
            </Box>
            {
                two_factor_auth !== 1 ?
                <>
                    <div style={{ padding: '30px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.currentPassword')}</b></Typography>
                        <div>
                            <TextField
                                error={errorFormState.old_password ? true : false}
                                helperText={errorFormState.old_password}
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                type="password"
                                inputProps={{ name: 'old_password' }}
                                onChange={handlePasswordChange}
                                value={passwordFormState.old_password}
                            />
                        </div>
                    </div>
                    <div style={{ padding: '5px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.newPassword')}</b></Typography>
                        <TextField
                            error={errorFormState.password ? true : false}
                            helperText={errorFormState.password}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            type="password"
                            inputProps={{ name: 'password' }}
                            onChange={handlePasswordChange}
                            value={passwordFormState.password}
                        />
                        <FormHelperText style={{ color: '#8e8e8e', lineHeight: 1.2,}}>{t('profile.passwordValidation')}</FormHelperText>
                    </div>
                    <div style={{ padding: '5px 20px 10px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.confirmPassword')}</b></Typography>
                        <TextField
                            error={errorFormState.password_confirmation ? true : false}
                            helperText={errorFormState.password_confirmation}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            type="password"
                            inputProps={{ name: 'password_confirmation' }}
                            onChange={handlePasswordChange}
                            value={passwordFormState.password_confirmation}
                        />
                    </div>
                    {/* <div style={{ padding: '5px 20px 10px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.authenticationCode')}</b></Typography>
                        <TextField
                            error={errorFormState.authentication_code ? true : false}
                            helperText={errorFormState.authentication_code}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            type="text"
                            inputProps={{ name: 'authentication_code' }}
                            onChange={handlePasswordChange}
                            value={passwordFormState.authentication_code}
                        />
                        <FormHelperText>{t('general.2FACaption')}</FormHelperText>
                    </div> */}
                    <List className={styles.listStyle} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                            <Button variant="contained" onClick={updatePassword} style={{ width: 500, maxWidth: '80%', color:'#fff' }} color="primary">{t('button.confirm')}</Button>
                        </Box>
                    </List>
                </>
                : 
                <Box style={{ padding:'20px 40px' }} display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                    <Typography variant="overline" color="textPrimary">{t('profile.set2FAFirst', { title: t('profile.password') })}</Typography>
                    <div align="center" style={{ paddingTop: 20 }}>
                        <Link underline='none' to="/profile-two-factor-authentication" component={RouterLink} >
                            <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff', maxWidth: '170px' }}>
                                <p align="center" style={{ lineHeight: 1.4 }}>{t('profile.2FA')}</p>
                            </div>
                        </Link>
                    </div>
                </Box>
            }
            
            <Snackbar
                open={state.snackbarShow}
                autoHideDuration={2000}
                onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                    <CardContent style={{ padding: 10 }}>
                        <Typography style={{ color: 'white' }}>{state.message}</Typography>
                    </CardContent>
                </Card>
            </Snackbar>

            <MuiThemeProvider key='warn' theme={announceDialogStyle}>
                <Dialog
                    open={viewAnnouncement? true : false}
                    onClose={closeAnnouncement}
                    scroll="body"
                    aria-labelledby="view Announcement"
                    aria-describedby="view Announcement detail"
                    key='warn'
                    style={{width:'547px', maxWidth:'100%', margin:'0 auto'}}
                >
                    <DialogTitle id='warn'>{t('topup.dialog.title')}</DialogTitle>
                    <DialogContent dividers={false}>
                        <div
                            id="viewAnnouncement"
                            tabIndex={-1}
                        >
                            <p>{t('profile.dialog.content')}</p><br/>
                            <input type="checkbox" id="agree" onChange={checkboxHandler} />
                            <label htmlFor="agree">{t('topup.dialog.agree')}</label>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!agree} onClick={btnHandler}>
                            {t('button.proceed')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    backIconStyle: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        cursor: 'pointer',
    },
}));

const announceDialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh'
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 24px'
            }
        },
    },
});