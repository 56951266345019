import _ from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, Button, Dialog, DialogContent, Link, List, ListItem, ListItemText, Slide, TextField, Typography, Divider, FormHelperText, InputAdornment, IconButton ,Grid,Text} from '@material-ui/core';
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { getUrl, postUrl } from '../helper/ApiAction';
import { currencyFormat } from '../helper/Tools';
import useNotificationLoading from '../helper/useNotificationLoading';

import { FiAlertOctagon, FiLayers } from 'react-icons/fi';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function Stakes() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [listing, setListing] = useState([]);
    const [disputeOpen, setDisputeOpen] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [freeAmount, setFreeAmount] = useState(0);
    const [freePendingAmount, setFreePendingAmount] = useState(0);
    const [totalPendingAmount, setTotalPendingAmount] = useState(0);
    const [disputeInfo, setDisputeInfo] = useState(null);

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const { walletList } = useSelector(state => ({
        walletList: state.wallet.walletList,
    }));

    const isMountedRef = useRef(null);

    const STATUS_COLORS = {
        default: theme.palette.primary.main, //'#FF5532',
        // 0: //Pending
        1: '#3F686B', //Approved
        2: theme.palette.gray.textTitle, //'#979797',//Rejected
    }

    const getApi = (page=1) => {
        getUrl(`default/get-stakes?page=${page}&per_page=10`).then(response => {
            console.log(response,'response.code')
            if(response.code == 0 && isMountedRef.current) {
                const { last_page, data } = response.data;
                setTotalPage(last_page);
                if(page === 1){
                    setListing(response.data.data);
                }else{
                    console.log(listing,'listing')
                    console.log(response.data.data,'data')
                    setListing(_.concat(listing, response.data.data));
                }
                setTotalAmount(response.total_amount);
                setFreeAmount(response.free_amount);
                setFreePendingAmount(response.free_pending_amount);
                setTotalPendingAmount(response.total_pending_amount);
            }
        }).catch(err => {
            addAlert(JSON.stringify(err.message));
        })
    }
    useEffect(() => {
        isMountedRef.current = true;

        getApi(page);
        
        return () => isMountedRef.current = false;
    }, [page]);

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const openDialog = dialogInfo => {
        setDialogInfo(dialogInfo);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogInfo([]);
        setDialogOpen(false);
    }

    const setDispute = dialogInfo => {
        setDisputeInfo(dialogInfo);
        setDisputeOpen(true);
    }

    const combinationIdLabel = (_detail) => {
        let label = "";
        _.map(_detail, (_amount, _wallet) => {
            let existWallet = _.find(walletList, { code: _wallet });
            label += label ? " + " : "";
            let decimal_cal = existWallet?.decimal ? 10**existWallet.decimal : 100;
            let rounded_amount = Math.round(_amount*decimal_cal)/decimal_cal;
            label += ((existWallet?.wallet_name || _wallet) + ": "+rounded_amount);
        })
        return label;
    }

    return (
        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
            {/* <TitleBar title={ t('title.stakesHistory') } back>
                <Box display="flex" alignItems="center" justifyContent="center" height="56%">
                    <Box className={classes.rankWrapperStyle}>
                        <Typography className={ classes.rankTitleStyle }>{ _.size(walletInfo) > 0 ? walletInfo.wallet_name[0] : '-' }</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" >
                        <Typography className={classes.balanceStyle}>{ currencyFormat(100) } <span style={{ fontSize: 16 }}>{ t('stake.stakes') }</span></Typography>
                    </Box>
                </Box>
            </TitleBar> */}
           
            <div style={{padding:'8px 15px'}}>
                <div style={{ height: 150, background: theme.palette.secondary.main, borderRadius: 15, width: '100%', display:'flex', justifyContent:'center', alignItems:'center', paddingBottom:25 }}>
                    <FiLayers style={{fontSize:39, color:theme.palette.primary.main}}/>
                    <div style={{ color: theme.palette.white.text, textTransform:'uppercase', paddingLeft:15 }}>
                        <p>{t('stake.currentStakeAmount')}</p>
                        <p style={{fontSize:25}}><b>{ currencyFormat(totalAmount) }/{currencyFormat(totalPendingAmount+totalAmount)} </b></p>
                    </div>
                </div>
                <div style={{padding:'0 20px', marginTop:-29}}>
                    <div style={{ backgroundColor:theme.palette.primary.main, borderRadius:15, padding:10, display:'flex', justifyContent:'space-around', alignItems:'flex-start'}}>
                        {/* <div style={{color:theme.palette.white.text, textAlign:'center', opacity:0.5}}>
                            <center><img src='/images/general/icon/withdrawal.png' alt="withdrawal" style={{width:30}}/></center>
                            <p>{t('title.walletWithdrawal')}</p>
                        </div> */}
                        <Link underline='none' to={`/stakes/purchase`} component={RouterLink}>
                            <div style={{ color: theme.palette.white.text, textAlign: 'center' }}>
                                <center><img src='/images/general/icon/topup.png' alt="topup" style={{ width: 30 }} /></center>
                                <p>{t('stake.purchase')}</p>
                            </div>
                        </Link>
                        {/* <div style={{color:theme.palette.white.text, textAlign:'center', opacity:1, cursor: "pointer"}} onClick={() => setDispute(null)}>
                            <center><img src='/images/general/icon/dispute.png' alt="dispute" style={{width:30}}/></center>
                            <p>{t('stake.dispute')}</p>
                        </div> */}
                        
                    </div>
                </div>
            </div>

            <Box padding={2}>
                <p style={{fontSize:15}}><b>{t('title.transaction')}</b></p>
                <List className={classes.listRoot}>
                    { _.map(listing, transactionItem => (
                        <TransactionItem key={transactionItem.id} data={transactionItem} openDialog={openDialog} />
                    ))}
                </List>
                { page < totalPage ?
                    <Button onClick={ loadTransaction } fullWidth>{ t('transaction.loadMore') }</Button>
                    : <Typography style={{ textAlign: 'center' }}>{ t('transaction.endOfList') }</Typography>
                }
            </Box>
            <MuiThemeProvider theme={DialogStyle}>
                <Dialog
                    open={dialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth
                    onClose={closeDialog}
                    style={{padding:'0 15px'}}
                >
                    <DialogContent style={{width:400, maxWidth:'100%', padding:0}}>
                        {!_.includes(['wallet', 'reactive'], dialogInfo.payment_method) && <div className={classes.watermark}>FREE</div>}
                        <div style={{background:STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default'], padding: '10px 15px', borderRadius: '0px 0px 20px 20px', width: 150, margin: '0 auto',}}>
                            <p style={{ textAlign: 'center', color:theme.palette.white.main, textTransform:'uppercase', fontSize:17 }}><b>{dialogInfo?.status_display?.[i18n.language] || '-'}</b></p>
                        </div>
                        <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" style={{paddingTop:15}}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 24, color: STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default']  }}>{`${currencyFormat((parseFloat(dialogInfo.amount) * 100) / 100 || 0)}`}</Typography>
                            <Typography style={{ textTransform: 'uppercase', fontSize: 14, color:theme.palette.silver.text, marginTop:-5 }}>
                            {t('stake.stakes')} {t('stake.amount')}</Typography>
                        </Box>
                        {/* <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('stake.status')}</Typography>
                            <Typography style={{ fontSize: 14 }}>{dialogInfo?.status_display?.[i18n.language] || '-'}</Typography>
                        </Box> */}
                        {/* <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('stake.paymentMethod')}</Typography>
                            <Typography style={{ fontSize: 14 }}>{t(`stake.${dialogInfo.payment_method}`)}</Typography>
                        </Box> */}

                        <div style={{ padding: '0 20px' }}>
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'15px 0'}}>
                                <div style={{ padding: '15px 0' }}>
                                    <p style={{ lineHeight: 1.3, paddingBottom: 10 }}>
                                        <b>{t('stake.stakeId')}</b><br />
                                        #{dialogInfo.id}
                                    </p>
                                    <p style={{ lineHeight: 1.3, paddingBottom: 10 }}>
                                        <b>{t('stake.rate')}</b><br />
                                        {dialogInfo.rate}
                                    </p>
                                    { dialogInfo.status !== 3 &&
                                    <p style={{ lineHeight: 1.3 }}>
                                        <b>{t('stake.nextIncrementIn')}</b><br />
                                        {dialogInfo.new_rate_at} ({dialogInfo.new_rate})
                                    </p>
                                    }
                                    {_.size(dialogInfo.payment_details?.['combination_id']) > 0 &&
                                    <p style={{ lineHeight: 1.3, paddingBottom: 10 }}>
                                        <b>{t('stake.paymentCombination')}</b><br />
                                        {combinationIdLabel(dialogInfo.payment_details['combination_detail'])}
                                    </p>
                                    }
                                    {_.size(dialogInfo.disputable_at) > 0 &&
                                    <p style={{ lineHeight: 1.3, paddingBottom: 10 }}>
                                        <b>{t('stake.disputableAfter')}</b><br />
                                        { dialogInfo.disputable_countdown['day'] > 0 && t('stake.xdays', {'day': dialogInfo.disputable_countdown['day']})}
                                        { (dialogInfo.disputable_countdown['day'] === 0 && dialogInfo.disputable_countdown['hour'] > 0) && t('stake.xhours', {'hour': dialogInfo.disputable_countdown['hour']})}
                                        { (dialogInfo.disputable_countdown['day'] === 0 && dialogInfo.disputable_countdown['hour'] === 0 && dialogInfo.disputable_countdown['minute'] > 0) && t('stake.xminutes', {'minute': dialogInfo.disputable_countdown['minute']})}
                                        { (dialogInfo.disputable_countdown['day'] === 0 && dialogInfo.disputable_countdown['hour'] === 0 && dialogInfo.disputable_countdown['minute'] === 0) && "-"}
                                    </p>
                                    }
                                </div>
                                { dialogInfo.status !== 3 &&
                                <div style={{paddingLeft:15}}>
                                    <div style={{borderRadius:15, boxShadow:'0 0 6px 0 #0003', minWidth:120}}>
                                        <div style={{ background: theme.palette.primary.main, padding: '9px 10px', borderRadius:'10px 10px 0 0' }}>
                                            <p style={{ textAlign: 'center', color: '#fff' }}>
                                                {t('stake.nextIncrement')}
                                            </p>
                                        </div>
                                        <div style={{textAlign:'center', textTransform:'uppercase', padding:15, border:'2px solid', borderColor:theme.palette.primary.main, color:theme.palette.primary.main, borderRadius:'0 0 10px 10px', background:'#fff'}}>
                                            <p style={{fontSize:40}}><b>{dialogInfo.new_rate_countdown}</b></p>
                                            <p><b>{t('stake.daysLeft')}</b></p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="space-between" borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.text, padding:'15px 0' }}>
                                <Box display="flex" width="100%" >
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{t('stake.createDate')}</Typography>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1, textAlign:'right' }}>{t('stake.disputeDate')}</Typography>
                                </Box>
                                <Box display="flex" width="100%">
                                    <Typography style={{ fontSize: 14, flex: 2, color: theme.palette.silver.text }}>{dialogInfo.created_at}</Typography>
                                    <Typography style={{ fontSize: 14, flex: 2, color: theme.palette.silver.text, textAlign:'right' }}>{dialogInfo.status === 3 ? dialogInfo.updated_at : "-"}</Typography>
                                </Box>
                            </Box>
                        </div>

                    </DialogContent>
                    <Box classes={{ root: classes.cancelBox }}>
                        {
                            (dialogInfo.status === 1 && dialogInfo.disputable === true) &&
                            <Button variant="contained" classes={{ root: classes.noButton }} onClick={() => setDispute(dialogInfo) }>
                                { t('stake.dispute') }
                            </Button>
                        }
                        <Button variant="contained" classes={{ root: classes.yesButton }} onClick={ closeDialog }>
                            { t('transaction.done') }
                        </Button>
                    </Box>
                    {/* <Button variant="contained" classes={{ root: classes.buttonRoot2 }} onClick={closeDialog}>
                        {t('button.confirm')}
                    </Button> */}
                </Dialog>
            </MuiThemeProvider>
            <DisputeStakePopUp disputeOpen={disputeOpen} setDisputeOpen={setDisputeOpen} id={disputeInfo ? disputeInfo.id : ""} amount={disputeInfo ? parseFloat(disputeInfo.amount)+parseFloat(disputeInfo.pending_amount): totalAmount-freeAmount-freePendingAmount+totalPendingAmount} getApi={getApi} setDialogInfo={setDialogInfo}/>
        </Box>
    )
}

const TransactionItem = ({ data, openDialog }) => {
    const { amount, remark, status_display, created_at, pending_amount, approved_at, new_rate_countdown, status ,payment_method} = data;
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    return (
        <ListItem divider onClick={() => openDialog(data)} classes={{ root: classes.listItemRoot }} display="flex" alignItems="center" style={{padding:'10px 0'}} >
            <Box className={classes.listItemTextRoot}>
                <ListItemText
                    style={{width:'100%'}}
                    classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
                    // primary={ `${t('withdrawal.status')}: ${status_display[i18n.language] || '-'}`}
                    primary={ _.includes(['wallet', 'reactive'], payment_method)? 
                    `${status_display[i18n.language] || '-'}`
                    :   
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography
                                component="p"
                                className={classes.freeremarkStyle}
                            >
                                {status_display[i18n.language] }<span style={{color: 'green',marginLeft:5}}>{'[Free]'}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    }
                    // primary={ `${status_display[i18n.language] || '-'} free`}
                    secondary={
                        status !== 3 &&
                        <Typography
                            component="p"
                            variant="body2"
                            className={classes.remarkStyle}
                        >
                            { t('stake.nextIncrementIn') } : 
                        </Typography>
                    }
                />
                <Typography
                    component="span"
                    variant="body2"
                    className={classes.remarkStyle}
                >
                    {t('stake.approvedDate')}
                </Typography>
            </Box>
            <div>
                <ListItemText
                    primary={ `${currencyFormat((amount * 100) / 100)} / ${currencyFormat(parseFloat(amount) + parseFloat(pending_amount))}` }
                    classes={{ root: classes.listItemAmountRoot, primary: classes.listItemAmountPrimary }}
                    style={{ color: theme.palette.secondary.main, whiteSpace:'nowrap',}}
                    secondary={
                        status !== 3 &&
                        <Typography
                            component="p"
                            variant="body2"
                            className={classes.remarkStyle}
                        >
                            {new_rate_countdown} {t('stake.days')}
                        </Typography>
                    }
                />
                <Typography
                    component="span"
                    variant="body2"
                    className={classes.remarkStyle}
                >
                    {approved_at}
                </Typography>
            </div>
        </ListItem>
    )
}

const DisputeStakePopUp = ({ amount, disputeOpen, setDisputeOpen, getApi, id, setDialogInfo }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading, loading } = useNotificationLoading();

    const DEFAULT_ERROR_FIELD = { security_password: "", authentication_code: "" };

    const [state, setState] = useState({ security_password: "", authentication_code: "", showPassword: false });
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);

    const { two_factor_auth } = useSelector(state => ({
        two_factor_auth: state.user.two_factor_auth,
    }));

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        setState({ ...state, [name]: newValue });
    }

    const disputeStake = () => {
        if(loading === false){
            setLoading(true);
            const formData = {
                security_password: state.security_password,
                authentication_code: state.authentication_code,
                id:id,
            };
            setErrorField(DEFAULT_ERROR_FIELD);
            postUrl(`default/order-dispute?id=${id}`, formData).then(response => {
                if(response.code == 0) {
                    addAlert('', 'success', response.msg, '');
                    setDialogInfo(response.data);
                    // if(response.data){
                    // }
                    getApi();
                    setDisputeOpen(false);
                } else {
                    // if(response.errors) {
                    //     let newErrorField = {};
                    //     _.map(response.errors, (errorMsg, errorField) => {
                    //         newErrorField[errorField] = errorMsg[0];
                    //     })
                    //     setErrorField(newErrorField);
                    // }
                    if(response.msg) {
                        addAlert(response.msg);
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(()=>{
                setLoading(false);
            })
        }
    }

    return (
        <MuiThemeProvider theme={DialogStyle}>
            <Dialog
                open={disputeOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {setDisputeOpen(false); setErrorField(DEFAULT_ERROR_FIELD);}}
            >
                <DialogContent >
                    <form noValidate autoComplete="off">
                        <div style={{textAlign:'center'}}>
                            <FiAlertOctagon style={{fontSize:45, color:theme.palette.primary.main, marginRight: 10 }}/>
                            <p style={{lineHeight:1.4, padding:'5px 7% 10px'}}>
                                {t('stake.disputeConfirmation')}
                                3454354354
                            </p>
                        </div>
                        <TextField 
                            type="text"
                            variant="outlined"
                            value={amount}
                            name="amount"
                            classes={{
                                root: classes.textFieldRoot,
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                            label={ t('stake.amount') }
                            error={ _.size(errorField.amount) ? true : false }
                            helperText={ errorField.amount }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            fullWidth
                        />
                        {
                            true || two_factor_auth === 1 ?
                            <>
                                <TextField 
                                    type={state.showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    value={state.security_password}
                                    name="security_password"
                                    onChange={ handleChange }
                                    classes={{
                                        root: classes.textFieldRoot,
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    label={ t('stake.security_password') }
                                    error={ _.size(errorField.security_password) ? true : false }
                                    helperText={ errorField.security_password }
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    fullWidth
                                />
                                {/* <TextField 
                                    type="string"
                                    variant="outlined"
                                    value={state.authentication_code}
                                    name="authentication_code"
                                    onChange={ handleChange }
                                    classes={{
                                        root: classes.textFieldRoot,
                                    }}
                                    label={ t('profile.authenticationCode') }
                                    error={ _.size(errorField.authentication_code) ? true : false }
                                    helperText={ errorField.authentication_code }
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    fullWidth
                                />
                                <FormHelperText>{t('general.2FACaption')}</FormHelperText> */}
                            </>
                        : 
                        <>
                            <TextField
                                type={state.showPassword ? 'text' : 'password'}
                                variant="outlined"
                                value={state.security_password}
                                name="security_password"
                                onChange={handleChange}
                                classes={{
                                    root: classes.textFieldRoot,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                            {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }}
                                label={t('stake.security_password')}
                                error={_.size(errorField.security_password) ? true : false}
                                helperText={errorField.security_password}
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                fullWidth
                            />
                        </>
                        }
                        
                    </form>
                </DialogContent>
                <Button variant="contained" classes={{ root: classes.buttonRoot2 }} onClick={ disputeStake }>
                    { t('button.proceed') }
                </Button>
                <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={()=> {setDisputeOpen(false); setErrorField(DEFAULT_ERROR_FIELD);} }>
                    { t('button.cancel') }
                </Button>
            </Dialog>
        </MuiThemeProvider>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 3,
    },
    buttonRoot: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 10,
        marginTop: 15, 
        marginLeft: 20,
        marginRight: 20,
        "&:hover":{
            backgroundColor: theme.palette.secondary.secondary,
        }
    },
    buttonRoot2: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 10,
        marginTop: 0, 
        marginLeft: 20,
        marginRight: 20,
        "&:hover":{
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    remarkStyle:{
        color: theme.palette.silver.text,
        fontSize: 15,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        width:'100%',
        overflow:'hidden'
    },
    freeremarkStyle:{
        fontSize: 15,
        display: '-webkit-box',
        fontWeight: 'bold',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        width:'100%',
        overflow:'hidden'
    },
    balanceStyle: {
        fontSize: 30, 
        color: "white",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    listRoot: {
        width: '100%',
    },
    listItemTextRoot: {
        flex: 4
    },
    listItemAmountRoot: {
        // flex: 1,
        // display: 'flex',
        // justifyContent: 'flex-end',
        //alignSelf: 'center',
        textAlign:'right',
    },
    listItemAmountPrimary: {
        fontWeight: 'bold',
        fontSize: 16
    },
    listItemTextPrimary: {
        fontSize: 14,
        fontWeight:'bold',
    },
    listItemTextSecondary: {
        fontSize: 10,
    },
    listItemRoot: {
        cursor: 'pointer'
    },
    cancelBox: {
        marginLeft: 20,
        marginRight: 20,
        display: "flex",
        justifyContent: "space-between",
    },
    yesButton: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 20,
        marginLeft: 10,
        marginRight: 10,
        width: "50% !important",
        "&:hover":{
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    noButton: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 20,
        marginLeft: 10,
        marginRight: 10,
        width: "50% !important",
        "&:hover":{
            backgroundColor: theme.palette.secondary.secondary,
        }
    },
    watermark: {
        position: 'absolute',
        opacity: 0.2,
        fontSize: '8em',
        width: '100%',
        zIndex: 1,
        color: '#bbbbbb',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        transform: 'rotate(-45deg)',
        pointerEvents: 'none'
    }
}));

const DialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh',
                padding: '0px 0 35px',
                borderRadius: 15,
            },
            paperFullWidth: {
                width: 400,
                maxWidth: '85%',
                margin: '0 auto'
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 24px'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                background: '#0008',
                borderRadius: 48,
                minWidth: 'unset',
                padding: 0,
            }
        }
        
    },
});