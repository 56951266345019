import {
    ADD_ERROR, CHANGE_LANGUAGE, STORE_UUID, REMOVE_UUID, CART_TOTAL, CODE_STATE, RESET_CODE_STATE, AUTH_SUCCESS, AVAILABLE_EVENT2, LINK_REFERRAL_CODE, SHARE_LINK_PRODUCT, USER_ORIGIN
} from '../actions/types';
import jwt from 'jwt-decode';
import { DEFAULT_LANGUAGE } from '../configs/Config';

const INITIAL_STATE = {
    error: false,
    errorMessage: '',
    token: '',
    language: DEFAULT_LANGUAGE || 'en',
    uuid: '',
    cartTotal: 0,
    loginState: '',
    codeVerifier: '',
    accessToken: '',
    refreshToken: '',
    expired: 0,
    availableEvent2: true,
    linkReferralCode: '',
    shareLinkProductId: '',
    role: "",
    userOrigin: ""
}

export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case ADD_ERROR:
            return { ...state, error: actions.payload.error };
        case CHANGE_LANGUAGE:
            return { ...state, language: actions.payload };
        case STORE_UUID:
            return { ...state, uuid: actions.payload };
        case REMOVE_UUID:
            return { ...state, uuid: '' };
        case CART_TOTAL:
            return { ...state, cartTotal: actions.payload };
        case CODE_STATE:
            return { ...state, loginState: actions.payload.state, codeVerifier: actions.payload.code };
        case RESET_CODE_STATE:
            return { ...state, loginState: '', codeVerifier: '', accessToken: '', refreshToken: '', expired: '' };
        case AUTH_SUCCESS:
            // const decoded_jwt = jwt(actions.payload.access_token);
            return { 
                ...state, 
                accessToken: actions.payload.access_token, 
                refreshToken: actions.payload.refresh_token,
                expired: actions.payload.expires_in,
                loginState: '',
                codeVerifier: '',
                // role: decoded_jwt ? decoded_jwt.role : '',
            };
        case AVAILABLE_EVENT2:
            return { ...state, availableEvent2: actions.payload };
        case LINK_REFERRAL_CODE:
            return { ...state, linkReferralCode: actions.payload };
        case SHARE_LINK_PRODUCT:
            return { ...state, shareLinkProductId: actions.payload };
        case USER_ORIGIN:
            return { ...state, userOrigin: actions.payload };
        default:
            return state;
    }
}