import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// import { loginUser } from '../helper/ApiAction';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { authSuccess, authFail, updateShareLinkRef } from '../actions/GeneralActions';
import { store } from '../configs/Store';

import { createMuiTheme, MuiThemeProvider, makeStyles, useTheme, } from '@material-ui/core/styles';


import { Card, CardHeader, CardContent, Divider, TextField, Button, Container, Grid, Box, Typography, Link, MenuItem, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { postUrl, getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';
import WebpImg from './Layouts/WebpImg';
import { BACKEND_URL, CLIENT_SECRET } from '../configs/Config';
import axios from 'axios';
import jwt_decode from "jwt-decode";

import { FiChevronLeft } from 'react-icons/fi';
import theme from '../Theme';

function useInput({ type, label, val, helperText, disabled = false, placeholder = "" }) {
    const [value, setValue] = useState(val ? val : "");
    const [error, setErrorValue] = useState("");
    const input =
        <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
                <MuiThemeProvider theme={fieldStyle}>
                    <TextField fullWidth style={{ color: '#fff' }} label={label} error={error ? true : false} helperText={error} variant="outlined" value={value} onChange={e => setValue(e.target.value)} type={type} InputProps={{ readOnly: disabled }} placeholder={placeholder} />
                    {helperText && <FormHelperText style={{ color: '#EAEAEA' }}>{helperText}</FormHelperText>}
                </MuiThemeProvider>
            </Grid>
        </Grid>;
    return [value, input, setErrorValue, setValue];
}

function useSelect({ data, label, val, disabled = false }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const select =
        <Grid container spacing={3} justify="center">

            <Grid item xs={12}>
                <MuiThemeProvider theme={fieldStyle}>
                    <TextField
                        select
                        label={label}
                        value={value}
                        onChange={({ target }) => setValue(target.value)}
                        variant="outlined"
                        fullWidth
                        error={error ? true : false}
                        helperText={error}
                        disabled={disabled}
                    >
                        {_.map(data, (option, key) => (
                            <MenuItem key={key} value={option.value} style={{ color: '#222' }}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </MuiThemeProvider>
            </Grid>
        </Grid>
    return [value, select, setErrorValue, setValue];
}

function useCheckbox({ label, val, note = '' }) {
    const [value, setValue] = useState(val);
    const [error, setErrorValue] = useState("");
    const checkbox =
        <Grid item xs={12} sm={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={e => setValue(e.target.checked)}
                        color="primary"
                    />
                }
                label={label}
            />
            <Typography variant="body2" >{note}</Typography>
        </Grid>;
    return [value, checkbox, setErrorValue];
}

export default function Register() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let history = useHistory();
    const query = useQuery();
    const encoded_ref_id = query.get('r');
    // const queryPlacement = query.get('placement') ? query.get('placement') : "";
    // const queryPosition = query.get('position') ? query.get('position') : 0;
    const queryName = query.get("name") ? query.get("name") : "";
    const queryUsername = query.get("username") ? query.get("username") : "";
    const queryMobile = query.get("mobile") ? query.get("mobile") : "";
    // const queryNric = query.get("nric") ? query.get("nric") : "";
    const queryEmail = query.get("email") ? query.get("email") : "";
    const source = query.get("src") ? query.get("src") : "";
    const sourceUserId = query.get("uid") ? query.get("uid") : "";

    const isMountedRef = useRef(null);
    const [userLogin, setUserLogin] = useState(false);

    // const placementPosition = [{value: 0, label: t("register.placementPosition.no")}, {value: 1, label: t("register.placementPosition.left")}, {value: 2, label: t("register.placementPosition.right")}];
    const [name, nameInput, nameValidate] = useInput({ type: "text", label: t('register.name') + "*", val: queryName });
    const [email, emailInput, emailValidate] = useInput({ type: "text", label: t('register.email') + "*", val: queryEmail });
    const [mobile, mobileInput, mobileValidate] = useInput({ type: "text", label: t('register.mobile'), val: queryMobile });
    // const [nric, nricInput, nricValidate] = useInput({ type: "text", label: t('register.nric_passport'), val: queryNric});
    const [username, usernameInput, usernameValidate] = useInput({ type: "text", label: t('register.username') + "*", val: queryUsername });
    const [referral, referralInput, referralValidate, setReferral] = useInput({ type: "text", label: t('register.referral') + "*" });
    // const [placement, placementInput, placementValidate] = useInput({ type: "text", label: t('register.placement'), val: queryPlacement, disabled: true });
    // const [position, positionInput, positionValidate] = useSelect({ data: placementPosition, label: t('register.position'), val: queryPosition, disabled: true });
    // const [autoPlacement, autoPlacementInput, autoPlacementValidate] = useCheckbox({ label: t('register.autoPlacement'), val: queryPlacement ? false : true });
    const [password, passwordInput, passwordValidate] = useInput({ type: "password", label: t('register.password') + "*", helperText: t('profile.passwordValidation') });
    const [passwordConfirmation, passwordConfirmationInput, passwordConfirmationValidate] = useInput({ type: "password", label: t('register.passwordConfirmation') + "*" });
    const [securityPassword, securityPasswordInput, securityPasswordValidate] = useInput({ type: "password", label: t('register.securityPassword') + "*" });
    const [securityPasswordConfirmation, securityPasswordConfirmationInput, securityPasswordConfirmationValidate] = useInput({ type: "password", label: t('register.securityPasswordConfirmation') + "*" });
    const [cryptoNetwork, cryptoNetworkInput, cryptoNetworkValidate] = useSelect({
        data: [
            { label: 'TRC20', value: 'trc20' },
            // { label: 'BEP20', value: 'bep20' }
        ], label: t('register.cryptoNetwork'), val: "trc20", disabled: false
    });
    const [cryptoAddress, cryptoAddressInput, cryptoAddressValidate] = useInput({ type: "text", label: t('register.cryptoAddress') + "*" });
    const [tac, tacInput, tacValidate] = useInput({ type: "text", label: t('profile.tac') + "*" });
    const [authentication_code, authentication_codeInput, authentication_codeValidate] = useInput({ type: "text", label: t('profile.authenticationCode') + "" });
    const [secret, setSecret] = useState("");
    const [qrcode, setQrcode] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const validate = {
        name: nameValidate, email: emailValidate, mobile: mobileValidate, /* nric: nricValidate, */ password: passwordValidate, password_confirmation: passwordConfirmationValidate,
        username: usernameValidate, referral: referralValidate, /* placement: placementValidate, position: positionValidate, autoPlacement: autoPlacementValidate */
        security_password: securityPasswordValidate, security_password_confirmation: securityPasswordConfirmationValidate, crypto_network: cryptoNetworkValidate, crypto_address: cryptoAddressValidate,
        tac: tacValidate, authentication_code: authentication_codeValidate
    };

    const { accessToken, linkReferralCode } = useSelector(state => state.general);
    const [refId, setRefId] = useState("");
    const [step, setStep] = useState(1);
    const [timer, setTimer] = useState();
    const [resendVerification, setResendVerification] = useState(false);
    const state = {
        button: 1
    };


    useEffect(() => {
        if (accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]);

    useEffect(() => {
        isMountedRef.current = true;
        if (encoded_ref_id) {
            getUrl(`default/get-user-info?id=${encoded_ref_id}`).then(response => {
                if (isMountedRef.current) {
                    if (response.data.ref_id) {
                        setRefId(response.data.ref_id);
                        setReferral(response.data.ref_username);
                    }
                }

            }).catch(error => {
                // addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
    }, [encoded_ref_id]);

    useEffect(() => {
        if (!encoded_ref_id && linkReferralCode) {
            history.replace(`register?r=${linkReferralCode}`);
        }
        if (encoded_ref_id == linkReferralCode) {
            dispatch(updateShareLinkRef(""));
        }
        // eslint-disable-next-line
    }, [encoded_ref_id, linkReferralCode]);

    const loginUser = () => {
        setLoading(true);
        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: CLIENT_SECRET,
            username: username,
            password: password,
            scope: '*',
        }
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.post(`${BACKEND_URL}/oauth/token`, loginState)
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    const decoded_jwt = jwt_decode(response.data.access_token);
                    const userRole = decoded_jwt ? decoded_jwt.role : [];
                    if (_.size(_.intersection(userRole, ['member']))) {
                        dispatch(authSuccess(response.data));
                    } else {
                        addAlert(t('general.incorrectUsernamePassword'));
                    }
                }
            }).catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 400) {
                    addAlert(t('general.incorrectUsernamePassword'));
                } else if (error.response && error.response.status === 401) {
                    let errorResponse = JSON.parse(error.request.response);
                    addAlert(errorResponse.message);
                } else {
                    addAlert(JSON.stringify(error.message));
                }
                dispatch(authFail());
            });
    }

    const linkUser = () => {
        setLoading(true);
        let params = {
            username: username,
            source: source,
            source_user_id: sourceUserId,
            source_username: queryUsername,
        }
        postUrl('account-link/register', params).then(response => {
            setLoading(false);
            if (response.status) {
                loginUser();
            } else {
                if (response.error) {
                    addAlert(JSON.stringify(_.map(response.error).join(' ')));
                } else {
                    addAlert(JSON.stringify(response.message));
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const handleSubmit = (evt) => {
        setLoading(true);
        evt.preventDefault();

        setErrorMessage("");
        _.map(validate, (valid) => {
            valid("");
        })
        let postData = {
            name,
            email,
            username,
            mobile,
            // nric,
            // referral,
            // placement: autoPlacement?'':placement,
            // position:autoPlacement?null:(position>0?position:null),
            // auto_placement: autoPlacement?1:0,
            password,
            password_confirmation: passwordConfirmation,
            security_password: securityPassword,
            security_password_confirmation: securityPasswordConfirmation,
            crypto_address: cryptoAddress,
            crypto_network: cryptoNetwork,
            tac: tac,
            // secret: secret,
            // authentication_code: authentication_code,
        }
        if (state.button === 1) {
            console.log("Button 1 clicked!");
            postData.secret = secret;
            postData.authentication_code = authentication_code;
        }
        if (state.button === 2) { //skip 2fa
            console.log("Button 2 clicked!");
            postData.secret = '';
            postData.authentication_code = '';
        }

        if (refId) {
            postData.ref_id = refId;
        } else {
            postData.referral = referral;
        }
        postUrl(`register`, postData)
            .then((response) => {
                // console.log(response);
                setLoading(false);
                let { status, message, error } = response;
                if (status === 0) {
                    setErrorMessage(message);
                    _.map(error, (value, key) => {
                        validate[key](value[0]);
                    })
                } else {
                    setSuccessMessage(message);
                    // linkUser();
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    const requestTac = () => {
        if (timer && timer !== 0) {
            return false;
        }
        setLoading(true);
        _.map(validate, (valid) => {
            valid("");
        })
        let postData = {
            username: username ? username : '',
            email: email ? email : '',
        }
        getUrl('default/send-mail', postData).then(result => {
            setErrorMessage("");
            if (result.code !==0) {
                setLoading(false);
                addAlert(result.msg);
            } else {
                setLoading(false);
                addAlert(t('general.checkMailBox'), 'success');
                // addAlert(result.msg, 'success');
                setResendVerification(true);
                setTimer(60);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('general.checkMailBox'), 'success');
        });
    }

    const validateEmail = () => {
        setLoading(true);
        _.map(validate, (valid) => {
            valid("");
        })
        const postData = {
            username: username ? username : '',
            email: email ? email : '',
            tac: tac,
        }
        getUrl('validate-email', postData).then(result => {
            setErrorMessage("");
            if (result.error) {
                setLoading(false);
                let { status, message, error } = result;
                if (status === 0) {
                    setErrorMessage(message);
                    _.map(error, (value, key) => {
                        validate[key](value[0]);
                    })
                }
                addAlert(result.message);
            } else {
                getUrl(`register-get-authentication-secret`, postData).then(response => {
                    setLoading(false);
                    if (response.status) {
                        setSecret(response.secret);
                        setQrcode(response.qrcode);
                    } else {
                        if (response.error) {
                            let { status, message, error } = response;
                            if (status === 0) {
                                setErrorMessage(message);
                                _.map(error, (value, key) => {
                                    validate[key](value[0]);
                                })
                            }
                            addAlert(JSON.stringify(response.message));
                        } else {
                            addAlert(JSON.stringify(response.message));
                        }
                    }
                }).catch(error => {
                    setLoading(false);
                    addAlert(JSON.stringify(error.message));
                });
                setStep(3);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const validateDetails = () => {
        console.log(333)
        setLoading(true);
        _.map(validate, (valid) => {
            valid("");
        })
        let postData = {
            name,
            email,
            username,
            mobile,
            // nric,
            // referral,
            // placement: autoPlacement?'':placement,
            // position:autoPlacement?null:(position>0?position:null),
            // auto_placement: autoPlacement?1:0,
            password,
            password_confirmation: passwordConfirmation,
            security_password: securityPassword,
            security_password_confirmation: securityPasswordConfirmation,
            crypto_address: cryptoAddress,
            crypto_network: cryptoNetwork,
            tac: tac,
            // authenticationCode: authenticationCode,
        }   
        setLoading(false);

        if (refId) {
            postData.ref_id = refId;
        } else {
            postData.referral = referral;
        }
        postUrl('auth/register', postData).then(result => {
            if (result.code != 0) {
                setLoading(false);
                setErrorMessage("");
                addAlert(result.msg);
            } else {
                setLoading(false);
                // setStep(2);
                setErrorMessage("");
                // console.log(JSON.stringify(result.data));
                dispatch(authSuccess((result.data)));
                // history.goBack();
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    useEffect(() => {
        if (timer === 0) {
            setResendVerification(false);
        }
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timer])

    return (
        <Box style={{ background: 'rgb(0,0,0)', minHeight: "100vh", background: theme.palette.secondary.main, paddingTop: 30 }}>
            {/* <img src={`/images/register_event/register-${i18n.language}.png`} style={{width:'100%'}} alt="registerevent"/> */}
            <Card style={{ boxShadow: 'none', border: 'none', background: 'transparent', padding: 25, paddingTop: 0 }}>
                {/* <center><WebpImg src="/images/logo-mistore.png" style={{ width:'20%', paddingLeft: 10, paddingRight: 10 }} alt="logo" /></center> */}
                <Typography style={{ fontSize: 21, textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase', color: '#eaeaea' }}>
                    {t('title.register')}
                </Typography>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 10px 0' }}>
                    <Link underline='none' to="/login" component={RouterLink} >
                        <Box style={{ color: theme.palette.silver.text, paddingLeft: 20, display: 'flex', alignItems: 'center' }}>
                            <FiChevronLeft style={{ marginRight: 10, color: '#fff' }} />
                            <Typography style={{ color: '#fff' }}>{t('button.login')}</Typography>
                        </Box>
                    </Link>
                    <Link underline='none' to="/TutorialRegister" target='blank' component={RouterLink} >
                        <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#333' }}>
                            <p>{t('tutorial.tutorial')}</p>
                        </div>
                    </Link>
                </div>
                {/* <Divider /> */}
                <CardContent style={{ padding: 20 }}>
                    {
                        successMessage ?
                            <Grid container spacing={2} justify="center" align="center" style={{ paddingTop: 50 }}>
                                <Grid item xs={12}>
                                    <CheckCircleOutlineIcon style={{ color: '#5fc75f', fontSize: 46 }} fontSize="large" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" style={{ textAlign: 'center', color: "white", fontSize: 28 }}>{successMessage}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Link style={{ textDecoration: 'none' }} to="/login" component={RouterLink} >
                                        <Button /* onClick={() => loginUser(dispatch)} */ fullWidth variant="contained" color="primary" size="large">{t('button.login')}</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                            :
                            <Box>
                                <Typography style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>{errorMessage}</Typography>
                                <form onSubmit={handleSubmit}>
                                    {
                                        step === 1 ?
                                            <>
                                                {nameInput}
                                                {usernameInput}
                                                {/* {emailInput} */}
                                                {/* {mobileInput} */}
                                                {/* {nricInput} */}
                                                {!refId ? referralInput
                                                    :
                                                    <Grid container spacing={3} justify="center">
                                                        <Grid item xs={12}>
                                                            <MuiThemeProvider theme={fieldStyle}>
                                                                <TextField style={{ color: '#fff', borderColor: '#fff' }} fullWidth label={t('register.referral')} variant="outlined" value={referral} type="text" InputProps={{ readOnly: true }} />
                                                            </MuiThemeProvider>
                                                        </Grid>
                                                    </Grid>
                                                    // null
                                                }
                                                {/* {autoPlacementInput} */}
                                                {/* {
                                                    autoPlacement ? null :
                                                        <>
                                                            {placementInput}
                                                            {positionInput}
                                                        </>
                                                } */}
                                                {passwordInput}
                                                {passwordConfirmationInput}
                                                {securityPasswordInput}
                                                {securityPasswordConfirmationInput}
                                                {/* {cryptoNetworkInput} */}
                                                {/* {cryptoAddressInput} */}
                                                <Grid container spacing={3} justify="center" style={{ padding: '0 15px' }}>
                                                    <Button fullWidth variant="contained" color="primary" style={{ borderRadius: 8, marginTop: 30, padding: '10px 15px' }} size="large" onClick={() => setStep(2)}>{t('button.next')}</Button>
                                                </Grid>
                                            </>
                                            : null
                                    }
                                    {
                                        step === 2 ? // verify email
                                            <>
                                                <div style={{ display: 'flex', width: '100%', alignItems: 'center', paddingBottom: 20 }}>
                                                    {emailInput}
                                                    <Button onClick={requestTac} variant="contained" color="primary" style={{ whiteSpace: 'nowrap', marginLeft: 15, }} > {timer > 0 ? timer : t('button.requestTac')}</Button>
                                                </div>
                                                {tacInput}
                                                <Grid container spacing={3} justify="center" style={{ padding: '0 15px' }}>
                                                    <Grid xs={6} style={{ paddingRight: 8 }}>
                                                        <Button fullWidth variant="outlined" color="primary" style={{ borderRadius: 8, marginTop: 30, padding: '10px 15px' }} size="large" onClick={() => setStep(1)}>{t('button.back')}</Button>
                                                    </Grid>
                                                    <Grid xs={6} style={{ paddingLeft: 8 }}>
                                                        <Button fullWidth variant="contained" color="primary" style={{ borderRadius: 8, marginTop: 30, padding: '10px 15px', '&:disable': { backgroundColor: '#d5c8e0', } }} size="large" onClick={validateDetails}>{t('button.register')}</Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            : null
                                    }
                                    {
                                        step === 3 ? // authenticator
                                            <>
                                                {
                                                    secret !== '' ?
                                                        <>
                                                            <Typography color="primary" variant="h6" >{t('profile.enable2FAoptional')}</Typography>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0', fontSize: 18 }}>
                                                                <img src={qrcode} alt="qrcode" />
                                                            </div>
                                                            <Typography color="primary" variant="h6" style={{ padding: '10px 0', fontSize: 18 }}>{t('profile.scan2FAQrcode') + " " + t('profile.insertSecret')}</Typography>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
                                                                <Typography color="primary" style={{ fontSize: 15 }}><b>{t('profile.secretKey', { secret: secret })}</b></Typography>
                                                            </div>
                                                            <Typography color="primary" variant="h6" style={{ padding: '10px 0', fontSize: 18 }}>{t('profile.verifyCode')}</Typography>
                                                            {authentication_codeInput}
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
                                                                <Typography color="primary" style={{ fontSize: 15 }}><b>{t('profile.register2faNote')}</b></Typography>
                                                            </div>
                                                            <Grid container spacing={3} justify="center" style={{ padding: '0 15px' }}>
                                                                <Grid xs={6} style={{ paddingRight: 8 }}>
                                                                    <Button fullWidth variant="outlined" color="primary" style={{ borderRadius: 8, marginTop: 30, padding: '10px 15px' }} size="large" onClick={() => setStep(2)}>{t('button.back')}</Button>
                                                                </Grid>
                                                                <Grid xs={6} style={{ paddingLeft: 8 }}>
                                                                    <Button onClic={() => (state.button = 1)} type='submit' fullWidth variant="contained" color="primary" style={{ borderRadius: 8, marginTop: 30, padding: '10px 15px', }} size="large">{t("button.enable2fa")}</Button>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3} justify="center" style={{ padding: '25px 15px' }}>
                                                                <Typography color="primary" style={{ fontSize: 15 }}><b>{t('or')}</b></Typography>
                                                            </Grid>
                                                            <Grid container spacing={3} justify="center" style={{ padding: '0 15px' }}>
                                                                <Button onClick={() => (state.button = 2)} type='submit' fullWidth variant="contained" color="primary" style={{ borderRadius: 8, padding: '10px 15px', }} size="large">{t("button.skip2fa")}</Button>
                                                            </Grid>
                                                        </>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                </form>
                            </Box>
                    }
                </CardContent>
            </Card>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    }
}));

// const textStyles = createMuiTheme({
//     overrides: {
//       MuiOutlinedInput: {
//         root: {
//           "& $notchedOutline": {
//             borderColor: "#fff",
//             backgroundColor:'##fff3'
//           },
//           "&:hover $notchedOutline": {
//             borderColor: "red"
//           },
//           "&$focused $notchedOutline": {
//             borderColor: "purple"
//           }
//         }
//       },
//       MuiInputBase: {
//         root: {
//             color:'#fff'
//         }
//       }
//     }
//   });

const fieldStyle = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                color: "#fff",
                "&$focused": {
                    color: theme.palette.primary.main
                },
                '&.$Mui-disabled': {
                    color: '#ffffff54'
                }
            },

        },
        MuiFormControl: {
            root: {
                backgroundColor: 'transparent',
                borderRadius: '8px 8px 0 0',
                "&:hover": {
                    backgroundColor: 'transparent',
                },
                "&$focused": {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: '#EAEAEA',
                "&$focused": {
                    color: theme.palette.primary.main
                },
                '&.$Mui-disabled': {
                    color: '#ffffff54'
                }
            }
        },
        MuiFilledInput: {
            underline: {
                '&:after': {
                    borderBottomColor: theme.palette.primary.main,
                },
            }
        },
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: "#5a5a5a",
                    backgroundColor: 'transparent'
                },
                "&:hover $notchedOutline": {
                    borderColor: theme.palette.primary.main
                },
                "&$focused $notchedOutline": {
                    borderColor: theme.palette.primary.main
                }
            }
        },
    },
});







