import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';
import { SELLER_URL } from '../configs/Config';
import _ from 'lodash';

import { makeStyles, useTheme, createMuiTheme, ThemeProvider, FormControlLabel, FormControl, Radio, RadioGroup, Checkbox, Typography,  Avatar, Link, Box, Accordion, AccordionDetails, AccordionSummary, Grid  } from '@material-ui/core';
import { PersonAdd, PersonOutlineRounded, MeetingRoomOutlined, PublicOutlined, ExpandMoreOutlined, NotificationsNoneRounded,  LinkOutlined, ChevronRightSharp, CheckCircle, AccountBalanceOutlined, AssignmentInd, ContactMailOutlined, LocalMall, AssignmentRounded, HomeWorkRounded, LinkRounded, AccountTreeOutlined, HowToReg } from '@material-ui/icons';
import { FiChevronRight, FiKey, FiPhone } from 'react-icons/fi';
import { FaTruck, FaUserEdit, FaDoorOpen, FaBoxOpen, FaCommentDots, FaWallet,FaVoicemail, FaChalkboardTeacher,FaTicketAlt } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg';
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';

import {  GiTeacher, } from 'react-icons/gi';
import {  BsInfoLg,BsFillFileTextFill } from 'react-icons/bs';
import { AiFillFileText, AiOutlineFileText} from 'react-icons/ai';
import {  MdCall, MdLocationOn} from 'react-icons/md';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import { changeLanguage as reduxChangeLanguage } from '../actions/GeneralActions';
import { getUrl, logoutUser } from '../helper/ApiAction';
import TitleBar from './Layouts/TitleBar';
import theme from '../Theme';
import WebpImg from './Layouts/WebpImg';
  
export default function Setting() {
    const { t, i18n } = useTranslation();
    const [valueLanguage, setLanguage] = useState(i18n.language);

    const { accessToken, role } = useSelector(state => state.general);
    const { fr_id, max_rank, username,web_path } = useSelector(state => state.user);
    console.log(web_path,'web_path')
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const history = useHistory();
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    const goToIshopping = () => {
        if(accessToken){
            getUrl(`/login-to/ishopping`).then(response => {
                setLoading(false);
                if(response.status === 1 && response.url){
                    window.open(response.url, '_blank').focus();
                }else{
                    addAlert(t('general.loginRedirectError'));
                }
            }).catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    return (

        <Box paddingBottom={5} style={{background: theme.palette.white.mobileBkg, paddingBottom:80, minHeight: '100%' }}>{/* className={styles.root} style={{ padding: '0 0 80px',}} */}
            {
                accessToken ?
            <div style={{padding:'10px 20px 0'}}>
                <TitleBar height="230px" title={t('profile.settings')} displayQr copyLink/>
            </div>
                :
                <div style={{padding:'10px 20px 20px'}}>
                    <Grid container spacing={1} justify="center" style={{padding:0, boxShadow:'0px 6px 11px 0 #00000026', borderRadius:15}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <AutoplaySlider
                            play={true}
                            cancelOnInteraction={false}
                            interval={3000}
                            autoplay={true}
                            bullets={false}
                            // media={[
                            //     { source: '/images/slides/slide1.png', },
                            // ]}
                            // style={{ width: '100%' }}
                            organicArrows={false}
                        >
                            {/* {
                                _.size(slideBanner) > 0 && slideBanner !== null ?
                                    _.map(slideBanner, (slideBannerLists) => {

                                        return (
                                            <div className="awssld__content" key={slideBannerLists.id} >
                                                <WebpImg src={slideBannerLists.file_name} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%' }} />
                                            </div>
                                        )
                                    }) :
                                    null
                            } */}
                            <div className="awssld__content">
                                <WebpImg src={`/images/slides/mainbanner-1.jpg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', borderRadius:15 }} />
                            </div>
                            <div className="awssld__content">
                                <WebpImg src={`/images/slides/mainbanner-2.jpg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', borderRadius: 15 }} />
                            </div>
                            <div className="awssld__content">
                                <WebpImg src={`/images/slides/mainbanner-3.jpg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', borderRadius: 15 }} />
                            </div>
                        </AutoplaySlider>
                    </Grid>
                </Grid>
                </div>
            }

                {/* //Account */}
                {/* <Box style={{padding:'18px 15px 0'}}>
                    <Typography style={{paddingBottom:15, color:theme.palette.silver.text}}>{t('title.account')}</Typography> */}
                    {/* {
                        _.size(max_rank) > 0 &&
                        <Link underline='none' to="/recruit" component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <PersonAdd fontSize="default" />
                                    </Avatar>
                                    <Typography style={{color:theme.palette.silver.text}}>{t('profile.recruit')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link>
                    } */}
                    {/* <Box onClick={() => goToIshopping()} >
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <LocalMall fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.goToIshopping')}</Typography>
                            </div>
                            <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                        </Box>
                    </Box> */}
                    {/* <Link underline='none' to="/profile-linking" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <LinkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.accountLink')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                    {/* <Link underline='none' to="/order" component={RouterLink} >
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <AssignmentRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('title.order')}</Typography>
                            </div>
                            <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                        </Box>
                    </Link> */}
                    {/* {
                        fr_id !=null && fr_id?
                        null:
                        <Link underline='none' to="/bindSubAccount" component={RouterLink} >
                             <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                                <div style={{display:'flex', alignItems:'center',}}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                        <LinkOutlined fontSize="default" />
                                    </Avatar>
                                    <Typography style={{color:theme.palette.silver.text}}>{t('profile.bindSubAccount')}</Typography>
                                </div>
                                <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                            </Box>
                        </Link>
                    } */}
                    {/* <Link underline='none' to="/kyc" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <AssignmentInd fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('title.kyc')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                    {/* <Link underline='none' to="/stakes" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <AssignmentInd fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('stake.stakes')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                </Box> */}

                {/* //Profile */}
                {
                    accessToken ?
                <Box style={{padding:'40px 15px 0', color:theme.palette.silver.text}}>
                    <Typography style={{paddingBottom:15, color:(accessToken ? "#939393" : "#939393")}}>{t('title.account')}</Typography>
                    {/* <Link underline='none' to={(accessToken ? "/profile" : "/login")} component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <FaUserEdit fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('profile.editProfile')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>                     */}
                    <Link underline='none' to={(accessToken ? "/profile-crypto-address" : "/login")} component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <FaWallet fontSize="default" style={{width:21}}/>
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('profile.cryptoAddress')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                    <Link underline='none' to={(accessToken ? "/profile-password" : "/login")} component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <FiKey fontSize="default" style={{width:21}}/>
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('profile.changePassword')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                    {
                        web_path ==''?''
                        :
                        <a href={web_path} target='blank' style={{textDecoration:'none'}} download="用户文件,txt">
                              {/* <Link underline='none' to={(accessToken ? web_path : "/login")} component={RouterLink} > */}
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    {/* <FiKey fontSize="default" style={{width:21}}/> */}
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('profile.downloadFile')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    {/* </Link> */}
                    </a>
                    }
                    
                    <Link underline='none' to={(accessToken ? "/profile-security-password" : "/login")}  component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <FiKey fontSize="default" style={{width:21}}/>
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('profile.changeSecurityPassword')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.silver.text, marginLeft:15}}/>
                        </Box>
                    </Link>
                    <Link underline='none' to={(accessToken ? "/profile-security-email" : "/login")}  component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <FaVoicemail fontSize="default" style={{width:21}}/>
                                    {/* <MailOutlineIcon size="small" /> */}
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('profile.verifyEmail')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.silver.text, marginLeft:15}}/>
                        </Box>
                    </Link>
                    {/* <Link underline='none' to="/profile-linking" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <LinkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.accountLink')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                    {/* <Link underline='none' to={(accessToken ? "/profile-two-factor-authentication" : "/login")}  component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <PhonelinkLockIcon fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('profile.2FA')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.silver.text, marginLeft:15}}/>
                        </Box>
                    </Link> */}

                    {/* <Link underline='none' to="/placement-setting" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <AccountTreeOutlined fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.placementSetting')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                    {/* <Link underline='none' to="/profile-bank-info" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <AccountBalanceOutlined fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.editBankInfo')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                    {/* <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <MdLocationOn fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.address')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                </Box>
                    : null
                }
                
                {/* //Annoucements */}
                {/* <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}>
                    <Typography style={{paddingBottom:15}}>{t('title.announcements')}</Typography>

                   
                    <Link underline='none' to="/announcement" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <NotificationsNoneRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('title.announcements')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                  
                </Box> */}

                {/* //Support */}
                {/* <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}> */}
                    {/* <Typography style={{paddingBottom:15}}>{t('support.support')}</Typography>   */}
                    {/* <Link underline='none' to="/policies" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <BsFillFileTextFill fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.policies')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>   */}       
                    {/* <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.tof')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.refundAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.privacyAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>

                    <Link underline='none' to="/profile-address" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HomeWorkRounded fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.merchantServicesAgreement')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}

                    {/* <Link underline='none' to="/contactUs" component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <MdCall fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('support.contactUs')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link> */}
                    {/* {_.size(_.intersection(role, ['merchant', 'super-merchant'])) == 0 ? 
                    <Link underline='none' onClick={() => window.open(`${SELLER_URL}sellers/register?username=${username}`)} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HowToReg fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.becomeSeller')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>: null} */}
                    {/* {_.size(_.intersection(role, ['merchant', 'super-merchant'])) > 0 ? 
                    <Link underline='none' onClick={() => window.open(`${SELLER_URL}login`)} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                    <HowToReg fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.silver.text}}>{t('profile.sellerSite')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>: null} */}
                {/* </Box> */}

                {/* //Setting */}
                <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}>
                    <Typography style={{paddingBottom:15}}>{t('footer.helpCenter')}</Typography>
                    <Link underline='none' to={"/tutorial"} component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <FaChalkboardTeacher fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('tutorial.tutorial')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>  
                    {/* { 
                        accessToken && 
                        <Link underline='none' to={"/ticket"} component={RouterLink} >
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                                <div style={{ display: 'flex', alignItems: 'center', }}>
                                    <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background: theme.palette.secondary.main }}>
                                        <FaTicketAlt fontSize="default" />
                                    </Avatar>
                                    <Typography style={{ color: theme.palette.secondary.main }}>{t('title.ticket')}</Typography>
                                </div>
                                <ChevronRightSharp style={{ color: theme.palette.secondary.main, marginLeft: 15 }} />
                            </Box>
                        </Link> 
                    } */}
                    <Link underline='none' to={"/contactUs"} component={RouterLink} >
                        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <FaCommentDots fontSize="default" />
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('support.contactUs')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </Link>
                    <a href='https://about.ledgerblock.net' target='blank' style={{textDecoration:'none'}}>
                    <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:15}}>
                            <div style={{display:'flex', alignItems:'center',}}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <img src="/images/logo-icon-white.png" alt="logo" style={{width:'100%', padding:5}}/>
                                </Avatar>
                                <Typography style={{color:theme.palette.secondary.main}}>{t('support.aboutUs')}</Typography>
                            </div>
                            <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                        </Box>
                    </a>
                    
                </Box>
         
                {/* //Setting */}
                <Box style={{padding:'18px 15px 0', color:theme.palette.silver.text}}>
                    <Typography style={{paddingBottom:15}}>{t('profile.settings')}</Typography>           
                
                <ThemeProvider theme={accordingStyles}>
                    <Accordion style={{ boxShadow: 'none', backgroundColor: 'transparent', padding: 0, margin: 0 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ padding: 0, margin: 0 }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:(accessToken ? "#10242f" : "#10242f") }}>
                                    <PublicOutlined fontSize="default" />
                                </Avatar>
                                <Typography style={{ color: theme.palette.secondary.main, }}>{t('home.language')}</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{padding:'8px 55px 16px',}}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="language" name="language" value={valueLanguage}>
                                    
                                {/* <FormControlLabel
                                    value="end"
                                    label="English"
                                    control={<Checkbox color="primary" />}
                                    labelPlacement="end"
                                    onClick={() => changeLanguage('en')}
                                />
                                <FormControlLabel
                                    value="end"
                                    label="中文"
                                    control={<Checkbox color="primary" />}
                                    labelPlacement="end"
                                    onClick={() => changeLanguage('cn')}
                                /> */}
                                <FormControlLabel
                                    onChange={() => changeLanguage('cn')}
                                    value="cn"
                                    control={<Radio color="primary"  checkedIcon={<CheckCircle color="secondary" />} />}
                                    label="简"
                                />
                                <FormControlLabel
                                    onChange={() => changeLanguage('tw')}
                                    value="tw"
                                    control={<Radio color="primary"  checkedIcon={<CheckCircle color="secondary" />} />}
                                    label="繁"
                                />
                                <FormControlLabel
                                    onChange={() => changeLanguage('en')}
                                    value="en"
                                    control={<Radio color="primary"  checkedIcon={<CheckCircle color="secondary" />}/>}
                                    label="English"
                                />
                                {/* <FormControlLabel
                                    onChange={() => changeLanguage('ms')}
                                    value="ms"
                                    control={<Radio color="primary"  checkedIcon={<CheckCircle color="secondary" />} />}
                                    label="Malay"
                                />
                                <FormControlLabel
                                    onChange={() => changeLanguage('id')}
                                    value="id"
                                    control={<Radio color="primary"  checkedIcon={<CheckCircle color="secondary" />} />}
                                    label="Indonesia"
                                /> */}
                                </RadioGroup>
                                
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                </ThemeProvider>
                {/* <Link underline='none' to="/contactUs" component={RouterLink} >
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                        <div style={{ display: 'flex', alignItems: 'center', }}>
                            <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15 }}>
                                <ContactMailOutlined fontSize="default" />
                            </Avatar>
                            <Typography style={{ color: theme.palette.silver.text }}>{t('footer.contactUs')}</Typography>
                        </div>
                        <ChevronRightSharp style={{color:theme.palette.secondary.main, marginLeft:15}}/>
                    </Box>
                </Link> */}

                    {
                accessToken ?
                    <Link underline='none'  component="button"  onClick={() => {
                        logoutUser(dispatch);
                        // handleMenuClose();
                        history.replace('/login');
                    }}
                    >
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    {/* <MeetingRoomOutlined fontSize="default" /> */}
                                    <FaDoorOpen fontSize="default" />
                                    
                                </Avatar>
                                <Typography style={{ color: theme.palette.secondary.main }}>{t('button.logout')}</Typography>
                            </Box>
                            {/* <FiChevronRight style={{color:theme.palette.secondary.main, marginLeft:15}}/> */}
                        </Box>
                    </Link>
                     :
                     <Link underline='none' to="/login" component={RouterLink} >
                     <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 15 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', }}>
                                <Avatar variant="circle" className={styles.iconColor} style={{ marginRight: 15, background:theme.palette.secondary.main }}>
                                    <MeetingRoomOutlined fontSize="default" />
                                </Avatar>
                                <Typography style={{ color: theme.palette.secondary.main }}>{t('button.login')}</Typography>
                            </Box>
                            {/* <FiChevronRight style={{color:theme.palette.secondary.main, marginLeft:15}}/> */}
                        </Box>
                        </Link>
                }
                </Box>
        </Box>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
       
    },
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
          backgroundColor: "transparent"
        }
      },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    iconColor: {
        padding: 5,
        // backgroundColor: theme.palette.profile.user,
        // backgroundColor:'#d7d7d7',
        backgroundColor:'#c9c9c9',
        color:"#fff"
    },
   

}));


  const accordingStyles = createMuiTheme({
    overrides: {
        MuiAccordion: {
            root: {
                '&::before':{
                    backgroundColor:'transparent'
                }
                
            }
        },
        MuiAccordionSummary:{
            content:{
                marginTop:0,
                "&$expanded":{
                    marginTop:0,
                    marginBottom:5,
                } 
            },  
            root:{
                "&$expanded":{
                    minHeight:'unset',
                } 
            },                  
        },
        MuiIconButton:{
            root:{
                color: theme.palette.secondary.main,
            }
            
        },
    }
});

