import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { storeProfile } from "../actions/UserActions";

import { Typography, Grid, Box, List, ListItem, Divider } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { putUrl } from "../helper/ApiAction";

import useNotificationLoading from "../helper/useNotificationLoading";

import TitleBarWhite from "./Layouts/TitleBarWhite";

export default function PlacementSetting() {
	const { t } = useTranslation();

	const { username, additional_info } = useSelector((state) => state.user);
	const { addAlert, setLoading } = useNotificationLoading();
	const theme = useTheme();

	const [additionalInfoFormState, setAdditionalInfoFormState] = useState({
		...additional_info,
	});

	const styles = useStyles();
	const dispatch = useDispatch();

	const handleAutoPlacementChange = (event) => {
		setLoading(true);

		if (
			event.target.name == "auto_placement_account" &&
			event.target.value == "0"
		) {
			setAdditionalInfoFormState({
				...additionalInfoFormState,
				[event.target.name]: event.target.value,
				auto_placement_position: "0",
			});
		} else {
			setAdditionalInfoFormState({
				...additionalInfoFormState,
				[event.target.name]: event.target.value,
			});
		}
		putUrl("user/additional_info", {
			[event.target.name]: event.target.value,
		}).then((result) => {
			setLoading(false);
			addAlert(result.message, result.status ? "success" : "error");
			if (result.status) {
				dispatch(storeProfile(result.data));
			}
		});
	};

	return (
		<Box
			className={styles.root}
			style={{
				background: theme.palette.white.mobileBkg,
				padding: "0 0 80px",
				minHeight: "100%",
			}}
		>
			<div
				style={{
					position: "fixed",
					width: 600,
					maxWidth: "100%",
					zIndex: 2,
				}}
			>
				<TitleBarWhite
					height={0}
					title={t("profile.profile")}
					displayInfo={false}
					back
				/>
			</div>

			<Grid
				container
				spacing={1}
				justify="center"
				style={{ padding: "40px 15px 0" }}
			>
				<Grid item xs={12}>
					<List
						className={styles.listStyle}
						style={{
							backgroundColor: "transparent",
							boxShadow: "none",
						}}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ListItem
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItem: "center",
									}}
								>
									<Box
										style={{
											color: theme.palette.primary.main,
										}}
									>
										<Typography variant="h6">
											{t("profile.placementSetting")}
										</Typography>
										{/* <Typography
											variant="caption"
											color="textSecondary"
										>
											{t("profile.placementSetting")}
										</Typography> */}
									</Box>
								</ListItem>

								<Divider variant="middle" light />
								<ListItem
									className={styles.listPaddingVertical}
								>
									<Grid
										container
										spacing={2}
										alignItems="center"
									>
										<Grid item xs={3} sm={2}>
											<Box
												display="flex"
												justifyContent="flex-end"
											>
												<Typography
													variant="caption"
													color="textSecondary"
												>
													{t(
														"profile.autoPlacementAccount"
													)}
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={9} sm={8}>
											<RadioGroup
												aria-label="auto_placement_account"
												row
												name="auto_placement_account"
												value={
													additionalInfoFormState.auto_placement_account
												}
												onChange={
													handleAutoPlacementChange
												}
											>
												<FormControlLabel
													value="0"
													control={<Radio />}
													label={t("profile.auto")}
												/>
												<FormControlLabel
													value="1"
													control={<Radio />}
													label={
														username.toUpperCase() +
														"-2"
													}
												/>
												<FormControlLabel
													value="2"
													control={<Radio />}
													label={
														username.toUpperCase() +
														"-3"
													}
												/>
											</RadioGroup>
										</Grid>
									</Grid>
								</ListItem>
								<ListItem
									className={styles.listPaddingVertical}
								>
									<Grid
										container
										spacing={2}
										alignItems="center"
									>
										<Grid item xs={3} sm={2}>
											<Box
												display="flex"
												justifyContent="flex-end"
											>
												<Typography
													variant="caption"
													color="textSecondary"
												>
													{t(
														"profile.autoPlacementPosition"
													)}
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={9} sm={8}>
											<RadioGroup
												aria-label="auto_placement_position"
												row
												name="auto_placement_position"
												value={
													additionalInfoFormState.auto_placement_position
												}
												onChange={
													handleAutoPlacementChange
												}
											>
												<FormControlLabel
													value="0"
													control={<Radio />}
													label={t("profile.auto")}
												/>
												<FormControlLabel
													value="1"
													control={<Radio />}
													label={t("profile.left")}
													disabled={
														additionalInfoFormState.auto_placement_account ==
														"0"
													}
												/>
												<FormControlLabel
													value="2"
													control={<Radio />}
													label={t("profile.right")}
													disabled={
														additionalInfoFormState.auto_placement_account ==
														"0"
													}
												/>
											</RadioGroup>
										</Grid>
									</Grid>
								</ListItem>
							</Grid>
						</Grid>
					</List>
				</Grid>
			</Grid>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	listPaddingVertical: {
		paddingTop: 15,
		paddingBottom: 15,
	},
	listStyle: {
		backgroundColor: "#fff",
		marginBottom: 20,
	},
}));
