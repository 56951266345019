import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, makeStyles, Box, Paper, Typography, FormControl, List, ListItem, ListItemText, useTheme, Select, MenuItem } from '@material-ui/core';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import TitleBarWhite from './Layouts/TitleBarWhite';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';
import theme from '../Theme';
const STATUS_COLORS = {
    default: theme.palette.primary.main, //'#FF5532',
    // 0: //Pending
    1: '#3F686B', //Approved
    3: theme.palette.gray.textTitle, //'#979797',//Rejected
}


export default function TopupHistory() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [topupHistory, setTopupHistory] = useState([]);
    const [totalTopup, setTotalTopup] = useState(0);

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();

    const isMountedRef = useRef(null);

    
    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        console.log(walletList,existWalletBalance)
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        setTopupHistory([]);
        setTotalPage(0);
        setPage(1);
    }, [wallet]);

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo) > 0) {
            let param = {page: page, per_page:10, wallet_id: walletInfo.id};
            getUrl(`default/get-topup-details`, param).then(response => {
                if(response.code == 0 && isMountedRef.current) {
                    const { last_page, data } = response.data;
                    setTotalPage(last_page);
                    setTopupHistory(_.concat(topupHistory, data));
                    setTotalTopup(response.data.total_topup);
                }
            }).catch(err => {
                addAlert(JSON.stringify(err.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [walletInfo, page]);

    const changeWallet = ({ target }) => {
        history.push(`/wallet-topups/${target.value}`);
    }

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const openDialog = dialogInfo => {
        setDialogInfo(dialogInfo);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogInfo([]);
        setDialogOpen(false);
    }

    return (
        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
            <TitleBarWhite title={ t('title.withdrawalHistory') } back displayInfo={false}/>

            <div className={classes.walletCard}>
                {/* <FiLayers style={{fontSize:39, color:theme.palette.primary.main}}/> */}
                <img src='/images/general/icon/usdt.png' alt="rank" className={classes.walletIconImg} />
                <div style={{ color: theme.palette.white.text, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                    <p className={classes.walletBalanceTitle}>{t('topup.totalTopup')} { _.size(walletInfo) > 0 ? walletInfo.wallet_name : '-' }</p>
                    <p className={classes.walletAmount}><b>{ currencyFormat(totalTopup) }</b></p>
                </div>
            </div>

            {
                _.size(walletList) >= 2 &&
                <Paper elevation={3} classes={{ root: classes.paperContainer }} style={{position:'relative'}}>
                    <Box className={classes.boxContainer} paddingX={1}>
                        <MuiThemeProvider theme={selectStyle}>
                            <FormControl className={classes.formControl}>
                                <Select
                                    value={wallet}
                                    name="wallet"
                                    onChange={changeWallet}
                                    label={t('transaction.wallet')}
                                >
                                    {_.map(walletList, walletItem => {
                                        return walletItem.allow_topup ? <MenuItem key={walletItem.code} value={walletItem.code}>{walletItem.wallet_name}</MenuItem> : null
                                    })}
                                </Select>
                            </FormControl>
                        </MuiThemeProvider>
                    </Box>
                </Paper>
            }
            <Box padding={2}>
                <List className={classes.listRoot}>
                    { _.map(topupHistory, transactionItem => (
                        <TransactionItem key={transactionItem.id} data={transactionItem} openDialog={openDialog} />
                    ))}
                </List>
                { page < totalPage ?
                    <Button onClick={ loadTransaction } fullWidth>{ t('transaction.loadMore') }</Button>
                    : <Typography style={{ textAlign: 'center', paddingTop:6 }}>{ t('transaction.endOfList') }</Typography>
                }
            </Box>
            <MuiThemeProvider theme={DialogStyle}>
            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                onClose={closeDialog}
            >
                    <div style={{background:'#fff'}}>
                        <div style={{ background: STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default']}} className={classes.statusDialog}>
                        <Typography style={{ fontSize: 14, color: '#fff', letterSpacing:1 }}><b>{dialogInfo?.status_display?.[i18n.language] || '-'}</b></Typography>
                        </div>
                    </div>
                <DialogContent >
                    
                    <Box style={{textAlign:'center', paddingTop:15}}>
                        <Typography style={{ fontWeight: 'bold', lineHeight:1.0, fontSize: 30, color: STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default'], }}>{ `${currencyFormat((parseFloat(dialogInfo.amount) * 100) / 100 || 0)}` }</Typography>
                        <Typography style={{ textTransform: 'uppercase', fontSize: 14 }}>{ t('topup.amount') }</Typography>
                    </Box>
                    {/* <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{ t('topup.status') }</Typography>
                        <Typography style={{ fontSize: 14, color: STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default'] }}>{ dialogInfo?.status_display?.[i18n.language] || '-' }</Typography>
                    </Box> */}
                    <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1} >
                        <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{ t('topup.paymentMethod') }</Typography>
                        {/* <Typography style={{ fontSize: 14 }}>{ t(`topup.${dialogInfo.payment_method}`) } - <span style={{wordBreak:'break-all'}}>{dialogInfo?.payment_details?.['value']}</span></Typography> */}
                        <Typography style={{ fontSize: 14 }}><span style={{wordBreak:'break-all'}}>{dialogInfo?.payment_details?.['value']}</span></Typography>
                    </Box>
                    <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{ t('transaction.dateTime') }</Typography>
                        <Typography style={{ fontSize: 14 }}>{ dialogInfo.created_at }</Typography>
                    </Box>
                    <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 14, }}>{ t('transaction.remark') }</Typography>
                        <Typography style={{ fontSize: 14, }}>{ dialogInfo.remark || "-" }</Typography>
                    </Box>
                    <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                        
                            <div style={{lineHeight:0}}>
                                <img src={dialogInfo.path} style={{width:'100%', background:'transparent'}}/>
                            </div>
                        {/* <img src="{dialogInfo.path}" alt="" /> */}
                    </Box>
                        <div style={{width: 245, margin: '0 auto'}}>
                        <Button variant="contained" classes={{ root: classes.buttonRoot }} style={{ backgroundColor: STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default'], width: '100%', margin: '0 auto' }} onClick={closeDialog}>
                            {t('transaction.done')}
                        </Button>
                        </div>
                </DialogContent>
                    <div style={{lineHeight:0}}>
                        <img src="/images/general/receipt2.png" alt="receipt" style={{width:'100%', background:'transparent'}}/>
                    </div>
            </Dialog>
            </MuiThemeProvider>
        </Box>
    )
}

const TransactionItem = ({ data, openDialog }) => {
    const { amount, remark, status_display, created_at, payment_details, status } = data;

    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    return (
       <>
        {/* <ListItem divider onClick={() => openDialog(data)} classes={{ root: classes.listItemRoot }} display="flex" alignItems="center" style={{padding:'10px 0'}} >
            <Box className={classes.listItemTextRoot}>
                <ListItemText
                    style={{width:'100%'}}
                    classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
                    primary={ t('topup.walletTopup') }
                    secondary={
                        <>
                        <Typography
                            component="p"
                            variant="body2"
                            className={classes.remarkStyle}
                        >
                            { `${t('topup.status')}: ${status_display[i18n.language] || '-'}`}
                        </Typography>
                        <Typography className='txt_truncate'>{payment_details?.['value']}</Typography>
                        </>
                    }
                />
                <Typography
                    component="span"
                    variant="body2"
                    style={{ color: theme.palette.silver.text, fontSize: 11 }}
                >
                    {created_at}
                </Typography>
            </Box>
            <ListItemText
                primary={ `${currencyFormat((amount * 100) / 100)}` }
                classes={{ root: classes.listItemAmountRoot, primary: classes.listItemAmountPrimary }}
                style={{ color: theme.palette.secondary.main, whiteSpace:'nowrap', width:85 }}
            />
        </ListItem> */}
            <Grid onClick={() => openDialog(data)} container spacing={2} style={{borderBottom:'1px dashed #c3c3c3', paddingTop:13}}>
                <Grid item xs={8}>
                    <p className={classes.listItemTextPrimary} style={{color:STATUS_COLORS[STATUS_COLORS[status] ? status : 'default'], }}><b>{t('topup.walletTopup')}</b></p>
                    <Typography
                        component="p"
                        variant="body2"
                        className={classes.remarkStyle}
                        style={{paddingTop:3}}
                    >
                        {`${t('topup.status')}: ${status_display[i18n.language] || '-'}`}
                        {/* {`${t('topup.status')}: '-'`} */}
                    </Typography>
                    <Typography className={classes.remarkStyle} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display:'block' }}>{payment_details?.['value']}</Typography>
                    <Typography
                        component="span"
                        variant="body2"
                        style={{ color: theme.palette.silver.text, fontSize: 11 }}
                    >
                        {created_at}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <p className={classes.listItemAmountPrimary} style={{ color: theme.palette.secondary.main,  textAlign: 'right' }}>{`${currencyFormat((amount * 100) / 100)}`}</p>
                </Grid>
            </Grid>
        </>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -38,
        padding: 10,
        borderRadius: 10,
        background:theme.palette.primary.main
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    buttonRoot: {
        // backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 10,
        marginTop: 15, 
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    remarkStyle:{
        color: theme.palette.silver.text,
        fontSize: 14,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        width:'100%',
        overflow:'hidden'
    },
    balanceStyle: {
        fontSize: 30, 
        color: theme.palette.primary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white, 
        fontSize: 11, 
        letterSpacing: 1 
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    listRoot: {
        width: '100%',
    },
    listItemTextRoot: {
        flex: 4
    },
    listItemAmountRoot: {
        // flex: 1,
        // display: 'flex',
        // justifyContent: 'flex-end',
        //alignSelf: 'center',
        textAlign:'right',
    },
    listItemAmountPrimary: {
        fontWeight: 'bold',
        fontSize: 14
    },
    listItemTextPrimary: {
        fontSize: 14,
    },
    listItemTextSecondary: {
        fontSize: 10,
    },
    listItemRoot: {
        cursor: 'pointer'
    },
    walletCard: {
        height: 183, 
        background: theme.palette.secondary.main,
        borderRadius: 15,
        width: '100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        paddingBottom:14,
        position:'relative',
        [theme.breakpoints.down('sm')]: {
            height: 163, 
        },
    },
    walletIconImg: {
        width:63,
        [theme.breakpoints.down('sm')]: {
            width:43,
        },
    },
    walletBalanceTitle: {
        textTransform:'uppercase',
        fontSize:17,
        paddingBottom:2,
        [theme.breakpoints.down('sm')]: {
            fontSize:13,
        },
    },
    walletAmount: {
        fontSize:34,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize:25,
        },
    },
    statusDialog: {
        padding: ' 10px 15px',
        textTransform: 'uppercase',
        textAlign: 'center',
        width: 151,
        margin: '0 auto',
        borderRadius: '0 0 15px 15px',
    }
}));

const selectStyle = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                color: "#fff",
                "&$focused": {
                    color: '#fff'
                    },
                    '&.$Mui-disabled': {
                        color: '#fff'
                      }
            },
           
        },
        MuiInput: {
            underline: {
                '&::before': {
                    borderBottom: '1px solid #fff'
                },
                '&::after': {
                    borderBottom: '2px solid #fff'
                },
                "&:not(.Mui-disabled):hover::before": {
                    borderColor: "#fff"
                },
            },
        },
        MuiIconButton: {
            root: {
                color:'#c2c2c2',
                "&$focused": {
                    color: theme.palette.primary.main
                    },
            }
        },
        MuiSelect: {
            icon: {
                color:'#fff'
            },
            select: {
                "&$focused": {
                    backgroundColor: 'transparent'
                    },
            }
        }
        
    },
});

const DialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiPaper:{
            root:{
                backgroundColor:'transparent'
            },
            elevation24:{
                boxShadow:'none',
                WebkitFilter: 'drop-shadow(2px 7px 10px #00000070)',
                filter: 'drop-shadow(2px 7px 10px #00000070)',
            }
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh',
                padding: '15px 0 35px',
                borderRadius: '15px 15px 0 0',
            },
            paperFullWidth: {
                width: 400,
                maxWidth: '85%',
                margin: '0 auto',
                padding:0
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 30px',
                background:'#fff'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                background: '#0008',
                borderRadius: 48,
                minWidth: 'unset',
                padding: 0,
            }
        }
        
    },
});