import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import _ from "lodash";

import { updateUserOrigin } from '../actions/GeneralActions';
import useGeoLocation from "react-ipgeolocation";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";

import { APP_SITE, OTHER_LOCAL_URL } from "../configs/Config";

export default function RedirectSite() {
	const { t } = useTranslation();
	const { userOrigin } = useSelector((state) => state.general);
    const dispatch = useDispatch();
    const userLocation = useGeoLocation();

	const [open, setOpen] = useState(false);

    useEffect(() => {
        if(userLocation.isLoading === false && userLocation.error === false && userLocation.country){
            if(userOrigin === ""){
                if((_.includes(["CN", "HK", "MO", "TW"], userLocation.country) && APP_SITE !== "CN") || (!_.includes(["CN", "HK", "MO", "TW"], userLocation.country) && APP_SITE === "CN")){
                    setOpen(true);
                }else{
                    // store user location
                    dispatch(updateUserOrigin(userLocation.country));
                }
            }
            /* else if(userOrigin && userOrigin !== userLocation.country){
                console.log('why not same ar? you travel to other country?');
            } */
        }
    }, [userLocation.isLoading, userLocation.error, userLocation.country])
    
    const goOtherSite = () => {
        if ((_.includes(["CN", "HK", "MO", "TW"], userLocation.country) && APP_SITE !== "CN") || (!_.includes(["CN", "HK", "MO", "TW"], userLocation.country) && APP_SITE === "CN")) {
			window.location.href = OTHER_LOCAL_URL;
		}
    }

    const stayHere = () => {
        dispatch(updateUserOrigin(userLocation.country));
        setOpen(false);
    }

	return (
		<Dialog
			open={open}
			onClose={null}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
            style={{ zIndex: 1400 }}
		>
			{/* <DialogTitle id="alert-dialog-title">
				Location
			</DialogTitle> */}
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    {t('general.redirectToOtherSite')}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={stayHere} color="primary">
                    {t('button.stayHere')}
				</Button>
				<Button onClick={goOtherSite} color="primary" autoFocus>
                    {t('button.letsGo')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
