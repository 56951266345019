import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Box, Card, CardContent, Button, Divider, InputAdornment, Input, InputLabel, FormControl, IconButton, Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import QRCode from "easyqrcodejs";
import SearchIcon from '@material-ui/icons/Search';
import { OrgChartComponent } from './components/OrgChart';
import "./css/tree.css";

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';

export default function GenealogyPlacement() {
    const [state, setState] = useState({
        totalDirectDownlines: 0,
        totalDownlines: 0,
        searchName: ''
    });
    const [data, setData] = useState({});
    const [qrData, setQrData] = useState({username: "", position: 1});

    const history = useHistory();
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const { id, max_rank } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const urlUsername = useQuery().get('username');

    const isMountedRef = useRef(null);
    const qrcode = useRef(null);
    const qrModal = useRef(null);
    const chartRef = useRef();

    useEffect(() => {
        isMountedRef.current = true;

        const newUsername = urlUsername ? urlUsername : '';
        getUrl(`genealogy/placement`,{ username: newUsername }).then(response => {
            let { status, message, error, data } = response;
            if (isMountedRef.current && status) {
                setData(data.tree_data);
                setState({ ...state, searchName: newUsername, totalDirectDownlines: data.total_direct_downlines, totalDownlines: data.total_downlines });
            } else {
                if (error || message) {
                    if (_.size(error) > 0) {
                        _.map(error, (value, key) => {
                            message += "\n " + value[0];
                        })
                    }
                    addAlert(message);
                }
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });

        return () => isMountedRef.current = false;

    }, [urlUsername]);

    useEffect(() => {
        function showQr(e){
            if(_.size(max_rank) > 0 &&  e.target && e.target.className== 'empty-container' && e.target.getAttribute('username')){
                setQrData({username: e.target.getAttribute('username'), position: e.target.getAttribute('position')});
             }
        }
        document.addEventListener('click', showQr);

        return () => {
            document.removeEventListener("click", showQr);
       }
    }, []);

    useEffect(() => {
        if(qrData.username !== ""){
            const handleClick = (e) => {
                if (e.target && e.target === qrModal.current) {
                    return setQrData({...qrData, username: ""});
                }
            };
            window.addEventListener("click", handleClick);

            new QRCode(qrcode.current, {
                text: `${window.location.origin}/register?r=${btoa(id)}&placement=${qrData.username}&position=${qrData.position}`,
                drawer: 'svg',
                // logo: `/images/logo.png`,
                // logoBackgroundTransparent: true,
                // logoBackgroundColor: theme.palette.primary.main,
                backgroundImage: `/images/logo-mistore.png`,
                backgroundImageAlpha: 0.5,
                PO: theme.palette.primary.main,
                AI: theme.palette.primary.main,
                // title: name,
                // titleTop: 10,
                // titleColor: '#e53935',
                dotScale: 0.6
            });
            qrModal.current.style.display = "flex";
        }else{
            qrModal.current.style.display = "none";
        }
    }, [qrcode, qrData.username]);

    const copyShareLink = () => {
        var textField = document.createElement('textarea');
        textField.innerText = `${window.location.origin}/register?r=${btoa(id)}&placement=${qrData.username}&position=${qrData.position}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        addAlert(t('profile.copySuccess'), "success");
    };

    const onNodeClick = (nodeId) => {
        const selectedNode = _.find(data, { nodeId });
        if(_.size(selectedNode) && selectedNode.id !== null) {
            history.push(`/community/genealogyPlacement?username=${selectedNode.username}`);
            history.go();
        }
    }

    const redirectSearchUsername = () => {
        history.push(`/community/genealogyPlacement?username=${state.searchName}`);
        history.go();
    }

    return (
        <Box>
        { _.size(data) ?
            <>
                <FormControl fullWidth style={{ marginTop: 0 }}>
                    <InputLabel htmlFor="username-search">{t('genealogy.username')}</InputLabel>
                    <Input
                        id="username-search"
                        type="text"
                        value={state.searchName}
                        onChange={(event) => setState({ ...state, searchName: event.target.value })}
                        // onChange={(event)=>setUsername(event.target.value)}
                        onKeyDown={(e) => (e.keyCode == 13 && redirectSearchUsername())}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="search button"
                                    onClick={redirectSearchUsername}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Typography variant="caption">*{t('genealogy.clickToViewFurther')}</Typography>
                <Box border={"2px solid red"} style={{ pointerEvents: 'none' }}>
                    <OrgChartComponent
                        data={data}
                        ref={chartRef}
                        onNodeClick={onNodeClick}
                        max_rank={max_rank}
                    />
                </Box>
            </>
                :
            <Card style={{ border:'none'}} variant="outlined">
                <CardContent style={{ height: 400, borderRadius: 0 }}>
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Typography variant="h5">...</Typography>
                    </Box>
                </CardContent>
            </Card>
        }
        <div className={`${styles.qrModal}`} ref={qrModal}>
            <div className={`${styles.modalContent}`}>
                <span className="close" onClick={() => setQrData({...qrData, username: ""})} style={{ float: "right", fontSize: 24 }}>&times;</span>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <div ref={qrcode}></div>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Typography variant="caption">
                            {`${window.location.origin}/register?r=${btoa(id)}&placement=${qrData.username}&position=${qrData.position}`}
                        </Typography>
                    </Grid>
                    {document.queryCommandSupported('copy') &&
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Button variant="contained" color="secondary" onClick={copyShareLink}>{t('profile.copyLink')}</Button>
                        </Grid>
                    }
                </Grid>
            </div>
        </div>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    tree: {
        // height: 110,
        flexGrow: 1,
        // maxWidth: 400,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    labelText: {
        fontWeight: 'inherit',
        marginLeft: 10,
        textTransform: "lowercase"
    },
    labelSubText: {
        marginLeft: 10,
        fontStyle: 'italic'
    },
    qrModal: {
        display: 'none',/* Hidden by default */
        position: 'fixed',/* Stay in place */
        zIndex: 1,/* Sit on top */
        left: 0,
        top: 0,
        width: '100%',/* Full width */
        height: '100%',/* Full height */
        overflow: 'auto',/* Enable scroll if needed */
        backgroundColor: 'rgb(0,0,0)',/* Fallback color */
        backgroundColor: 'rgba(0,0,0,0.4)'/* Black w/ opacity */
    },
    modalContent: {
        backgroundColor: '#fefefe',
        margin: 'auto', /* centered */
        padding: 20,
        border: '1px solid #888',
        width: '80%', /* Could be more or less, depending on screen size */
        maxWidth: 600
    }
}));
