import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, withStyles, Box, Paper, Typography, InputAdornment, TextField, useTheme, Button, FormControl, FormLabel, FormControlLabel, RadioGroup, Link, Radio, FormHelperText, Divider, IconButton } from '@material-ui/core';

import TitleBarWhite from './Layouts/TitleBarWhite';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';
import { storeWalletBalance } from '../actions/UserActions';
import { FiChevronLeft } from 'react-icons/fi';
import WebpImg from './Layouts/WebpImg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const DEFAULT_ERROR_FIELD = { amount: "", security_password: "", transaction_hash: "", wallet: "", combination_id: "", authentication_code: "" };

export default function StakePurchase() {
    const [walletInfo, setWalletInfo] = useState(null);
    const [userWalletInfo, setUserWalletInfo] = useState(null);
    const [state, setState] = useState({ amount: "", payment_method: "wallet", payment_detail: "", transaction_hash: "", security_password: "", combination_id: "0", authentication_code: "", showPassword: false, packages: '' });
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [purchaseStatus, setPurchaseStatus] = useState("insert"); // insert -> x review -> result
    const [stakeSetting, setStakeSetting] = useState(null);
    const [paymentCombinationLabel, setPaymentCombinationLabel] = useState("");
    
    
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const wallet = "usdt";
    const { walletList, walletBalance, two_factor_auth } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
        two_factor_auth: state.user.two_factor_auth,
    }));
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
    }, [walletBalance, walletList]);

    useEffect(() => {
        isMountedRef.current = true;
        
        fetchSetting();
        refetchWallet();
        
        return () => isMountedRef.current = false;
    }, [addAlert])

    useEffect(() => {
        console.log("selected", state.combination_id);
        if(_.size(stakeSetting) > 0 && state.amount > 0){
            let _label = "";
            let t_label = "";
            let index_t = 0
            let _combination_id = stakeSetting.combination_payments[state.combination_id];
            _.map(_combination_id, (_percent, _wallet) => {
                let wallet = _.find(walletList, { code: _wallet });
                let _amount = 0;
                if(undefined == wallet){
                    if(state.combination_id >= 100){
                        t_label = "质押成功后，预计30分钟内升级，每个账户仅限购买一次直升";
                    }
                }else{
                    if(_wallet == "lbvv"){
                        // calculate using 2 decimals
                        _amount = Math.round(((parseFloat(state.amount) * (parseFloat(_percent)/100)) / stakeSetting['current_price']['lbvv']) * 100) / 100;
                    }else{
                        _amount = parseFloat(state.amount) * (parseFloat(_percent)/100);
                    }
                    let decimal_cal = 10**wallet.decimal;
                    _amount = Math.round(_amount*decimal_cal)/decimal_cal;
                    _label += _label ? " + " : "";
                    _label += wallet.wallet_name +": "+ _amount;
                }
            })
            console.log(_label,'_label')
            setPaymentCombinationLabel({t:t_label,t1:_label});
        }else{
            setPaymentCombinationLabel({t:'',t1:''});
        }

        if(_.size(stakeSetting) > 0 && state.packages === ""){
            if(stakeSetting?.packages?.aidil){
                setState({...state, packages: "aidil"})
            }
            if(stakeSetting?.packages?.pancake){
                setState({...state, packages: "pancake"})
            }

            if(stakeSetting?.packages?.reactive){
                setState({...state, packages: "reactive" })
            }
        }
        console.log('stakeSetting',stakeSetting)
        if(undefined != stakeSetting && undefined != stakeSetting.combination_payments[state.combination_id]){
            let money = 0;
            let readOnly = null
            if(stakeSetting.combination_payments[state.combination_id].type == 2){
                // 上锁
                money = stakeSetting.combination_payments[state.combination_id].money
                readOnly = true
                setState({ ...state, ['amount']: money,['readOnly']: readOnly });
            }else{
                // 解锁
                setState({ ...state,['readOnly']: readOnly });
            }
        }
    }, [state.combination_id, state.amount, state.readOnly, stakeSetting]);

    useEffect(() => {	
        if(state.packages !== "normal" && _.size(stakeSetting) > 0 && _.size(stakeSetting.packages) > 0){	
            setState({...state, amount: stakeSetting.packages[state.packages]})	
        }	
    }, [state.packages]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if(name === 'combination_id'){
            let money = 0
            
        }
        if(name === 'amount') {
            if(parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }
        setState({ ...state, [name]: newValue });
    }

    const purchaseStake = () => {
        setLoading(true);
        const { amount, payment_detail, payment_method, security_password, transaction_hash, combination_id, authentication_code, packages } = state;
        const purchaseData = {
            amount,
            // payment_detail,
            payment_method,
            transaction_hash,
            // wallet: walletInfo.code,
            security_password,
            combination_id,
            authentication_code,
            packages
        };
        setErrorField(DEFAULT_ERROR_FIELD);
        postUrl(`default/get-stakes`, purchaseData).then(response => {
            if(response.code == 0) {
                // const { created_at, transaction_id, data } = response;
                setPurchaseStatus('result');
                // setState({ ...state, id: transaction_id, dateTime: created_at });
                refetchWallet();
            } else {
                if(response.msg) {
                    addAlert(response.msg);
                }
                setPurchaseStatus('insert')
            }
        }).catch(error => {
            setPurchaseStatus('insert')
            addAlert(JSON.stringify(error.message));
        }).finally(() => {
            setLoading(false);
        })
    }

    const refetchWallet = () => {
        getUrl('default/balance').then(response => {
            
            if(response.code == 0) {
                dispatch(storeWalletBalance(response.data));
            }
            // if(response.status) {
            //     dispatch(storeWalletBalance(response.data));
            // }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    const fetchSetting = () => {
        getUrl('default/get-setting').then(response => {
            if(response.code == 0) {
                setStakeSetting(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    const combinationIdLabel = (_setting) => {
        let label = "";
        let percent = "";
        if(_setting.type == 2){
            return _setting.name
        }
        _.map(_setting, (_percent, _wallet) => {
            if(_wallet == 'type'){
                return true
            }
            let existWallet = _.find(walletList, { code: _wallet });
            console.log(existWallet,'existWallet')
            label += label ? " + " : "";
            label += existWallet?.wallet_name;
            percent += percent ? " + " : "";
            percent += _percent+"%";

        })
        return label+` (${percent})`;
    }

    const WALLET_COLORS = {
        usdt: 'linear-gradient(124deg, rgba(37,64,110,1) 0%, rgba(16,36,47,1) 100%)', 
        usdtr: 'linear-gradient(124deg, #37889e 0%, #2f6c88 100%)', 
        usdts: 'linear-gradient(124deg, #188fbe 0%, #2c439e 100%)', 
        lbpin: 'linear-gradient(124deg, #188fbe 0%, #2c439e 100%)', 
        lp:'linear-gradient(124deg, #ffc455 0%, #ffb858 100%)', 
        lbvv:'linear-gradient(124deg, rgba(37,64,110,1) 0%, rgba(16,36,47,1) 100%)', 
    }

    return (
        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <TitleBarWhite title={ t('tutorial.stakes') } back displayInfo={false}/>
                <Link underline='none' to="/TutorialStakes" component={RouterLink} >
                <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff' }}>
                    <p>{t('tutorial.tutorial')}</p>
                </div>
                </Link>
            </div>  
                        

            <Paper  classes={{ root: classes.paperContainer }}>
                { purchaseStatus === 'result' ?
                    <Box className={classes.boxContainer} style={{paddingBottom:25}}>
                        <WebpImg src="/images/general/stake-success.png" style={{ width: 196, }} alt=" logo" containerStyle={{ lineHeight: 0 }} />
                        <Typography variant="h6" style={{   textTransform:'uppercase', lineHeight:1.0, fontFamily: 'times-new-roman', fontWeight: 'bold', fontSize: 28, color:'#3F686B'}}>{ t('stake.successfullyPurchaseTitle') }</Typography>
                        <p style={{ fontSize: 19, color:theme.palette.silver.text, fontFamily:'times-new-roman', padding:'5px 0'}}><b>{t('stake.stakes')}: { `${ currencyFormat(state.amount) }`}</b></p>
                        <p variant="h6" style={{ fontSize: 14, color:theme.palette.silver.text}}>{ t('stake.successfullyPurchase') }</p>
                        <Typography variant="h6" style={{ ...styles.inputPropsStyle, color: theme.palette.secondary.main }}>
                            
                        </Typography>
                    </Box>
                : <Box >
                    
                    {state.payment_method == "wallet" ?
                        <div style={{paddingTop:40}}>
                            {/* <FiLayers style={{fontSize:39, color:theme.palette.primary.main}}/> */}
                            
                            {_.map(walletBalance, (wallet) => {
                                return(
                                <div key={wallet.code} className={classes.walletCard} style={{background:WALLET_COLORS[wallet.code]}}>
                                    <img src={`/images/general/icon/${wallet.code}.png`} alt="rank" className={classes.walletIconImg} />
                                    <div style={{ color: theme.palette.white.main, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                                        <p className={classes.walletBalanceTitle}>{wallet.wallet_name} {t('stake.balance')}</p>
                                        <p className={classes.walletAmount}><b>{currencyFormat(parseFloat(wallet.balance).toFixed(userWalletInfo?.decimal || 2) || 0)}</b></p>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                        : null}
                    <p className={classes.title}>{t('stake.purchaseStake') }</p>
                    <Box width="100%">
                        {_.size(stakeSetting) > 0 &&
                        // <FormControl component="fieldset" style={{marginTop: 10}}>
                        //     <FormLabel component="legend">{t('stake.paymentCombination')}</FormLabel>
                        //     <RadioGroup aria-label="Payment Combination" name="combination_id" value={state.combination_id} onChange={handleChange}>
                        //         {_.map(stakeSetting.combination_payments, (_setting, _combination_id) => (
                        //             <FormControlLabel key={_combination_id} value={_combination_id} control={<Radio />} label={combinationIdLabel(_setting)} />
                        //         ))}
                        //     </RadioGroup>
                        // </FormControl>
                            <>
                                {_.size(stakeSetting.packages) > 0 && 
                                    <TextField 
                                        select
                                        variant="outlined"
                                        value={state.packages}
                                        classes={{ root: classes.textFieldRoot }}
                                        label={ t('stake.package') }
                                        name="packages"  
                                        onChange={ handleChange }
                                        fullWidth
                                        error={ _.size(errorField.packages) ? true : false }
                                        helperText={ errorField.packages }
                                        FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                    >
                                        {_.map(stakeSetting.packages, (_setting, _package) => (
                                            <option key={_package} value={_package}>
                                                {t(`stake.${_package}`)}
                                            </option>
                                        ))}
                                    </TextField>
                                }
                                <TextField 
                                    select
                                    variant="outlined"
                                    value={state.combination_id}
                                    classes={{ root: classes.textFieldRoot }}
                                    label={ t('stake.paymentCombination') }
                                    name="combination_id"  
                                    onChange={ handleChange }
                                    fullWidth
                                    error={ _.size(errorField.combination_id) ? true : false }
                                    helperText={ errorField.combination_id }
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option value={0}>
                                        {t(`general.pleaseSelect`)}
                                    </option>
                                    {_.map(stakeSetting.combination_payments, (_setting, _combination_id) => (
                                        <option key={_combination_id} value={_combination_id}>
                                            {combinationIdLabel(_setting)}
                                        </option>
                                    ))}
                                </TextField>
                            </>
                        }
                        <TextField 
                            type="number"
                            variant="outlined"
                            value={state.amount}
                            name="amount"          
                            onChange={ handleChange }
                            classes={{ root: classes.textFieldRoot }}
                            InputProps={{ readOnly: (!_.includes(["", "normal"], state.packages)|| purchaseStatus === 'review'||state.readOnly) ? true : false }}
                            inputProps={{ step: "100", min: "100", max: "3000"}}
                            label={ t('stake.amount') }
                            fullWidth
                            error={ _.size(errorField.amount) ? true : false }
                            helperText={ errorField.amount }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                        />
                        <FormHelperText>{paymentCombinationLabel.t1}</FormHelperText>
                        <FormHelperText>{paymentCombinationLabel.t}</FormHelperText>
                        {/* <FormControl component="fieldset">
                            <FormLabel component="legend">{ t('stake.paymentMethod') }</FormLabel>
                            <RadioGroup aria-label="payment_method" name="payment_method" value={state.payment_method} onChange={handleChange}>
                                <FormControlLabel value="crypto" control={<Radio />} label={ t('stake.crypto') } />
                                <FormControlLabel value="wallet" control={<Radio />} label={ t('stake.wallet') } />
                            </RadioGroup>
                        </FormControl> */}

                           
                        {state.payment_method == "crypto" ? 
                            <>
                                    <Typography>TRC20: idkwhattheformatshouldlooklike</Typography>
                                <TextField 
                                    type="text"
                                    variant="outlined"
                                    value={state.transaction_hash}
                                    classes={{ root: classes.textFieldRoot }}
                                    label={ t('stake.transactionHash') }
                                    name="transaction_hash"  
                                    onChange={ handleChange }
                                    fullWidth
                                    error={ _.size(errorField.transaction_hash) ? true : false }
                                    helperText={ errorField.transaction_hash }
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                />
                            </>
                        : null}
                        {/* {state.payment_method == "wallet" ? 
                            <TextField 
                                type="text"
                                variant="outlined"
                                value={`${walletInfo?.wallet_name} (${t('stake.balance')}: ${currencyFormat(parseFloat(userWalletInfo?.balance).toFixed(userWalletInfo?.decimal || 2) || 0)})`}
                                name="wallet"          
                                // onChange={ handleChange }   
                                classes={{ root: classes.textFieldRoot }}
                                label={ t('stake.wallet') }
                                InputProps={{ readOnly: true }}
                                fullWidth
                                error={ _.size(errorField.wallet) ? true : false }
                                helperText={ errorField.wallet }
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            />
                        : null} */}
                            {
                                two_factor_auth === 1 && false ?
                                <>
                                {
                                    state.payment_method == "wallet" ?
                                        <TextField
                                            type={state.showPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            value={state.security_password}
                                            name="security_password"
                                            onChange={handleChange}
                                            classes={{
                                                root: classes.textFieldRoot,
                                            }}
                                            InputProps={{
                                                readOnly: purchaseStatus === 'review' ? true : false,
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                            label={t('stake.security_password')}
                                            error={_.size(errorField.security_password) ? true : false}
                                            helperText={errorField.security_password}
                                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                            fullWidth
                                        />
                                        : null
                                }
                                    <TextField
                                        type="string"
                                        variant="outlined"
                                        value={state.authentication_code}
                                        name="authentication_code"
                                        onChange={handleChange}
                                        classes={{
                                            root: classes.textFieldRoot,
                                        }}
                                        label={t('profile.authenticationCode')}
                                        error={_.size(errorField.authentication_code) ? true : false}
                                        helperText={errorField.authentication_code}
                                        FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                        fullWidth
                                    />
                                    <FormHelperText>{t('general.2FACaption')}</FormHelperText>
                                </>
                            : 
                            <>
                                {
                                    state.payment_method == "wallet" ?
                                        <TextField
                                            type={state.showPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            value={state.security_password}
                                            name="security_password"
                                            onChange={handleChange}
                                            classes={{
                                                root: classes.textFieldRoot,
                                            }}
                                            InputProps={{
                                                readOnly: purchaseStatus === 'review' ? true : false,
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                            label={t('stake.security_password')}
                                            error={_.size(errorField.security_password) ? true : false}
                                            helperText={errorField.security_password}
                                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                            fullWidth
                                        />
                                        : null
                                }
                            </>
                            }
                        
                    </Box>
                </Box> }
                <Box style={{paddingTop:15}}>
                    { purchaseStatus === 'insert' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ purchaseStake }>
                        { t('stake.purchaseNow') }
                    </Button> }
                    { purchaseStatus === 'result' && <Button variant="contained" color="primary" classes={{ root: classes.buttonRoot }}  onClick={ () => history.push('/stakes') }>
                        { t('stake.complete') }
                    </Button> }
                </Box>
            </Paper>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        // marginLeft: 15,
        // marginRight: 15,
        // marginTop: -58,
        // padding: 10,
        borderRadius: 20,
        boxShadow:'none'
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 0,
    },
    buttonRoot: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 20,
        marginTop: 15, 
        marginBottom: 30,
        borderRadius: 5,
        width: '100%',
        maxWidth: '100%',
        '&:hover': {
            backgroundColor: theme.palette.primary.secondary,
        },
    },
    title: {
        fontSize: 18,
        paddingTop: 25,
        fontWeight: 'bold',
        color: '#10242f',
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: "white",//theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    balanceStyle: {
        fontSize: 24, 
        color: "white",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white, 
        fontSize: 11, 
        letterSpacing: 1 
    },
    backIconStyle: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        cursor: 'pointer',
    },
    walletCard: {
        height: 110,
        // background: theme.palette.secondary.main,
        marginTop: -20,
        boxShadow: '0 -6px 6px 0px #0000002b',
        background: 'rgb(255,238,184)',
        background: 'linear-gradient(124deg, rgba(255,238,184,1) 0%, rgba(255,191,0,1) 100%)',
        borderRadius: 15,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 5,
        position: 'relative',
        border: '4px solid #fff5',
        boxShadow: 'inset 0 0 6px 0 #0000003b',
        [theme.breakpoints.down('sm')]: {
            height: 100,
        },
    },
    walletIconImg: {
        width: 51,
        [theme.breakpoints.down('sm')]: {
            width: 43,
        },
    },
    walletBalanceTitle: {
        textTransform: 'uppercase',
        fontSize: 15,
        paddingBottom: 2,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },
    walletAmount: {
        fontSize: 30,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
        },
    },
}));

const styles = {
    inputPropsStyle: { 
        textAlign: 'center', 
        fontSize: 36, 
        fontWeight: 'bold', 
    }
}
  
  
