import {
    STORE_PROFILE,
    RESET_PROFILE,
    STORE_WALLET_BALANCE
} from './types';

export const storeProfile = profile => dispatch => {
    dispatch({ type: STORE_PROFILE, payload: profile });
}

export const resetProfile = () => dispatch => {
    dispatch({ type: RESET_PROFILE });
}

export const storeWalletBalance = walletBalance => dispatch => {
    dispatch({ type: STORE_WALLET_BALANCE, payload: walletBalance });
}