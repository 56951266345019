export const ENV = 'test'; // 'dev';
export const APP_NAME = 'LedgerBlock';
export const PROJECT_TYPE = 'mall'; // 'mall/catalog';
export const APP_SITE = "CN"; // CN/INT
export const FRONTEND_VERSION = "20230814"; // must same with backend, force user refresh browser

export const BASE_URL = ENV === 'liv' ? '' : '';

const temp_url = {
    '139.180.199.0':{api:'//api.ledgerblock.vip',page:'//cn.ledgerblock.vip'},
    '45.32.105.142':{api:'//api.fz67.ledgerblock.vip',page:'//cn.fz67.ledgerblock.vip'},
} 
const temp_key = "45.32.105.142"

let BACKEND_URL_LIST = {
    liv: temp_url[temp_key].api,
    stage: `https://dcnapi.ledgerblock.net`,
    dev: `http://cmp0516.com`,
    test: 'https://app.cxcmpfy.dev.1nww.com',
};

let LOCAL_URL_LIST = {
    liv: temp_url[temp_key].page,
    stage: `https://dcnmall.ledgerblock.net`,
    dev: `http://localhost:3000`,
    test: 'https://app.cxcmpfyh5.dev.1nww.com',
};

let OTHER_LOCAL_URL_LIST = {
    liv: temp_url[temp_key].page,
    stage: `https://dmall.ledgerblock.net`,
    dev: `http://localhost:3000`,
    test: 'https://app.cxcmpfyh5.dev.1nww.com',
};

let SELLER_URL_LIST = {
    liv: temp_url[temp_key].page,
    stage: 'https://dcnbackend.ledgerblock.net/',
    dev: 'http://localhost:3001/',
    test: 'https://app.cxcmpfyh5.dev.1nww.com',
};
if(window.location.hostname != 'localhost' && ENV == 'liv'){
    BACKEND_URL_LIST[ENV] = "//api"+window.location.host.substring(2)
    LOCAL_URL_LIST[ENV] = "//"+window.location.host
    OTHER_LOCAL_URL_LIST[ENV] = "//"+window.location.host
    SELLER_URL_LIST[ENV] = "//"+window.location.host
}

export const BACKEND_URL = BACKEND_URL_LIST[ENV];
export const LOCAL_URL = LOCAL_URL_LIST[ENV];
export const OTHER_LOCAL_URL = OTHER_LOCAL_URL_LIST[ENV];
export const SELLER_URL = SELLER_URL_LIST[ENV];

export const CLIENT_SECRET = 'EykDL0NJzr2GydtmeMnTC2DI5xjwRUAv6adPEBes';

export const API_URL = `${BACKEND_URL}/api/`;

export const EXTERNAL_MEMBER_SITE = ENV === 'liv' ? 'https://mib88.com/' : 'https://mib88.com/';
export const EXTERNAL_LOGIN = ENV === 'liv' ? 'https://mib88.com/live/login-authentication?src=mib_mall' : 'https://mib88.com/demo/login-authentication?src=mib_mall';
export const EXTERNAL_ABOUTUS = ENV === 'liv' ? 'https://mib88.com/' : 'https://mib88.com/';

// export const UPAY_URL = ENV === 'liv' ? 'https://www.upay2us.com/iServeGateway/transaction_window' : 'https://uat.upay2us.com/iServeGateway/transaction_window';
export const UPAY_URL = ENV === 'liv' ? '' : '';
// export const PAYPAL_URL = ENV === 'liv' ? 'https://www.paypal.com/sdk/js?client-id=Aaq7A28-Kgp1PF3nV9ITbTVYLrmMXa9N724dc1TxG_ggQt1BrSotwXY9zimouxKPofAfGf2VTsZVc7uz&disable-funding=credit,card&currency=USD' : 'https://www.paypal.com/sdk/js?client-id=ATmcw_7BZ11ecNZBgC8F-UHP93-aj2wuKEsgJV4EpGhxbH-TVlvCpxPecWEd-WgZ6L74vwWNayzzlqIh&disable-funding=credit,card&currency=USD';
export const PAYPAL_URL = ENV === 'liv' ? '' : '';
export const STRIPE_KEY = ENV === 'liv' ? 'pk_live_51H7jIYE5DDnYa3UmpwPlgUV8zyM85kdyd4Gm6xPyiUXLMGD5RA8YSgqmssynS8moH4Y89Pi9JQ9uJ0b7afn6T9Qh007vZw5Jsv' : 'pk_test_51H7jIYE5DDnYa3UmBqHIcm1taXM6ecCgfdZDo7w2Ly0sXkKiv2l3b6bbZyqD0Ebqb13TMM1BsFD6DtBMVJ9rt6CU00vZ9Kcxme';

export const CONTACT_US_EMAIL = 'cnsupport@ledgerblock.net';

export const PUSHER_KEY = ENV === 'liv' ? '300a8ddcd263bda445fc' : '4055f5d563df469ce07a';
export const PUSHER_CLUSTER = 'ap1';
export const PUSHER_AUTH = `${BACKEND_URL}/broadcasting/auth`;
export const PUSHER_MESSAGE_CHANNEL = 'chat';
export const PUSHER_MESSAGE_EVENT = 'MessageSent';

export const FACEBOOK_APPID = "616126255894876"; // 1neway
export const DEFAULT_LANGUAGE = "cn"; // "en"

export const LOGIN_SECRET_KEY = "VSgwUGPVQvN4z88s0hUVo9mb";

export const ISHOPPING_LOGIN = ENV === 'liv' ? 'https://www.i-shopping.app/login?src=mistore' : 'https://dmall.i-shopping.app/login?src=mistore';
