import React, { Suspense }  from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';

// import configureStore from './configs/Store';
import { store, persistor } from './configs/Store';
import { PersistGate } from 'redux-persist/integration/react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import App from './components/App';
import theme from './Theme';
import { ENV } from './configs/Config';

import './i18n';

// const { store, persistor } = configureStore();

if (ENV !== "dev") {
    console.log = () => {};
}

setInterval(() => {
    let t = document.getElementsByClassName("cc-nsge")
    if(undefined !== t[0]){
        let r = t[0].style.setProperty('bottom','65px','important')
    }
}, 1000);


ReactDOM.render(
    <Provider store={ store }>
        <PersistGate persistor={ persistor }>
            <ThemeProvider theme={ theme }>
                <CssBaseline />
                <Suspense fallback="loading">
                    <Router basename={""}>
                        <App />
                    </Router>
                </Suspense>
            </ThemeProvider>
        </PersistGate>
    </Provider>, 
    document.getElementById('root')
);
