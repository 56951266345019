import { red, orange, lightBlue, deepOrange, teal, deepPurple, indigo, green,cyan, brown, blueGrey, amber } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4dbedb',
            secondary: '#42a0b9',
            dark:'#166682',
            lighter:'#8ad5ed',
            darker:'#268cbe',
            shadow:'#2a81c880 2px 2px 4px 0px'
        },
        secondary: {
            main: '#10242F',
            secondary: '#3c4f59',
            shadow:'#5cccf96e 0px 4px 6px 0px'
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f7f7f7',
            review: '#efefef',
        },
        white: {
            main: '#FFFFFF',
            text: '#ededed',
        },
        green: {
            main: '#4caf50',
        },
        gray: {
            main: '#b5b5b5',
            border: '#dee2e6',
            chip: '#b8b9b9',
            text:'#9d9d9d',
            textTitle:'#979797',
            ltext: '#d1d1d1',
        },
        silver:{
            bg:'#bebfc4',
            bgDarker:'#9d9ea2',
            text:'#939393',
            textDarker:'#6b6b6b',
        },
        gold:{
            bg:'#d8b57c',
            bgDarker:'#aa8743',
            text:'#dcb574',
            textLight:'#efcd97',
            main:'#f2c278',
            secondary:'#caa060'
        },
        price: {
            main: '#4dbedb',
        },
        profile: {
            user: orange.A400,
            order: lightBlue.A400,
            notification: deepOrange.A400,
            wallets: deepPurple.A400,
            integration: teal.A400,
            likes: red.A400,
            chat:indigo.A400,
            voucher: red.A700,
            sponsor: green.A400,
            placement:cyan.A400,
            recruit:brown.A400,
            bindSubAccount:blueGrey.A400,
            accountLink: amber.A400,
        },
        button: {
            main: '#42a0b9',
            step: '#42a0b9',
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;