import React, { useState, useEffect, useCallback, useRef } from 'react'; //,{ useState, useEffect, useRef, createRef } 
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import WebpImg from '../Layouts/WebpImg';
import QRCode from 'qrcode.react';

import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Box, makeStyles, Typography, useTheme, Link, Avatar, Dialog, Hidden, Button, Grid, Badge } from '@material-ui/core';
import { FiChevronLeft } from 'react-icons/fi';
import { AiOutlineQrcode } from 'react-icons/ai'
import { LOCAL_URL } from '../../configs/Config';
import useNotificationLoading from '../../helper/useNotificationLoading';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { getUrl, logoutUser } from '../../helper/ApiAction';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { LocalMall } from '@material-ui/icons';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export default function TitleBar(props) {
    const { title, height = 150, back = false, backUrl = null, children, displayQr = false, copyLink = false, displayCart = false, linkMall = false, displayCartTop = false, displayInfo = true, } = props;

    const [showQr, setShowQr] = useState(false);
    const [qrUrl, setQrUrl] = useState("");

    const theme = useTheme();
    const classes = useStyles();
    const { id, username, landing_icon, max_rank: rank, max_mrank, contract, expiry_date, account_link, email_verified_at, referral_username } = useSelector(state => state.user);
    const { cartTotal, accessToken } = useSelector(state => state.general);
    const history = useHistory();
    const [contractNameDisplay, setContractNameDisplay] = useState('');
    const shareLink = useRef(null);
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();
    const [showIshoppingLink, setShowIshoppingLink] = useState(false);
    const [sendVerifyEmail, setSendVerifyEmail] = useState(true);
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        let name = '-';
        if (contract) {
            _.map(contract, (corder) => {
                if (corder.status === 20) {
                    name = corder.contract_name_display;
                }
            })
        }
        setContractNameDisplay(name);
    }, [contract]);

    const showQR = useCallback(() => {
        setQrUrl(`${LOCAL_URL}/register?r=${btoa(id)}`);
        setShowQr(true);
    }, [id]);

    const copyShareLink = useCallback((e) => {
        shareLink.current.select();
        document.execCommand('copy');
        e.target.focus();
        addAlert(t('profile.copySuccess'), "success");
    }, [id]);

    const copyShareLink2 = useCallback((e) => {
        navigator.clipboard.writeText(`${LOCAL_URL}/register?r=${btoa(id)}`)
        addAlert(t('profile.copySuccess'), "success");
    }, [id]);

    const daysLeft = expiry_date => {
        var today = new Date();

        expiry_date = expiry_date.substring(0, 10);
        var expiry = new Date(expiry_date);

        if (expiry <= today) return 0;

        var diffInTime = expiry.getTime() - today.getTime();
        return Math.ceil((diffInTime / (1000 * 3600 * 24)));
    }

    const formatDate = date => {
        date = date.substring(0, 10);
        let theDate = new Date(date);
        let offset = theDate.getTimezoneOffset();
        theDate = new Date(theDate.getTime() - (offset * 60 * 1000));
        return theDate.toISOString().substring(0, 10);
    }


    useEffect(() => {
        _.map(account_link, linking => {
            if (linking.source === 'ishopping') {
                setShowIshoppingLink(true);
            }
        })
    }, [account_link]);

    const goToIshopping = () => {
        if (accessToken) {
            getUrl(`/login-to/ishopping`).then(response => {
                setLoading(false);
                if (response.status === 1 && response.url) {
                    window.open(response.url, '_blank').focus();
                } else {
                    addAlert(t('general.loginRedirectError'));
                }
            }).catch(error => {
                setLoading(false);
                addAlert(error.message);
            });
        }
    }

    const verifyEmailUrl = (self) => {
        console.log(self)
        console.log(self.history)
        return false
        setLoading(true);
        setSendVerifyEmail(false);
        getUrl('user/send-email-verification-url').then(result => {
            if (result.error) {
                setLoading(false);
                addAlert(result.message);
            } else {
                setLoading(false);
                addAlert(result.message, 'success');
                setEmailSent(true);
            }
        }).catch(error => {
            setLoading(false);
            addAlert(t('general.checkMailBox', 'success'));
            setEmailSent(true);
        });
    }

    return (
        <>
            {/* <Box className={classes.titleContainerWrapperStyle} paddingY={2}>
            { 
                linkMall && showIshoppingLink &&
                    <Box onClick={() => goToIshopping()} style={{ marginRight: 30, cursor:'pointer', display:'flex', alignItems:'center', backgroundColor:"#fff", borderTopRightRadius:24, borderBottomRightRadius:24, padding:'5px 15px' }} >
                        <Typography style={{color:theme.palette.primary.main, fontSize:14}}>{t('general.ishoppingMall')}</Typography>
                        <LocalMall fontSize="default"style={{color:theme.palette.primary.main, marginLeft:8, fontSize:17}} />
                    </Box>
            }
               
                <div className={classes.titleWrapperStyle} style={{ marginLeft: back ? -30 : 0 }}>
                    <Typography classes={{ root: classes.titleStyle }}>{ title }</Typography>
                </div>
                <Hidden only={'xs'}>
                { linkMall &&
                    <Box onClick={() => goToIshopping()} style={{ marginRight: 30, cursor:'pointer', display:'flex', alignItems:'center' }} >
                        <LocalMall fontSize="default"style={{color:theme.palette.white.main, marginRight:8}} />
                        <Typography style={{color:theme.palette.white.main, fontSize:14}}>{t('general.ishoppingMall')}</Typography>
                        
                    </Box>
                }
                </Hidden>

                {linkMall &&
                    <Box onClick={() => goToIshopping()} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', backgroundColor: "#fff", borderTopLeftRadius: 24, borderBottomLeftRadius: 24, padding: '5px 15px' }} >
                        <Typography style={{ color: theme.palette.primary.main, fontSize: 14 }}>{t('general.ishoppingMall')}</Typography>
                        <LocalMall fontSize="default" style={{ color: theme.palette.primary.main, marginLeft: 8, fontSize: 17 }} />
                    </Box>
                }
                { displayCartTop &&
                    <Link underline='none' to="/cart" component={RouterLink} style={{ marginRight: 30 }}>
                        <Badge badgeContent={cartTotal} classes={{ badge: classes.badgeStyle }}>
                            <ShoppingCartIcon style={{ color: "white" }} />
                        </Badge>
                    </Link>
                }
               
            </Box> */}
            {back &&
                <div style={{ zIndex: 3 }}>
                    {backUrl ?
                        <Link underline='none' to={backUrl} className={classes.backIconStyle} component={RouterLink} style={{ zIndex: 3 }} >
                            <FiChevronLeft style={{ color: theme.palette.white.main, fontSize: 21 }} />
                        </Link>
                        : <div onClick={() => history.goBack()} className={classes.backIconStyle}>
                            <FiChevronLeft style={{ color: theme.palette.white.main, fontSize: 21 }} />
                        </div>}

                </div>
            }

            {
                displayInfo &&

                <>
                    <Box height={height} display="flex" flexDirection="row" alignItems="flex-start" style={{ background: theme.palette.secondary.main, borderRadius: 10, padding: 20, position: 'relative' }}>
                    </Box>
                    <Box style={{ padding: '0 20px', position: 'absolute', top: 40, width: '90%', left: '5%' }}>
                        <Box height={height} style={{ width: '100%', background: theme.palette.primary.main, borderRadius: 10, position: 'relative' }}>
                            <Box flex={1}>
                                {children ? children :
                                    <Box>
                                        <Box className={classes.rankWrapperStyle}>
                                            <Box style={{ borderRadius: '50%', paddingTop: 20 }}>
                                                {
                                                    _.size(landing_icon) > 0 && landing_icon ?
                                                        <Avatar className={classes.large} style={{ color: theme.palette.primary.main }} alt="icon" src={landing_icon.file_name} />
                                                        :
                                                        // <Typography className={classes.rankTitleStyle}>{_.size(username) > 0 ? username[0] : '-'}</Typography>
                                                        <Box className={classes.rankTitleStyle}>
                                                            <WebpImg src="/images/logo.png" alt="logo" containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                                                        </Box>
                                                }
                                            </Box>
                                        </Box>
                                        {accessToken ?
                                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ paddingTop: 5 }}>
                                                <Typography className={classes.usernameStyle}>{username}</Typography>
                                                
                                                {
                                                    email_verified_at !== null &&
                                                    <div style={{ padding: 1, color: '#34a873', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} >
                                                        <CheckCircleIcon size="small" /><span style={{ paddingLeft: '5px' }}><b>{t('profile.emailVerified')}</b></span>
                                                    </div>
                                                }

                                                <Typography className={classes.infoStyle} style={{ fontSize: 13, textTransform: 'uppercase' }}>{t('profile.referral')}: {referral_username}</Typography>

                                                <Typography className={classes.infoStyle} style={{ fontSize: 13, textTransform: 'uppercase' }}>{t('profile.rank')}: {(rank && _.size(rank) > 0) ? rank.name_display : '-'}{(max_mrank && _.size(max_mrank) > 0) ? " | " + max_mrank.name_display : ''}</Typography>

                                            {copyLink &&
                                                <div>
                                                    <Grid item xs style={{ textAlign: "center", display: 'none' }}>
                                                        <form>
                                                            <textarea ref={shareLink} value={qrUrl} readOnly style={{ border: 'none', resize: 'none', outline: 'none' }} />
                                                        </form>
                                                    </Grid>
                                                        {document.queryCommandSupported('copy') &&
                                                            <Grid item xs style={{ textAlign: "center", padding:'10px 0' }}>
                                                                <Button variant="contained" color="secondary" style={{ background: '#413d37', borderRadius: 10, fontSize: 12, boxShadow: '#00000033 1px 4px 4px 0px' }} onClick={copyShareLink2}>{t('profile.copyReferralLink')}</Button>
                                                            </Grid>
                                                        }
                                                    </div>
                                                }

                                                {
                                                    email_verified_at === null && sendVerifyEmail ?
                                                        <Link to="/profile-security-email" component={RouterLink} style={{ cursor: "pointer", color: 'white', display: 'flex', alignItems: 'center', flexWrap: 'wrap',padding: '5px 15px', background: '#2d5793', borderRadius: 10, fontSize: 12, minWidth: 157, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '3px 3px 8px 0 #1b3c6b5c', }} >
                                                            <MailOutlineIcon size="small" /><span style={{ paddingLeft: '5px' }}><b>{t('profile.verifyEmail')}</b></span>
                                                        </Link>
                                                        : null
                                                }

                                                {/* <Typography className={classes.infoStyle}>{mobile ? mobile : '-'} <span className={classes.dividerStyle}>|</span> {country ? country : '-'} </Typography>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: 188, paddingTop: 9 }}>
                                            <Typography style={{ lineHeight: 'unset' }} variant="caption" className={classes.infoStyle}>
                                                {t('profile.maintenanceExpiry')}  <br /> <b style={{ fontSize: 12 }}>{expiry_date ? formatDate(expiry_date) : ''}</b>
                                            </Typography>
                                            <div style={{ height: 23, backgroundColor: 'rgb(255 255 255 / 46%)', width: 1.7, margin: '0 10px' }}>
                                            </div>

                                            {expiry_date ?
                                                <Typography style={{ lineHeight: 'unset' }} variant="caption" className={classes.infoStyle}>
                                                    {t('profile.daysLeft')}<br /> <b style={{ fontSize: 12 }}>{daysLeft(expiry_date)}</b>
                                                </Typography>
                                                : null}
                                        </div> */}
                                            </Box>
                                            : null}
                                    </Box>
                                }
                            </Box>
                            {(displayQr && accessToken) && <Box display="flex" style={{ cursor: 'pointer', position: 'absolute', top: 10, right: 10 }} onClick={showQR}>
                                {/* <Grid style={{ color:theme.palette.secondary.main }}/> */}
                                {/* <FontAwesomeIcon icon={faQrcode} color={theme.palette.secondary.main} style={{ width: 25, height: 25 }} /> */}
                                <AiOutlineQrcode color={theme.palette.white.main} style={{ width: 33, height: 33 }} />
                            </Box>}
                            <Box display="flex" paddingTop={2} justifyContent="center" alignItems="center">
                                {displayCart &&
                                    <Link underline='none' to="/cart" component={RouterLink} style={{ marginRight: 15 }}>
                                        <Badge badgeContent={cartTotal} classes={{ badge: classes.badgeStyle }}>
                                            <ShoppingCartIcon style={{ color: "white" }} />
                                        </Badge>
                                    </Link>
                                }

                                {/* {accessToken ?
                                <Link underline='none' component="button" onClick={() => {
                                    logoutUser(dispatch);
                                    history.replace('/login');
                                }}>
                                    <ExitToAppIcon style={{ color: "white" }} />
                                </Link> :
                                <Link underline='none' to="/login" component={RouterLink} style={{ marginRight: 15 }}>
                                    <ExitToAppIcon style={{ color: "white" }} />
                                </Link>} */}

                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" paddingTop={2} justifyContent="center" alignItems="center">
                        {displayCart &&
                            <Link underline='none' to="/cart" component={RouterLink} style={{ marginRight: 15 }}>
                                <Badge badgeContent={cartTotal} classes={{ badge: classes.badgeStyle }}>
                                    <ShoppingCartIcon style={{ color: "white" }} />
                                </Badge>
                            </Link>
                        }

                        {/* {accessToken ?
                    <Link underline='none'  component="button"  onClick={() => {
                        logoutUser(dispatch);
                        history.replace('/login');
                    }}>
                        <ExitToAppIcon style={{color: "white"}}/>
                    </Link> : 
                    <Link underline='none' to="/login" component={RouterLink} style={{marginRight: 15 }}>
                        <ExitToAppIcon style={{color: "white"}}/>
                    </Link>} */}

                    </Box>
                </>
            }
            <Dialog
                open={showQr}
                onClose={() => setShowQr(false)}
                maxWidth="md"
            >
                <Box style={{ padding: '20px 0 ' }}>
                    <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center" padding={3}>
                        <QRCode value={qrUrl} renderAs="svg" fgColor={theme.palette.primary.main} />
                    </Box>
                    <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center" padding={3} >
                        <Grid container spacing={2}>
                            <Grid item xs style={{ textAlign: "center" }}>
                                <form>
                                    <textarea ref={shareLink} value={qrUrl} readOnly style={{ border: 'none', resize: 'none', outline: 'none' }} />
                                </form>
                            </Grid>
                            {document.queryCommandSupported('copy') &&
                                <Grid item xs style={{ textAlign: "center" }}>
                                    <Button variant="contained" color="secondary" onClick={copyShareLink}>{t('profile.copyLink')}</Button>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={emailSent}
                onClose={() => setEmailSent(false)}
                maxWidth="sm"
            >
                <Box style={{ padding: '20px 0 ' }}>
                    <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center">
                        <MailOutlineIcon size="large" style={{ color: theme.palette.primary.main, fontSize: '100px' }} />
                    </Box>
                    <Box display="flex" flexDirection="center" width="100%" alignItems="center" justifyContent="center" padding={3} >
                        <Grid item xs style={{ textAlign: "center" }}>
                            <Typography style={{ color: theme.palette.primary.main, fontSize: 17 }}>{t('profile.emailUrlSent')}</Typography>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    titleContainerWrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        position: 'relative',
    },
    backIconStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        cursor: 'pointer',
    },
    titleWrapperStyle: {
        flexGrow: 1
    },
    titleStyle: {
        textAlign: 'center',
        color: theme.palette.white.main
    },
    rankWrapperStyle: {
        // border: '3px solid',
        // borderColor: theme.palette.secondary.main,
        width: '100%',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: 'rgb(168,120,33)',
        // background: 'linear-gradient(90deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)'
    },

    rankTitleStyle: {
        // color: theme.palette.secondary.main,
        fontSize: 36,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        backgroundColor: '#fff',
        height: 55,
        width: 55,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: '-webkit-linear-gradient(135deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)',
        // WebkitBackgroundClip:' text',
        // WebkitTextFillColor: 'transparent',
        borderRadius: 5,
        padding: 5,
    },
    dividerStyle: {
        padding: '0 10px'
    },
    usernameStyle: {
        fontSize: 15,
        color: theme.palette.white.main,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    infoStyle: {
        color: theme.palette.white.main,
        fontSize: 11,
        letterSpacing: 1
    },
    large: {
        width: 70,
        height: 70,
    },
    badgeStyle: {
        backgroundColor: theme.palette.background.default,
        color: "#000000"
    }
}));