import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, makeStyles, Box, Paper, Typography, FormControl, List, ListItem, ListItemText, useTheme, Select, MenuItem, NativeSelect, } from '@material-ui/core';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import TitleBarWhite from './Layouts/TitleBarWhite';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';


export default function Transaction() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [transactionList, setTransactionList] = useState([]);

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
    }));

    console.log("walletList", walletList);
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        setTransactionList([]);
        setTotalPage(0);
        setPage(1);
    }, [wallet]);

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo) > 0) {
            getUrl(`default/get-details?page=${page}&limit=10&type=${walletInfo.id}`).then(response => {
                if(response.code == 0 && isMountedRef.current) {
                    const { last_page, data } = response.data;
                    setTotalPage(last_page);
                    setTransactionList(_.concat(transactionList, data));
                }
            }).catch(err => {
                addAlert(JSON.stringify(err.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [walletInfo, page]);

    const changeWallet = ({ target }) => {
        history.push(`/transaction/${target.value}`);
    }

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const openDialog = dialogInfo => {
        setDialogInfo(dialogInfo);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogInfo([]);
        setDialogOpen(false);
    }

    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
      });
    
      const handleChange = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
      };

    console.log("transactionList", transactionList);

    return (
        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
            {/* <TitleBar title={ t('title.transaction') } back>
                <Box display="flex" alignItems="center" justifyContent="center" height="56%">
                    <Box display="flex" flexDirection="column" >
                        <Typography className={classes.balanceStyle}>{ currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1) } <span style={{ fontSize: 16 }}>{ _.size(walletInfo) > 0 ? walletInfo.wallet_name : '-' }</span></Typography>
                        <Typography className={ classes.infoStyle } style={{color:'#fff', fontSize:13}}>{ t('transfer.transferableBalance')}</Typography>
                    </Box>
                </Box>
            </TitleBar> */}
            <TitleBarWhite title={ t('title.bonus') } back displayInfo={false}/>

            <div className={classes.walletCard}>
                {/* <FiLayers style={{fontSize:39, color:theme.palette.primary.main}}/> */}
                <img src='/images/general/icon/usdt.png' alt="rank" className={classes.walletIconImg} />
                <div style={{ color: theme.palette.white.text, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                    <p className={classes.walletBalanceTitle}>{_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'} {t('wallet.balance')}</p>
                    <p className={classes.walletAmount}><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)}</b></p>
                </div>
            </div>
            {
                _.size(walletList) >= 2 &&
                <Paper elevation={3} classes={{ root: classes.paperContainer }} style={{position:'relative'}}>
                    <Box className={classes.boxContainer} paddingX={1}>
                    <MuiThemeProvider theme={selectStyle}>
                        <FormControl className={classes.formControl}>
                            <Select
                                value={wallet}
                                name="wallet"
                                onChange={ changeWallet }
                                label={t('transaction.wallet')}
                            >
                                { _.map(walletList, walletItem => (
                                    <MenuItem key={walletItem.code} value={walletItem.code}>{ walletItem.wallet_name }</MenuItem>
                                ) ) }
                            </Select>
                        </FormControl>
                        </MuiThemeProvider>
                    </Box>
                </Paper>
            }
            <Box padding={2}>
                <List className={classes.listRoot}>
                    <p style={{fontSize:18, paddingTop:15}}><b>{t('title.transaction')}</b></p>
                    { _.map(transactionList, transactionItem => (
                        <TransactionItem key={transactionItem.id} data={transactionItem} openDialog={openDialog} />
                    ))}
                </List>
                { page < totalPage ?
                    <Button onClick={ loadTransaction } fullWidth>{ t('transaction.loadMore') }</Button>
                    : <Typography style={{ textAlign: 'center' }}>{ t('transaction.endOfList') }</Typography>
                }
            </Box>
            <MuiThemeProvider theme={DialogStyle}>
            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                onClose={closeDialog}
            >
                <div style={{background:'#fff'}}>
                        <div style={{ background: '#F2C278'}} className={classes.transactionDialog}>
                        {/* <Typography style={{ fontSize: 14, color: '#fff', letterSpacing:1 }}><b>{ _.hasIn(dialogInfo.type_display_str, i18n.language) ? dialogInfo.type_display[i18n.language] : '-' }</b></Typography> */}
                        <Typography style={{ fontSize: 14, color: '#fff', letterSpacing:1 }}><b>{ dialogInfo.type_display_str }</b></Typography>
                        </div>
                    </div>
                <DialogContent >
                    <Box display="flex" alignItems="center" flexDirection="column" style={{padding:'15px 0 20px'}}>
                        {/* <Typography style={{ fontWeight: 'bold', fontSize: 24, color:theme.palette.secondary.main, marginLeft:-15, lineHeight:1.0 }}>{ `${dialogInfo.factor > 0 ? "+" : "-"} ${currencyFormat(dialogInfo.amount || 0)}` }</Typography> */}
                        <Typography style={{ fontWeight: 'bold', fontSize: 24, color:theme.palette.secondary.main, marginLeft:-15, lineHeight:1.0 }}>{ `${dialogInfo.factor > 0 ? "+" : "-"} ${currencyFormat(dialogInfo.amount || 0)}` }</Typography>
                        <Typography style={{ textTransform: 'uppercase', fontSize: 14 }}>{ t('transaction.amount') }</Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" width="100%" >
                            <Typography style={{  flex: 2,  fontSize:14 }}><b>{ t('transaction.idNo') }</b></Typography>
                            <Typography style={{  flex: 1,  fontSize:14, textAlign:'right' }}><b>{ _.size(dialogInfo.transaction_details) > 0 ? dialogInfo.transaction_details[0].transaction_id : '-' }</b></Typography>
                        </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="row" paddingY={1}>
                        <Typography style={{fontSize: 14, flex:1 }}>{ t('transaction.transaction') }</Typography>
                        {/* <Typography style={{ fontSize: 14, flex:2, textAlign:'right' }}>{ _.hasIn(dialogInfo.type_display, i18n.language) ? dialogInfo.type_display[i18n.language] : '-' }</Typography> */}
                        <Typography style={{ fontSize: 14, flex:2, textAlign:'right' }}>{ dialogInfo.type_display_str }</Typography>
                    </Box>
                    
                    <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                    <Box display="flex" alignItems="flex-start" flexDirection="column">
                            <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{t('transaction.dateTime')}</Typography>
                            <Typography style={{ fontSize: 14, flex: 2 }}>{dialogInfo.created_at}</Typography>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="column"  paddingY={1}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{t('transaction.remark')}</Typography>
                            <Typography style={{ fontSize: 14, flex: 2 }}>{dialogInfo.remark || "-"}</Typography>
                        </Box>
                    </Box>
                    
                <div style={{ margin: '0 auto'}}>
                <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ closeDialog }>
                    { t('transaction.done') }
                </Button>
                        </div>
                </DialogContent>
                <div style={{lineHeight:0}}>
                        <img src="/images/general/receipt2.png" alt="receipt" style={{width:'100%', background:'transparent'}}/>
                    </div>
            </Dialog>
            </MuiThemeProvider>
        </Box>
    )
}

const TransactionItem = ({ data, openDialog }) => {
    const { amount, factor, remark, created_at, type_display_str } = data;

    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    let operator = factor > 0 ? "+" : "-";

    return (
        <ListItem divider onClick={() => openDialog(data)} classes={{ root: classes.listItemRoot }} display="flex" alignItems="center" style={{padding:'10px 0'}} >
            <Box className={classes.listItemTextRoot}>
                <ListItemText
                    style={{width:'100%'}}
                    classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
                    primary={ type_display_str }
                    secondary={
                        <Typography
                            component="p"
                            variant="body2"
                            className={classes.remarkStyle}
                        >
                            { `${t('transaction.remark')}: ${remark || '-'}`}
                        </Typography>
                    }
                />
                <Typography
                    component="span"
                    variant="body2"
                    style={{ color: theme.palette.silver.text, fontSize: 11 }}
                >
                    {created_at}
                </Typography>
            </Box>
            <ListItemText
                primary={ `${operator} ${currencyFormat((amount * 100) / 100)}` }
                classes={{ root: classes.listItemAmountRoot, primary: classes.listItemAmountPrimary }}
                style={{ color: operator === '+' ? "green"/* theme.palette.gold.bg */ : 'red', whiteSpace:'nowrap', width:85 }}
            />
        </ListItem>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -38,
        padding: 10,
        borderRadius: 10,
        background:theme.palette.primary.main
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    buttonRoot: {
        backgroundColor: theme.palette.gold.main,
        color: '#FFF',
        borderRadius: 10,
        marginTop: 15, 
        width:'100%',
        padding:'8px 15px',
        "&:hover":{
            backgroundColor: theme.palette.gold.secondary
        }
    },
    MuiButton: {
        contained:{
            "&:hover":{
                backgroundColor: theme.palette.secondary.main
            }
        }
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    remarkStyle:{
        color: theme.palette.silver.text,
        fontSize: 14,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        width:'100%',
        overflow:'hidden'
    },
    balanceStyle: {
        fontSize: 30, 
        color: "white",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white, 
        fontSize: 11, 
        letterSpacing: 1 
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    listRoot: {
        width: '100%',
    },
    listItemTextRoot: {
        flex: 4
    },
    listItemAmountRoot: {
        // flex: 1,
        // display: 'flex',
        // justifyContent: 'flex-end',
        //alignSelf: 'center',
        textAlign:'right',
    },
    listItemAmountPrimary: {
        fontWeight: 'bold',
        fontSize: 14
    },
    listItemTextPrimary: {
        fontSize: 14,
    },
    listItemTextSecondary: {
        fontSize: 10,
    },
    listItemRoot: {
        cursor: 'pointer'
    },

    walletCard: {
        height: 183, 
        background: theme.palette.secondary.main,
        borderRadius: 15,
        width: '100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        paddingBottom:14,
        position:'relative',
        [theme.breakpoints.down('sm')]: {
            height: 163, 
        },
    },
    walletIconImg: {
        width:63,
        [theme.breakpoints.down('sm')]: {
            width:43,
        },
    },
    walletBalanceTitle: {
        textTransform:'uppercase',
        fontSize:17,
        paddingBottom:2,
        [theme.breakpoints.down('sm')]: {
            fontSize:13,
        },
    },
    walletAmount: {
        fontSize: 34,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
        },
    },
    selectControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    transactionDialog: {
        padding: ' 10px 15px',
        textTransform: 'uppercase',
        textAlign: 'center',
        width: 230,
        margin: '0 auto',
        borderRadius: '0 0 15px 15px',
    }
}));

const selectStyle = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                color: "#fff",
                "&$focused": {
                    color: '#fff'
                    },
                    '&.$Mui-disabled': {
                        color: '#fff'
                      }
            },
           
        },
        MuiInput: {
            underline: {
                '&::before': {
                    borderBottom: '1px solid #fff'
                },
                '&::after': {
                    borderBottom: '2px solid #fff'
                },
                "&:not(.Mui-disabled):hover::before": {
                    borderColor: "#fff"
                },
            },
        },
        MuiIconButton: {
            root: {
                color:'#c2c2c2',
                "&$focused": {
                    color: '#FF5532'
                    },
            }
        },
        MuiSelect: {
            icon: {
                color:'#fff'
            },
            select: {
                "&$focused": {
                    backgroundColor: 'transparent'
                    },
            }
        }
        
    },
});

const DialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiPaper:{
            root:{
                backgroundColor:'transparent'
            },
            elevation24:{
                boxShadow:'none',
                WebkitFilter: 'drop-shadow(2px 7px 10px #00000070)',
                filter: 'drop-shadow(2px 7px 10px #00000070)',
            }
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh',
                padding: '15px 0 35px',
                borderRadius: '15px 15px 0 0',
            },
            paperFullWidth: {
                width: 400,
                maxWidth: '85%',
                margin: '0 auto',
                padding:0
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 30px 30px',
                background:'#fff'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                background: '#0008',
                borderRadius: 48,
                minWidth: 'unset',
                padding: 0,
            }
        }
        
    },
});