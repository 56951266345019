import request from '../configs/AxiosConfig';
import { removeLoginAccess } from '../configs/AxiosConfig';

export const getUrl = async (url, param) => {
    return request.get(url, {params: param}).then(response => {
        return response;
    }).catch(error => {
        return Promise.reject(error.message);
    });
}

export const postUrl = async (url, postData) => {
    return request.post(url, postData).then(response => {
        return response;
    }).catch(error => {
        return Promise.reject(error.message);
    })
}

export const putUrl = async (url, putData) => {
    return request.put(url, putData).then(response => {
        return response;
    }).catch(error => {
        return Promise.reject(error.message);
    })
}

export const deleteUrl = async url => {
    return request.delete(url).then(response => {
        return response;
    }).catch(error => {
        return Promise.reject(error.message);
    })
}

export const logoutUser = () => {
    removeLoginAccess();
}