import React, { Suspense, Fragment, useEffect, useState } from "react";
import { Switch, BrowserRouter as Router, Route, Redirect, useLocation } from 'react-router-dom';
// import { CircularProgress } from '@material-ui/core';
import ScrollToTop from '../helper/ScrollToTop';
import { LastLocationProvider } from 'react-router-last-location';

import NotificationLoadingProvider from '../providers/NotificationLoadingProvider';
import NotificationLoading from '../components/Layouts/NotificationLoading';

import Header from './Layouts/Header';
import Footer from './Layouts/Footer';
// import ChatButton from './Layouts/ChatButton';

import Home from './Home';
import Maintenance from './Maintenance';

// import Account from './Account';
import AdminChat from './AdminChat';
import Announcement from './Announcement';
import BindSubAccount from './BindSubAccount';
// import Categories from './Categories';
// import Collections from './Collections';
import ContactUs from './ContactUs';
// import ContactUsForm from './ContactUsForm';
import ComingSoon from './ComingSoon';
import Migrated from './Migrated';
// import Callback from './Callback';
// import CategoryList from './CategoryList';
// import Contract from './Contract';
// import ContractDetails from './ContractDetails';
// import Cart from './Cart';
// import Checkout from './Checkout';
// import Ticket from './Ticketing';
// import TicketDetail from './TicketingDetail';

// import Event from './Event';

import Register from './Register';
// import Item from './Item';
// import VoucherItem from './VoucherItem';
// import Payment from './Payment';
// import PaymentCallback from './PaymentCallback';

// import Faq from './Faq';
// import Search from './Search';
// import Shop from './Shop';
import Setting from './Setting';
// import Policies from './Policies';

// import GenealogySponsor from './GenealogySponsor';
import Genealogy from './Genealogy';
// import Review from './Review';

import Profile from './Profile';
import ProfileBankInfo from './ProfileBankInfo';
import ProfilePassword from './ProfilePassword';
import ProfileSecurityPassword from './ProfileSecurityPassword';
import ProfileSecurityEmail from './ProfileSecurityEmail';
import PasswordForgot from './PasswordForgot';
import PasswordReset from './PasswordReset';
import SecurityPasswordReset from './SecurityPasswordReset';
import ProfileAddress from "./ProfileAddress";
import PlacementSetting from "./PlacementSetting";
import ProfileCryptoAddress from './ProfileCryptoAddress';
import ProfileTwoFactorAuthentication from './ProfileTwoFactorAuthentication';

// import MerchantServiceAgreement from './MerchantServiceAgreement';
// import PrivacyAgreement from './PrivacyAgreement';
// import RefundAndReturnAgreement from './RefundAndReturnAgreement';
// import TermOfUse from './TermOfUse';

import Recruit from './Recruit';
// import MyStore from './MyStore';

// import VoucherRedemption from './VoucherRedemption';
// import VoucherCart from './VoucherCart';
// import VoucherCheckout from './VoucherCheckout';

import Wallet from './Wallet';
import UsdtToLbvv from './UsdtToLBVV';
import Transfer from "./Transfer";
import Convert from "./Convert";
import Transaction from "./Transaction";
import Withdrawal from "./Withdrawal";
import WithdrawalHistory from "./WithdrawalHistory";
// import OrderHistory from "./OrderHistory";
import Bonus from "./Bonus";

import Kyc from './Kyc';
import AccountLink from './AccountLink';
// import AccountLinkVerify from './AccountLinkVerify';

import Chat from './Chat';
import ChatHome from './ChatHome';
import Chatting from './Chatting';

import Login from './Login';

import Stakes from './Stakes';
import StakePurchase from './StakePurchase';


import Tutorial from './Tutorial';
import TutorialAuthenticator from './TutorialAuthenticator';
import TutorialDisableAuthenticator from './TutorialDisableAuthenticator';
import TutorialDeposit from './TutorialDeposit';
import TutorialRegister from './TutorialRegister';
import TutorialForgetPassword from './TutorialForgetPassword';
import TutorialPassword from './TutorialPassword';
import TutorialSecurityPassword from './TutorialSecurityPassword';
import TutorialWalletAddress from './TutorialWalletAddress';
import TutorialWithdrawal from './TutorialWithdrawal';
import TutorialStakes from './TutorialStakes';

import Topup from './Topup';
import TopupHistory from './TopupHistory';

import RedirectSite from './RedirectSite';

import EmailVerification from "./EmailVerification";

// import { BASE_URL, STRIPE_KEY, PROJECT_TYPE } from '../configs/Config';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getUrl } from '../helper/ApiAction';
import { useTranslation } from 'react-i18next';

import './css/styles.css';

// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

// const promise = loadStripe(STRIPE_KEY);

// function GenericNotFound() {
//     return (
//         <div>Not Found</div>
//     );
// }
// const NewPayment = () => {
//     return (
//         <Elements stripe={promise}>
//             <Payment />
//         </Elements>
//     )
// }

// const Loading = () => {
//     return (
//         <div style={{ position: "fixed", top: '50%', left: '50%' }}>
//             <CircularProgress disableShrink />
//         </div>
//     );
// };

const routes = [
    {
        path: "/home",
        component: <Home />,
        exact: true
    },
    {
        path: "/maintenance",
        component: <Maintenance />,
        exact: true
    },
    // {
    //     path: "/checkout",
    //     component: <Checkout />,
    //     exact: true
    // },
    {
        path: "/tutorial",
        component: <Tutorial />,
        exact: true
    },
    {
        path: "/tutorialAuthenticator",
        component: <TutorialAuthenticator />,
        exact: true
    },
    {
        path: "/tutorialDisableAuthenticator",
        component: <TutorialDisableAuthenticator />,
        exact: true
    },
    {
        path: "/tutorialDeposit",
        component: <TutorialDeposit />,
        exact: true
    },
    {
        path: "/TutorialRegister",
        component: <TutorialRegister />,
        exact: true
    },
    {
        path: "/tutorialForgetPassword",
        component: <TutorialForgetPassword />,
        exact: true
    },
    {
        path: "/tutorialPassword",
        component: <TutorialPassword />,
        exact: true
    },
    {
        path: "/tutorialSecurityPassword",
        component: <TutorialSecurityPassword />,
        exact: true
    },
    {
        path: "/tutorialWithdrawal",
        component: <TutorialWithdrawal />,
        exact: true
    },
    {
        path: "/tutorialStakes",
        component: <TutorialStakes />,
        exact: true
    },
    {
        path: "/tutorialWalletAddress",
        component: <TutorialWalletAddress />,
        exact: true
    },
    // {
    //     path: "/contract/:username",
    //     component: <ContractDetails />,
    //     exact: true
    // },
    // {
    //     path: "/contract",
    //     component: <Contract />,
    //     exact: true
    // },
    // {
    //     path: "/payment",
    //     component: <NewPayment />,
    //     exact: true
    // },  
    // {
    //     path: "/payment/:oid",
    //     component: <NewPayment />,
    //     exact: true
    // },
    // {
    //     path: "/account/:page/:oid",
    //     component: <Account />,
    //     exact: true
    // },
    // {
    //     path: "/account/:page",
    //     component: <Account />,
    //     exact: true
    // },
    // {
    //     path: "/account",
    //     component: <Account />,
    //     exact: true
    // },
    {
        path: "/chat",
        component: <Chat />,
        exact: true
    },
    {
        path: "/chatHome",
        component: <ChatHome />,
        exact: true
    },
    {
        path: "/chatting",
        component: <Chatting />,
        exact: true
    },
    {
        path:"/bindSubAccount",
        component: <BindSubAccount />,
        exact: true
    },
    // {
    //     path:"/genealogySponsor",
    //     component:<GenealogySponsor />,
    //     exact: true,
    // },
    {
        path: "/community/:page",
        component: <Genealogy/>,
        exact: true
    },
    {
        path:"/profile",
        component: <Profile />,
        exact: true
    },
    {
        path:"/profile-bank-info",
        component: <ProfileBankInfo />,
        exact: true
    },
    {
        path:"/profile-password",
        component: <ProfilePassword />,
        exact: true
    },
    {
        path:"/profile-security-password",
        component: <ProfileSecurityPassword />,
        exact: true
    },
    {
        path:"/profile-security-email",
        component: <ProfileSecurityEmail />,
        exact: true
    },
    {
        path:"/profile-address",
        component: <ProfileAddress />,
        exact: true
    },
    {
        path:"/profile-crypto-address",
        component: <ProfileCryptoAddress />,
        exact: true
    },
    {
        path:"/profile-two-factor-authentication",
        component: <ProfileTwoFactorAuthentication />,
        exact: true,
    },
    {
        path:"/recruit",
        component: <Recruit />,
        exact: true
    },
    {
        path:"/customerservice",
        component:<AdminChat />,
        exact:true,
    },
    // {
    //     path:"/voucher_checkout",
    //     component: <VoucherCheckout />,
    //     exact: true
    // },
    {
        path: "/wallet",
        component: <Wallet />,
        exact: true
    },
    {
        path: "/to/LBvv",
        component: <UsdtToLbvv />,
        exact: true
    },
    {
        path: "/transfer/:wallet",
        component: <Transfer />,
        exact: true
    },
    {
        path: "/convert/:wallet",
        component: <Convert />,
        exact: true
    },
    {
        path:"/transaction/:wallet",
        component: <Transaction />,
        exact: true
    },
    {
        path:"/withdrawal/:wallet",
        component: <Withdrawal />,
        exact: true
    },
    {
        path:"/withdrawal-history/:wallet",
        component: <WithdrawalHistory />,
        exact: true
    },
    {
        path:"/announcement",
        component: <Announcement />,
        exact: true
    },
    {
        path:"/announcement/:id",
        component: <Announcement />,
        exact: true
    },
    // {
    //     path: "/order",
    //     component: <OrderHistory />,
    //     exact: true
    // },
    // {
    //     path: "/order/:oid",
    //     component: <OrderHistory />,
    //     exact: true
    // },
    {
        path:"/bonus/:bonus",
        component: <Bonus />,
        exact: true
    },
    {
        path:"/bonus",
        component: <Bonus />,
        exact: true
    },
    {
        path:"/profile-linking",
        component: <AccountLink />,
        exact: true
    },
    {
        path:"/placement-setting",
        component: <PlacementSetting />,
        exact: true
    },
    {
        path: "/stakes",
        component: <Stakes />,
        exact: true
    },
    {
        path: "/stakes/purchase",
        component: <StakePurchase />,
        exact: true
    },
    {
        path: "/topup/:wallet",
        component: <Topup />,
        exact: true
    },
    {
        path: "/topup-history/:wallet",
        component: <TopupHistory />,
        exact: true
    },
    {
        path: "/email-verification",
        component: <EmailVerification />,
        exact: false
    }
    /* {
        path: "/ticket",
        component: <Ticket />,
        exact: true
    },
    {
        path: "/ticket-detail/:id",
        component: <TicketDetail />,
        exact: true
    }, */
];

function PrivateRoute({ children, ...rest }) {
    const { accessToken } = useSelector(state => state.general);
    const { kycRequired, verified } = useSelector(state => state.user);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                accessToken && kycRequired === true && verified < 3 ?
                    <Redirect
                        to={{
                            pathname: "/kyc",
                            state: { from: location }
                        }}
                    />
                    :
                    accessToken ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default function App() {
    const { accessToken, language, userOrigin } = useSelector(state => state.general);
    const { verified, kycRequired, max_rank } = useSelector(state => state.user);
    const location = useLocation();
    const [mtOrMg, setMaintenanceOrMigrated] = useState('');
    const { i18n } = useTranslation();

    let datetimeNow = new Date();
    let timeFrom = new Date(datetimeNow.getFullYear(), datetimeNow.getMonth(), datetimeNow.getDate(), 0, 0, 0);
    let timeTo = new Date(datetimeNow.getFullYear(), datetimeNow.getMonth(), datetimeNow.getDate(), 1, 0, 0);

    useEffect(() => {
        i18n.changeLanguage(language);

        // getUrl('mtOrMg').then(res => {
        //     if(res.status){
        //         setMaintenanceOrMigrated(res.data);
        //         if(_.size(res.upcoming_event) > 0){
        //             _.map(res.upcoming_event, (event) => {
        //                 setTimeout(() => {
        //                     setMaintenanceOrMigrated(event.countdown_to);
        //                 }, event.countdown_in_ms);
        //             })
        //         }
        //     }
        // }).catch(error => {
        // });
    }, [])

    React.useLayoutEffect(() => {
        // if there is an update available and no state passed to route
        if (!location.state && window.localStorage.getItem('version-update-needed')) {
            window.localStorage.removeItem('version-update-needed'); // remove the storage object
            window.location.reload(); // refresh the browser
        }
      }, [location]);

    return (
        <NotificationLoadingProvider>
            {/* <Suspense fallback={<Loading />}> */}
                {/* <Router basename={BASE_URL}> */}
                    <LastLocationProvider>
                        <Fragment>
                            <ScrollToTop />
                            {!userOrigin && <RedirectSite />}
                            {
                                !_.includes(['/login', '/forgot-password', '/register', '/maintenance', '/migrated'], location.pathname) ? <Header /> : null
                            }
                            
                            <div id='chatStyle' style={{backgroundColor:'#fff', paddingBottom:56, width:450, margin:'0 auto', maxWidth:'100%', position:'relative'}}>
                                {/* 6 is July. */}
                                {
                                    // datetimeNow >= timeFrom && datetimeNow <= timeTo
                                    _.includes(['migrated', 'maintenance', 'comingSoon'], mtOrMg)
                                    ?
                                    (
                                        <Switch>
                                            <Route path={`/${mtOrMg}`} component={
                                                mtOrMg === "migrated" ? Migrated :
                                                (mtOrMg === "comingSoon" ? ComingSoon : 
                                                Maintenance)
                                                } />
                                            <Redirect to={{ pathname: `/${mtOrMg}` }} />
                                        </Switch>
                                    )
                                    :
                                    <Switch>
                                        {/* <Route path="/search/:keyword" component={Search} /> */}
                                        {/* <Route path="/search" component={Search} /> */}
                                        {/* {
                                            PROJECT_TYPE === "catalog" ? 
                                                <Route path="/mystore" component={MyStore} />
                                                :
                                                <Route path="/cart" component={Cart} />
                                        } */}
                                        <Route path="/setting" component={Setting} />
                                        {/* <Route path="/event" component={Event} /> */}
                                        {/* <Route path="/policies" component={Policies} /> */}
                                        {/* <Route path="/merchantServiceAgreement" component={MerchantServiceAgreement} /> */}
                                        {/* <Route path="/privacyAgreement" component={PrivacyAgreement} /> */}
                                        {/* <Route path="/refundAndReturnAgreement" component={RefundAndReturnAgreement} /> */}
                                        {/* <Route path="/termOfUse" component={TermOfUse} /> */}
                                        {/* <Route path="/contactUsForm" component={ContactUsForm} /> */}
                                        <Route path="/contactUs" component={ContactUs} />
                                        {/* <Route path="/comingSoon" component={ComingSoon} /> */}
                                        {/* <Route path="/item/:id" component={Item} /> */}
                                        {/* <Route path="/voucher/:id" component={VoucherItem} /> */}
                                        <Route path="/login" component={Login} />
                                        <Route path="/register" component={Register} />
                                        {/* <Route path="/categorylist" component={CategoryList} /> */}
                                        {/* <Route path="/category/:id" component={Categories} /> */}
                                        {/* <Route path="/collection/:id" component={Collections} /> */}
                                        {/* <Route path="/faq" exact component={Faq} /> */}
                                        <Route path="/" exact component={Home}></Route>
                                        {/* <Route path="/callback" component={Callback} /> */}
                                        {/* <Route path="/revenuemonster/:oid/callback" render={(props) => (<Redirect to={{ pathname: `/paymentresult/${props.match.params.oid}`, search: '?source=revenuemonster' }} /> )}></Route> */}
                                        {/* <Route path="/paymentresult/:oid" component={PaymentCallback} /> */}
                                        {/* <Route path="/paymentresult/" component={PaymentCallback} /> */}
                                        {/* <Route path="/shop/:shopSlug" component={Shop} /> */}
                                        {/* <Route path="/review/:id" component={Review} /> */}
                                        <Route path="/forgot-password" component={PasswordForgot} />
                                        {/* <Route path="/voucher_redemption" component={VoucherRedemption}/> */}
                                        {/* <Route path="/voucher_cart" component={VoucherCart}/> */}
                                        <Route path="/reset-password/:token" component={PasswordReset}>{accessToken ? <Redirect to={{ pathname: "/" }} /> : <NotificationLoadingProvider><PasswordReset /><NotificationLoading /></NotificationLoadingProvider> }</Route>
                                        <Route path="/reset-security-password/:token" component={SecurityPasswordReset}><NotificationLoadingProvider><SecurityPasswordReset /><NotificationLoading /></NotificationLoadingProvider></Route>
                                        <Route path="/kyc">{accessToken && kycRequired === true ?  <NotificationLoadingProvider><Kyc /><NotificationLoading /></NotificationLoadingProvider> : <Redirect to={{ pathname: "/" }} /> }</Route>
                                        {/* <Route path="/account-link" component={AccountLinkVerify}/> */}
                                        <Route path='/tutorial' component={Tutorial} />
                                        <Route path='/tutorialAuthenticator' component={TutorialAuthenticator} />
                                        <Route path='/tutorialDisableAuthenticator' component={TutorialDisableAuthenticator} />
                                        <Route path='/tutorialDeposit' component={TutorialDeposit} />
                                        <Route path='/tutorialRegister' component={TutorialRegister} />
                                        <Route path='/tutorialForgetPassword' component={TutorialForgetPassword} />
                                        <Route path='/tutorialPassword' component={TutorialPassword} />
                                        <Route path='/tutorialSecurityPassword' component={TutorialSecurityPassword} />
                                        <Route path='/tutorialWithdrawal' component={TutorialWithdrawal} />
                                        <Route path='/tutorialStakes' component={TutorialStakes} />
                                        <Route path='/tutorialWalletAddress' component={TutorialWalletAddress} />;
                                        <Route path='/maintenance' component={Maintenance} />
                                        <Route path='/email-verification' component={EmailVerification} />
                                        {
                                            _.map(routes, route => (
                                                route.path == "/recruit" && _.size(max_rank) == 0 ? 
                                                <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{route.component}<Redirect to={{ pathname: "/" }} /></PrivateRoute>
                                                :
                                                <PrivateRoute key={route.path} exact={route.exact} path={route.path} >{route.component}</PrivateRoute>
                                                
                                            ))
                                        }

                                        {/* <Route path="/checkout" component={Checkout} />
                                    <Route path="/payment/:oid" component={Payment} />
                                    <Route path="/payment" component={Payment} />
                                    <Route path="/account/:page/:oid" component={Account} />
                                    <Route path="/account/:page" component={Account} />
                                    <Route path="/account" component={Account} />
                                    <Route component={GenericNotFound} />
                                    <Route path="/callback" component={Callback} /> */}
                                        <Redirect to={{ pathname: "/" }} />
                                    </Switch>
                                }
                                
                            </div>
                            {/* <ChatButton /> */}
                            <Footer />
                        </Fragment>
                    </LastLocationProvider>
                {/* </Router>
            </Suspense> */}
            <NotificationLoading />
        </NotificationLoadingProvider>
    );
};