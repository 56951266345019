import {
    STORE_PROFILE,
    RESET_PROFILE,
    STORE_WALLET_BALANCE
} from '../actions/types';

const INITIAL_STATE = {
    id: null,
    name: '',
    email: '',
    mobile: '',
    nric: '',
    kycRequired: false,
    walletBalance: [],
    expiry_date: "",
    additional_info: {},
    crypto_address: "",
    crypto_network: "",
    two_factor_auth: "",
    pending_date: "",
    email_verified_at: "",
}

export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_PROFILE:
            return { 
                ...state,
                id: actions.payload.id,
                name: actions.payload.name,
                email: actions.payload.email,
                nric: actions.payload.nric,
                mobile: actions.payload.mobile,
                source: actions.payload.source,
                username: actions.payload.username,
                referral_username: actions.payload.genealogy ? actions.payload.genealogy.referral_username : state.referral_username,
                bank_name: actions.payload.bank ? actions.payload.bank.name : "",
                bank_holder_name: actions.payload.bank ? actions.payload.bank.holder_name : "",
                bank_account: actions.payload.bank ? actions.payload.bank.account : "",
                bank_swift: actions.payload.bank ? actions.payload.bank.swift : "",
                verified: actions.payload.verified,
                kycRequired: actions.payload.kyc_required,
                rank: actions.payload.rank_array,
                max_rank: actions.payload.max_rank,
                max_mrank: actions.payload.max_mrank,
                expiry_date: actions.payload.expiry_date,
                account_link: actions.payload.account_link,
                additional_info: actions.payload.additional_info,
                crypto_address: actions.payload.wallets && actions.payload.wallets.general ? actions.payload.wallets.general : "",
                crypto_network: actions.payload.wallets && actions.payload.wallets.network ? actions.payload.wallets.network : "",
                two_factor_auth: actions.payload.two_factor_auth,
                pending_date: actions.payload.pending_date,
                email_verified_at: actions.payload.email_verified_at,
                web_path:actions.payload.web_path
            };
        case RESET_PROFILE:
            return INITIAL_STATE;
        case STORE_WALLET_BALANCE: 
            return { ...state, walletBalance: actions.payload };
        default:
            return state;
    }
}