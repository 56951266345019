import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, Box, Paper, Typography, InputAdornment, TextField, useTheme, Button, Divider, FormHelperText, IconButto, Link, IconButton } from '@material-ui/core';

import TitleBarWhite from './Layouts/TitleBarWhite';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';
import { storeWalletBalance } from '../actions/GeneralActions';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Alert } from '@material-ui/lab';
import { AppsOutlined } from '@material-ui/icons';

const DEFAULT_ERROR_FIELD = { amount: "", recipient: "", remark: "", security_password: "", to_wallet_id: "", authentication_code: "" };

export default function Transfer() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [walletInfoLbvv, setWalletInfoLbvv] = useState([]);
    const [userWalletInfoLbvv, setUserWalletInfoLbvv] = useState([]);
    const [state, setState] = useState({ amount: "", recipient: "", remark: "", security_password: "", dateTime: "", id: "", to_wallet_id: "", authentication_code: "", showPassword: false });
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [status, setStatus] = useState("insert"); // insert -> review -> result
    const [rules, setRules] = useState(null);
    const [currentPrice, setCurrentPrice] = useState();
    const [amountPaid, setAmountPaid] = useState(0.00);

    const classes = useStyles();
    const { t } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance, two_factor_auth } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
        two_factor_auth: state.user.two_factor_auth,
    }));
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: 'usdt' });
        const existWalletBalance = _.find(walletBalance, { code: 'usdt' });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        const existWallet2 = _.find(walletList, { code: 'lbvv' });
        const existWalletBalance2 = _.find(walletBalance, { code: 'lbvv' });
        setWalletInfoLbvv(existWallet2);
        setUserWalletInfoLbvv(existWalletBalance2);
        if (!existWallet || existWallet.allowTransfer === false) {
            history.goBack();
        }

    }, [wallet, walletBalance]);

    useEffect(() => {
        if (_.size(walletInfoLbvv) > 0) setState({ ...state, "to_wallet_id": walletInfoLbvv.id })
    }, [walletInfo, rules]);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`member/lbvvtoken/current_price`).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setCurrentPrice(response.data);
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
    }, []);

    function amount_paid_calculation(newValue) {
        isMountedRef.current = true;
        let apidata = { "token": newValue }
        postUrl(`member/calculation/amount`, apidata).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setAmountPaid(response.data);
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
    }

    function token_calculation(newValue) {
        isMountedRef.current = true;
        let apidata = { "usdt": newValue }
        postUrl(`member/calculation/token`, apidata).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                setState({ ...state, amount: response.data })
            }
        }).catch((error) => {
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
        return () => { isMountedRef.current = false };
    }


    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if (name === 'amountPaid') {
            setAmountPaid(newValue);
            if (newValue !== 0) {
                token_calculation(newValue);
            }

        } else if (name === 'amount') {
            if (parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
            setState({ ...state, [name]: newValue });
            amount_paid_calculation(newValue)
        } else {
            setState({ ...state, [name]: newValue });
        }


    }

    const exchangetoken = () => {
        setLoading(true);
        isMountedRef.current = true;
        let apidata = { "token": state.amount, "security_password": state.security_password, "authentication_code": state.authentication_code, "to_token": state.to_wallet_id, "from_walet": "usdt" }
        postUrl(`member/exchange/token`, apidata).then(response => {
            if (response.status === 1 && isMountedRef.current) {
                addAlert(response.data, 'success');
                setState({...state,amount:response.token})
                setAmountPaid(response.amount)
                setStatus('result');
            } else if (response.error) {
                addAlert(response.err_msg)
            } else {
                if (response.errors) {
                    setErrorField(response.errors);
                }
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        }).finally(() => {
            setLoading(false);
        });
        return () => { isMountedRef.current = false };
    }


    return (
        <Box className={classes.root} style={{ padding: '0 20px 30px', minHeight: '100%' }}>
            <TitleBarWhite title={t('title.bonus')} back displayInfo={false} />

            <p variant="h6" style={{ color: theme.palette.secondary.main, padding: 15, fontSize: 18, paddingBottom: 7 }}><b>{t('swap.walletSwap')}</b></p>
            {
                two_factor_auth === 1 ?
                    <>
                        {/* <div className={classes.wallet}>
                            <div className={classes.walletCard}>
                                <img src={`/images/general/icon/${walletInfo.code}.png`} alt="rank" className={classes.walletIconImg} />
                                <div style={{ color: theme.palette.white.text, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                                    <p className={classes.walletBalanceTitle}>{t('swap.amountPaid')} ({_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'})</p>
                                    <p className={classes.walletAmount}><b>{amountPaid ? (amountPaid):0.00}</b></p>
                                </div>
                            </div>
                            <div style={{ color: theme.palette.white.text, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                                <p className={classes.tokenPrice}>{t('swap.currentPrice', { price: currentPrice })}</p>
                            </div>
                        </div>
                        <div style={{paddingLeft: 15, width: 'fit-content',paddingTop:5}}>
                            <Alert severity="info" style={{fontSize:10}}>{t('swap.info')}</Alert>
                        </div> */}

                        <Paper classes={{ root: classes.paperContainer }}>
                        { status === 'result' ?
                            <Box className={classes.boxContainer}>
                                <Typography variant="h6" style={{ color: theme.palette.secondary.main }}>{ t('swap.scuccessful') }</Typography>
                                <Typography variant="h6" style={{ ...styles.inputPropsStyle, color: theme.palette.secondary.main }}>
                                    { `${ currencyFormat(amountPaid?amountPaid:0) } ${_.size(walletInfo) ? walletInfo.wallet_name : ''}`}
                                </Typography>
                                <Box paddingY={2} className={classes.boxContainer}>
                                    <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 16 }}>{ t('swap.exchangeTo') }</Typography>
                                    <Typography variant="h6" style={{ ...styles.inputPropsStyle, fontSize: 20 }}> { `${ currencyFormat(state.amount?state.amount:0) } ${_.size(walletInfoLbvv) ? walletInfoLbvv.wallet_name : ''}`}</Typography>
                                </Box>
                                
                            </Box>
                        : <Box className={classes.boxContainer}>
                                <Box width="100%">
                                    <TextField
                                        id="to_wallet_id"
                                        select
                                        variant="outlined"
                                        label={t('swap.toToken')}
                                        value={state.to_wallet_id}
                                        name="to_wallet_id"
                                        onChange={handleChange}
                                        classes={{ root: classes.textFieldRoot }}
                                        InputProps={{ readOnly: status === 'review' ? true : false }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        fullWidth
                                        error={_.size(errorField.to_wallet_id) ? true : false}
                                        helperText={errorField.to_wallet_id}
                                        FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    >
                                        {_.map(walletList, (detail) => {
                                            if (detail.id == walletInfoLbvv.id) {
                                                return <option key={detail.id} value={detail.id}>
                                                    {detail.wallet_name}
                                                </option>
                                            }
                                        })}
                                    </TextField>
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={state.amount}
                                        name="amount"
                                        onChange={handleChange}
                                        classes={{ root: classes.textFieldRoot }}
                                        label={t('swap.token')}
                                        fullWidth
                                        error={_.size(errorField.to_token) ? true : false}
                                        helperText={errorField.to_token}
                                        FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    />
                                    <TextField
                                        type="text"
                                        variant="outlined"
                                        value={amountPaid}
                                        name="amountPaid"
                                        onChange={handleChange}
                                        classes={{ root: classes.textFieldRoot }}
                                        label={t('swap.fromwallet')}
                                        fullWidth
                                        error={_.size(errorField.from_wallet) ? true : false}
                                        helperText={errorField.fro_wallet}
                                        FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    />
                                    {
                                        two_factor_auth === 1 ?
                                            <>
                                                <TextField
                                                    type={state.showPassword ? 'text' : 'password'}
                                                    variant="outlined"
                                                    value={state.security_password}
                                                    name="security_password"
                                                    onChange={handleChange}
                                                    classes={{
                                                        root: classes.textFieldRoot,
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }}
                                                    label={t('transfer.security_password')}
                                                    error={_.size(errorField.security_password) ? true : false}
                                                    helperText={errorField.security_password}
                                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                                    fullWidth
                                                />
                                                <TextField
                                                    type="string"
                                                    variant="outlined"
                                                    value={state.authentication_code}
                                                    name="authentication_code"
                                                    onChange={handleChange}
                                                    classes={{
                                                        root: classes.textFieldRoot,
                                                    }}
                                                    label={t('profile.authenticationCode')}
                                                    error={_.size(errorField.authentication_code) ? true : false}
                                                    helperText={errorField.authentication_code}
                                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                                    fullWidth
                                                />
                                                <FormHelperText>{t('general.2FACaption')}</FormHelperText>
                                            </>
                                            :
                                            <TextField
                                                type={state.showPassword ? 'text' : 'password'}
                                                variant="outlined"
                                                value={state.security_password}
                                                name="security_password"
                                                onChange={handleChange}
                                                classes={{
                                                    root: classes.textFieldRoot,
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                            {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                                label={t('transfer.security_password')}
                                                error={_.size(errorField.security_password) ? true : false}
                                                helperText={errorField.security_password}
                                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                                fullWidth
                                            />
                                    }
                                </Box>
                            </Box>}
                            {status == 'insert' && <Box className={classes.boxContainer}>
                                <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={exchangetoken}>
                                    {t('swap.exchange')}
                                </Button>
                            </Box>}
                            {status == 'result' && <Box className={classes.boxContainer}>
                                <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ () => history.push('/wallet') }>
                                { t('transfer.complete') }
                                </Button>
                            </Box>}
                        </Paper>
                    </>
                    :
                    <Paper elevation={0} classes={{ root: classes.paperContainer }}>
                        <Box style={{ padding: '20px 40px' }} display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                            <Typography variant="overline" color="textPrimary">{t('profile.set2FAFirst', { title: t('swap.lbvv') })}</Typography>
                            <div align="center" style={{ paddingTop: 20 }}>
                                <Link underline='none' to="/profile-two-factor-authentication" component={RouterLink} >
                                    <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff', maxWidth: '170px' }}>
                                        <p align="center" style={{ lineHeight: 1.4 }}>{t('profile.2FA')}</p>
                                    </div>
                                </Link>
                            </div>
                        </Box>
                    </Paper>
            }
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        padding: 10,
        borderRadius: 20,
        boxShadow: 'none'
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 0,
    },
    buttonRoot: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 8,
        marginTop: 15,
        marginBottom: 30,
        width: '80%',
        "&:hover": {
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    buttonRoot2: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 8,
        marginTop: 15,
        marginBottom: 30,
        width: '80%',
        "&:hover": {
            backgroundColor: theme.palette.secondary.secondary,
        }
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: '0 10px',
    },
    balanceStyle: {
        fontSize: 30,
        color: theme.palette.primary.main,//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white,
        fontSize: 11,
        letterSpacing: 1
    },
    walletCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 14,
        position: 'relative',
        margin: '0 auto',
    },
    wallet: {
        height: 165,
        background: theme.palette.secondary.main,
        borderRadius: 15,
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 14,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 163,
        },
    },
    walletIconImg: {
        width: 51,
        [theme.breakpoints.down('sm')]: {
            width: 43,
        },
    },
    walletBalanceTitle: {
        textTransform: 'uppercase',
        fontSize: 13,
        paddingBottom: 2,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },
    walletAmount: {
        fontSize: 30,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
        },
    },
    tokenPrice: {
        flexBasis: '100%',
        fontSize: 10,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },

    },
}));

const styles = {
    inputPropsStyle: {
        textAlign: 'center',
        fontSize: 36,
        fontWeight: 'bold',
    }
}