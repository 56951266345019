import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { CardHeader, CardContent, TextField, InputAdornment,Hidden, MenuItem, IconButton, Button,  Typography, Container, Grid, Link, Box, Divider, Card, Menu } from '@material-ui/core';
import useNotificationLoading from '../helper/useNotificationLoading';
import { changeLanguage as reduxChangeLanguage } from '../actions/GeneralActions';

import WebpImg from './Layouts/WebpImg';
import { Globe } from 'react-feather';
import i18next from 'i18next';
import theme from '../Theme';



export default function ComingSoon() {
    
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();

    let history = useHistory();

    const defaultLang = i18n.language ? (_.includes(['en', 'cn'], i18n.language) ? i18n.language : 'en') : 'en';
    const styles = useStyles();

    const [anchorEl, setAnchorEl] = useState({
        languageAnchorEl: null,
    })

    const handleMenuClose = () => {
        setAnchorEl({ ...anchorEl, languageAnchorEl: null });
    };

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
        handleMenuClose();
    };

    const languageId = 'primary-search-language-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl.languageAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={languageId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl.languageAnchorEl)}
            onClose={handleMenuClose}

        >
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            <MenuItem onClick={() => changeLanguage('cn')}>中文</MenuItem>
            <MenuItem onClick={() => changeLanguage('ms')}>Malay</MenuItem>
            <MenuItem onClick={() => changeLanguage('id')}>Indonesia</MenuItem>
        </Menu>
    );

    return (
        <Box className={styles.bkgmain} >
            <div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
            <WebpImg src="/images/comingsoon/logo.png" containerStyle={{ width:'100%', paddingLeft:30, paddingTop:30}} style={{width:100}} alt="logo" />
            <div style={{ display: 'flex', justifyContent: "end" }}>
                <IconButton
                    aria-label="language"
                    aria-controls={languageId}
                    aria-haspopup="true"
                    onClick={(event) => setAnchorEl({ ...anchorEl, languageAnchorEl: event.currentTarget })}
                    color="inherit"
                    style={{ paddingRight: 27 }}
                >
                    <Globe color={theme.palette.primary.main} size={20} />
                </IconButton>
                {renderMenu}
            </div>
            </div>
           
            
            <center><WebpImg src={`/images/comingsoon/text-${i18n.language}.png`} containerStyle={{paddingTop:20}} style={{width:300, margin:'0 auto'}} alt="logo" /></center>
            <WebpImg src="/images/comingsoon/graphic.png"  style={{width:'100%', padding:25}} alt="graphic" />
           
        </Box>

    );
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    bkgmain: {
        backgroundImage:`url('/images/comingsoon/bottom-app.png')`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'contain',
        backgroundPosition:'bottom left',
        minHeight:'calc(100vh - 56px)',
        background:'#00255d'
    },
    root: {
        padding: '10px 0 10px 0',
        backgroundColor: 'white',
    },
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    containerStyle: {
        padding: 20,
    }
}));