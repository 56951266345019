import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, Box, Paper, Typography, InputAdornment, TextField, useTheme, Button, Divider, FormHelperText, IconButto, Link, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TitleBarWhite from './Layouts/TitleBarWhite';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';
import { storeWalletBalance } from '../actions/UserActions';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// const DEFAULT_ERROR_FIELD = { amount: "", recipient: "", remark: "", password: "" };
const DEFAULT_ERROR_FIELD = { amount: "", recipient: "", remark: "", security_password: "", to_wallet_id: "", authentication_code: "" };

export default function Transfer() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [state, setState] = useState({ amount: "", recipient: "", remark: "", security_password: "", dateTime: "", id: "", to_wallet_id: "", authentication_code: "", showPassword: false });
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [recipientList, setRecipientList] = useState([]);
    const [transferStatus, setTransferStatus] = useState("insert"); // insert -> review -> result
    const [rules, setRules] = useState(null);

    const classes = useStyles();
    const { t } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance, two_factor_auth } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
        two_factor_auth: 1,
    }));
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        if(!existWallet || existWallet.allowTransfer === false) {
            history.goBack();
        }
    }, [wallet, walletBalance]);

    useEffect(() => {
        fetchTransferRule();
    }, []);

    useEffect(() => {
        if( _.size(walletInfo) > 0 && _.size(rules) > 0) setState({...state, "to_wallet_id": rules[walletInfo.id][0]})
    }, [walletInfo, rules]);

    // useEffect(() => {
    //     isMountedRef.current = true;
    //     getUrl('bind_account_accross').then(response => {
    //         if(response.data && isMountedRef.current) {
    //             setRecipientList(response.data);
    //         }
    //     }).catch(error => {
    //         addAlert(JSON.stringify(error.message));
    //     })

    //     return () => isMountedRef.current = false;
    // }, [addAlert]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if(name === 'amount') {
            if(parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }
        setState({ ...state, [name]: newValue });
    }

    const reviewTransfer = () => {
        let error = false;
        let newErrorField = {};
        _.map(["amount", "recipient"], field => {
            if(_.size(state[field]) === 0) {
                error = true;
                newErrorField[field] = t('transfer.isRequired', { field: t(`transfer.${field}`) });
            } else {
                newErrorField[field] = "";
            }
        })
        setErrorField(newErrorField);
        if(!error) {
            setTransferStatus('review')
        }
    }
    
    const transferUser = () => {
        setLoading(true);
        const { recipient, amount, remark, security_password, authentication_code } = state;
        // const { recipient, amount, remark, password } = state;
        const transferData = {
            source: 'local',
            login_method: recipient,
            to_wallet_id: state.to_wallet_id,
            id: walletInfo.id,
            amount,
            remark,
            // password,
            security_password,
            authentication_code
        };
        setErrorField(DEFAULT_ERROR_FIELD);
        postUrl(`default/transfer`, transferData).then(response => {
            if(response.code == 0) {
                // const { created_at, transaction_id, data } = response;
                setTransferStatus('result');
                // setState({ ...state, id: transaction_id, dateTime: created_at });
                refetchWallet();
            } else {
                if(response.errors) {
                    let newErrorField = {};
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if(response.msg) {
                    addAlert(response.msg);
                }
                setTransferStatus('insert')
            }
        }).catch(error => {
            setTransferStatus('insert')
            addAlert(JSON.stringify(error.message));
        }).finally(() => {
            setLoading(false);
        })
    }
    
    const refetchWallet = () => {
        getUrl('default/balance').then(response => {
            // if(response.status) {
            //     dispatch(storeWalletBalance(response.data));
            // }
            
            if(response.code == 0) {
                dispatch(storeWalletBalance(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    const fetchTransferRule = () => {
        // getUrl('wallets/rules').then(response => {
        //     if(response.status) {
        //         setRules(response.data.other);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // })
    }

    return (
        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
            <TitleBarWhite title={ t('title.bonus') } back displayInfo={false}/>

            <p variant="h6" style={{ color: theme.palette.secondary.main, padding: 15, fontSize:18, paddingBottom:7 }}><b>{ t('title.walletTransfer', { wallet: walletInfo.wallet_name }) }</b></p>
            {
                two_factor_auth === 1 ?
                <>
                <div className={classes.walletCard}>
                    <img src={`/images/general/icon/${walletInfo.code}.png`} alt="rank" className={classes.walletIconImg} />
                    <div style={{ color: theme.palette.white.text, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                        <p className={classes.walletBalanceTitle}>{t('transfer.transferableBalance')} ({_.size(walletInfo) > 0 ? walletInfo.wallet_name : '-'})</p>
                        <p className={classes.walletAmount}><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)}</b></p>
                    </div>
                </div>

                <Paper classes={{ root: classes.paperContainer }}>
                
                    { transferStatus === 'result' ?
                        <Box className={classes.boxContainer}>
                            <Typography variant="h6" style={{ color: theme.palette.secondary.main }}>{ t('transfer.successfullyTransfer') }</Typography>
                            <Typography variant="h6" style={{ ...styles.inputPropsStyle, color: theme.palette.secondary.main }}>
                                { `${ currencyFormat(state.amount) } ${_.size(walletInfo) ? walletInfo.wallet_name : ''}`}
                            </Typography>
                            <Box paddingY={2} >
                                <Typography variant="h6" style={{ color: theme.palette.silver.text, fontSize: 16 }}>{ t('transfer.transferTo') }</Typography>
                                <Typography variant="h6" style={{ ...styles.inputPropsStyle, fontSize: 20 }}>{ state.recipient }</Typography>
                            </Box>
                            <Box paddingY={2} paddingX={1} display="flex" flexDirection="column" width="100%">
                                <Typography variant="h6" style={{ fontSize: 12 }}>{ t('transfer.remark') }</Typography>
                                <Typography variant="h6" style={{ fontSize: 12, wordWrap: "break-word" }}>{ state.remark }</Typography>
                            </Box>
                            {/* <Box paddingY={2} paddingX={1} display="flex" width="100%" flexDirection="row" borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                                <Box flex={1}>
                                    <Typography variant="h6" style={{ fontSize: 12 }}>{ t('transfer.dateTime') }</Typography>
                                    <Typography variant="h6" style={{ fontSize: 12 }}>{ t('transfer.idNo') }</Typography>
                                </Box>
                                <Box flex={2}>
                                    <Typography variant="h6" style={{ fontSize: 12 }}>{ state.dateTime }</Typography>
                                    <Typography variant="h6" style={{ fontSize: 12 }}>{ state.id }</Typography>
                                </Box>
                            </Box> */}
                        </Box>
                    : <Box className={classes.boxContainer}>
                    
                        {/* <TextField 
                            type="text"
                            inputProps={{ style: { ...styles.inputPropsStyle, color: theme.palette.gold.bg }, inputMode: 'numeric' }} 
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><span style={{ color: theme.palette.gold.bg }}>{ _.size(walletInfo) ? walletInfo.wallet_name : '' }</span></InputAdornment>,
                                disableUnderline: true,
                                readOnly: transferStatus === 'review' ? true : false,
                            }}
                            placeholder={"0.00"}
                            value={state.amount}
                            name="amount"          
                            onChange={ handleChange }   
                            classes={{
                                root: classes.amountFieldRoot
                            }}
                            error={ _.size(errorField.amount) ? true : false }
                            helperText={ errorField.amount }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                        /> */}
                        <Box  width="100%">
                            {/* <Autocomplete
                                id="user-to-transfer"
                                options={recipientList}
                                getOptionLabel={(option) => option.username ? option.username : "" }
                                fullWidth
                                onChange={(event, newValue) => {
                                    console.log("newValue", newValue);
                                    setState({ ...state, 'recipient': newValue ? newValue.username : "" });
                                }}
                                inputValue={state.recipient}
                                onInputChange={(event, newInputValue) => {
                                    setState({ ...state, 'recipient': newInputValue });
                                }}
                                renderInput={(params) => (
                                    <TextField 
                                        { ...params } 
                                        label={ t('transfer.transferTo') }
                                        variant="outlined" 
                                        classes={{
                                            root: classes.textFieldRoot
                                        }}
                                        error={ _.size(errorField.recipient) ? true : false }
                                        helperText={ errorField.recipient }
                                        FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    />
                                )}
                            /> */}
                            {_.size(rules) > 0 &&
                            <TextField
                                id="to_wallet_id"
                                select
                                variant="outlined"
                                label={t('transfer.toWallet')}
                                value={state.to_wallet_id}
                                name="to_wallet_id"  
                                onChange={handleChange}
                                classes={{ root: classes.textFieldRoot }}
                                InputProps={{ readOnly: transferStatus === 'review' ? true : false }}
                                SelectProps={{
                                    native: true,
                                }}
                                fullWidth
                                error={ _.size(errorField.to_wallet_id) ? true : false }
                                helperText={ errorField.to_wallet_id }
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            >
                                {_.map(rules[walletInfo.id], (_wallet_id) => {
                                    let _wallet = _.find(walletList, {id: _wallet_id});
                                    if(_.size(_wallet))
                                    return <option key={_wallet.id} value={_wallet.id}>
                                        {_wallet.wallet_name}
                                    </option>
                                })}
                            </TextField>
                            }
                            <TextField 
                                type="text"
                                variant="outlined"
                                value={state.amount}
                                name="amount"          
                                onChange={ handleChange }   
                                classes={{ root: classes.textFieldRoot }}
                                InputProps={{ readOnly: transferStatus === 'review' ? true : false }}
                                label={ t('transfer.amount') }
                                fullWidth
                                error={ _.size(errorField.amount) ? true : false }
                                helperText={ errorField.amount }
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            />
                            <TextField
                                label={t('transfer.transferTo')}
                                variant="outlined"
                                type="text"
                                name="recipient"
                                classes={{
                                    root: classes.textFieldRoot
                                }}
                                error={_.size(errorField.recipient) ? true : false}
                                helperText={errorField.recipient}
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                fullWidth
                                value={state.recipient}
                                onChange={ handleChange }
                                InputProps={{ readOnly: transferStatus === 'review' ? true : false }}
                            />

                            

                            <TextField 
                                type="text"
                                variant="outlined"
                                value={state.remark}
                                name="remark"          
                                onChange={ handleChange }   
                                classes={{
                                    root: classes.textFieldRoot
                                }}
                                InputProps={{
                                    readOnly: transferStatus === 'review' ? true : false,
                                }}
                                label={ t('transfer.remark') }
                                error={ _.size(errorField.remark) ? true : false }
                                helperText={ errorField.remark }
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                fullWidth
                                multiline
                                rowsMax={5}
                            />
                            {/* <TextField 
                                type="password"
                                variant="outlined"
                                value={state.password}
                                name="password"          
                                onChange={ handleChange }   
                                classes={{
                                    root: classes.textFieldRoot,
                                }}
                                InputProps={{
                                    readOnly: transferStatus === 'review' ? true : false,
                                }}
                                label={ t('transfer.password') }
                                error={ _.size(errorField.password) ? true : false }
                                helperText={ errorField.password }
                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                fullWidth
                            /> */}
                            
                            <TextField
                                            type={state.showPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            value={state.security_password}
                                            name="security_password"
                                            onChange={handleChange}
                                            classes={{
                                                root: classes.textFieldRoot,
                                            }}
                                            InputProps={{
                                                readOnly: transferStatus === 'review' ? true : false,
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                            label={t('transfer.security_password')}
                                            error={_.size(errorField.security_password) ? true : false}
                                            helperText={errorField.security_password}
                                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                            fullWidth
                                        />
                                {/* {
                                    two_factor_auth === 1 ?
                                        <>
                                            <TextField
                                                type={state.showPassword ? 'text' : 'password'}
                                                variant="outlined"
                                                value={state.security_password}
                                                name="security_password"
                                                onChange={handleChange}
                                                classes={{
                                                    root: classes.textFieldRoot,
                                                }}
                                                InputProps={{
                                                    readOnly: transferStatus === 'review' ? true : false,
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                            {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                                label={t('transfer.security_password')}
                                                error={_.size(errorField.security_password) ? true : false}
                                                helperText={errorField.security_password}
                                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                                fullWidth
                                            />
                                            <TextField
                                                type="string"
                                                variant="outlined"
                                                value={state.authentication_code}
                                                name="authentication_code"
                                                onChange={handleChange}
                                                classes={{
                                                    root: classes.textFieldRoot,
                                                }}
                                                InputProps={{
                                                    readOnly: transferStatus === 'review' ? true : false,
                                                }}
                                                label={t('profile.authenticationCode')}
                                                error={_.size(errorField.authentication_code) ? true : false}
                                                helperText={errorField.authentication_code}
                                                FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                                fullWidth
                                            />
                                            <FormHelperText>{t('general.2FACaption')}</FormHelperText>
                                        </>
                                        :
                                        <TextField
                                            type={state.showPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            value={state.security_password}
                                            name="security_password"
                                            onChange={handleChange}
                                            classes={{
                                                root: classes.textFieldRoot,
                                            }}
                                            InputProps={{
                                                readOnly: transferStatus === 'review' ? true : false,
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                            label={t('transfer.security_password')}
                                            error={_.size(errorField.security_password) ? true : false}
                                            helperText={errorField.security_password}
                                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                            fullWidth
                                        />
                                } */}
                        </Box>
                    </Box> }
                    <Box className={classes.boxContainer}>
                        { transferStatus === 'insert' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ reviewTransfer }>
                            { t('transfer.transferNow') }
                        </Button> }
                        { transferStatus === 'review' && <Box display="flex" width="100%" flexDirection="row" justifyContent="space-around">
                            <Button variant="contained" classes={{ root: classes.buttonRoot2 }} style={{width:'49%'}} onClick={ () => setTransferStatus('insert') }>
                                { t('transfer.changeDetail') }
                            </Button>
                            <Button variant="contained" classes={{ root: classes.buttonRoot }} style={{width:'49%'}} onClick={ transferUser }>
                                { t('transfer.confirmTransfer') }
                            </Button>
                        </Box> }
                        { transferStatus === 'result' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ () => history.push('/wallet') }>
                            { t('transfer.complete') }
                        </Button> }
                    </Box>
                </Paper>
                </>
                :
                <Paper elevation={0} classes={{ root: classes.paperContainer }}>
                    <Box style={{ padding: '20px 40px' }} display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                        <Typography variant="overline" color="textPrimary">{t('profile.set2FAFirst', { title: t('title.walletTransfer') })}</Typography>
                        <div align="center" style={{ paddingTop: 20 }}>
                            <Link underline='none' to="/profile-two-factor-authentication" component={RouterLink} >
                                <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff', maxWidth: '170px' }}>
                                    <p align="center" style={{ lineHeight: 1.4 }}>{t('profile.2FA')}</p>
                                </div>
                            </Link>
                        </div>
                    </Box>
                </Paper>
            }
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        padding: 10,
        borderRadius: 20,
        boxShadow:'none'
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 0,
    },
    buttonRoot: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 8,
        marginTop: 15, 
        marginBottom: 30,
        width:'80%',
        "&:hover":{
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    buttonRoot2: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 8,
        marginTop: 15, 
        marginBottom: 30,
        width:'80%',
        "&:hover":{
            backgroundColor: theme.palette.secondary.secondary,
        }
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    balanceStyle: {
        fontSize: 30, 
        color: theme.palette.primary.main,//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white, 
        fontSize: 11, 
        letterSpacing: 1 
    },
    walletCard: {
        height: 165,
        background: theme.palette.secondary.main,
        borderRadius: 15,
        width: '90%',
        margin:'0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 14,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 163,
        },
    },
    walletIconImg: {
        width: 51,
        [theme.breakpoints.down('sm')]: {
            width: 43,
        },
    },
    walletBalanceTitle: {
        textTransform: 'uppercase',
        fontSize: 13,
        paddingBottom: 2,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },
    walletAmount: {
        fontSize: 30,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
        },
    },
}));

const styles = {
    inputPropsStyle: { 
        textAlign: 'center', 
        fontSize: 36, 
        fontWeight: 'bold', 
    }
}