import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Card, CardContent, Link, Grid, List, ListItem, Snackbar, TextField, Typography, FormHelperText, FormControl, Select, MenuItem, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { storeProfile } from '../actions/UserActions';
import { postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import TitleBarWhite from './Layouts/TitleBarWhite';
import theme from '../Theme';

export default function ProfileCryptoAddress() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { crypto_address, crypto_network } = useSelector(state => state.user);
    const [profileFormState, setProfileFormState] = useState({ crypto_address, crypto_network, authentication_code: '' });
    const [errorFormState, setErrorFormState] = useState({
        crypto_address: '',
        crypto_network: '',
        authentication_code: '',
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });
    const two_factor_auth = 1

    const { setLoading } = useNotificationLoading();

    const handleInfoChange = (event) => {
        const name = event.target.name;
        setProfileFormState({ ...profileFormState, [name]: event.target.value });
    }

    const updateInfo = () => {
        setLoading(true);
        postUrl('user/crypto-address', profileFormState).then(result => {
            if (result.code != 0) {
                setLoading(false);
                setState(state => ({ ...state, snackbarShow: true, error: true, message: result.msg }));
                let allErrorField = [];
                // _.map(result.error, (errorItem, errorField) => {
                //     allErrorField[errorField] = errorItem[0];
                // })
                setErrorFormState(allErrorField);
                setProfileFormState({ ...profileFormState, authentication_code: ''});
            } else {
                setLoading(false);
                setState(state => ({ ...state, snackbarShow: true, error: false, message: result.msg }));
                // dispatch(storeProfile(result.data));
                setErrorFormState({});
                setProfileFormState({ ...profileFormState, authentication_code: ''});
            }
        })
    }

    const [viewAnnouncement, setViewAnnouncement] = useState(true);
    const closeAnnouncement = (event, reason) => {
        if (reason && (reason == "backdropClick" || reason == "escapeKeyDown")) 
        return;
        setViewAnnouncement(false);
    }
    const [agree, setAgree] = useState(false);
    const checkboxHandler = () => {
        setAgree(!agree);
    }
    const btnHandler = () => {
        setViewAnnouncement(false);
    };

    return (
        <Box className={styles.root} style={{ padding: '0 0 80px', }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                <TitleBarWhite title={t('profile.cryptoAddress')} back displayInfo={false} />
                <Link underline='none' to="/TutorialWalletAddress" component={RouterLink} >
                    <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff' }}>
                        <p>{t('tutorial.tutorial')}</p>
                    </div>
                </Link>
            </div>
            <Grid style={{ padding: '0 20px' }}>
                <Grid item xs={12}>
                    <center><img src="/images/general/profile-crypto-address.png" style={{ width: 200, maxWidth: '80%' }} alt={t('profile.cryptoAddress')} /></center>
                    <List className={styles.listStyle} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                        <ListItem style={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
                            <Box style={{ textAlign: 'center' }}>
                                <Typography variant="h6" style={{ fontSize: 24, color: theme.palette.primary.main }}>
                                    <b>{t('profile.cryptoAddress')}</b>
                                </Typography>
                                {/* <Typography color="textSecondary" style={{fontSize:14, lineHeight:1.4}}>
                                    {t('profile.cryptoAddressNote')}
                                </Typography> */}
                            </Box>
                        </ListItem>
                        {
                            two_factor_auth === 1 ?
                                <>
                                    {/* <Divider variant="middle" light /> */}
                                    <ListItem className={styles.listPaddingVertical}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={3} sm={3}>
                                                <Box display="flex" justifyContent="flex-start">
                                                    <Typography variant="caption" color="textSecondary">{t('profile.cryptoAddress')}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sm={8}>
                                                <TextField
                                                    error={errorFormState.crypto_address ? true : false}
                                                    helperText={errorFormState.crypto_address}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    size="small"
                                                    inputProps={{ name: 'crypto_address', readOnly: two_factor_auth ? false : true }}
                                                    onChange={handleInfoChange}
                                                    multiline
                                                    value={profileFormState.crypto_address ?? ""}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <ListItem className={styles.listPaddingVertical}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={3} sm={3}>
                                                <Box display="flex" justifyContent="flex-start">
                                                    <Typography variant="caption" color="textSecondary">{t('wallet.walletType')}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sm={8}>
                                                <MuiThemeProvider theme={selectStyle}>
                                                    <FormControl variant="outlined">
                                                        <Select
                                                            id="demo-simple-select-outlined"
                                                            onChange={handleInfoChange}
                                                            fullWidth
                                                            name="crypto_network"
                                                            defaultValue={crypto_network}
                                                            error={_.size(errorFormState.crypto_network) ? true : false}
                                                            disabled={two_factor_auth ? false : true}
                                                        >
                                                            <MenuItem value={"trc20"}>TRC20 {t('address.address')}</MenuItem>
                                                            <MenuItem value={"bep20"}>BEP20 {t('address.address')}</MenuItem>
                                                        </Select>
                                                        <FormHelperText error>{errorFormState.crypto_network}</FormHelperText>
                                                    </FormControl>
                                                </MuiThemeProvider>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    {
                                        crypto_address
                                            ?
                                            <>
                                                {
                                                    two_factor_auth === 1 ?
                                                        <>
                                                            {/* <ListItem className={styles.listPaddingVertical}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={3} sm={3}>
                                                                        <Box display="flex" justifyContent="flex-start">
                                                                            <Typography variant="caption" color="textSecondary">{t('profile.authenticationCode')}</Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={9} sm={8}>
                                                                        <TextField
                                                                            error={errorFormState.authentication_code ? true : false}
                                                                            helperText={errorFormState.authentication_code}
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            size="small"
                                                                            inputProps={{ name: 'authentication_code' }}
                                                                            onChange={handleInfoChange}
                                                                            multiline
                                                                            value={profileFormState.authentication_code ?? ""}
                                                                        />
                                                                        <FormHelperText>{t('general.2FACaption')}</FormHelperText>
                                                                    </Grid>
                                                                </Grid>
                                                            </ListItem> */}
                                                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                                                                <Button variant="contained" onClick={updateInfo} style={{ width: '90%', padding: '8px 9px', maxWidth: '80%', borderRadius: 10, }} color="primary">{t('button.update')}</Button>
                                                            </Box>
                                                        </>
                                                        : null
                                                }
                                            </>
                                            :
                                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                                                <Button variant="contained" onClick={updateInfo} style={{ width: '90%', padding: '8px 9px', maxWidth: '80%', borderRadius: 10, }} color="primary">{t('button.update')}</Button>
                                            </Box>
                                    }
                                </>
                                : <Box style={{ padding: '20px 40px' }} display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                                    <Typography variant="overline" color="textPrimary">{t('profile.set2FAFirst', { title: t('profile.cryptoAddress') })}</Typography>
                                    <div align="center" style={{ paddingTop: 20 }}>
                                        <Link underline='none' to="/profile-two-factor-authentication" component={RouterLink} >
                                            <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff', maxWidth: '170px' }}>
                                                <p align="center" style={{ lineHeight: 1.4 }}>{t('profile.2FA')}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </Box>
                        }
                    </List>
                    <Snackbar
                        open={state.snackbarShow}
                        autoHideDuration={2000}
                        onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                            <CardContent style={{ padding: 10 }}>
                                <Typography style={{ color: 'white' }}>{state.message}</Typography>
                            </CardContent>
                        </Card>
                    </Snackbar>
                </Grid>
            </Grid>

            <MuiThemeProvider key='warn' theme={announceDialogStyle}>
                <Dialog
                    open={viewAnnouncement? true : false}
                    onClose={closeAnnouncement}
                    scroll="body"
                    aria-labelledby="view Announcement"
                    aria-describedby="view Announcement detail"
                    key='warn'
                    style={{width:'547px', maxWidth:'100%', margin:'0 auto'}}
                >
                    <DialogTitle id='warn'>{t('topup.dialog.title')}</DialogTitle>
                    <DialogContent dividers={false}>
                        <div
                            id="viewAnnouncement"
                            tabIndex={-1}
                        >
                            <p>{t('profile.dialog.content')}</p><br/>
                            <input type="checkbox" id="agree" onChange={checkboxHandler} />
                            <label htmlFor="agree">{t('topup.dialog.agree')}</label>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!agree} onClick={btnHandler}>
                            {t('button.proceed')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        // paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
}));

const selectStyle = createMuiTheme({
    overrides: {
        MuiFormControl: {
            root: {
                width: '100%'
            },

        },
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: theme.palette.primary.main
                },
            }
        },
        MuiOutlinedInput: {
            root: {
                "&$focused": {
                    borderColor: theme.palette.primary.main,
                },
                "&$focused $notchedOutline": {
                    borderColor: theme.palette.primary.main
                }
            },
            notchedOutline: {
                "&$focused": {
                    borderColor: theme.palette.primary.main,
                },
            }
        }
    },
});

const announceDialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh'
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 24px'
            }
        },
    },
});