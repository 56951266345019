import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import SwipeableViews from 'react-swipeable-views';

import { withStyles, Box, Paper, Link, Typography, Grid, TextField, Button, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Select, MenuItem, InputLabel, FormHelperText, AppBar, Tabs, Tab, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';

import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import TitleBar from './Layouts/TitleBar';
import { getUrl, postUrl,putUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';
import { storeWalletBalance } from '../actions/UserActions';
import { FiCopy } from 'react-icons/fi';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WebpImg from './Layouts/WebpImg';

import TitleBarWhite from './Layouts/TitleBarWhite';
import theme from '../Theme';
import { CONTACT_US_EMAIL } from '../configs/Config';
import QRCode from 'qrcode.react';
import TelegramIcon from '@material-ui/icons/Telegram';

const DEFAULT_ERROR_FIELD = { amount: "", transaction_hash: "" };

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


export default function Topup() {
    const { walletList, walletBalance, crypto_address, crypto_network } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
        crypto_address: state.user.crypto_address,
        crypto_network: state.user.crypto_network,
    }));
    const [walletInfo, setWalletInfo] = useState(null);
    const [userWalletInfo, setUserWalletInfo] = useState(null);
    const [state, setState] = useState({ amount: "", payment_method: "crypto", transaction_hash: "", wallet_type: crypto_network ? crypto_network : "" });
    const [upFormState, setupFormState] = useState({money:'',img:'',net:''});

    
    const handleSecurityPasswordChange = (event) => {
        const name = event.target.name;
        setupFormState({ ...upFormState, [name]: event.target.value });
    }
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [purchaseStatus, setPurchaseStatus] = useState("insert"); // insert -> x review -> result
    const [depositAddress, setDepositAddress] = useState("");
    const [depositRule, setDepositRule] = useState(null);
    const [depositAddressErrorMessage, setDepositAddressErrorMessage] = useState("");

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);
    const inputRef = useRef()
    const [viewuploadImgPy, setViewuploadImgPy] = useState(false);

    const UpImgProof = useCallback((addr, net) => {
        console.log(addr, net)
        inputRef.current.click()
    })
    const UpImgProofBtn = useCallback((addr, net) => {
        setupFormState({money:'',img:'',net:net})
        setViewuploadImgPy(true)
    })
    const UpImgProofCloneBtn = useCallback((addr, net) => {
        setViewuploadImgPy(false)
        // console.log(addr, net)
        // inputRef.current.click()
    })
    const UpImgProofSubmitBtn = useCallback((addr, net) => {
        setLoading(true)
        putUrl(`user/submit-proof`, upFormState).then(response => {
            setLoading(false)
            if (response.code == 0) {
                setViewuploadImgPy(false)
                addAlert(response.msg, "success");
                history.push("/topup-history/usdt")
            } else {
                addAlert(response.msg, "error");
            }
        }).catch(error => {
            setLoading(false)
        })
        // console.log(addr, net)
        // inputRef.current.click()
    })
    const fileChange = async (e) => {
        const files = e.target.files || [];
        await uploadFile(files)
        // inputRef.current.value = '';
        // setFileList([...fileList, ...list]);
    };

    const getBase64 = async(file) => {
        let document = "";
        setLoading(true)
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const formData = new FormData();
            document = reader.result;
            formData.append('file',document);
            putUrl(`upload/index?action=uploadimage`, formData).then(response => {
                setLoading(false)
                if (response.code == 0) {
                    setupFormState({ ...upFormState, ['img']: response.data });
                    // addAlert(response.msg, "success");
                } else {
                    addAlert(response.msg, "error");
                }
            }).catch(error => {
                setLoading(false)
            })
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        return document;
    }


    const uploadFile = async (file) => {
        console.log(file[0],'file')
        await getBase64(file[0])
    };


    const copyShareLink = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert(t('general.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert(t('general.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert(t('general.copied'), "success");
            }).catch((error) => {
                addAlert(t('general.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert("Copy is not supported by browser", "error");
        }
    }, []);

    const copyShareLink2 = useCallback((e) => {
        navigator.clipboard.writeText('TEqNVLTkUc3vfw3M8mHvszQqCxhm8UkDZq')
        addAlert(t('general.copied'), "success");
    }, []);

    const copyShareLink3 = useCallback((e) => {
        navigator.clipboard.writeText('0x52bf46fD5Ab5f5FfE2Fa01D10bd0eD00dE6F7319')
        addAlert(t('general.copied'), "success");
    }, []);

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        if (!existWallet) {
            history.goBack();
        }
    }, [walletBalance, walletList]);

    useEffect(() => {
        isMountedRef.current = true;

        setLoading(true);
        refetchWallet();
        getDepositAddress();

        return () => isMountedRef.current = false;
    }, [addAlert])

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if (name === 'amount') {
            if (parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }
        setState({ ...state, [name]: newValue });
    }

    const topupWallet = () => {
        const { amount, payment_method, transaction_hash, wallet_type } = state;
        const purchaseData = {
            amount,
            payment_method,
            transaction_hash,
            wallet_id: walletInfo.id,
            wallet_type: wallet_type,
        };
        setErrorField(DEFAULT_ERROR_FIELD);
        postUrl(`wallet-topups`, purchaseData).then(response => {
            console.log("response", response)
            if (response.status) {
                setPurchaseStatus('result');
                refetchWallet();
            } else {
                if (response.errors) {
                    let newErrorField = {};
                    _.map(response.errors, (errorMsg, errorField) => {
                        newErrorField[errorField] = errorMsg[0];
                    })
                    setErrorField(newErrorField);
                }
                if (response.message) {
                    addAlert(response.message);
                }
                setPurchaseStatus('insert')
            }
        }).catch(error => {
            setPurchaseStatus('insert')
            addAlert(JSON.stringify(error.message));
        })
    }

    const refetchWallet = () => {
        getUrl('default/balance').then(response => {
            // if(response.status) {
            //     dispatch(storeWalletBalance(response.data));
            // }

            if (response.code == 0) {
                dispatch(storeWalletBalance(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    const getDepositAddress = () => {
        getUrl('default/get-deposit-address').then(response => {
            console.log(response);
            if (response.code == 0) {
                setDepositAddress(response.data);
                // setDepositRule(response.rule);
            } else {
                // setDepositAddressErrorMessage(response.message);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        }).finally(() => {
            setLoading(false);
        })
    }

    ////  tabs///////
    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [viewAnnouncement, setViewAnnouncement] = useState(true);
    const closeAnnouncement = (event, reason) => {
        if (reason && (reason == "backdropClick" || reason == "escapeKeyDown"))
            return;
        setViewAnnouncement(false);
    }
    const [agree, setAgree] = useState(false);
    const checkboxHandler = () => {
        setAgree(!agree);
    }
    const btnHandler = () => {
        setViewAnnouncement(false);
    };

    return (
        <Box className={classes.root} style={{ padding: '0 20px 30px', minHeight: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TitleBarWhite title={t('title.bonus')} back displayInfo={false} />
                <Link underline='none' to="/TutorialDeposit" component={RouterLink} >
                    <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff' }}>
                        <p>{t('tutorial.tutorial')}</p>
                    </div>
                </Link>
            </div>
            <Paper classes={{ root: classes.paperContainer }}>
                {purchaseStatus === 'result' ?
                    <>
                        {/* <Box className={classes.boxContainer}>
                        <Typography variant="h6" style={{ color: theme.palette.secondary.main }}>{ t('topup.successfullyPurchase') }</Typography>
                        <Typography variant="h6" style={{ ...styles.inputPropsStyle, color: theme.palette.secondary.main }}>
                            { `${ currencyFormat(state.amount) }`}
                        </Typography>
                    </Box> */}

                        <Box className={classes.boxContainer} style={{ paddingBottom: 25 }}>
                            <WebpImg src="/images/general/stake-success.png" style={{ width: 196, }} alt=" logo" containerStyle={{ lineHeight: 0 }} />
                            <Typography variant="h6" style={{ textTransform: 'uppercase', lineHeight: 1.0, fontFamily: 'times-new-roman', fontWeight: 'bold', fontSize: 28, color: '#3F686B' }}>{t('topup.successfullyPurchaseTitle')}</Typography>
                            <p style={{ fontSize: 19, color: theme.palette.silver.text, fontFamily: 'times-new-roman', padding: '5px 0' }}><b>{t('topup.topup')}: {`${currencyFormat(state.amount)}`}</b></p>
                            <p variant="h6" style={{ fontSize: 14, color: theme.palette.silver.text }}>{t('topup.successfullyPurchase')}</p>
                            <Typography variant="h6" style={{ ...styles.inputPropsStyle, color: theme.palette.secondary.main }}>

                            </Typography>
                        </Box>
                    </>

                    : <Box className={classes.boxContainer}>
                        <div className={classes.walletCard}>
                            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', height: '100%', }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                    <img src='/images/general/icon/usdt.png' alt="rank" className={classes.walletIconImg} />
                                    <div style={{ color: theme.palette.white.text, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                                        <p className={classes.walletBalanceTitle}>{walletInfo?.wallet_name} {t('wallet.balance')}</p>
                                        <p className={classes.walletAmount}><b>{currencyFormat(parseFloat(userWalletInfo?.balance).toFixed(userWalletInfo?.decimal || 2) || 0)}</b></p>
                                    </div>
                                </div>
                                <div className={classes.walletAddressInfo}>
                                    {crypto_network && <p><b style={{ textTransform: 'uppercase' }}>{crypto_network} :</b> {crypto_address}</p>}
                                </div>
                            </div>
                        </div>
                        <Box borderColor={theme.palette.silver.bg} width="100%">
                            <p className={classes.title} >{`${t('topup.topupWallet')} - ${walletInfo?.wallet_name || ""} `}</p>

                            <div style={{ paddingTop: 25 }}>
                                <AppBar position="static" color="default" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChangeTab}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <Tab label="TRC20" {...a11yProps(0)} />
                                        <Tab label="BEP20" {...a11yProps(1)} />
                                    </Tabs>
                                </AppBar>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    {
                                        _.map(['trc20', 'bep20'], (_network, _index) => (
                                            <TabPanel key={_index} value={value} index={_index} dir={theme.direction}>
                                                {_.size(depositAddress) > 0 &&
                                                    <div style={{ padding: '0 10px 15px', borderRadius: 5, background: '#efefef', marginTop: 20 }}>
                                                        <div style={{ background: '#EEBE7F', width: 'fit-content', textAlign: 'center', padding: '7px 19px', borderRadius: '0 0 15px 15px', margin: '0 auto', fontSize: 12 }}>
                                                            <p>{t('topup.crypto')} {t('address.address')}</p>
                                                        </div>
                                                        <div className={classes.cryptoAddressBox} style={{ paddingTop: 20 }}>
                                                            {/* <img src="/images/general/cryptoAddress/trc20.png" alt="trc20" className={classes.cryptoQr}/> */}
                                                            <div style={{ width: '35%' }}>
                                                                <QRCode
                                                                    value={depositAddress[`ionpay_${_network}`]['address']}
                                                                    renderAs="svg"
                                                                    fgColor={theme.palette.secondary.main}
                                                                    // size={100}
                                                                    bgColor={'transparent'}
                                                                    style={{ maxWidth: '100%', width: '100%', }}
                                                                />
                                                            </div>
                                                            <div style={{ width: '65%' }}>
                                                                <Typography className={classes.cryptoAddressText}>
                                                                    <b>{_network.toUpperCase()} {t('address.address')} :</b><br />
                                                                    {depositAddress[`ionpay_${_network}`]['address']}
                                                                </Typography>
                                                                {document.queryCommandSupported('copy') &&
                                                                    <Grid item xs style={{ paddingLeft: 7, paddingTop: 5 }}>
                                                                        <Button style={{ boxShadow: '2px 3px 4px 0 #4b96a98c' }} variant="contained" color="primary" className={classes.copyButton} onClick={() => copyShareLink(depositAddress[`ionpay_${_network}`]['address'])}><FiCopy style={{ marginRight: 5 }} /> {t('button.copy')}</Button>
                                                                        <input type="file" accept='image/jpg,image/png,image/jpeg' onChange={fileChange} ref={inputRef} style={{ display:"none", }} />
                                                                        {/* <Button style={{ boxShadow: '2px 3px 4px 0 #4b96a98c', marginLeft: 3 }} variant="contained" color="primary" className={classes.copyButton} onClick={() => UpImgProof(depositAddress[`ionpay_${_network}`]['address'], _network)}> {t('profile.uploadImgPy')}</Button> */}
                                                                        <Button style={{ boxShadow: '2px 3px 4px 0 #4b96a98c', marginLeft: 3 }} variant="contained" color="primary" className={classes.copyButton} onClick={() => UpImgProofBtn(depositAddress[`ionpay_${_network}`]['address'], _network)}> {t('profile.uploadImgPy')}</Button>
                                                                    </Grid>
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                }
                                            </TabPanel>
                                        ))}
                                    <TabPanel value={value} index={1} dir={theme.direction}>
                                        Item Two
                                    </TabPanel>
                                </SwipeableViews>
                            </div>


                            {depositAddressErrorMessage && <Typography style={{ color: "red", marginTop: 20 }}>{depositAddressErrorMessage}</Typography>}
                            {/* { _.size(depositAddress) > 0 &&
                        <div style={{ padding: '0 10px 15px', borderRadius: 5, background: '#efefef', marginTop:20 }}>
                            <div style={{ background: '#EEBE7F', width: 'fit-content', textAlign: 'center', padding: '7px 19px', borderRadius: '0 0 15px 15px', margin: '0 auto', fontSize: 12 }}>
                                <p>{t('topup.crypto')} {t('address.address')}</p>
                            </div>
                            {
                                _.map(['trc20','bep20'], _network => (
                                    <div key={_network} className={classes.cryptoAddressBox} style={{ paddingTop: 20 }}>
                                        <QRCode 
                                            value={depositAddress[`ionpay_${_network}`]['address']}
                                            renderAs="svg"
                                            fgColor={theme.palette.primary.main}
                                        />
                                        <div>
                                            <Typography className={classes.cryptoAddressText}>
                                                <b>{_network.toUpperCase()} {t('address.address')} :</b><br />
                                                {depositAddress[`ionpay_${_network}`]['address']}
                                            </Typography>
                                            {document.queryCommandSupported('copy') &&
                                                <Grid item xs style={{paddingLeft:15, paddingTop:5}}>
                                                    <Button variant="contained" color="primary" className={classes.copyButton} onClick={() => copyShareLink(depositAddress[`ionpay_${_network}`]['address'])}><FiCopy style={{ marginRight: 5 }} /> {t('button.copy')}</Button>
                                                </Grid>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        } */}
                            {/* <img src="/images/general/cryptoAddress/trc20.png" alt="trc20" className={classes.cryptoQr}/> */}
                            <div style={{ backgroundColor: "#ffead2", marginTop: 20, padding: "20px 10px", borderRadius: 8, boxShadow: '0 6px 7px 0 #e1ccb3' }}>
                                <Typography style={{ display: "flex", justifyContent: "center" }}><ErrorOutlineIcon />{t('topup.notice')}</Typography>
                                <ul>
                                    <li>{t('topup.noticeDesc1')}</li>
                                    <li>{t('topup.noticeDesc2')}</li>
                                    <li>{t('topup.noticeDesc3')}</li>
                                    <li>{t('topup.noticeDesc4')}</li>
                                </ul>
                            </div>

                            {/* <TextField 
                            type="number"
                            variant="outlined"
                            value={state.amount}
                            name="amount"          
                            onChange={ handleChange }
                            classes={{ root: classes.textFieldRoot }}
                            InputProps={{ readOnly: purchaseStatus === 'review' ? true : false }}
                            inputProps={{ step: "50", min: "100"}}
                            label={ t('topup.amount') }
                            fullWidth
                            error={ _.size(errorField.amount) ? true : false }
                            helperText={ errorField.amount }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            style={{marginTop:15,}}
                        /> */}
                            {/* <FormControl component="fieldset">
                            <FormLabel component="legend">{ t('topup.paymentMethod') }</FormLabel>
                            <RadioGroup aria-label="payment_method" name="payment_method" value={state.payment_method} onChange={handleChange}>
                                <FormControlLabel value="crypto" control={<Radio />} label={ t('topup.crypto') } />
                                <FormControlLabel value="payment_gateway" control={<Radio />} label={ t('topup.payment_gateway') } />
                            </RadioGroup>
                        </FormControl> */}

                            {/* <TextField 
                            type="text"
                            variant="outlined"
                            value={crypto_address}
                            name="crypto_address"
                            classes={{ root: classes.textFieldRoot }}
                            InputProps={{ readOnly: true }}
                            label={ t('withdrawal.crypto_address') }
                            fullWidth
                            multiline
                        />
                        <MuiThemeProvider theme={selectStyle}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="walletSelect">{t('wallet.walletType')}</InputLabel>
                                <Select
                                    labelId="walletSelect"
                                    id="demo-simple-select-outlined"
                                    onChange={handleChange}
                                    label={t('wallet.walletType')}
                                    fullWidth
                                    name="wallet_type"
                                    value={state.wallet_type}
                                    error={ _.size(errorField.wallet_type) ? true : false }
                                    disabled={crypto_network?true:false}
                                >
                                    <MenuItem value={"trc20"}>TRC20 {t('address.address')}</MenuItem>
                                    <MenuItem value={"bep20"}>BEP20 {t('address.address')}</MenuItem>
                                </Select>
                                <FormHelperText error>{errorField.wallet_type}</FormHelperText>
                            </FormControl>
                        </MuiThemeProvider> */}

                            {/* {state.payment_method == "crypto" ? 
                            <>
                                
                                <TextField 
                                    type="text"
                                    variant="outlined"
                                    value={state.transaction_hash}
                                    classes={{ root: classes.textFieldRoot }}
                                    label={ t('topup.transactionHash') }
                                    name="transaction_hash"  
                                    onChange={ handleChange }
                                    fullWidth
                                    error={ _.size(errorField.transaction_hash) ? true : false }
                                    helperText={ errorField.transaction_hash }
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                />
                                    {
                                        state.wallet_type === "trc20"
                                            ?
                                            <div style={{ padding: '0 10px 15px', borderRadius: 5, background: '#efefef', marginTop:20 }}>
                                                <div style={{ background: '#EEBE7F', width: 'fit-content', textAlign: 'center', padding: '7px 19px', borderRadius: '0 0 15px 15px', margin: '0 auto', fontSize: 12 }}>
                                                    <p>{t('topup.crypto')} {t('address.address')}</p>
                                                </div>

                                                <div className={classes.cryptoAddressBox} style={{ paddingTop: 20 }}>
                                                    <img src="/images/general/cryptoAddress/trc20.png" alt="trc20" className={classes.cryptoQr}/>
                                                    <div>
                                                    <Typography className={classes.cryptoAddressText}>
                                                        <b>TRC20 {t('address.address')} :</b><br />
                                                        TEqNVLTkUc3vfw3M8mHvszQqCxhm8UkDZq
                                                    </Typography>
                                                    {document.queryCommandSupported('copy') &&
                                                        <Grid item xs style={{paddingLeft:15, paddingTop:5}}>
                                                            <Button variant="contained" color="primary" className={classes.copyButton} onClick={copyShareLink2}><FiCopy style={{ marginRight: 5 }} /> {t('button.copy')}</Button>
                                                        </Grid>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        state.wallet_type === "bep20"
                                            ?
                                            <div style={{ padding: '0 10px 15px', borderRadius: 5, background: '#efefef' }}>
                                                <div style={{ background: '#EEBE7F', width: 'fit-content', textAlign: 'center', padding: '7px 19px', borderRadius: '0 0 15px 15px', margin: '0 auto', fontSize: 12 }}>
                                                    <p>{t('topup.crypto')} {t('address.address')}</p>
                                                </div>
                                                <div className={classes.cryptoAddressBox} style={{ paddingTop: 20 }}>
                                                    <img src="/images/general/cryptoAddress/bep20.png" alt="bep20" className={classes.cryptoQr}/>
                                                    <div>
                                                    <Typography className={classes.cryptoAddressText}>
                                                        <b>BEP20 {t('address.address')} :</b><br />
                                                        0x52bf46fD5Ab5f5FfE2Fa01D10bd0eD00dE6F7319
                                                    </Typography>
                                                    {document.queryCommandSupported('copy') &&
                                                        <Grid item xs style={{paddingLeft:15, paddingTop:5}}>
                                                            <Button variant="contained" color="primary" className={classes.copyButton} onClick={copyShareLink3}><FiCopy style={{ marginRight: 5 }} /> {t('button.copy')}</Button>
                                                        </Grid>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                            </>
                        : null} */}
                        </Box>
                    </Box>}
                {/* <Box className={classes.boxContainer} style={{paddingTop:15}}>
                    { purchaseStatus === 'insert' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ topupWallet }>
                        { t('topup.topupNow') }
                    </Button> }
                    { purchaseStatus === 'result' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ () => history.push(`/topup-history/${walletInfo.code}`) }>
                        { t('topup.complete') }
                    </Button> }
                </Box> */}
                <Box className={classes.boxContainer} style={{ paddingTop: 20, }}>
                    <div style={{ paddingBottom: 10, textAlign: 'center' }}>
                        <p> {t('topup.inquiries')}</p>
                        <a href={`mailto:${CONTACT_US_EMAIL}`}>{`${CONTACT_US_EMAIL}`}</a>
                    </div>
                    {/* <Button variant="contained" color="primary" style={{color:'#fff'}} href="https://web.telegram.org/z/#?tgaddr=tg%3A%2F%2Fresolve%3Fdomain%3Dledgerblock_bot"><TelegramIcon style={{ marginRight: 5, color:'#fff' }} />Telegram</Button> */}
                </Box>
            </Paper>
            <MuiThemeProvider key='warn' theme={announceDialogStyle}>
                <Dialog
                    open={viewAnnouncement ? true : false}
                    // open={false}
                    onClose={closeAnnouncement}
                    scroll="body"
                    aria-labelledby="view Announcement"
                    aria-describedby="view Announcement detail"
                    key='warn'
                    style={{ width: '547px', maxWidth: '100%', margin: '0 auto' }}
                >
                    <DialogTitle id='warn'>{t('topup.dialog.title')}</DialogTitle>
                    <DialogContent dividers={false}>
                        <div
                            id="viewAnnouncement"
                            tabIndex={-1}
                        >
                            <p>{t('topup.dialog.content')}</p><br />
                            <input type="checkbox" id="agree" onChange={checkboxHandler} />
                            <label htmlFor="agree">{t('topup.dialog.agree')}</label>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!agree} onClick={btnHandler}>
                            {t('button.proceed')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
            <MuiThemeProvider key='warns' theme={announceDialogStyle}>
                <Dialog
                    open={viewuploadImgPy?true:false}
                    // open={false}
                    onClose={closeAnnouncement}
                    scroll="body"
                    aria-labelledby="view Announcement"
                    aria-describedby="view Announcement detail"
                    key='warns'
                    style={{ width: '547px', maxWidth: '100%', margin: '0 auto' }}
                >
                    <DialogTitle id='warns'>{t('profile.uploadImgPy')}</DialogTitle>
                    <DialogContent dividers={false}>
                        <div
                            id="viewuploadImgPy"
                            tabIndex={-1}
                        >
                            <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('topup.purchase')}{t('wallet.amount')}</b></Typography>
                            <TextField
                            // error={upFormState.money ? true : false}
                            // helperText={upFormState.money}
                            fullWidth={true}
                            variant="outlined"
                            type="number"
                            size="small"
                            inputProps={{ name: 'money' }}
                            onChange={handleSecurityPasswordChange}
                            value={upFormState.money}
                        />
                            <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.uploadImgPy')}({upFormState.net})</b></Typography>
                            <Button style={{ boxShadow: '2px 3px 4px 0 #4b96a98c',backgroundColor:'#4dbedb' }} variant="contained" color="primary" className={classes.copyButton} onClick={() => UpImgProof(depositAddress[`ionpay_trc20`]['address'], 'trc20')}> {t('profile.uploadImgPy')}</Button>
                            <img src={upFormState.img} alt="" />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={UpImgProofCloneBtn}>
                            {t('button.close')}
                        </Button>
                        <Button onClick={UpImgProofSubmitBtn}>
                            {t('button.submit')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        // marginLeft: 15,
        // marginRight: 15,
        // marginTop: -58,
        padding: 10,
        borderRadius: 20,
        boxShadow: 'none'
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 18,
        paddingTop: 25,
        fontWeight: 'bold',
        color: '#10242f',
    },
    cryptoQr: {
        width: 85,
        maxWidth: '100%'
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        // marginBottom: 15,
    },
    cryptoAddressBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    cryptoAddressText: {
        wordBreak: 'break-all',
        paddingLeft: 15,
        fontSize: 12,
        width: '100%',
        paddingRight: 10,
        color: theme.palette.silver.textDarker,
        lineHeight: 1.4,
    },
    copyButton: {
        padding: '3px 7px',
        borderRadius: 7,
        fontSize: 12,
        color: '#fff'
    },
    buttonRoot: {
        backgroundColor: theme.palette.primary.main,
        width: '90%',
        color: '#FFF',
        borderRadius: 10,
        marginTop: 15,
        marginBottom: 30
    },
    walletAddressInfo: {
        color: theme.palette.white.text,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: 15,
        position: 'absolute',
        bottom: 10
    },
    walletCard: {
        height: 200,
        background: theme.palette.secondary.main,
        borderRadius: 15,
        width: '100%',
        paddingBottom: 14,
        position: 'relative',
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 163,
        },
    },
    walletIconImg: {
        width: 51,
        [theme.breakpoints.down('sm')]: {
            width: 43,
        },
    },
    walletBalanceTitle: {
        textTransform: 'uppercase',
        fontSize: 15,
        paddingBottom: 2,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },
    walletAmount: {
        fontSize: 30,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
        },
    },
}));

const styles = {
    inputPropsStyle: {
        textAlign: 'center',
        fontSize: 36,
        fontWeight: 'bold',
    }
}

const selectStyle = createMuiTheme({
    overrides: {
        MuiFormControl: {
            root: {
                width: '100%'
            },

        },
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: theme.palette.primary.main
                },
            }
        },
        MuiOutlinedInput: {
            root: {
                "&$focused": {
                    borderColor: theme.palette.primary.main,
                },
                "&$focused $notchedOutline": {
                    borderColor: theme.palette.primary.main
                }
            },
            notchedOutline: {
                "&$focused": {
                    borderColor: theme.palette.primary.main,
                },
            }
        }

        // MuiInput: {
        //     underline: {
        //         '&::before': {
        //             borderBottom: '1px solid #fff'
        //         },
        //         '&::after': {
        //             borderBottom: '2px solid #fff'
        //         },
        //         "&:not(.Mui-disabled):hover::before": {
        //             borderColor: "#fff"
        //         },
        //     },
        // },
        // MuiIconButton: {
        //     root: {
        //         color:'#c2c2c2',
        //         "&$focused": {
        //             color: '#FF5532'
        //             },
        //     }
        // },
        // MuiSelect: {
        //     icon: {
        //         color:'#fff'
        //     },
        //     select: {
        //         "&$focused": {
        //             backgroundColor: 'transparent'
        //             },
        //     }
        // }

    },
});

const announceDialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
                width: 450,
                maxWidth: '100%',
                margin: '0 auto',
            },

        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth: '90%',
                minHeight: '24vh'
            }
        },
        MuiDialogContent: {
            root: {
                padding: '8px 24px 24px'
            }
        },
        /* MuiButton: {
            textPrimary: {
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                background: '#0008',
                borderRadius: 48,
                minWidth: 'unset',
                padding: 0,
            }
        } */
    },
});