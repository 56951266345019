import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import _ from 'lodash';
import { postUrl,getUrl } from '../helper/ApiAction';
import { APP_NAME } from '../configs/Config';
import useNotificationLoading from '../helper/useNotificationLoading';
import { changeLanguage as reduxChangeLanguage } from '../actions/GeneralActions';

import { makeStyles, useTheme, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { TextField, Button, Typography, Grid, Link, Box, Paper, Divider, Menu, MenuItem, IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import { FiChevronLeft } from 'react-icons/fi';
import { Globe } from 'react-feather';
import theme from '../Theme';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                { APP_NAME }
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function PasswordForgot() {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    // const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();

    const [inputErrors, setInputErrors] = useState([]);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [anchorEl, setAnchorEl] = useState({
        languageAnchorEl: null,
    })
    const [completed, setCompleted] = useState(false);
    const [completedMessage, setCompletedMessage] = useState("");

    const handleMenuClose = () => {
        setAnchorEl({ ...anchorEl, languageAnchorEl: null });
    };

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
        handleMenuClose();
    };

    const languageId = 'primary-search-language-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl.languageAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={languageId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl.languageAnchorEl)}
            onClose={handleMenuClose}

        >
            <MenuItem onClick={() => changeLanguage('cn')}>简</MenuItem>
            <MenuItem onClick={() => changeLanguage('tw')}>繁</MenuItem>
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            {/* <MenuItem onClick={() => changeLanguage('ms')}>Malay</MenuItem> */}
            {/* <MenuItem onClick={() => changeLanguage('id')}>Indonesia</MenuItem> */}
        </Menu>
    );

    useEffect(() => {
        isMountedRef.current = true;

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    const forgotPassword = () => {
        setLoading(true);

        getUrl(`default/send-mail`, { email: email, username: username,type:1 }).then(result => {
            if (isMountedRef.current) {
                setLoading(false);
                let { status, message, error } = result;
                if (result.code === 0) {
                    setCompleted(true);
                    setCompletedMessage(result.msg);
                    addAlert(result.msg, 'success', '', '');
                } else {
                    // if (_.size(error) > 0) {
                    //     _.map(error, (value, key) => {
                    //         message += "\n" + value[0];
                    //     })
                    //     setInputErrors(error);
                    // }
                    addAlert(result.msg, 'error', '', '');
                }
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
            }
        });
    }

    return (
        <Grid container component="main" className={classes.root} justify="center">
        <Box component={Paper} width="100%" style={{backgroundColor:theme.palette.secondary.main, boxShadow:'none'}}>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', paddingTop:20}}>
                <Link underline='none' to="/login" component={RouterLink} >
                    <Box style={{ color: theme.palette.primary.main, paddingLeft: 20, display: 'flex', alignItems: 'center' }}>
                        <FiChevronLeft style={{ marginRight: 10 }} />
                        <Typography>{t('button.back')}</Typography>
                    </Box>
                </Link>
                <div style={{ display: 'flex', justifyContent: "end" }}>
                    <IconButton
                        aria-label="language"
                        aria-controls={languageId}
                        aria-haspopup="true"
                        onClick={(event) => setAnchorEl({ ...anchorEl, languageAnchorEl: event.currentTarget })}
                        color="inherit"
                        style={{ paddingRight: 27 }}
                    >
                        <Globe color={theme.palette.primary.main} size={20} />
                    </IconButton>
                    {renderMenu}
                </div>
                </div>
            <div className={classes.paper}>
                <center><img src="/images/logo-text.png" style={{ width:165, paddingLeft: 10, paddingRight: 10, }} alt="logo" /></center>
                    <div style={{textAlign: 'center', color: '#cecece',}}>
                        <Typography style={{ fontSize: 18,  paddingTop: 25, fontWeight: 'bold', textTransform: 'uppercase', }}>
                            {t('forgotPassword.title')}
                        </Typography>
                        {
                            !completed
                            ?
                        <Typography variant="body2">
                            {t('forgotPassword.subtitle')}
                        </Typography>
                            :
                            null
                        }
                    </div>
                    {
                        completed
                        ?
                        <>
                        <Typography variant="body2">{completedMessage}</Typography>
                        <Link underline='none' to={`/login`} component={RouterLink}>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {t('button.proceedToLogin')}
                            </Button>
                        </Link>
                        </>
                        :
                    <MuiThemeProvider theme={formStyle}>
                    <form className={classes.form} noValidate onSubmit={ev => { ev.preventDefault(); forgotPassword() }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={ t('forgotPassword.email')}
                            name="email"
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                            autoComplete="email"
                            autoFocus
                            error={inputErrors.email ? true : false}
                        />
                        <Typography variant="body2" style={{color: theme.palette.silver.text}} >
                            {t('forgotPassword.helperText')}
                        </Typography>
                        {inputErrors.email &&
                            <Typography color="error"><ErrorIcon style={{ verticalAlign: "middle" }} />{inputErrors.email}</Typography>
                        }
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={ t('forgotPassword.username')}
                            name="username"
                            value={username}
                            onChange={({ target }) => setUsername(target.value)}
                            autoComplete="username"
                            autoFocus
                            error={inputErrors.username ? true : false}
                        />
                        {inputErrors.username &&
                            <Typography color="error"><ErrorIcon style={{ verticalAlign: "middle" }} />{inputErrors.username}</Typography>
                        }
                        <div style={{display:'flex', justifyContent:'center'}}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{borderRadius:10, padding:'8px 15px'}}
                        >
                            {t('button.reset')}
                        </Button>
                        </div>
                        {/* <Box mt={5}>
                            <Copyright />
                        </Box> */}
                    </form>
                    </MuiThemeProvider>
                    }
                </div>
                </Box>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color:theme.palette.white.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoImg: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
}));

const formStyle = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                color: theme.palette.silver.text,
                "&$focused": {
                    color:  theme.palette.primary.main,
                    },
            },
           
        },
        MuiInputBase: {
            root: {
                color:theme.palette.white.main,
                "&$focused": {
                    color:  theme.palette.primary.main,
                    },
            }
        },
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor:theme.palette.silver.text,
                },
                "&:hover $notchedOutline": {
                    borderColor:theme.palette.silver.text,
                },
                "&$focused $notchedOutline": {
                    borderColor:theme.palette.primary.main,
                }
              },
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor :theme.palette.primary.main,
                "&:hover": {
                    backgroundColor :theme.palette.primary.secondary,
                }
            }
        }
        
    },
});