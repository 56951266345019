import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from '../reducers';

import { ENV } from './Config';

const logger = createLogger();

const persistConfig = {
    key: 'root',
    storage
}

const combinedReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, combinedReducer);
const middlewareApplied = ENV === 'dev' ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

/* export default function configureStore() {

    let middleware;

    if(ENV === 'liv') {
    //     middleware = applyMiddleware(thunk);
    } else {
    //     middleware = applyMiddleware(thunk, logger);
    }
    middleware = applyMiddleware(thunk, logger);
    const store = createStore(
        persistedReducer,
        {},
        middleware
    );

    let persistor = persistStore(store);
    // persistor.purge();
    return { store, persistor };
} */

const store = createStore(
    persistedReducer,
    {},
    middlewareApplied
);

let persistor = persistStore(store);

export { store, persistor };
