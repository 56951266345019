import React, { useEffect, useRef, useState } from 'react';
import { getUrl, postUrl, putUrl } from '../helper/ApiAction';
import { useSelector } from 'react-redux';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import { PUSHER_KEY, PUSHER_CLUSTER, PUSHER_AUTH, PUSHER_MESSAGE_CHANNEL, PUSHER_MESSAGE_EVENT } from '../configs/Config';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Typography, Container, Button, Hidden, Box, Paper, List, ListItem, SwipeableDrawer, Avatar, ListItemText, Link, IconButton, TextField, Divider, ListItemSecondaryAction, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useNotificationLoading from '../helper/useNotificationLoading';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';
import WebpImg from './Layouts/WebpImg';
import _ from 'lodash';

import MenuIcon from '@material-ui/icons/Menu';

import clsx from 'clsx';

import './css/chatStyle.css';

export default function AdminChat() {
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { id } = useSelector(state => state.user);
    const { accessToken } = useSelector(state => state.general);
    const theme = useTheme();

    const isMountedRef = useRef(null);
    const chatRef = useRef();

    const [state, setState] = useState({
        newMessage: '', anchorEl: null,
    });
    const [chatAdmin, setChatAdmin] = useState({
        id: null
    });

    const [chatList, setChatList] = useState();
    const [chatData, setChatData] = useState(null);
    const [newMessage, setNewMessage] = useState();

    const classes = useStyles();
    const [state2, setState2] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState2({ ...state2, [anchor]: open });
    };

    useEffect(() => {
        document.getElementById('chatStyle').classList.add('hfull');
        document.getElementById('root').classList.add('hvh');
        // document.body.classList.add('hfull');
        return () => { document.getElementById('chatStyle').classList.remove('hfull'); document.getElementById('root').classList.remove('hvh'); };
    }, []);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl('adminchats').then(response => {
            if (isMountedRef.current) {
                console.log("chatList", response.data);
                setLoading(false);
                setChatList(response.data)
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        isMountedRef.current = true;
        if (chatAdmin.id > 0) {
            getUrl(`adminchats/${chatAdmin.id}`).then(response => {
                if (isMountedRef.current) {
                    response.data.data = _.reverse(response.data.data);
                    setChatData(response.data);
                }
                scrollToBottom();
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [chatAdmin.id]);


    // when receive new message from pusher
    useEffect(() => {
        isMountedRef.current = true;
        console.log("newMessage", newMessage);
        if (isMountedRef.current) {
            if (_.size(newMessage) > 0) {
                // if received new message recipient is self
                if (parseInt(newMessage.recipient) === id) {
                    // find if new message chat_id in the current pull list
                    const chatExistIndex = _.findIndex(chatList, { 'id': newMessage.chat_id });
                    if (chatExistIndex >= 0) {
                        // exist, replace chat last message
                        let newChatList = chatList;
                        newChatList[chatExistIndex].last_chat[0] = newMessage;
                        newChatList[chatExistIndex].updated_at = newMessage.updated_at;
                        newChatList[chatExistIndex].unread_count++;

                        newChatList = _.orderBy(newChatList, ['updated_at'], ['desc']);
                        setChatList(chatList => ([...newChatList]));

                        // if chatData loaded and chat_info id is current opening chatbox id
                        if (_.size(chatData) > 0) {
                            if (chatData.admin_chat_info.id === parseInt(newMessage.chat_id)) {
                                const prevData = chatData.data;
                                setChatData(chatData => ({ ...chatData, data: prevData.concat(newMessage) }));
                            }
                        }

                        console.log("chatList", chatList);
                    }
                }
            }
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [newMessage])

    useEffect(() => {
        console.log("chatList", chatList);
        if (_.size(chatData) && _.size(chatData.data) <= 30) {
            scrollToBottom();
        }
        // eslint-disable-next-line
    }, [chatData])

    useEffect(() => {
        isMountedRef.current = true;
        if (accessToken) {
            if (isMountedRef.current) {
                const echo = new Echo({
                    broadcaster: 'pusher',
                    key: PUSHER_KEY,
                    cluster: PUSHER_CLUSTER,
                    forceTLS: true,
                    authEndpoint: PUSHER_AUTH,
                    auth: {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        },
                    },
                });
                console.log("echo initial done", echo);

                echo.private(PUSHER_MESSAGE_CHANNEL).listen(PUSHER_MESSAGE_EVENT, (pusherMsg) => {
                    console.log("pusherMsg", pusherMsg)
                    setNewMessage(newMessage => (pusherMsg.message));
                });
                console.log("echo start listening");
            }
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [accessToken])

    const sendMessage = () => {
        if (state.newMessage) {
            const newMessage = state.newMessage;
            setState(state => ({ ...state, newMessage: '' }));
            // console.log('chatId', chatAdmin.id);
            postUrl(`adminchats/${chatAdmin.id}`, {
                message: newMessage
            }).then(response => {
                if (response.status) {
                    const prevData = chatData.data;
                    setChatData({ ...chatData, data: prevData.concat(response.data) });

                    const chatExistIndex = _.findIndex(chatList, { 'id': parseInt(response.data.chat_id) });
                    console.log("chatList", chatList)
                    console.log("response.data", response.data)
                    console.log("chatExistIndex", chatExistIndex)
                    if (chatExistIndex >= 0) {
                        // exist, replace chat last message
                        let newChatList = chatList;
                        newChatList[chatExistIndex].last_chat[0] = response.data;
                        newChatList[chatExistIndex].updated_at = response.data.updated_at;

                        // if chatData loaded and chat_info id is current opening chatbox id
                        if (_.size(chatData) > 0) {
                            if (chatData.admin_chat_info.id === parseInt(response.data.chat_id)) {
                                newChatList[chatExistIndex].last_chat[0]['read'] = true;
                                newChatList[chatExistIndex].unread_count--;
                            }
                        }
                        newChatList = _.orderBy(newChatList, ['updated_at'], ['desc']);
                        setChatList(chatList => ([...newChatList]));
                        console.log("chatList2", chatList);
                        scrollToBottom();
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            style={{ padding: 20 }}
        >
            <List>
                {
                    renderChatList(chatList)
                }
            </List>
        </div>
    );

    const scrollToBottom = () => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }

    const openMessage = chatItem => {
        setChatAdmin({ id: chatItem.id });
        // set messages to read
        // console.log("id",chatItem.id);
        putUrl(`adminchats/${chatItem.id}`)
            .then(response => {
                console.log(response);

                const chatExistIndex = _.findIndex(chatList, { 'id': chatItem.id });
                if (chatExistIndex >= 0) {
                    // exist, replace chat last message
                    let newChatList = chatList;
                    if (_.size(newChatList[chatExistIndex].last_chat) > 0) {
                        newChatList[chatExistIndex].last_chat[0]['read'] = true;
                        newChatList[chatExistIndex].unread_count = 0;
                    }
                    setChatList(chatList => ([...newChatList]));
                }
                setState({ ...state });

            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
    }

    const renderChatList = (toRenderList) => {
        return (
            <Box>
                {
                    _.isNull(toRenderList) ?
                        <Box display="flex" flex={1} justifyContent="center" alignItems="center"><CircularProgress /></Box>
                        :
                        _.size(toRenderList) === 0 ?
                            <Box display="flex" flex={1} justifyContent="center" alignItems="center"><Typography variant="caption">{t('chat.noChat')}</Typography></Box>
                            :
                            _.map(toRenderList, chatItem => {

                                return (
                                    <Box key={chatItem.id}>
                                        <ListItem button onClick={() => openMessage(chatItem)} style={{ backgroundColor: _.size(chatAdmin) > 0 ? (chatAdmin.id === chatItem.id ? theme.palette.background.default : 'transparent') : 'transparent' }}>
                                            <ListItemText
                                                primary={t('adminChat.customerService')}
                                                secondary={_.size(chatItem.last_chat) > 0 ? `${chatItem.last_chat[0].sender !== id ? `${t('adminChat.customerService')} :` :  `${t('chat.you')} :`} ${chatItem.last_chat[0].message}` : ''}
                                                secondaryTypographyProps={{
                                                    style: {
                                                        color: _.size(chatItem.last_chat) > 0 ? (chatItem.last_chat[0].read ? theme.palette.gray.main : '#000000') : theme.palette.gray.main,
                                                        fontWeight: _.size(chatItem.last_chat) > 0 ? (chatItem.last_chat[0].read ? 'normal' : 'bold') : 'normal',
                                                    },
                                                    noWrap: true
                                                }}
                                            />
                                            {
                                                chatItem.unread_count > 0 ?
                                                    <ListItemSecondaryAction>
                                                        <Avatar style={{ backgroundColor: theme.palette.primary.main, width: theme.spacing(3), height: theme.spacing(3) }}>
                                                            <Typography variant="caption">{chatItem.unread_count}</Typography>
                                                        </Avatar>
                                                    </ListItemSecondaryAction>
                                                    : null
                                            }
                                        </ListItem>
                                        <Divider />
                                    </Box>
                                )
                            })
                }
            </Box>
        )
    }

    const checkScroll = event => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        console.log("scrollTop", { scrollTop, scrollHeight })
        if (scrollTop === 0) {
            if (_.size(chatData) > 0) {
                if (chatData.current_page !== chatData.last_page) {
                    const newCurrentPage = chatData.current_page + 1;
                    getUrl(`adminchats/${chatData.admin_chat_info.id}?page=${newCurrentPage}`).then(response => {
                        const mergedChatDataConversation = _.concat(_.reverse(response.data.data), chatData.data);
                        response.data.data = mergedChatDataConversation;
                        setChatData(response.data);

                        // set the chat height back to the height before call api
                        const newHeight = chatRef.current.scrollHeight - scrollHeight;
                        chatRef.current.scrollTop = newHeight;
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                }
            }
        }

        if (scrollHeight - scrollTop === clientHeight) {
            console.log("bottom")
            // set messages to read
            putUrl(`adminchats/${chatAdmin.id}`)
                .then(response => {
                    console.log(response);

                    const chatExistIndex = _.findIndex(chatList, { 'id': chatAdmin.id });
                    if (chatExistIndex >= 0) {
                        // exist, replace chat last message
                        let newChatList = chatList;
                        if (_.size(newChatList[chatExistIndex].last_chat) > 0) {
                            newChatList[chatExistIndex].last_chat[0]['read'] = true;
                            newChatList[chatExistIndex].unread_count = 0;
                        }
                        setChatList(chatList => ([...newChatList]));
                    }
                    setState({ ...state });

                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
    }

    let prevDate = null;

    return (
        <Box>
            <Container style={{ height: '90vh', paddingBottom: 50 }}>
                <Grid container style={{ height: '100%' }}>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            {
                                chatAdmin.id > 0 ?
                                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            {/* <IconButton style={{ padding: 0 }} onClick={(event) => setState({ ...state, anchorEl: event.currentTarget })}>
                                                <MoreVertIcon />
                                            </IconButton> */}
                                            <Typography style={{ marginLeft: 10 }}>{t('adminChat.customerService')}</Typography>
                                        </Box>

                                    </Box>
                                    :
                                    <Typography style={{ fontSize: 24, color: '#686868' }}>
                                        {t('adminChat.chat')}
                                    </Typography>
                            }
                            
                            <div>
                                <React.Fragment key={'left'}>
                                    <Button onClick={toggleDrawer('left', true)}>
                                        <MenuIcon color={theme.palette.primary.main} style={{ marginLeft: 15 }} size={30} />
                                    </Button>
                                    <SwipeableDrawer
                                        anchor={'left'}
                                        open={state2['left']}
                                        onClose={toggleDrawer('left', false)}
                                        onOpen={toggleDrawer('left', true)}
                                    >
                                        {list('left')}
                                    </SwipeableDrawer>
                                </React.Fragment>
                            </div>
                        </Box>
                    </Hidden>

                    <Hidden only={['xs']}>
                        <Grid item xs={5} md={3} style={{ padding: '0 20px', borderRight: '1px solid #c1c1c1', }}>
                            <Typography style={{ fontSize: 24, paddingBottom: 20, color: '#686868' }}>
                            {t('adminChat.chat')}
                            </Typography>
                            <Paper style={{ minHeight: 464, overflow: 'auto', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                <List>
                                    {
                                        renderChatList(chatList)
                                    }
                                </List>

                            </Paper>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={7} md={9} style={{ padding: '0 20px', height: '-webkit-fill-available' }}>
                        {
                            chatAdmin.id > 0 ?
                                <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ height: '100%', maxHeight: '100%', paddingBottom: 20 }}>
                                    <Hidden only={['xs']}>
                                        <Paper style={{ padding: 8 }}>
                                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                                <Box display="flex" flexDirection="row" alignItems="center">
                                                    <Typography style={{ marginLeft: 10 }}>{t('adminChat.customerService')}</Typography>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Hidden>
                                    <Box display="flex" flex={1} flexDirection="column" justifyContent="flex-end" style={{ height: '100%', maxHeight: '100%', overflow: 'hidden', padding: '20px 0' }}>
                                        {console.log("chatData", chatData)}
                                        <Paper ref={chatRef} style={{ backgroundColor: 'transparent', boxShadow: 'none', height: '100%', maxHeight: '100%', overflow: 'hidden', overflowY: 'scroll' }} onScroll={checkScroll} >
                                            {
                                                _.size(chatData) > 0 ?
                                                    _.size(chatData.data) > 0 ?
                                                        _.map(chatData.data, (selectedChatItem, selectedChatItemIndex) => {
                                                            let showDate = false;
                                                            if (prevDate !== null) {
                                                                const d1 = new Date(prevDate);
                                                                const d2 = new Date(selectedChatItem.created_date_compare);
                                                                if (d1 < d2) {
                                                                    showDate = true;
                                                                }
                                                            } else {
                                                                showDate = true;
                                                            }
                                                            prevDate = selectedChatItem.created_date_compare;

                                                            return (
                                                                <Box key={selectedChatItemIndex} display="flex" flexDirection="column">
                                                                    {
                                                                        showDate ?
                                                                            <Box display="flex" flexDirection="row" justifyContent="center" padding={1}>
                                                                                <Paper style={{ backgroundColor: theme.palette.gray.main, padding: 5 }} elevation={0}>
                                                                                    <Typography variant="caption" style={{ color: '#FFF' }}>{selectedChatItem.created_date_display}</Typography>
                                                                                </Paper>
                                                                            </Box>
                                                                            : null
                                                                    }
                                                                    <Paper style={{ margin: 5, padding: 8, alignSelf: selectedChatItem.sender === id ? 'flex-end' : 'flex-start' }} variant="outlined">
                                                                        <Box display="flex" flexDirection="column">
                                                                            <Typography variant="caption" style={{ fontSize: 15 }}>{selectedChatItem.message}</Typography>
                                                                            <Typography variant="caption" style={{ fontSize: 11, marginLeft: 30, alignSelf: 'flex-end' }}>{selectedChatItem.created_time_display}</Typography>
                                                                        </Box>
                                                                    </Paper>
                                                                </Box>
                                                            )
                                                        })
                                                        :
                                                        <Box display="flex" flexDirection="row" justifyContent="center" padding={1}>
                                                            <Paper style={{ backgroundColor: theme.palette.gray.main, padding: 5 }} elevation={0}>
                                                                <Typography variant="caption" style={{ color: '#FFF', fontSize: 12 }}>{t('adminChat.chatWithAdmin')}</Typography>
                                                            </Paper>
                                                        </Box>
                                                    : null
                                            }
                                        </Paper>
                                    </Box>
                                    <Paper style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                                        <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ backgroundColor: '#fff', padding: 10, boxShadow: '#d0d0d0 3px 3px 6px', borderRadius: 30, marginBottom: 10 }}>
                                            <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
                                                <TextField
                                                    fullWidth
                                                    placeholder={t('chat.typeMessageHere')}
                                                    multiline
                                                    rowsMax={4}
                                                    value={state.newMessage}
                                                    onChange={({ target }) => setState({ ...state, newMessage: target.value })}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter' && e.shiftKey) {
                                                        } else if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            sendMessage();
                                                        }
                                                    }}
                                                />
                                            </Box>
                                            <IconButton aria-label="settings" onClick={sendMessage} style={{ backgroundColor: '#f7952a', height: 50, width: 50, padding: 10, marginLeft: 15 }}>
                                                <SendIcon style={{ color: '#fff' }} />
                                            </IconButton>
                                        </Box>
                                        <Typography style={{ fontSize: 13, height:'41px' }}> {t('chat.helperText')} </Typography>
                                    </Paper>
                                </Box>
                                :
                                <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                                    <WebpImg src="/images/general/nochat2.png" alt="noimage" style={{ width: '300px', maxWidth: '100%' }} />
                                    <Typography>{t('chat.noChatSelected')}</Typography>
                                </Box>
                        }
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
        height: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}));