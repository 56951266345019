import React, { useState, useCallback, createContext } from 'react';

export const NotificationLoadingContext = createContext({
    alert: null,
    addAlert: () => {},
    removeAlert: () => {},
    loading: null,
    setLoading: () => {},
});
  
export default function NotificationLoadingProvider({ children }) {
    const [alert, setAlert] = useState({ open: false, severity: '', title: '', message: "", position: "" });
    const [loading, setLoadingShow] = useState(false);
  
    const removeAlert = () => setAlert({ open: false });
  
    const addAlert = (message = "", severity = 'error', title = "", position = "") => {
        setAlert({ open: true, severity, title, message, position });
    }

    const setLoading = (isLoading = false) => {
        setLoadingShow(isLoading);
    }
  
    const contextValue = {
        alert,
        addAlert: useCallback((severity, title, message, position) => addAlert(severity, title, message, position), []),
        removeAlert: useCallback(() => removeAlert(), []),
        loading,
        setLoading: useCallback((isLoading) => setLoading(isLoading), []),
    };
  
    return (
        <NotificationLoadingContext.Provider value={ contextValue }>
            { children }
        </NotificationLoadingContext.Provider>
    );
}