import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, IconButton, Link, makeStyles, Menu, MenuItem, useTheme, Button } from '@material-ui/core';
import { Globe } from 'react-feather';

import { changeLanguage as reduxChangeLanguage } from '../actions/GeneralActions';
import WebpImg from './Layouts/WebpImg';
import moment from 'moment-timezone';
  
export default function Setting() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const [state, setState] = useState({
        languageAnchorEl: null,
    })

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    const handleMenuClose = () => {
        setState({ ...state, languageAnchorEl: null });
    };

    const languageId = 'primary-search-language-menu';
    const renderMenu = (
        <Menu
            anchorEl={state.languageAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={languageId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(state.languageAnchorEl)}
            onClose={handleMenuClose}

        >
            <MenuItem onClick={() => changeLanguage('cn')}>简</MenuItem>
            <MenuItem onClick={() => changeLanguage('tw')}>繁</MenuItem>
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            {/* <MenuItem onClick={() => changeLanguage('ms')}>Malay</MenuItem> */}
            {/* <MenuItem onClick={() => changeLanguage('id')}>Indonesia</MenuItem> */}
        </Menu>
    );
    const [serverTime, setServerTime] = useState("");
    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval( () => {
            setServerTime(moment().format("YYYY-MM-DD HH:mm:ss"))
        },1000)
      
        return () => clearInterval(secTimer);
    }, [])

    return (
        <Box className={styles.root}>
            <Box style={{ backgroundColor: theme.palette.white.main, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 15px 6px', width: "100%" }}>
                <Link underline='none' to="/" component={RouterLink} className={styles.iconStyle} style={{ paddingTop: 5 }}>
                    <WebpImg src="/images/logo.png" style={{ height: 36, }} alt=" logo" containerStyle={{ lineHeight: 0 }} />
                </Link>
                <div style={{display:'flex', alignItems:'center'}}>
                    <IconButton
                        aria-label="language"
                        aria-controls={languageId}
                        aria-haspopup="true"
                        onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget })}
                        color="inherit"
                        style={{ paddingRight:27 }}
                    >
                        <Globe color={theme.palette.primary.main} size={20} />
                    </IconButton>
                    {renderMenu}
                </div>
            </Box>
                    <div style={{ padding: '6px 15px 0', textAlign: "right", color:"#767373" }}>
                        <span>{t('general.serverTime')}: {serverTime}</span>
                    </div>
            <div style={{width:330, maxWidth:'100%', margin:'0 auto', paddingTop:50 }}>
                <img src="/images/general/maintenance.png" alt="maintenance"/>
            </div>
            <div style={{textAlign:'center', color:'#606060'}}>
            <p className={styles.title}><b>{t('maintenance.maintenance')}</b></p>
            <p className={styles.desc}>{t('maintenance.message')}</p>
            </div>
            <Box style={{ paddingTop: 30 }}>
                <Link style={{ textDecoration: 'none' }} to="/" component={RouterLink} >
                    <Button fullWidth variant="contained" color="primary" size="large">{t('voucher.backToHomepage')}</Button>
                </Link>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0 20px 30px',
        minHeight: '71vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    title: {
        fontSize:21,
        paddingBottom:5
    },
    desc: {
        fontSize:14,
        lineHeight:1.4,
        padding:'8px 11% 0'
    }
}));
