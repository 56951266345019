import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { makeStyles, Box, Paper, Typography, InputAdornment, TextField, Button, FormHelperText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Link, Divider, IconButton } from '@material-ui/core';

import { useTheme, createMuiTheme, MuiThemeProvider  } from '@material-ui/core/styles';

import TitleBarWhite from './Layouts/TitleBarWhite';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat, stringToLang } from '../helper/Tools';
import { storeWalletBalance } from '../actions/UserActions';
import { TiInfoLarge } from 'react-icons/ti';
import WebpImg from './Layouts/WebpImg';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const DEFAULT_ERROR_FIELD = { amount: "", security_password: "", crypto_address: "", authentication_code: "" };
// const DEFAULT_ERROR_FIELD = { amount: "", password: "" };
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function Withdrawal() {
    const [withdrawRules, setWithdrawRules] = useState({
        "min": null,
        "max": null,
        "charges": null,
        "charges_type": null,
        "charges_value": null,
        "withdrawal_limit": null,
    });

    const [walletInfo, setWalletInfo] = useState([]);
    const [walletName, setWalletName] = useState("");
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [state, setState] = useState({ amount: "", security_password: "", dateTime: "", id: "", crypto_address: "", authentication_code: "", showPassword: false });
    const [errorField, setErrorField] = useState(DEFAULT_ERROR_FIELD);
    const [withdrawalStatus, setWithdrawalStatus] = useState("insert"); // insert -> review -> result
    const [withdrawalLimit, setWithdrawalLimit] = useState([]);

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance, crypto_address, crypto_network, two_factor_auth } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
        crypto_address: state.user.crypto_address,
        crypto_network: state.user.crypto_network,
        two_factor_auth: 1,
    }));
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMountedRef = useRef(null);

    
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setWalletName(stringToLang(existWallet.name));
        setUserWalletInfo(existWalletBalance);
        if(!existWallet) {
            history.goBack();
        }
    }, [wallet, walletBalance]);

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo)) {
            getUrl('default/get-withdrawal-rules').then(response => {
                if(response.code == 0 && isMountedRef.current) {
                    setWithdrawRules(response.data)
                    // if(response.code == 0) {
                    //     // if(parseInt(response?.data['wallet_id']) !== walletInfo.id) {
                    //     //     history.push('/wallet');
                    //     // } else {
                    //     //     // setMaxWithdraw(response.data?.max);
                    //     //     // setMinWithdraw(response.data?.min);
                    //     // }
                    // } else {
                    // }
                }else{
                    history.push('/wallet');
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
            
            let userid = 4;

            getUrl('default/get-withdrawal-limit').then(response => {
                if(response.code == 0 && isMountedRef.current) {
                    setWithdrawalLimit(response.data);
                }else{
                    history.push('/wallet');
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => isMountedRef.current = false;
    }, [addAlert, walletInfo])

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        if(name === 'amount') {
            if(parseFloat(value) > 0) {
                const patt = /^\d+\.{0,1}\d{0,2}$/;
                newValue = patt.test(newValue) ? newValue : state.amount;
            }
        }
        setState({ ...state, [name]: newValue });
    }

    const reviewWithdrawal = () => {
        let error = false;
        let newErrorField = {};
        _.map(["amount"], field => {
        //  _.map(["amount", "password"], field => {
            if(_.size(state[field]) === 0) {
                error = true;
                newErrorField[field] = t('withdrawal.isRequired', { field: t(`transfer.${field}`) });
            } else {
                newErrorField[field] = "";
            }
        });

        if(withdrawRules.min > 0) {
            if(parseFloat(state.amount) < parseFloat(withdrawRules.min)) {
                error = true;
                newErrorField['amount'] = t('withdrawal.amountLesserThan', { amount: withdrawRules.min });
            }
        }
        if(withdrawRules.max > 0) {
            if(parseFloat(state.amount) > parseFloat(withdrawRules.max)) {
                error = true;
                newErrorField['amount'] = t('withdrawal.amountGreaterThan', { amount: withdrawRules.max });
            }
        }

        setErrorField(newErrorField);
        if(!error) {
            setWithdrawalStatus('review')
        } else {
            setWithdrawalStatus('insert')
        }
    }

    const withdrawWallet = () => {
        setLoading(true);
        const { amount, security_password, authentication_code } = state;
        const withdrawalData = {
            amount,
            security_password,
            // crypto_address
            // password,
            authentication_code,
        };

        postUrl(`cash/apply`, withdrawalData).then(response => {
            // console.log("response", response)
            if(response.code == 0) {
                // const { created_at, transaction_id, data } = response;
                setWithdrawalStatus('result');
                // setState({ ...state, id: transaction_id, dateTime: created_at });
                setErrorField(DEFAULT_ERROR_FIELD);
                refetchWallet();
            } else {
                // if(response.errors) {
                //     let newErrorField = errorField;
                //     _.map(response.errors, (errorMsg, errorField) => {
                //         newErrorField[errorField] = errorMsg[0];
                //     })
                //     setErrorField(newErrorField);
                // }
                if(response.msg) {
                    addAlert(response.msg);
                }
                setWithdrawalStatus('insert')
            }
        }).catch(error => {
            setWithdrawalStatus('insert')
            addAlert(JSON.stringify(error.message));
        }).finally(() => {
            setLoading(false);
        })
    }

    const refetchWallet = () => {
        getUrl('default/balance').then(response => {
            // if(response.status) {
            //     dispatch(storeWalletBalance(response.data));
            // }
            if(response.code == 0) {
                dispatch(storeWalletBalance(response.data));
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
    }

    const getChargesValue = () => {
        const { charges_type, charges_value } = withdrawRules;

        if(charges_type === 'percent') {
            return (parseFloat(state.amount) * charges_value) / 100;
        } else {
            return charges_value;
        }
    }

    return (
        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
            
            
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <TitleBarWhite title={ t('tutorial.withdrawal') } back displayInfo={false}/>
                <Link underline='none' to="/TutorialWithdrawal" component={RouterLink} >
                <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff' }}>
                    <p>{t('tutorial.tutorial')}</p>
                </div>
                </Link>
            </div>
            {
                two_factor_auth === 1 ?
                <Paper elevation={0} classes={{ root: classes.paperContainer }}>
                    {
                        withdrawalStatus === 'result' ?
                        <Box className={classes.boxContainer} style={{ paddingBottom: 25 }}>
                            <WebpImg src="/images/general/stake-success.png" style={{ width: 196, }} alt=" logo" containerStyle={{ lineHeight: 0 }} />
                            <Typography variant="h6" style={{  lineHeight: 1.0, fontFamily: 'times-new-roman', fontWeight: 'bold', fontSize: 24, paddingTop:15, color: '#3F686B' }}>{t('withdrawal.successfullyWithdraw')}</Typography>

                            <Typography variant="h6" style={{ ...styles.inputPropsStyle, color: theme.palette.primary.main }}>
                                { `${ currencyFormat(state.amount) } ${_.size(walletInfo) ? walletName : ''}`}
                            </Typography>
                            <p style={{ fontSize: 15, color:theme.palette.silver.text, textAlign:'center' }}>{ t('withdrawal.feeCharges') } : { currencyFormat(getChargesValue()) }</p>
                        </Box>
                        :
                        <Box>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 0 15px' }}>
                                <p variant="h6" style={{ color: theme.palette.secondary.main, padding: 0, fontSize: 18 }}><b>{_.size(walletInfo) > 0 ? walletName : '-'} {t('title.walletWithdrawal', { wallet: walletInfo.wallet_name })} </b></p>
                                <div onClick={handleClickOpen} className={classes.infoButton}>
                                    <TiInfoLarge style={{ color: '#fff', fontSize: 32 }} />
                                </div>
                            </div>
                            <MuiThemeProvider theme={DialogStyle}>
                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-slide-title"
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle id="alert-dialog-slide-title">{t('withdrawal.withdrawalDetails')}</DialogTitle>
                                    <DialogContent>
                                        <Box>
                                            <div>
                                                <p className={classes.withdrawalDetailstext}>{t('withdrawal.minWithdraw', { amount: currencyFormat(withdrawRules.min) })}</p>
                                            </div>
                                            <div>
                                                <p className={classes.withdrawalDetailstext}>{t('withdrawal.maxWithdraw', { amount: currencyFormat(withdrawRules.max) })}</p>
                                            </div>
                                            <Typography className={classes.withdrawalDetailstext} style={{ paddingTop: 20 }}>{`${t('withdrawal.chargesFee', { amount: currencyFormat(withdrawRules.charges_value) })} ${withdrawRules.charges_type === 'percent' ? "%" : walletName}`}</Typography>

                                            <div style={{ paddingTop: 20 }}>
                                                {
                                                    currencyFormat(withdrawalLimit.daily) >= 0 && currencyFormat(withdrawalLimit.lifetime) >= 0 || currencyFormat(withdrawRules.withdrawal_limit) >= 0
                                                        ? <p className={classes.withdrawalDetailstext} style={{ paddingBottom: 3 }}><b>{t('withdrawal.withdrawalLimit')}</b></p>
                                                        : <></>
                                                }
                                                {
                                                    currencyFormat(withdrawalLimit.daily) >= 0 || currencyFormat(withdrawRules.withdrawal_limit) >= 0
                                                        ? <Typography className={classes.withdrawalDetailstext}>{t('withdrawal.daily')}:  {currencyFormat(withdrawalLimit.daily) >= 0 ? currencyFormat(withdrawalLimit.daily) : currencyFormat(withdrawRules.withdrawal_limit)}</Typography>
                                                        : <></>
                                                }
                                                {
                                                    currencyFormat(withdrawalLimit.lifetime) >= 0
                                                        ? <Typography className={classes.withdrawalDetailstext}>{t('withdrawal.lifetime')}:  {currencyFormat(withdrawalLimit.lifetime) >= 0 ? currencyFormat(withdrawalLimit.lifetime) : t('withdrawal.unlimited')}</Typography>
                                                        : <></>
                                                }
                                                {/* <Typography className={classes.withdrawalDetailstext}>{t('withdrawal.note')}</Typography> */}
                                            </div>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            X
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </MuiThemeProvider>
                            <div className={classes.walletCard}>
                                <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', height: '100%',}}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <img src='/images/general/icon/usdt.png' alt="rank" className={classes.walletIconImg} />
                                        <div style={{ color: theme.palette.white.text, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                                            <p className={classes.walletBalanceTitle}>{t('withdrawal.withdrawableBalance')}</p>
                                            <p className={classes.walletAmount}><b>{currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1)} </b></p>
                                        </div>
                                    </div>
                                    <div className={classes.walletAddressInfo}>
                                        <p><b style={{ textTransform: 'uppercase' }}>{crypto_network} :</b> {crypto_address}</p>
                                    </div>
                                </div>
                            </div>
                            <Box width="100%">
                                {/* <p style={{margin: '10px'}}>{t('withdrawal.note')}</p> */}
                                <TextField 
                                    type="number"
                                    variant="outlined"
                                    value={state.amount}
                                    name="amount"          
                                    onChange={ handleChange }   
                                    classes={{ root: classes.textFieldRoot }}
                                    InputProps={{ readOnly: withdrawalStatus === 'review' ? true : false }}
                                    label={ t('withdrawal.amount') }
                                    fullWidth
                                    error={ _.size(errorField.amount) ? true : false }
                                    helperText={ errorField.amount }
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                />
                                <TextField 
                                    type="text"
                                    variant="outlined"
                                    value={crypto_address}
                                    name="crypto_address"     
                                    classes={{ root: classes.textFieldRoot }}
                                    InputProps={{ readOnly: true /** withdrawalStatus === 'review' ? true : false */ }}
                                    label={ t('withdrawal.crypto_address') }
                                    fullWidth
                                    error={ _.size(errorField.crypto_address) ? true : false }
                                    helperText={ errorField.crypto_address }
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    multiline
                                    // placeholder={t('withdrawal.cryptoAddressHelperText')}
                                    // onChange={ handleChange } 
                                    style={{display:'none'}}
                                />
                                
                                {/* <FormHelperText>{t('withdrawal.cryptoAddressHelperText')}</FormHelperText> */}
                                <TextField 
                                    type="text"
                                    variant="outlined"
                                    value={crypto_network.toUpperCase()}
                                    name="crypto_network"          
                                    classes={{ root: classes.textFieldRoot }}
                                    InputProps={{ readOnly: true }}
                                    label={ t('wallet.walletType') }
                                    fullWidth
                                    style={{display:'none'}}
                                />
                                { withdrawalStatus === 'review' ?
                                    <TextField 
                                        type="text"
                                        disabled
                                        variant="outlined"
                                        value={getChargesValue()}
                                        onChange={ handleChange }   
                                        classes={{ root: classes.textFieldRoot }}
                                        label={ t('withdrawal.feeCharges') }
                                        fullWidth
                                    />
                                : null }

                                {/* <TextField
                                    type="password"
                                    variant="outlined"
                                    value={state.password}
                                    name="password"
                                    onChange={handleChange}
                                    classes={{
                                        root: classes.textFieldRoot,
                                    }}
                                    InputProps={{
                                        readOnly: withdrawalStatus === 'review' ? true : false,
                                    }}
                                    label={t('withdrawal.password')}
                                    error={_.size(errorField.password) ? true : false}
                                    helperText={errorField.password}
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    fullWidth
                                /> */}
                                <TextField 
                                            type={state.showPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            value={state.security_password}
                                            name="security_password"          
                                            onChange={ handleChange }   
                                            classes={{
                                                root: classes.textFieldRoot,
                                            }}
                                            InputProps={{
                                                readOnly: withdrawalStatus === 'review' ? true : false,
                                                endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            }}
                                            label={ t('withdrawal.security_password') }
                                            error={ _.size(errorField.security_password) ? true : false }
                                            helperText={ errorField.security_password }
                                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                            fullWidth
                                        />
                                {
                                    two_factor_auth === 1 ?
                                    <>
                                        {/* <TextField 
                                            type={state.showPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            value={state.security_password}
                                            name="security_password"          
                                            onChange={ handleChange }   
                                            classes={{
                                                root: classes.textFieldRoot,
                                            }}
                                            InputProps={{
                                                readOnly: withdrawalStatus === 'review' ? true : false,
                                                endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            }}
                                            label={ t('withdrawal.security_password') }
                                            error={ _.size(errorField.security_password) ? true : false }
                                            helperText={ errorField.security_password }
                                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                            fullWidth
                                        />
                                        <TextField 
                                            type="string"
                                            variant="outlined"
                                            value={state.authentication_code}
                                            name="authentication_code"          
                                            onChange={ handleChange }  
                                            classes={{
                                                root: classes.textFieldRoot,
                                            }}
                                            InputProps={{
                                                readOnly: withdrawalStatus === 'review' ? true : false,
                                            }}
                                            label={ t('profile.authenticationCode') }
                                            error={ _.size(errorField.authentication_code) ? true : false }
                                            helperText={ errorField.authentication_code }
                                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                            fullWidth
                                        /> */}
                                        {/* <FormHelperText>{t('general.2FACaption')}</FormHelperText> */}
                                    </>
                                    :
                                    <TextField 
                                        type={state.showPassword ? 'text' : 'password'}
                                        variant="outlined"
                                        value={state.security_password}
                                        name="security_password"          
                                        onChange={ handleChange }   
                                        classes={{
                                            root: classes.textFieldRoot,
                                        }}
                                        InputProps={{
                                            readOnly: withdrawalStatus === 'review' ? true : false,
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        label={ t('withdrawal.security_password') }
                                        error={ _.size(errorField.security_password) ? true : false }
                                        helperText={ errorField.security_password }
                                        FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                        fullWidth
                                    />
                                }
                            </Box>
                        </Box>
                    }
                    <Box className={classes.boxContainer}>
                        { withdrawalStatus === 'insert' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ reviewWithdrawal }>
                            { t('withdrawal.withdrawNow') }
                        </Button> }
                        { withdrawalStatus === 'review' && <Box display="flex" width="100%" flexDirection="row" justifyContent="space-around">
                            <Button variant="contained" classes={{ root: classes.buttonRoot2 }} style={{width:'48%'}} onClick={ () => setWithdrawalStatus('insert') }>
                                { t('withdrawal.changeDetail') }
                            </Button>
                            <Button variant="contained" classes={{ root: classes.buttonRoot }} style={{width:'48%'}} onClick={ withdrawWallet }>
                                { t('withdrawal.confirmWithdraw') }
                            </Button>
                        </Box> }
                        { withdrawalStatus === 'result' && <Button variant="contained" classes={{ root: classes.buttonRoot }} onClick={ () => history.push('/wallet') }>
                            { t('withdrawal.complete') }
                        </Button> }
                    </Box>
                </Paper>
                :
                <>
                <Paper elevation={0} classes={{ root: classes.paperContainer }}>
                    <Box style={{ padding: '20px 40px' }} display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                        <Typography variant="overline" color="textPrimary">{t('profile.set2FAFirst', { title: t('wallet.withdrawal') })}</Typography>
                        <div align="center" style={{ paddingTop: 20 }}>
                            <Link underline='none' to="/profile-two-factor-authentication" component={RouterLink} >
                                <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff', maxWidth: '170px' }}>
                                    <p align="center" style={{ lineHeight: 1.4 }}>{t('profile.2FA')}</p>
                                </div>
                            </Link>
                        </div>
                    </Box>
                </Paper>
                </>
            }
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        // marginLeft: 15,
        // marginRight: 15,
        // marginTop: -58,
        padding: '5px 15px 10px',
        // borderRadius: 20
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 0,
    },
    buttonRoot: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 10,
        marginTop: 15, 
        marginBottom: 30,
        width:'100%',
        "&:hover":{
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    buttonRoot2: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 10,
        marginTop: 15, 
        marginBottom: 30,
        width:'100%',
        "&:hover":{
            backgroundColor: '#1e4154',
        }
    },
    withdrawalDetailstext: {
        color:theme.palette.silver.text,
        fontSize:14,
        lineHeight:1.4
    },
    walletAddressInfo: {
        color: theme.palette.white.text,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%' ,
        paddingTop:15,
        position:'absolute',
        bottom:10
    },
    walletCard: {
        height: 165,
        background: theme.palette.secondary.main,
        borderRadius: 15,
        width: '100%',
        margin:'0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 14,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 163,
        },
    },
    walletIconImg: {
        width: 51,
        [theme.breakpoints.down('sm')]: {
            width: 43,
        },
    },
    walletBalanceTitle: {
        textTransform: 'uppercase',
        fontSize: 13,
        paddingBottom: 2,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },
    walletAmount: {
        fontSize: 30,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
        },
    },
    infoButton:{
        width: 30, height: 30, padding: 5, borderRadius: 5, border: '2px solid',
        borderColor:theme.palette.primary.darker,
        background: theme.palette.primary.main, boxShadow: theme.palette.primary.shadow, display: 'flex', alignItems: 'center', justifyContent: 'center',
        "&:hover": {
            backgroundColor: theme.palette.primary.secondary,
        }
    }
}));

const styles = {
    inputPropsStyle: { 
        textAlign: 'center', 
        fontSize: 30, 
        fontWeight: 'bold', 
        lineHeight: 1,
        paddingTop: 10,
        paddingBottom:5
    }
}

const DialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh'
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 24px'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                background: '#0008',
                borderRadius: 48,
                minWidth: 'unset',
                padding: 0,
            }
        }
      
        // MuiInput: {
        //     underline: {
        //         '&::before': {
        //             borderBottom: '1px solid #fff'
        //         },
        //         '&::after': {
        //             borderBottom: '2px solid #fff'
        //         },
        //         "&:not(.Mui-disabled):hover::before": {
        //             borderColor: "#fff"
        //         },
        //     },
        // },
        // MuiIconButton: {
        //     root: {
        //         color:'#c2c2c2',
        //         "&$focused": {
        //             color: '#FF5532'
        //             },
        //     }
        // },
        // MuiSelect: {
        //     icon: {
        //         color:'#fff'
        //     },
        //     select: {
        //         "&$focused": {
        //             backgroundColor: 'transparent'
        //             },
        //     }
        // }
        
    },
});