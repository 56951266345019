import React, { useState, useRef, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import _ from 'lodash';
import { postUrl, getUrl } from '../helper/ApiAction';
import { APP_NAME } from '../configs/Config';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';


import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { TextField, Button, Typography, Grid, Link, Box, FormHelperText } from '@material-ui/core';

import WebpImg from './Layouts/WebpImg';
import theme from '../Theme';

function Copyright() {
    return (
        <Typography variant="body2" align="center" style={{color:theme.palette.silver.text}}>
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                { APP_NAME }
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function PasswordReset() {

    const { t } = useTranslation();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    // const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let { token } = useParams();
    const query = useQuery();
    const email = query.get("email");
    const username = query.get("username");

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({ password: '', passwordConfirm: '', authentication_code: '', tac: '', tfa: false});
    const [completed, setCompleted] = useState(false);
    const [timer, setTimer] = useState();
    const [resendVerification, setResendVerification] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    /* useEffect(() => {
        if(accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]); */

    const resetPassword = () => {
        setLoading(true);
        const apiData = {
            password: state.password,
            password_confirmation: state.passwordConfirm,
            token,
            email,
            username,
            authentication_code: state.authentication_code,
            tac: state.tac
        }
        postUrl(`user/edit-password`, apiData).then(result => {
            setLoading(false);
            let { status, message, error } = result;
            if (result.code === 0) {
                addAlert(result.msg, 'success', '', '');
                setCompleted(true);
            } else {
                // if (_.size(error) > 0) {
                //         _.map(error, (value, key) => {
                //             message += "\n" + value[0];
                //         })
                //         setInputErrors(error);
                //     }
                addAlert(result.msg, 'error', '', '');
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
            }
        });
    }

    const requestTac = () => {
        setLoading(true);
        let postData = {
            username: username ? username : '',
            email: email ? email : '',
        }
        getUrl('default/send-mail', postData).then(result => {
            setInputErrors([]);
            if (result.code !== 0) {
                setLoading(false);
                addAlert(result.msg);
            } else {
                setLoading(false);
                addAlert(result.msg, 'success');
                setResendVerification(true);
                setTimer(60);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('general.checkMailBox'), 'success');
        });
    }

    // useEffect(() => {
    //     setLoading(true);
    //     let getData = {
    //         username: username ? username : '',
    //         email: email ? email : '',
    //     }
    //     getUrl('get-username-by-data', getData).then(result => {
    //         setInputErrors([]);
    //         if (result.error) {
    //             setLoading(false);
    //         } else {
    //             setLoading(false);
    //             if(result.data.tfa){
    //                 setState({ ...state, tfa: true});
    //             }
    //         }
    //     }).catch((error) => {
    //         setLoading(false);
    //         addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
    //     });
    // }, [])

    useEffect(() => {
        if (timer === 0) {
            setResendVerification(false);
        }
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timer])

    return (
        <Grid container component="main" className={classes.root} justify="center">
                <div className={classes.paper}>
                <center><img src="/images/logo-text.png" style={{ width:165, paddingLeft: 10, paddingRight: 10, }} alt="logo" /></center>
                    <div style={{color:'#ececec', textAlign:'center'}}>
                    <Typography component="h1" variant="h5" style={{ marginTop: '20px' }}>
                        <b>{t('general.resetPassword')}</b>
                    </Typography>
                    {!completed?<Typography variant="body2" style={{paddingTop:8}}>{t('general.resetPasswordMsg')}</Typography>:null}
                    <Typography variant="body2" style={{paddingTop:8}}>{username + ' - ' +email}</Typography>
                    </div>
                    {!completed ?
                        <> 
                        <MuiThemeProvider theme={formStyle}>
                            <form className={classes.form} noValidate onSubmit={ev => { ev.preventDefault(); resetPassword() }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label={t('profile.newPassword')}
                                    name="password"
                                    value={state.password}
                                    onChange={({ target }) => setState({ ...state, password: target.value })}
                                    autoFocus
                                    type="password"
                                    error={inputErrors.password ? true : false}
                                    helperText={inputErrors.password ? inputErrors.password : ''}
                                />
                                <Typography variant="caption" style={{ color: "#fff" }}>{t('profile.passwordValidation')}</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="passwordConfirm"
                                    label={t('profile.reenterNewPassword')}
                                    name="passwordConfirm"
                                    value={state.passwordConfirm}
                                    onChange={({ target }) => setState({ ...state, passwordConfirm: target.value })}                                    
                                    type="password"
                                    error={inputErrors.password_confirmation ? true : false}
                                    helperText={inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                                />
                                {
                                    state.tfa === true ? 
                                    <>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="authentication_code"
                                            label={t('profile.authenticationCode')}
                                            name="authentication_code"
                                            value={state.authentication_code}
                                            onChange={({ target }) => setState({ ...state, authentication_code: target.value })}                                    
                                            type="text"
                                            error={inputErrors.authentication_code ? true : false}
                                            helperText={inputErrors.authentication_code ? inputErrors.authentication_code : ''}
                                        />
                                        <FormHelperText variant="outlined" style={{ color: "#fff" }}>{t('general.2FACaption')}</FormHelperText>
                                    </>
                                    :
                                    <>
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <TextField
                                                    error={inputErrors.tac ? true : false}
                                                    helperText={inputErrors.tac}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    size="small"
                                                    label={t('profile.tac')}
                                                    type="text"
                                                    inputProps={{ name: 'tac' }}
                                                    onChange={({ target }) => setState({ ...state, tac: target.value })}        
                                                    value={state.tac}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ paddingLeft: '20px' }}>
                                                <Button onClick={requestTac} variant="contained" color="primary" disabled={resendVerification}>{timer > 0 ? timer : t('button.requestTac')}</Button>
                                            </Grid>
                                        </Grid>
                                        <FormHelperText style={{ color: "#fff" }}>{t('general.tacCaption')}</FormHelperText>
                                    </>
                                }
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >{t('profile.changePassword')}</Button>
                                </div>
                                <Box mt={5}>
                                    <Copyright/>
                                </Box>
                            </form>
                            </MuiThemeProvider>           
                        </>
                        :
                        <>
                        <Typography variant="body2" style={{ color:'#ececec', paddingTop:8 }}>{t('general.resetPasswordSuccess')}</Typography>
                        <Link underline='none' to={`/login`} component={RouterLink}>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {t('button.proceedToLogin')}
                            </Button>
                        </Link>
                        </>
                    }
                </div>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        background:theme.palette.secondary.main
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background:theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        
    },
    logoImg: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
}));

const formStyle = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                color: "#ececec",
                "&$focused": {
                    color:  theme.palette.primary.main,
                    },
            },
           
        },
        MuiInputBase: {
            root: {
                color: "#ececec",
                "&$focused": {
                    color:  theme.palette.primary.main,
                    },
            }
        },
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor:theme.palette.silver.text,
                },
                "&:hover $notchedOutline": {
                    borderColor:theme.palette.silver.text,
                },
                "&$focused $notchedOutline": {
                    borderColor:theme.palette.primary.main,
                }
              },
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor :theme.palette.primary.main,
                "&:hover": {
                    backgroundColor :theme.palette.primary.secondary,
                }
            }
        }
        
    },
});