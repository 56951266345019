import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Link, Snackbar, Card, CardContent, FormHelperText, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl, postUrl, getUrl } from '../helper/ApiAction';
import { useSelector,useDispatch } from 'react-redux';
import useNotificationLoading from '../helper/useNotificationLoading';

import { FiChevronLeft } from 'react-icons/fi';
import TitleBarWhite from './Layouts/TitleBarWhite';
import { storeProfile} from '../actions/UserActions'


export default function ProfileSecurityPassword() {
    const { id, username, landing_icon, max_rank: rank, max_mrank, contract, expiry_date, account_link, email_verified_at, referral_username,email } = useSelector(state => state.user);
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    
    const INITIAL_SECURITY_PASSWORD_STATE = { tac: '',old_email:email,new_email:'',new_tac:'' };
    // const { two_factor_auth, username, email } = useSelector(state => ({
    //     two_factor_auth: state.user.two_factor_auth,
    //     username: state.user.username,
    //     email: state.user.email,
    // }));
    const [securityPasswordFormState, setSecurityPasswordFormState] = useState(INITIAL_SECURITY_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        tac: '',old_email:''
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });
    const { addAlert, setLoading } = useNotificationLoading();
    const [timer, setTimer] = useState();
    const [resendVerification, setResendVerification] = useState(false);

    const handleSecurityPasswordChange = (event) => {
        const name = event.target.name;
        setSecurityPasswordFormState({ ...securityPasswordFormState, [name]: event.target.value });
    }

    const updateSecurityPassword = () => {
        setLoading(true);
        const { tac,old_email,new_email,new_tac } = securityPasswordFormState;
        if(email == ''){
            if(!old_email || !tac) {
                setLoading(false);
                setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
                return false;
            }
        }else{
            if(!old_email || !tac || !new_email || !new_tac) {
                setLoading(false);
                setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
                return false;
            }
        }
        putUrl('user/edit-security-email', securityPasswordFormState).then(result => {
            if(result.code == 0) {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: false, message: result.msg }) );
                let allErrorField = [];
                setErrorFormState(allErrorField);
                setSecurityPasswordFormState(INITIAL_SECURITY_PASSWORD_STATE);
                getUrl('user/index')
                .then(userData => {
                    if (userData.code == 0) {
                        dispatch(storeProfile(userData.data));
                    }
                }).catch(error => {
                });
            } else {
                setLoading(false);
                setState( state => ({ ...state, snackbarShow: true, error: true, message: result.msg }) );
                setErrorFormState({});
            }
        })
    }

    const [viewAnnouncement, setViewAnnouncement] = useState(true);
    const closeAnnouncement = (event, reason) => {
        if (reason && (reason == "backdropClick" || reason == "escapeKeyDown")) 
        return;
        setViewAnnouncement(false);
    }
    const [agree, setAgree] = useState(false);
    const checkboxHandler = () => {
        setAgree(!agree);
    }
    const btnHandler = () => {
        setViewAnnouncement(false);
    };
    const forgotPassword = () => {
        setLoading(true);

        postUrl(`forgot_security_password`, { email: email, username: username }).then(result => {
            setLoading(false);
            let { status, message } = result;
            if (status === 1) {
                addAlert(message, 'success', '', '');
            } else {
                addAlert(message, 'error', '', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
        });
    }

    const requestTac = () => {
        const { tac,old_email,new_email,new_tac } = securityPasswordFormState;
        if(old_email == ''){
            return addAlert("请填写邮箱", 'error', '', '');
        }
        let postData = {
            email: old_email,
        }
        setLoading(true);
        getUrl('default/send-mail', postData).then(result => {
            setErrorFormState({});
            if (result.code!==0) {
                setLoading(false);
                addAlert(result.msg);
            } else {
                setLoading(false);
                addAlert(t('general.checkMailBox'), 'success');
                // addAlert(result.message, 'success');
                // setResendVerification(true);
                // setTimer(60);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('general.checkMailBox'), 'success');
        });
    }

    const requestTacNew = () => {
        const { tac,old_email,new_email,new_tac } = securityPasswordFormState;
        if(new_email == ''){
            return addAlert("请填写邮箱", 'error', '', '');
        }
        let postData = {
            email: new_email,
        }
        setLoading(true);
        getUrl('default/send-mail', postData).then(result => {
            setErrorFormState({});
            if (result.code!==0) {
                setLoading(false);
                addAlert(result.msg);
            } else {
                setLoading(false);
                addAlert(t('general.checkMailBox'), 'success');
                // addAlert(result.message, 'success');
                // setResendVerification(true);
                // setTimer(60);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('general.checkMailBox'), 'success');
        });
    }

    

    useEffect(() => {
        if (timer === 0) {
            setResendVerification(false);
        }
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timer])

    return (
        <Box>
            
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'0 10px'}}>
            <TitleBarWhite title={ t('profile.changeSecurityPassword') } back displayInfo={false}/>
                {/* <Link underline='none' to="/TutorialSecurityPassword" component={RouterLink} >
                <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff' }}>
                    <p>{t('tutorial.tutorial')}</p>
                </div>
                </Link> */}
            </div>

            {/* <Box style={{ padding:'0 20px' }}>

                <Typography variant="h6">
                    {t('profile.changeSecurityPassword')}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    {t('profile.securityPasswordSubtitle')}
                </Typography>
                <div style={{ padding: 1 }}>
                    <Typography variant="caption" color="textSecondary">
                        <b>{t('profile.forgotSecurityPassword')}</b>
                    </Typography>
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                        <Button variant="contained" onClick={forgotPassword} style={{ width: 500, maxWidth: '80%', color:'#fff' }} color="primary">{t('profile.buttonForgotSecurityPassword')}</Button>
                    </Box>
                </div>
            </Box> */}
            {
                email == '' ?
                <>
                    <div style={{ padding: '30px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('ticket.email')}</b></Typography>
                        <div>
                            <TextField
                                error={errorFormState.old_email ? true : false}
                                helperText={errorFormState.old_email}
                                // fullWidth={true}.
                                variant="outlined"
                                size="small"
                                inputProps={{ name: 'old_email' }}
                                onChange={handleSecurityPasswordChange}
                                value={securityPasswordFormState.old_email}
                            />
                            <Button variant="contained" onClick={requestTac} style={{float:'right', color:'#fff' }} color="primary">{t('button.requestTac')}</Button>
                            {/* <FormHelperText>{t('profile.currentSecurityPasswordInfo')}</FormHelperText> */}
                        </div>
                    </div>
                    <div style={{ padding: '5px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.tac')}</b></Typography>
                        <TextField
                            error={errorFormState.tac ? true : false}
                            helperText={errorFormState.tac}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            inputProps={{ name: 'tac' }}
                            onChange={handleSecurityPasswordChange}
                            value={securityPasswordFormState.tac}
                        />
                    </div>
                    <List className={styles.listStyle} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                            <Button variant="contained" onClick={updateSecurityPassword} style={{ width: 500, maxWidth: '80%', color:'#fff' }} color="primary">{t('button.confirm')}</Button>
                        </Box>
                    </List>
                </>
                :
                <>
                <div style={{ padding: '30px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('ticket.email')}</b></Typography>
                        <div>
                            <TextField
                                error={errorFormState.old_email ? true : false}
                                helperText={errorFormState.old_email}
                                // fullWidth={true}.
                                variant="outlined"
                                size="small"
                                inputProps={{ name: 'old_email',readOnly: true, }}
                                onChange={handleSecurityPasswordChange}
                                value={securityPasswordFormState.old_email}
                            />
                            <Button variant="contained" onClick={requestTac} style={{float:'right', color:'#fff' }} color="primary">{t('button.requestTac')}</Button>
                            {/* <FormHelperText>{t('profile.currentSecurityPasswordInfo')}</FormHelperText> */}
                        </div>
                    </div>
                    <div style={{ padding: '5px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.tac')}</b></Typography>
                        <TextField
                            error={errorFormState.tac ? true : false}
                            helperText={errorFormState.tac}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            inputProps={{ name: 'tac' }}
                            onChange={handleSecurityPasswordChange}
                            value={securityPasswordFormState.tac}
                        />
                    </div>
                    <div style={{ padding: '5px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('button.update')}{t('ticket.email')}</b></Typography>
                        <div>
                            <TextField
                                error={errorFormState.new_email ? true : false}
                                helperText={errorFormState.new_email}
                                // fullWidth={true}.
                                variant="outlined"
                                size="small"
                                inputProps={{ name: 'new_email' }}
                                onChange={handleSecurityPasswordChange}
                                value={securityPasswordFormState.new_email}
                            />
                            <Button variant="contained" onClick={requestTacNew} style={{float:'right', color:'#fff' }} color="primary">{t('button.requestTac')}</Button>
                            {/* <FormHelperText>{t('profile.currentSecurityPasswordInfo')}</FormHelperText> */}
                        </div>
                    </div>
                    <div style={{ padding: '5px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.tac')}</b></Typography>
                        <TextField
                            error={errorFormState.new_tac ? true : false}
                            helperText={errorFormState.new_tac}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            inputProps={{ name: 'new_tac' }}
                            onChange={handleSecurityPasswordChange}
                            value={securityPasswordFormState.new_tac}
                        />
                    </div>
                    <List className={styles.listStyle} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                            <Button variant="contained" onClick={updateSecurityPassword} style={{ width: 500, maxWidth: '80%', color:'#fff' }} color="primary">{t('button.confirm')}</Button>
                        </Box>
                    </List>
                 </>
                // <Box style={{ padding:'20px 40px' }} display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                //     <Typography variant="overline" color="textPrimary">{t('profile.set2FAFirst', { title: t('profile.securityPassword') })}</Typography>
                //     <div align="center" style={{ paddingTop: 20 }}>
                //         <Link underline='none' to="/profile-two-factor-authentication" component={RouterLink} >
                //             <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff', maxWidth: '170px' }}>
                //                 <p align="center" style={{ lineHeight: 1.4 }}>{t('profile.2FA')}</p>
                //             </div>
                //         </Link>
                //     </div>
                // </Box>
            }
            
            <Snackbar
                open={state.snackbarShow}
                autoHideDuration={8000}
                onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                    <CardContent style={{ padding: 10 }}>
                        <Typography style={{ color: 'white' }}>{state.message}</Typography>
                    </CardContent>
                </Card>
            </Snackbar>

            {/* <MuiThemeProvider key='warn' theme={announceDialogStyle}>
                <Dialog
                    open={viewAnnouncement? true : false}
                    onClose={closeAnnouncement}
                    scroll="body"
                    aria-labelledby="view Announcement"
                    aria-describedby="view Announcement detail"
                    key='warn'
                    style={{width:'547px', maxWidth:'100%', margin:'0 auto'}}
                >
                    <DialogTitle id='warn'>{t('topup.dialog.title')}</DialogTitle>
                    <DialogContent dividers={false}>
                        <div
                            id="viewAnnouncement"
                            tabIndex={-1}
                        >
                            <p>{t('profile.dialog.content')}</p><br/>
                            <input type="checkbox" id="agree" onChange={checkboxHandler} />
                            <label htmlFor="agree">{t('topup.dialog.agree')}</label>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!agree} onClick={btnHandler}>
                            {t('button.proceed')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider> */}
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    backIconStyle: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        cursor: 'pointer',
    },
}));

const announceDialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh'
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 24px'
            }
        },
    },
});