import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';
import { postUrl } from '../helper/ApiAction';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Container, Grid, Box, TextField, Link } from '@material-ui/core';
import 'react-awesome-slider/dist/styles.css';
import { Mail } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CONTACT_US_EMAIL } from '../configs/Config';
import useNotificationLoading from '../helper/useNotificationLoading';
import SendIcon from '@material-ui/icons/Send';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TitleBarWhite from './Layouts/TitleBarWhite';
import WebpImg from './Layouts/WebpImg';
import theme from '../Theme';
import TelegramIcon from '@material-ui/icons/Telegram';

function useInput({ type, label, multiline }) {
    const [value, setValue] = useState("");
    const [error, setErrorValue] = useState("");
    const input = 
    <TextField label={label} variant="outlined" error={error ? true : false}  helperText={error} value={value} onChange={e => setValue(e.target.value)} type={type} style={{ width: '100%', maxWidth: '100%', marginTop: '15px' }} multiline={multiline} />;
    return [value, input, setErrorValue];
}

export default function Home() {
    const { t } = useTranslation();
    const styles = useStyles();
    const classes = useStyles();
    // const dispatch = useDispatch();
    const { addAlert } = useNotificationLoading();
    // const [setMessageValue] = React.useState('Controlled');

    const [username, usernameInput, usernameValidate] = useInput({ type: "text", label: t('form.username'), multiline: false });
    const [name, nameInput, nameValidate] = useInput({ type: "text", label: t('form.name'), multiline: false });
    const [email, emailInput, emailValidate] = useInput({ type: "text", label: t('form.email'), multiline: false });
    const [subject, subjectInput, subjectValidate] = useInput({ type: "text", label: t('form.subject'), multiline: false });
    const [message, messageInput, messageValidate] = useInput({ type: "text", label: t('form.message'), multiline: true });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const validate = { username: usernameValidate, name: nameValidate, email: emailValidate, subject: subjectValidate, message: messageValidate };

    let history = useHistory();
    const { accessToken } = useSelector(state => state.general);

    // const handleChange = (event) => {
    //     setMessageValue(event.target.value);
    // };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        postUrl(`contactus`, {
            username,
            name,
            email,
            subject,
            message
        })
        .then((response) => {
            console.log(response);
            let {status, message, error} = response;
            if(status === 0){
                setErrorMessage(message);
                _.map(error, (value, key) => {
                    validate[key](value[0]);
                })
            }else{
                setSuccessMessage(message);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const chatWithAdmin = () => {
        postUrl('adminchats').then(response => {
            // console.log("adminchats",response);
            if (response.status) {
                history.push(`/customerservice`);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    return (
        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
             <TitleBarWhite title={ t('title.bonus') } back displayInfo={false}/>
            <Box>
                <div style={{width:270, margin:'0 auto', maxWidth:'100%'}}>
                <img src="/images/general/contact.png" alt={t('support.contactUs')} />
                </div>
                <Typography style={{ fontSize: '25px', color: '#757575', textAlign:'center' }} >{t('form.getInTouch')}</Typography>
                {/* <Typography style={{ fontSize: '15px', color: '#757575', textAlign:'center' }}>{t('contact.submessage')}</Typography> */}

                {/* <Box style={{display:'flex', justifyContent:'space-around', alignItems:'center',width:'100%', paddingTop:30}}>
                    <a className={styles.footerFont} style={{ fontSize: '13px', textDecoration: 'none' }} href={`mailto:${CONTACT_US_EMAIL}`}>
                    <Box >
                            <WebpImg src="/images/general/icon/email.png" className={styles.moveTopHoverYellow} style={{ height: 65, borderRadius: 18, }} alt=" icon" />
                            <Typography style={{ fontSize: 11, color: '#757575', textAlign: 'center' }}>{t('support.emailUs')}</Typography>
                        </Box>
                    </a>
                    
                    <a className={styles.footerFont} style={{ fontSize: '13px', textDecoration: 'none' }} href="https://t.ly/GiXi">
                        <Box>
                            <WebpImg src="/images/general/icon/map2.png" className={styles.moveTopHoverRed}  style={{ height: 65, borderRadius: 18, }} alt=" icon" />
                            <Typography style={{ fontSize: 11, color: '#757575', textAlign: 'center' }}>{t('contact.location')}</Typography>
                        </Box>
                    </a>

                    <a className={styles.footerFont} style={{ fontSize: '13px', textDecoration: 'none' }} href="https://www.facebook.com/">
                    <Box>
                            <WebpImg src="/images/general/icon/fb2.png" className={styles.moveTopHoverBlue} style={{ height: 65, borderRadius: 18,  }} alt=" icon" />
                            <Typography style={{ fontSize: 11, color: '#757575', textAlign: 'center' }}>{t('title.mistorefb')}</Typography>
                        </Box>
                    </a>
                    <Box>
                        <Link underline='none' to="/contactUsForm"  component={RouterLink}>
                        <WebpImg src="/images/general/icon/message.png" style={{ height: 65, borderRadius:18, boxShadow:'#00000036 4px 4px 17px 1px' }} alt=" message" />
                        <Typography style={{fontSize:11, color:'#757575', textAlign:'center'}}>{t('support.dropUsALine')}</Typography>
                        </Link>
                    </Box>
                    {
                            accessToken?
                    <Box onClick={chatWithAdmin} style={{cursor:'pointer'}}>
                        <WebpImg src="/images/general/icon/livechat.png" style={{ height: 65, borderRadius:18, boxShadow:'#00000036 4px 4px 17px 1px' }} alt=" livechat" />
                        <Typography style={{fontSize:11, color:'#757575', textAlign:'center'}}>{t('support.liveChat')}</Typography>
                    </Box>
                    :
                    null
                }
                            
                </Box> */}


                {/* <Box style={{ padding: '30px 7%' }}>
                    <Box style={{ paddingBottom: 24 }}>
                        <Typography style={{ fontSize: 17, color: '#757575' }}><b>{t('contact.email')}</b></Typography>
                        <Typography style={{ fontSize: 14 }}>{CONTACT_US_EMAIL}</Typography>
                    </Box>
                    <Box style={{ paddingBottom: 24 }}>
                        <Typography style={{ fontSize: 17, color: '#757575' }}><b>{t('contact.address')}</b></Typography>
                        <Typography style={{ fontSize: 14 }}>{t('address.projectAddress')}</Typography>
                    </Box>
                    <Box style={{ paddingBottom: 24 }}>
                        <Typography style={{ fontSize: 17, color: '#757575' }}><b>{t('contact.address')}</b></Typography>
                        <Typography style={{ fontSize: 14 }}>014-966 1502</Typography>
                    </Box>
                    <Box style={{ paddingBottom: 24 }}>
                        <Typography style={{ fontSize: 18, color: '#606060' }}><b>{t('contact.followUs')}</b></Typography>
                        <div style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }}>
                            <WebpImg src="/images/general/icon/insta.png" style={{ height: 55, borderRadius: 18, boxShadow: '#00000036 4px 4px 17px 1px', marginRight: 30 }} alt="insta" />
                            <WebpImg src="/images/general/icon/fb.png" style={{ height: 55, borderRadius: 18, boxShadow: '#00000036 4px 4px 17px 1px' }} alt="fb" />
                        </div>
                    </Box>
                </Box> */}
                <Box className={classes.boxContainer} style={{paddingTop:20,}}>
                    <div style={{paddingBottom:10, textAlign:'center'}}>
                    <p> {t('topup.inquiries')}</p>
                    <a href={`mailto:${CONTACT_US_EMAIL}`}>{`${CONTACT_US_EMAIL}`}</a>
                    <br/><br/>
                    {/* <Button variant="contained" color="primary" style={{color:'#fff'}} href="https://web.telegram.org/z/#?tgaddr=tg%3A%2F%2Fresolve%3Fdomain%3Dledgerblock_bot"><TelegramIcon style={{ marginRight: 5, color:'#fff' }} />Telegram</Button> */}
                    </div>
                </Box>
                { false && 
                <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off" >
                <Container>
                { successMessage ? 
                        <Box style={{ padding: '0 16px', backgroundColor: '#fff', borderRadius: '0 15px 15px 0' }}>
                            {/* <Typography style={{paddingBottom:0, color:'#757575', fontSize:17, }}><b>{t('support.dropUsAlLine')}</b></Typography> */}
                            {/* <CheckCircleOutlineIcon style={{ color: 'green' }} fontSize="large" /> */}
                            <p style={{color:theme.palette.silver.text, textAlign:'center', lineHeight:1.4, fontSize:15}}>{ successMessage }</p>
                        </Box>
                        :
                        <Box style={{ padding: '0 16px', backgroundColor: '#fff', borderRadius: '0 15px 15px 0' }}>
                            {/* <Typography style={{paddingBottom:0, color:'#757575', fontSize:17,}}><b>{t('support.dropUsALine')}</b></Typography> */}
                            <Typography style={{ fontSize: 12, color: 'red' }}>{ errorMessage }</Typography>
                            {usernameInput}
                            {nameInput}
                            {emailInput}
                            {subjectInput}
                            {messageInput}
                        
                        <div style={{ width: '100%', maxWidth: '100%', justifyContent:'flex-end', display:'flex' }}>
                            <Button type="submit" style={{ marginTop:34, width:'100%', padding: '11px 15px', backgroundColor: theme.palette.primary.main, color: '#fff', borderRadius: '10px', display:'flex', alignItems:'center', boxShadow:theme.palette.secondary.shadow, }}>
                                <Typography>{t('button.sendNow')}</Typography>
                                {/* <SendIcon style={{ marginLeft: '15px', fontSize: '22px' }} /> */}
                            </Button>
                        </div>
                        </Box>
                }
                </Container>
                </form>
                }
            </Box>

            

            
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '5px !important',
    },
    cardRootStyle: {
        margin: '0px 1px 0px 1px'
    },
    buttonFont: {
        fontSize: '10px',
    },
    buttonStyle: {
        margin: '5px',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    listItemStyle: {
        justifyContent: 'center',
        padding: '5px 0px 5px 0px',
    },
    cardStyle: {
        // minHeight: 380,
        borderRadius: 0
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'black',
        backgroundColor: 'white'
    },
    moveTopHoverBlue:{
        marginTop: 0,
        transition: '0.8s',
        boxShadow:'rgb(14 76 205 / 31%) 4px 4px 17px 1px',
        "&:hover":{
            marginTop: -8,
            transition: '0.5s',
            boxShadow:'rgb(39 115 242 / 41%) 0px 3px 4px 1px'
        }
    },
    moveTopHoverRed:{
        marginTop: 0,
        transition: '0.8s',
        boxShadow:'rgb(232 68 65 / 31%) 1px 8px 17px 1px',
        "&:hover":{
            marginTop: -8,
            transition: '0.5s',
            boxShadow:'rgb(232 68 65 / 31%) 1px 3px 5px 1px'
            
        }
    },
    moveTopHoverYellow:{
        marginTop: 0,
        transition: '0.8s',
        boxShadow:'rgb(228 166 25 / 40%) 4px 4px 17px 1px',
        "&:hover":{
            marginTop: -8,
            transition: '0.5s',
            boxShadow:'rgb(228 166 25 / 40%) 2px 3px 3px 1px'
        }
    },
    buttonSendHover:{
        marginTop: 0,
        transition: '0.8s',
        boxShadow:'rgb(228 166 25 / 40%) 4px 4px 17px 1px',
        "&:hover":{
            marginTop: -8,
            transition: '0.5s',
            boxShadow:'rgb(228 166 25 / 40%) 2px 3px 3px 1px'
        }
    }

    

    
}));