import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { getUrl, logoutUser } from '../helper/ApiAction';
import _, { create } from 'lodash';
import NumberFormat from 'react-number-format';
import { storeWalletBalance } from '../actions/UserActions';
import { currencyFormat } from '../helper/Tools';

// import YoutubeEmbed from "./YoutubeEmbed";




import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { 
    // CircularProgress, 
    Card, CardMedia, CardContent, Typography, Container, Grid, IconButton,
    // Avatar,  
    Link, Box, Hidden, Button, Menu, MenuItem,
    //  List, ListItem, 
    Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, 
    //Dialog
    DialogContentText, Slide,
    //  Badge 
    } from '@material-ui/core';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { BiUserCircle } from 'react-icons/bi';
import { TiInfoLarge } from 'react-icons/ti';

import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

// import AnnouncementIcon from '@material-ui/icons/Announcement';
import { RssFeedOutlined } from '@material-ui/icons';
import TextLoop from "react-text-loop";

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import {  EXTERNAL_ABOUTUS } from '../configs/Config'; //EXTERNAL_LOGIN
import useNotificationLoading from '../helper/useNotificationLoading';
import WebpImg from './Layouts/WebpImg';
import TitleBar from './Layouts/TitleBar';
import Header from './Layouts/Header';

import './css/styles.css';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Hidden only='xs'>
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
                onClick={onClick}
            />
        </Hidden>
    );
}
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Hidden only='xs'>
            <div
                className={className}
                style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
                onClick={onClick}
            />
        </Hidden>
    );
}

export default function Home() {
    // const [categories, setCategories] = useState(null);
    const [recommend, setRecommend] = useState(null);
    // const [suggestion, setSuggestion] = useState(null);
    const [collections, setCollections] = useState(null);
    const [slideBanner, setSlideBanner] =useState(null);
    // const [advBanner, setAdvBanner] =useState(null);
    // const [iconImg , setIconImg ]=useState();
    const [announcements, setAnnouncements] = useState([]);
    const [viewAnnouncement, setViewAnnouncement] = useState({});
    const [viewAnnouncementIndex, setViewAnnouncementIndex] = useState("");
    const [unreadCount, setUnreadAnnouncementCount] = useState(0);
    // const [walletBalance, setWalletBalance] = useState({});
    // const [topSellers, setTopSellers]=useState(null);
    // const [premiumStores,setPremiumStores]=useState(null);
    // const [sideImg, setSideImg] = useState({
    //     category: '',
    //     wishlist: '',
    //     order: '',
    // });
    const [dashboardInfo, setDashboardInfo] = useState({});
    

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpenMarquee = () => {
        setOpen(true);
    };
    const handleCloseMarquee = () => {
        setOpen(false);
      };

    const handleClose = () => {
        setAnchorEl(null);
    };

    


    const dispatch = useDispatch();
    const { accessToken, walletBalance } = useSelector(state => ({
        accessToken: state.general.accessToken,
        walletBalance: state.user.walletBalance,
    }));

    const { name, username, email, mobile, rank, max_rank } = useSelector(state => state.user);
    let history = useHistory();
    const { addAlert } = useNotificationLoading();

   
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    const AutoplaySlider = withAutoplay(AwesomeSlider);
    const isMountedRef = useRef(null);

    useEffect(() => {
        // showLoading();
        isMountedRef.current = true;
        // getUrl('categories?level=1').then(categoryList => {
        //     if (isMountedRef.current) {
        //         setCategories(categoryList.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        // getUrl('products', { exclude: 'collections' }).then(productList => {
        //     if (isMountedRef.current) {
        //         setRecommend(productList.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        // getUrl('products').then(productList => {
        //     if (isMountedRef.current) {
        //         setSuggestion(productList.data);
        //     }
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // });

        // getUrl('collections')
        // .then(collectionList => {
        //     if (isMountedRef.current) {
        //         setCollections(collectionList.data);
        //     }
        //     // console.log("collectionList", collectionList);
        // }).catch(error => {
        //     addAlert(JSON.stringify(error.message));
        // })

        // getUrl('sliderbanners')
        // .then(slideBannerList =>{
        //     if(isMountedRef.current)
        //     {
        //         setSlideBanner(slideBannerList.data);
        //     }
        //     // console.log("slideBannerList", slideBannerList);
        // }).catch(error =>
        //     { 
        //         addAlert(JSON.stringify(error.message));
        //     });

        // getUrl('filebanners')
        // .then(advBannerList =>{
        //     if(isMountedRef.current)
        //     {
        //         setAdvBanner(advBannerList.data);
        //     }
        //     // console.log("fileBannerList", advBannerList);
        // }).catch(error =>
        //     { 
        //         addAlert(JSON.stringify(error.message));
        //     });

        if(accessToken){
            getUrl('default/get-dashboard').then(response => {
                if(response.code == 0 && isMountedRef.current) {
                    setDashboardInfo(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })

            getUrl('default/balance').then(response => {
                // console.log('wallets/balance repsonse', response);
                // if(response.status && isMountedRef.current) {
                //     dispatch(storeWalletBalance(response.data));
                //     // setWalletBalance(response.data);
                // }
                
                if(response.code == 0) {
                    dispatch(storeWalletBalance(response.data));
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
            
            getUrl('default/get-announcements').then(res => {
                if (isMountedRef.current) {
                    // setAnnouncements(res.data);
                    // setUnreadAnnouncementCount(res.new_count);
                    let show  = { 0 :false};
                    let today = new Date();
                    
                    let showAnnouncement = [];
                    _.map(res.data, (row, index)=>{
                        // && _.size(row['users']) === 0
                        if(row.popup && today >= new Date(row.popup_sdate) && today <= new Date(row.popup_edate) ){
                            show[index] = true;
                            showAnnouncement.push(row);
                        }
                    });
                    setAnnouncements(showAnnouncement);
                    if(_.size(showAnnouncement)) setViewAnnouncementIndex(0);

                    console.log(showAnnouncement)
                    // console.log(show);
                    setViewAnnouncement({...viewAnnouncement, ...show});
                    
                }
            }).catch(error => {
                // addAlert(JSON.stringify(error.message));
            });
        }

        // getUrl('get_premium_stores')
        // .then(premiumStoreList =>{
        //     if(isMountedRef.current)
        //     {
        //         setPremiumStores(premiumStoreList.data);
        //     }
        // }).catch(error =>
        //     { 
        //         addAlert(JSON.stringify(error.message));
        //     });
        
        // getUrl('get_top_sellers')
        // .then(topSellerList =>{
        //     if(isMountedRef.current)
        //     {
        //         setTopSellers(topSellerList.data);
        //     }
        // }).catch(error =>
        //     { 
        //         addAlert(JSON.stringify(error.message));
        //     });
        // hideLoading();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    const descriptionElementRef = useRef(null);
    /* useEffect(() => {
        if (viewAnnouncement !== "") {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [viewAnnouncement]); */

    const closeAnnouncement = (index) => {
        setViewAnnouncement({ ...viewAnnouncement, [index]: false });
        /* getUrl(`member_announcements/${announcements[index]['id']}`).then(result => {
            if (result.status === 1) {
                setUnreadAnnouncementCount(unreadCount - 1);
            }
        }).catch((error) => {
        }); */
    }

    // useEffect(() => {
    //     const defaultLang = i18n.language ? (_.includes(['en', 'cn'], i18n.language) ? i18n.language : 'en') : 'en';
    //     setSideImg({
    //         // category: `/images/side_image/btn-category-${defaultLang}.png`,
    //         wishlist: `/images/side_image/btn-wishlist-${defaultLang}.png`,
    //         order: `/images/side_image/btn-order-${defaultLang}.png`,
    //         daily: `/images/side_image/daily-${defaultLang}.png`,
    //         recommended: `/images/side_image/recommended-${defaultLang}.png`,
    //     })
    // }, [i18n.language])

    // useEffect(function getLandingIcon() {

    //     if (accessToken) {
    //         isMountedRef.current = true;
    //         getUrl('user/landingicon')
    //             .then(iconImage => {
    //                 if (isMountedRef.current) {
    //                     setIconImg(iconImage.data)
    //                 }
    //                 // console.log("iconImage", iconImage);
    //             }).catch(error => {
    //                 // addAlert(JSON.stringify(error.message));
    //             });
    //         // hideLoading();
    //         return () => { isMountedRef.current = false };
    //     }
    // }, []);
    



    return (
        <Box style={{ background: theme.palette.white.mobileBkg, minHeight: '100%' }}>
            
            {/* { _.size(walletBalance) ? 
            <>
            <Box style={{ padding: '0 15px', marginTop: '-37px', marginBottom:28 }}>
                <Box className={styles.goldFrame}>
                    <Box className={styles.frameBkg} style={{ padding: 5 }}>
                        <Box className='cnyFrameTop'>
                        <Box className='cnyFrameBottom' style={{ display: 'flex', justifyContent: 'space-around',  padding:'13px 0 13px 0 ', position:'relative'}}>
                           {_.map(walletBalance, (walletItem, index) => {
                                if(walletItem.code==="MP" && walletItem.id===4 && walletItem.balance==0){}
                                else{
                                    let name =  _.split(walletItem.name, "|");
                                    return(
                                        <React.Fragment key={index}>
                                        <Box style={{ textAlign: 'center', color: theme.palette.primary.main,}}>
                                            <Typography style={{ fontSize: 21 }}><b>{Number.parseFloat(walletItem.balance).toFixed(2)}</b></Typography>
                                            <Typography style={{ fontSize: 12 }}>{(i18n.language === "cn" && name[1] ? name[1] : name[0])}</Typography>
                                        </Box>
                                        </React.Fragment>
                                    )
                                }
                            })}
                        </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            </>
            : 
            null
            } */}
            
            {/* <Header /> */}
            {/* <div style={{ backgroundColor: theme.palette.white.main, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 15px 6px' }}>
                <Link underline='none' to="/" component={RouterLink} className={styles.iconStyle} style={{ paddingTop: 5 }}>
                    <WebpImg src="/images/logo.png" style={{ height: 36, }} alt=" logo" containerStyle={{ lineHeight: 0 }} />
                </Link>
                {accessToken ?
                    
                    <Typography aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{ fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center', lineHeight: 0 }}><span style={{ paddingRight: 5 }}>{username}</span> <BiUserCircle style={{ fontSize: 24 }} /></Typography>
                    :
                    <Link underline='none' color="inherit" to="/login" component={RouterLink}>
                        <div style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white.main, borderRadius: 48, padding: '8px 20px', textTransform: 'uppercase', letterSpacing: 1 }}>
                            <b>{t('button.login')}</b>
                        </div>
                    </Link>
                }

            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        logoutUser(dispatch);
                        handleClose();
                        history.replace('/');
                    }}
                >
                    {t('button.logout')}
                </MenuItem>
            </Menu> */}
            <Container fixed className={styles.root} style={{padding:'10px 20px 20px'}}>
                <Grid container spacing={1} justify="center" style={{padding:0, boxShadow:'0px 6px 11px 0 #00000026', borderRadius:15}}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <AutoplaySlider
                            play={true}
                            cancelOnInteraction={false}
                            interval={3000}
                            autoplay={true}
                            bullets={false}
                            // media={[
                            //     { source: '/images/slides/slide1.png', },
                            // ]}
                            // style={{ width: '100%' }}
                            organicArrows={false}
                        >
                            {/* {
                                _.size(slideBanner) > 0 && slideBanner !== null ?
                                    _.map(slideBanner, (slideBannerLists) => {

                                        return (
                                            <div className="awssld__content" key={slideBannerLists.id} >
                                                <WebpImg src={slideBannerLists.file_name} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%' }} />
                                            </div>
                                        )
                                    }) :
                                    null
                            } */}
                            {/* <div className="awssld__content">
                                <WebpImg src={`/images/slides/dubai/dubai-${i18n.language}.jpg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', borderRadius:15 }} />
                            </div> */}
                            <div className="awssld__content">
                                <WebpImg src={`/images/slides/mainbanner-1.jpg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', borderRadius:15 }} />
                            </div>
                            <div className="awssld__content">
                                <WebpImg src={`/images/slides/mainbanner-2.jpg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', borderRadius: 15 }} />
                            </div>
                            <div className="awssld__content">
                                <WebpImg src={`/images/slides/mainbanner-3.jpg`} alt="slide" style={{ objectFit: 'contain', display: 'block', width: '100%', borderRadius: 15 }} />
                            </div>
                        </AutoplaySlider>
                    </Grid>
                </Grid>

                {/* //// winner list//// */}
                {/* { accessToken ?
                <div style={{marginTop:35}}>
                    <WebpImg src={`/images/slides/dubai/dubai-home-header-${i18n.language}.png`} alt="slide" containerStyle={{lineHeight:0}} style={{ width: '100%', }} />
                    <div style={{display:'flex', justifyContent:'space-between',color:'#ffd39f', backgroundColor:'#224e85', padding:15}}>
                    <p style={{fontSize:'1.2rem', paddingRight:15, color:theme.palette.white.main}}><b>{t('home.winnerMarqueeTitle')}</b></p>
                    <p onClick={handleClickOpenMarquee} style={{paddingBottom:3, borderBottom:'1px solid', width:'fit-content', textAlign:'right', cursor:'pointer' }}><b>{t('button.viewAll')}</b></p>
                    </div>
                    <div className='marquee'>
                        <div className='marquee_content' style={{fontSize:15, display:'flex', alignItems:'center',}}>
                            {_.map(dashboardInfo.winner_list, (winnerusername, uid) => {
                                return (
                                    <p>{username === winnerusername ? winnerusername : winnerusername.replace((winnerusername).substring(1, (winnerusername).length - 2), "*****")}</p>
                                )
                            })}

                        </div>
                    </div>
                </div>
                : null } */}
                  
                <MuiThemeProvider theme={marqueeDialog}>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseMarquee}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <WebpImg src={`/images/slides/dubai/dubai-dialog-header-${i18n.language}.png`} alt="slide" containerStyle={{lineHeight:0}} style={{ width: '100%', }} />
                        <DialogTitle id="alert-dialog-slide-title" style={{ color: '#ffd39f', textAlign:'center', background: '#1a2250', borderBottom: '5px solid #12162e' }}><b>{t('home.winnerMarqueeTitle')}</b></DialogTitle>
                        <DialogContent style={{backgroundColor:'#fff', borderRadius:'0 0 15px 15px'}}>
                            {/* <DialogContentText id="alert-dialog-slide-description"> */}
                                <ol style={{ margin: 0 }}>
                                    {_.map(dashboardInfo.winner_list, (winnerusername, uid) => {
                                        return (
                                            <li>{username === winnerusername ? winnerusername : winnerusername.replace((winnerusername).substring(1, (winnerusername).length - 2), "*****")}</li>
                                        )
                                    })}
                                </ol>
                            {/* </DialogContentText> */}
                        </DialogContent>
                        <DialogActions style={{position:'absolute', right:0,}}>
                            <Button onClick={handleCloseMarquee} >
                                X
                            </Button>
                        </DialogActions>
                    </Dialog>
                </MuiThemeProvider> 

                <div style={{display:'flex', justifyContent:'space-between', width:'100%', alignItems:'center', padding:'30px 0 15px'}}>
                    <div style={{width:'35%'}}>
                        <div style={{width:'100%', height:180, borderRadius:15, backgroundColor:theme.palette.primary.dark, padding:10, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', position:'relative'}}>
                            <center><img src={`/images/rank_logo/rank-${max_rank?.code || 0}.png`} alt="rank" style={{ width: 135 }} /></center>
                            <div style={{ color: theme.palette.white.text, textAlign: 'center', paddingTop: 6 }}>
                                <p style={{ margin: 0, paddingBottom: 5 }}><b>{t('profile.rank')}</b></p>
                                <p style={{ fontSize: 12, margin: 0, lineHeight: 1.1 }}>{dashboardInfo.total_sponsor_downlines || 0} {t('genealogy.downlines')}</p>
                            </div>
                            <div style={{ width: 25, height: 25, padding: 5, borderRadius: 5, border: '2px solid', borderColor:theme.palette.primary.secondary, background: theme.palette.primary.dark, boxShadow: '#00000070 2px 5px 5px 0px', position: 'absolute', top: 10, right: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <TiInfoLarge style={{ color: '#aed7e6', fontSize: 32 }} />
                            </div>
                        </div>
                    </div>
                    <div style={{width:'62%'}}>
                        <div style={{backgroundColor:'#EEBE7F', marginBottom:10}} className={styles.stakeBox}>
                            <div style={{position: 'absolute', right: 19,top: 20}}><img src='/images/home/stack.png' alt="stack" style={{width:60}}/></div>
                            <div style={{color:'#fff'}}>
                            <p style={{margin:0, paddingBottom:5, fontSize:24}}><b>{currencyFormat(dashboardInfo.total_stakes) || 0}</b></p>
                            <p style={{fontSize:12, margin:0, lineHeight:1.1, textTransform:'uppercase'}}><b>{t('stake.totalStakesAmount')}</b></p>
                            </div>
                        </div>
                        <div style={{backgroundColor:theme.palette.primary.main,}} className={styles.stakeBox}>
                            <div style={{position: 'absolute', right: 19,top: 20}}><img src='/images/home/groupstack.png' alt="groupstack" style={{width:60}}/></div>
                            <div style={{color:'#fff'}}>
                            <p style={{margin:0, paddingBottom:5, fontSize:24}}><b>{currencyFormat(dashboardInfo.group_stakes) || 0}</b></p>
                            <p style={{fontSize:12, margin:0, lineHeight:1.1, textTransform:'uppercase'}}><b>{t('stake.totalGroupStake')}</b></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <p  style={{fontSize:'1.3rem', padding:'20px 0 10px'}}><b>{t('home.publicityVideo')}</b></p>
                    
                    <div style={{paddingTop:20}}>
                        <iframe width="100%"  height="240" src="//player.bilibili.com/player.html?aid=483056474&bvid=BV1gT411e7wV&cid=1041710182&page=1" title="INTERNATIONAL KEY LEADER'S SUMMIT 2023 HIGHLIGHT"  scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
                        {/* <iframe width="100%"  height="240" style={{borderRadius:15}} src="//player.bilibili.com/player.html?aid=483056474&bvid=BV1gT411e7wV&cid=1041710182&page=1" title="INTERNATIONAL KEY LEADER'S SUMMIT 2023 HIGHLIGHT" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe> */}

                       {/* <iframe width="100%"  height="240" style={{borderRadius:15}} 
                       src="//player.bilibili.com/player.html?aid=483056474&bvid=BV1gT411e7wV&cid=1041710182&page=1" title="INTERNATIONAL KEY LEADER'S SUMMIT 2023 HIGHLIGHT" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                       </div>
                </div>

                {/* <Box style={{backgroundColor:'transparent', height:15, width:'100%', height: 9, width: '100%', marginBottom:10, boxShadow:'0px 7px 15px 0 #22222226'}} />
                <Box style={{padding:'0 15px'}}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" paddingRight={1} paddingTop={2}>
                        
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <Typography variant="h5" style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{t('home.dailyRecommend')}</Typography>
                        </Box>
                        <Link underline='none' color="inherit" to={`/category/all`} component={RouterLink}>
                            <Typography color="primary" style={{ fontSize: 14, flex: 1 }}>{`${t('shop.seeAll')} >`}</Typography>
                        </Link>
                    </Box>
                </Box>
                <Grid container spacing={1} justify="center" style={{paddingLeft:13, paddingRight:13}}>
                            {
                                _.map(recommend, (product) => {
                                    return (
                                        <Grid item xs={6} sm={4} key={product.id} className={styles.cardContentRootStyle}>
                                            {productItemCard(product)}
                                        </Grid>
                                    )
                                })
                            }
                </Grid>
                 */}

                {/* {
                    _.size(announcements) ?
                        _.map(announcements, (row, index) => (
                            row.popup && new Date() >= new Date(row.popup_sdate) && new Date() <= new Date(row.popup_edate) ?
                                <MuiThemeProvider key={index} theme={announceDialogStyle}>
                                    <Dialog
                                        open={viewAnnouncement[index] ? true : false}
                                        onClose={() => closeAnnouncement(index)}
                                        scroll="body"
                                        aria-labelledby="view Announcement"
                                        aria-describedby="view Announcement detail"
                                        key={index}
                                        style={{width:'547px', maxWidth:'100%', margin:'0 auto'}}
                                    >
                                        <DialogTitle id={`scroll-dialog-title${index}`}>{row[`title_${i18n.language}`]}</DialogTitle>
                                        <DialogContent dividers={false}>
                                            <div
                                                id="viewAnnouncement"
                                                ref={descriptionElementRef}
                                                tabIndex={-1}

                                            >
                                                {row['image'] ?
                                                    <WebpImg containerStyle={{ maxWidth: "80vw", display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={row['image']['file_name']} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', maxWidth: "80vw" }} alt="announcement" />
                                                    : null
                                                }
                                                <div dangerouslySetInnerHTML={{ __html: row['content'][`${i18n.language}`] }} />
                                            </div>
                                            <Button onClick={() => closeAnnouncement(index)} color="primary">X</Button>
                                        </DialogContent>
                                    </Dialog>
                                </MuiThemeProvider>
                                : null
                        ))
                        : null
                } */}
                {
                    _.size(announcements[viewAnnouncementIndex]) > 0 &&
                        <MuiThemeProvider theme={announceDialogStyle}>
                            <Dialog
                                open={_.size(announcements[viewAnnouncementIndex]) ? true : false}
                                onClose={() => setViewAnnouncementIndex(viewAnnouncementIndex+1)}
                                scroll="paper"
                                aria-labelledby="view Announcement"
                                aria-describedby="view Announcement detail"
                                key={viewAnnouncementIndex}
                                style={{width:'547px', maxWidth:'100%', margin:'0 auto'}}
                                classes={{
                                    paper: announcements[viewAnnouncementIndex]['setting']['transparent_background'] ? styles.inviPaper: ""
                                }}
                            >
                                { !announcements[viewAnnouncementIndex]['setting']['hide_title']  &&
                                <DialogTitle id={`scroll-dialog-title${viewAnnouncementIndex}`}>{announcements[viewAnnouncementIndex]['title_array'][`${i18n.language}`]}</DialogTitle>
                                }
                                <DialogContent dividers={false}>
                                    <div
                                        id="viewAnnouncement"
                                        ref={descriptionElementRef}
                                        tabIndex={-1}

                                    >
                                        {announcements[viewAnnouncementIndex]['image'] ?
                                            <WebpImg containerStyle={{ maxWidth: "80vw", display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={announcements[viewAnnouncementIndex]['image']['file_name']} style={{ width: 'fit-content', padding: '0', display: 'block', objectFit: 'cover', maxWidth: "80vw" }} alt="announcement" />
                                            : null
                                        }
                                        <div dangerouslySetInnerHTML={{ __html: announcements[viewAnnouncementIndex]['content'][`${i18n.language}`] }} />
                                    </div>
                                    <Button onClick={() => setViewAnnouncementIndex(viewAnnouncementIndex+1)} color="primary">X</Button>
                                </DialogContent>
                            </Dialog>
                        </MuiThemeProvider>
                }
            </Container>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
    },
    cardRootStyle: {
        margin: '0px 1px 0px 1px'
    },
    buttonFont: {
        fontSize: '10px',
    },
    buttonStyle: {
        margin: '5px',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    listItemStyle: {
        justifyContent: 'center',
        padding: '5px 0px 5px 0px',
    },
    cardStyle: {
        // minHeight: 380,
        borderRadius: 0
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'black',
        backgroundColor: 'white'
    },
    goldFrame: {
        boxShadow: 'rgb(0 0 0 / 12%) -2px 1px 20px 0',
        // padding: 5,
        // borderRadius: 15,
        borderRadius: 8,
        background: '#fff',
        // background: 'rgb(168,120,33)',
        // background: 'linear-gradient(90deg, rgba(168,120,33,1) 0%, rgba(252,242,169,1) 19.59%, rgba(255,196,79,1) 50%, rgba(252,237,166,1) 80.42%, rgba(184,140,54,1) 100%)'
    },
    frameBkg: {
        // background: 'background: rgb(209,170,105)',
        // background: 'linear-gradient(180deg, rgba(209,170,105,1) 0.81%, rgba(234,205,158,1) 21.88%, rgba(233,204,155,1) 77.03%, rgba(225,185,110,1) 100%)',
        // background: 'linear-gradient(180deg, #050a12 0.81%, #182a49 21.88%, #182a49 77.03%, #0b162a 100%)',
        // borderRadius: 11,
        // background: '#fff',
    },
    loopText: {
        width: '85vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    productHover:{
        top: 0,
        transition: '0.5s',
        "&:hover":{
            boxShadow:'0 0 19px 0 #00000029',
            top: -5,
        }
    },
    stakeBox: {
        height:85,
        width:'100%',
        borderRadius:15, 
        position:'relative',
        padding:10,
        display:'flex',
        alignItems:'center'
    },
    inviPaper: {
        background: "none",
        boxShadow: "none"
    }
}));

const marqueeDialog = createMuiTheme({
    overrides: {
        MuiDialog: {
            paperWidthSm: {
                maxWidth:400,
                width:400
            }
        },
        MuiButton: {
            root: {
                color: '#fff',
                height: 30,
                width: 30,
                borderRadius: 48,
                background: '#f4c04e',
                padding: 0,
                minWidth: 'unset',
                fontSize: 18,
            }
        },
        MuiPaper: {
            root:{
                backgroundColor:'transparent'
            },
            elevation24:{
                boxShadow:'none'
            }
        },
        MuiBackdrop:{
            root: {
                backgroundColor:'rgb(0 0 0 / 74%)'
            }
        }
    }
})

const announceDialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh'
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 24px'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                background: '#0008',
                borderRadius: 48,
                minWidth: 'unset',
                padding: 0,
            }
        }
      
        // MuiInput: {
        //     underline: {
        //         '&::before': {
        //             borderBottom: '1px solid #fff'
        //         },
        //         '&::after': {
        //             borderBottom: '2px solid #fff'
        //         },
        //         "&:not(.Mui-disabled):hover::before": {
        //             borderColor: "#fff"
        //         },
        //     },
        // },
        // MuiIconButton: {
        //     root: {
        //         color:'#c2c2c2',
        //         "&$focused": {
        //             color: '#FF5532'
        //             },
        //     }
        // },
        // MuiSelect: {
        //     icon: {
        //         color:'#fff'
        //     },
        //     select: {
        //         "&$focused": {
        //             backgroundColor: 'transparent'
        //             },
        //     }
        // }
        
    },
});