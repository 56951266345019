import React, { useEffect, useRef, useState } from 'react';
import { getUrl, postUrl, putUrl } from '../helper/ApiAction';
import { useSelector } from 'react-redux';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import { PUSHER_KEY, PUSHER_CLUSTER, PUSHER_AUTH, PUSHER_MESSAGE_CHANNEL, PUSHER_MESSAGE_EVENT } from '../configs/Config';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Typography, Container, Button, Hidden, Box, Paper, List, ListItem, ListItemAvatar, SwipeableDrawer, Avatar, ListItemText, Link, IconButton, Menu, MenuItem, TextField, Divider, ListItemSecondaryAction, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import useNotificationLoading from '../helper/useNotificationLoading';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';
import WebpImg from './Layouts/WebpImg';
import _ from 'lodash';

import { Search } from 'react-feather';
import MenuIcon from '@material-ui/icons/Menu';

import clsx from 'clsx';

import './css/chatStyle.css';

export default function Chat() {
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { id } = useSelector(state => state.user);
    const { accessToken } = useSelector(state => state.general);
    const theme = useTheme();

    const isMountedRef = useRef(null);
    const chatRef = useRef();

    const [state, setState] = useState({
        newMessage: '', showSearch: false, searchChat: '', anchorEl: null,
    });
    const [chatMerchant, setChatMerchant] = useState({
        id: null
    });
    const [searchChatList, setSearchChatList] = useState(null);
    const [chatList, setChatList] = useState();
    const [chatData, setChatData] = useState(null);
    const [newMessage, setNewMessage] = useState();
    
    const classes = useStyles();
    const [state2, setState2] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState2({ ...state2, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            style={{padding:20}}
        >

            <Paper style={{ backgroundColor: 'transparent', boxShadow: 'none', marginTop: 20, justifyContent: 'space-between', display: 'flex' }}>
                <TextField
                    size="small"
                    fullWidth
                    value={state.searchChat}
                    onChange={({ target }) => {
                        if (target.value !== '') {
                            setState({ ...state, searchChat: target.value, showSearch: true });
                        } else {
                            setState({ ...state, searchChat: '', showSearch: false })
                        }
                    }}
                    placeholder={t('chat.searchMerchant')}
                />
                <Search color={theme.palette.primary.main} style={{ marginLeft: 15 }} size={30} />
            </Paper>
            <List>
                {
                    state.showSearch ?
                        renderChatList(searchChatList)
                        :
                        renderChatList(chatList)
                }
            </List>
        </div>
    );

    useEffect(() => {
        document.getElementById('chatStyle').classList.add('hfull');
        document.getElementById('root').classList.add('hvh');
        // document.body.classList.add('hfull');
        return () => { document.getElementById('chatStyle').classList.remove('hfull'); document.getElementById('root').classList.remove('hvh'); };
    }, []);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl('chats').then(response => {
            if (isMountedRef.current) {
                console.log("chatList", response.data);
                setLoading(false);
                setChatList(response.data)
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        isMountedRef.current = true;
        if(chatMerchant.id > 0) {
            getUrl(`chats/${chatMerchant.id}`).then(response => {
                if (isMountedRef.current) {
                    response.data.data = _.reverse(response.data.data);
                    setChatData(response.data);
                }
                scrollToBottom();
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [chatMerchant.id]);

    // useEffect(() => {
    //     scrollToBottom();
    // },[chatData])

    // when receive new message from pusher
    useEffect(() => {
        isMountedRef.current = true;
        console.log("newMessage", newMessage);
        if (isMountedRef.current) {
            if(_.size(newMessage) > 0) {
                // if received new message recipient is self
                if(parseInt(newMessage.recipient) === id) {
                    // find if new message chat_id in the current pull list
                    const chatExistIndex = _.findIndex(chatList, { 'id': newMessage.chat_id });
                    if(chatExistIndex >= 0) {
                        // exist, replace chat last message
                        let newChatList = chatList;
                        newChatList[chatExistIndex].last_chat[0] = newMessage;
                        newChatList[chatExistIndex].updated_at = newMessage.updated_at;
                        newChatList[chatExistIndex].unread_count++;

                        newChatList = _.orderBy(newChatList, ['updated_at'], ['desc']);
                        setChatList(chatList => ([ ...newChatList ]));

                        // if chatData loaded and chat_info id is current opening chatbox id
                        if(_.size(chatData) > 0) {
                            if(chatData.chat_info.id === parseInt(newMessage.chat_id)) {
                                const prevData = chatData.data;
                                setChatData(chatData => ({ ...chatData, data: prevData.concat(newMessage) }));


                                // newChatList[chatExistIndex].last_chat[0]['read'] = true;
                                // newChatList[chatExistIndex].unread_count--;
                                // chatRef.current.scrollTop =
                            }
                        }

                        console.log("chatList", chatList);
                    } else {
                        // grab the latest data put on first
                        // if(_.size(chatData) > 0) {
                        //     if(chatData.chat_info.id === parseInt(newMessage.chat_id)) {
                        //         const prevData = chatData.data;
                        //         setChatData(chatData => ({ ...chatData, data: prevData.concat(newMessage) }));

                        //         newChatList[chatExistIndex].last_chat[0]['read'] = true;
                        //         newChatList[chatExistIndex].unread_count--;
                        //     }
                        // }
                    }
                }
            }
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [newMessage])

    useEffect(() => {
        console.log("chatList", chatList);
        if(_.size(chatData) && _.size(chatData.data) <= 30) {
            scrollToBottom();
        }
        // eslint-disable-next-line
    },[chatData])

    useEffect(() => {
        isMountedRef.current = true;
        if(accessToken) {
            if (isMountedRef.current) {
                const echo = new Echo({
                    broadcaster: 'pusher',
                    key: PUSHER_KEY,
                    cluster: PUSHER_CLUSTER,
                    forceTLS: true,
                    authEndpoint: PUSHER_AUTH,
                    auth: {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        },
                    },
                });
                console.log("echo initial done", echo);

                echo.private(PUSHER_MESSAGE_CHANNEL).listen(PUSHER_MESSAGE_EVENT, (pusherMsg) => {
                    console.log("pusherMsg", pusherMsg)
                    setNewMessage(newMessage => (pusherMsg.message));
                });
                console.log("echo start listening");
            }
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [accessToken])

    const sendMessage = () => {
        if(state.newMessage) {
            const newMessage = state.newMessage;
            setState(state => ({ ...state, newMessage: '' }));
            postUrl(`chats/${chatMerchant.id}`, {
                message: newMessage
            }).then(response => {
                if(response.status) {
                    const prevData = chatData.data;
                    setChatData({ ...chatData, data: prevData.concat(response.data) });

                    const chatExistIndex = _.findIndex(chatList, { 'id': parseInt(response.data.chat_id) });
                    console.log("chatList", chatList)
                    console.log("response.data", response.data)
                    console.log("chatExistIndex", chatExistIndex)
                    if(chatExistIndex >= 0) {
                        // exist, replace chat last message
                        let newChatList = chatList;
                        newChatList[chatExistIndex].last_chat[0] = response.data;
                        newChatList[chatExistIndex].updated_at = response.data.updated_at;

                        // if chatData loaded and chat_info id is current opening chatbox id
                        if(_.size(chatData) > 0) {
                            if(chatData.chat_info.id === parseInt(response.data.chat_id)) {
                                newChatList[chatExistIndex].last_chat[0]['read'] = true;
                                newChatList[chatExistIndex].unread_count--;
                            }
                        }
                        newChatList = _.orderBy(newChatList, ['updated_at'], ['desc']);
                        setChatList(chatList => ([ ...newChatList ]));
                        console.log("chatList2", chatList);
                        scrollToBottom();
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }
    
    // use for search
    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(state.searchChat) > 0) {
            if (isMountedRef.current) {
                getUrl(`chats/search/${state.searchChat}`).then(response => {
                    if (isMountedRef.current) {
                        console.log("chatList", response.data);
                        setLoading(false);
                        setSearchChatList(response.data);
                    }
                }).catch(error => {
                    setLoading(false);
                    addAlert(JSON.stringify(error.message));
                });
            }
        }
        // eslint-disable-next-line
    }, [state.searchChat]);

    const scrollToBottom = () => {
        if(chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }

    const openMessage = chatItem => {
        setChatMerchant({ id: chatItem.id, merchant_company: chatItem.merchant_company });
        // set messages to read
        putUrl(`chats/${chatItem.id}`)
        .then(response => {
            console.log(response);

            const chatExistIndex = _.findIndex(chatList, { 'id': chatItem.id });
            if(chatExistIndex >= 0) {
                // exist, replace chat last message
                let newChatList = chatList;
                if(_.size(newChatList[chatExistIndex].last_chat) > 0) {
                    newChatList[chatExistIndex].last_chat[0]['read'] = true;
                    newChatList[chatExistIndex].unread_count = 0;
                }
                setChatList(chatList => ([ ...newChatList ]));
            }
            setState({ ...state, showSearch: false, searchChat: '' });
            setSearchChatList(null);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const renderChatList = (toRenderList) => {
        return (
            <Box>
            {
                _.isNull(toRenderList) ?
                <Box display="flex" flex={1} justifyContent="center" alignItems="center"><CircularProgress /></Box>
                :
                _.size(toRenderList) === 0 ?
                <Box display="flex" flex={1} justifyContent="center" alignItems="center"><Typography variant="caption">{ t('chat.noChat') }</Typography></Box>
                :
                _.map(toRenderList, chatItem => {
                    // console.log('chatItem', chatItem);
                    return (
                        <Box key={chatItem.id}>
                            <ListItem button onClick={() => openMessage(chatItem)} style={{ backgroundColor: _.size(chatMerchant) > 0 ? (chatMerchant.id === chatItem.id ? theme.palette.background.default : 'transparent') : 'transparent' }}>
                                <ListItemAvatar>
                                    {
                                        _.size(chatItem.merchant_company.merchant_company_icon) > 0 ?
                                        // <WebpImg src={chatItem.merchant_company.merchant_company_icon.file_name} style={{ height: 40, width: 40, objectFit:'cover' }} alt={chatItem.merchant_company[`shop_name_${i18n.language}`]}/>
                                        <Avatar  src={chatItem.merchant_company.merchant_company_icon.file_name}alt={chatItem.merchant_company[`shop_name_${i18n.language}`]}/>
                                        : 
                                        <Avatar><Typography variant="button">{ chatItem.merchant_company.shop_name[0] }</Typography></Avatar>
                                    }
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={ chatItem.merchant_company[`shop_name_${i18n.language}`] } 
                                    secondary={ _.size(chatItem.last_chat) > 0 ? `${chatItem.last_chat[0].sender !== id ? `${chatItem.merchant_company[`shop_name_${i18n.language}`]} :` : `${t('chat.you')} :`} ${chatItem.last_chat[0].message}` : '' } 
                                    secondaryTypographyProps={{ 
                                        style: { 
                                            color: _.size(chatItem.last_chat) > 0 ? ( chatItem.last_chat[0].read ? theme.palette.gray.main : '#000000') : theme.palette.gray.main,
                                            fontWeight: _.size(chatItem.last_chat) > 0 ? ( chatItem.last_chat[0].read ? 'normal' : 'bold') : 'normal',
                                        },
                                        noWrap: true
                                    }}
                                />
                                {
                                    chatItem.unread_count > 0 ?
                                    <ListItemSecondaryAction>
                                        <Avatar style={{ backgroundColor: theme.palette.primary.main, width: theme.spacing(3), height: theme.spacing(3) }}>
                                            <Typography variant="caption">{ chatItem.unread_count }</Typography>
                                        </Avatar>
                                    </ListItemSecondaryAction>
                                    : null
                                }
                            </ListItem>
                            <Divider />
                        </Box>
                    )
                })
            }
            </Box>
        )
    }

    const checkScroll = event => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        console.log("scrollTop", {scrollTop, scrollHeight})
        if (scrollTop === 0) {
            if(_.size(chatData) > 0) {
                if(chatData.current_page !== chatData.last_page) {
                    const newCurrentPage = chatData.current_page + 1;
                    getUrl(`chats/${chatData.chat_info.id}?page=${newCurrentPage}`).then(response => {
                        const mergedChatDataConversation = _.concat(_.reverse(response.data.data), chatData.data);
                        response.data.data = mergedChatDataConversation;
                        setChatData(response.data);

                        // set the chat height back to the height before call api
                        const newHeight = chatRef.current.scrollHeight - scrollHeight;
                        chatRef.current.scrollTop = newHeight;
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });
                }
            }
        }
        
        if(scrollHeight - scrollTop === clientHeight) {
            console.log("bottom")
            // set messages to read
            putUrl(`chats/${chatMerchant.id}`)
            .then(response => {
                console.log(response);

                const chatExistIndex = _.findIndex(chatList, { 'id': chatMerchant.id });
                if(chatExistIndex >= 0) {
                    // exist, replace chat last message
                    let newChatList = chatList;
                    if(_.size(newChatList[chatExistIndex].last_chat) > 0) {
                        newChatList[chatExistIndex].last_chat[0]['read'] = true;
                        newChatList[chatExistIndex].unread_count = 0;
                    }
                    setChatList(chatList => ([ ...newChatList ]));
                }
                setState({ ...state, showSearch: false, searchChat: '' });
                setSearchChatList(null);
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    let prevDate = null;

    return (
    <Box>
        <Container style={{ height: '90vh', paddingBottom:50 }}>
        <Grid container style={{ height: '100%' }}>
           <Hidden only={['sm', 'md', 'lg', 'xl']}>
           <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:'100%'}}>
                    {
                                chatMerchant.id > 0 ?
                                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <IconButton style={{padding:0}} onClick={(event) => setState({ ...state, anchorEl: event.currentTarget })}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Typography style={{ marginLeft: 10 }}>{chatMerchant.merchant_company[`shop_name_${i18n.language}`]}</Typography>
                                        </Box>
                                        
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={state.anchorEl}
                                            keepMounted
                                            open={Boolean(state.anchorEl)}
                                            onClose={() => setState({ ...state, anchorEl: null })}
                                        >
                                            <Link underline='none' color="inherit" to={`/shop/${chatMerchant.merchant_company.slug}`} component={RouterLink}>
                                                <MenuItem>
                                                    <Typography variant="caption">{t(`chat.goToProfile`, { 'company': chatMerchant.merchant_company[`shop_name_${i18n.language}`] })}</Typography>
                                                </MenuItem>
                                            </Link>
                                        </Menu>
                                    </Box>
                                    :
                                    <Typography style={{ fontSize: 24, color: '#686868' }}>
                                        {t('item.chat')}
                                    </Typography>
                            }
                        <div>
                            <React.Fragment key={'left'}>
                                <Button onClick={toggleDrawer('left', true)}>
                                    {/* <Menu color={theme.palette.primary.main} style={{ marginLeft: 15 }} size={30} /> */}
                                    <MenuIcon color={theme.palette.primary.main} style={{ marginLeft: 15 }} size={30} />
                                </Button>
                                <SwipeableDrawer
                                    anchor={'left'}
                                    open={state2['left']}
                                    onClose={toggleDrawer('left', false)}
                                    onOpen={toggleDrawer('left', true)}
                                >
                                    {list('left')}
                                </SwipeableDrawer>
                            </React.Fragment>
                        </div>
                    </Box>
           </Hidden>
           
           <Hidden only={['xs']}>
            <Grid item xs={5} md={3} style={{ padding: '0 20px', borderRight: '1px solid #c1c1c1', }}>
                {/*<Paper variant="outlined" style={{ padding: 12 }}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Avatar><Typography variant="button">{ name[0] }</Typography></Avatar>
                            <Typography style={{ marginLeft: 10 }}>{ name }</Typography>
                        </Box>
                    </Box>
                </Paper>*/}
                 <Typography style={{ fontSize: 24, paddingBottom: 20, color: '#686868' }}>
                   {t('item.chat')}
                </Typography>
                <Paper style={{ backgroundColor: 'transparent', boxShadow: 'none', marginBottom: 20, justifyContent: 'space-between', display: 'flex' }}>
                    <TextField 
                        size="small"
                        fullWidth 
                        value={state.searchChat}
                        onChange={({target}) => {
                            if(target.value !== '') {
                                setState({ ...state, searchChat: target.value, showSearch: true });
                            } else {
                                setState({ ...state, searchChat: '', showSearch: false })
                            }
                        }}
                        placeholder={ t('chat.searchMerchant') }
                    />
                    <Search color={theme.palette.primary.main} style={{ marginLeft: 15 }} size={30} />
                </Paper>
                
                <Paper style={{ minHeight: 464, overflow: 'auto', backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <List>
                        {
                            state.showSearch ?
                            renderChatList(searchChatList)
                            :
                            renderChatList(chatList)
                        }
                    </List>
                    
                </Paper>                
            </Grid>
           </Hidden>
            <Grid item xs={12} sm={7} md={9} style={{ padding: '0 20px', height: '-webkit-fill-available' }}>
                {
                    chatMerchant.id > 0 ?
                    <Box display="flex" flexDirection="column" justifyContent="space-between" style={{ height: '100%', maxHeight: '100%', paddingBottom: 20 }}>
                        <Hidden only={['xs']}>
                        <Paper style={{ padding: 8, backgroundColor:theme.palette.background.default }} >
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box display="flex" flexDirection="row" alignItems="center" style={{ marginLeft: 10 }}>
                                    {
                                        _.size(chatMerchant.merchant_company.merchant_company_icon) > 0 ?
                                        // <WebpImg src={chatMerchant.merchant_company.merchant_company_icon.file_name} style={{ height: 40, width: 40, objectFit:'cover' }} alt={chatMerchant.merchant_company[`shop_name_${i18n.language}`]}/>
                                        <Avatar src={chatMerchant.merchant_company.merchant_company_icon.file_name} alt={chatMerchant.merchant_company[`shop_name_${i18n.language}`]} />
                                        : 
                                        <Avatar><Typography variant="button">{ chatMerchant.merchant_company.shop_name[0] }</Typography></Avatar>
                                    }
                                    <Typography style={{ marginLeft: 10 }}>{ chatMerchant.merchant_company[`shop_name_${i18n.language}`] }</Typography>
                                </Box>
                                <IconButton onClick={ (event) => setState({ ...state, anchorEl: event.currentTarget })}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={state.anchorEl}
                                    keepMounted
                                    open={Boolean(state.anchorEl)}
                                    onClose={() => setState({ ...state, anchorEl: null })}
                                >
                                    <Link underline='none' color="inherit" to={`/shop/${chatMerchant.merchant_company.slug}`} component={RouterLink}>
                                        <MenuItem>
                                            <Typography variant="caption">{ t(`chat.goToProfile`, { 'company': chatMerchant.merchant_company[`shop_name_${i18n.language}`] }) }</Typography>
                                        </MenuItem>
                                    </Link>
                                </Menu>
                            </Box>
                        </Paper>
                        </Hidden>
                        <Box display="flex" flex={1} flexDirection="column" justifyContent="flex-end" style={{ height: '100%', maxHeight: '100%', overflow: 'hidden', padding: '20px 0' }}>
                        { console.log("chatData", chatData) }
                            <Paper ref={chatRef} style={{ backgroundColor: 'transparent', boxShadow: 'none', height: '100%', maxHeight: '100%', overflow: 'hidden', overflowY: 'scroll' }} onScroll={checkScroll} >
                            {
                                _.isNull(chatData) ?
                                <Box display="flex" flex={1} justifyContent="center" alignItems="center"><CircularProgress /></Box>
                                :
                                _.size(chatData) > 0 ?
                                    _.size(chatData.data) > 0 ?
                                        _.map(chatData.data, (selectedChatItem, selectedChatItemIndex) => {
                                            let showDate = false;
                                            if(prevDate !== null) {
                                                const d1 = new Date(prevDate);
                                                const d2 = new Date(selectedChatItem.created_date_compare);
                                                if(d1 < d2) {
                                                    showDate = true;
                                                }
                                            } else {
                                                showDate = true;
                                            }
                                            prevDate = selectedChatItem.created_date_compare;

                                            return (
                                                <Box key={selectedChatItemIndex} display="flex" flexDirection="column">
                                                    {
                                                        showDate ?
                                                        <Box display="flex" flexDirection="row" justifyContent="center" padding={1}>
                                                            <Paper style={{ backgroundColor: theme.palette.gray.main, padding: 5 }} elevation={0}>
                                                                <Typography variant="caption" style={{ color: '#FFF' }}>{ selectedChatItem.created_date_display }</Typography>
                                                            </Paper>
                                                        </Box>
                                                        : null
                                                    }
                                                    <Paper style={{ margin: 5, padding: 8, alignSelf: selectedChatItem.sender === id ? 'flex-end' : 'flex-start',backgroundColor: theme.palette.background.default }} variant="outlined">
                                                        <Box display="flex" flexDirection="column">
                                                            <Typography variant="caption" style={{ fontSize: 15 }}>{selectedChatItem.message}</Typography>
                                                            <Typography variant="caption" style={{ fontSize: 11, marginLeft: 30, alignSelf: 'flex-end' }}>{ selectedChatItem.created_time_display }</Typography>
                                                        </Box>
                                                    </Paper>
                                                </Box>
                                            )
                                        } )
                                    : 
                                    <Box display="flex" flexDirection="row" justifyContent="center" padding={1}>
                                        <Paper style={{ backgroundColor: theme.palette.gray.main, padding: 5 }} elevation={0}>
                                            <Typography variant="caption" style={{ color: '#FFF', fontSize: 12 }}>{ t('chat.chatWithMerchant', { 'company': chatMerchant.merchant_company[`shop_name_${i18n.language}`] } ) }</Typography>
                                        </Paper>
                                    </Box>
                                : null
                            }
                            </Paper>
                        </Box>
                        <Paper style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ backgroundColor:theme.palette.background.default, padding: 10, boxShadow: '#d0d0d0 3px 3px 6px', borderRadius: 30, marginBottom: 10 }}>
                                <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
                                    <TextField 
                                        fullWidth 
                                        placeholder={t('chat.typeMessageHere')}
                                        multiline 
                                        rowsMax={4}
                                        value={state.newMessage}
                                        onChange={({target}) => setState({ ...state, newMessage: target.value })}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter' && e.shiftKey) {
                                            } else if (e.key === 'Enter') {
                                                e.preventDefault();
                                                sendMessage();
                                            }
                                        }}
                                        // helperText={ t('chat.helperText') }
                                        // FormHelperTextProps={{ style: { fontSize: 13 } }}
                                    />
                                </Box>
                                <IconButton aria-label="settings" onClick={sendMessage} style={{ backgroundColor: '#f7952a', height: 50, width: 50, padding: 10, marginLeft: 15 }}>
                                    <SendIcon style={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                            <Typography style={{ fontSize: 13 , height:'41px'}}> {t('chat.helperText')} </Typography>
                        </Paper>
                    </Box>
                    : 
                    <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                                        <WebpImg src="/images/general/nochat2.png" alt="noimage" style={{ width: '300px', maxWidth: '100%' }} />
                                        <Typography>{t('chat.noChatSelected')}</Typography>
                                    </Box>
                }
            </Grid>
        </Grid>
        </Container>
            {/* </Hidden> */}

          
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
        height: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}));
