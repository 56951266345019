import {
    STORE_WALLETS,
} from '../actions/types';

const INITIAL_STATE = {
    walletList: [],
}

export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_WALLETS:
            return { ...state, walletList: actions.payload };
        default:
            return state;
    }
}