import React from 'react';
import {
    Snackbar, Box, Backdrop, CircularProgress
} from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import useNotificationLoading from '../../helper/useNotificationLoading';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
  
const NotificationLoading = () => {
    const { alert, removeAlert, loading } = useNotificationLoading();
    const classes = useStyles();

    let anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
    if(alert.position) {
        const newAnchorOrigin = _.split(alert.position, '-');
        anchorOrigin = { vertical: newAnchorOrigin[0], horizontal: newAnchorOrigin[1] };
    }

    return (
        <Box>
            {
                alert.open
                ?
                <Snackbar open={ !!alert.open } autoHideDuration={ alert.severity === 'error' ?  10000 : 3000 } onClose={ removeAlert } anchorOrigin={ anchorOrigin }>
                    <Alert elevation={ 6 } variant="filled" onClose={ removeAlert } severity={ alert.severity }>
                        {
                            alert.title ?
                            <AlertTitle>{ alert.title }</AlertTitle>
                            : null
                        }
                        { alert.message }
                    </Alert>
                </Snackbar>
                : null
            }
            {
                loading ?
                <Backdrop className={ classes.backdrop } open={ loading }>
                    <CircularProgress color="inherit" />
                </Backdrop>
                : null
            }
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default NotificationLoading;