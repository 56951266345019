import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Button, makeStyles, Box, Paper, Typography, FormControl, List, ListItem, ListItemText, useTheme, Select, MenuItem } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import TitleBarWhite from './Layouts/TitleBarWhite';
import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat, stringToLang } from '../helper/Tools';

export default function Bonus() {
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [bonusList, setBonusList] = useState([]);
    const [commissionSummary, setCommissionSummary] = useState([]);
    const [bonusSummary, setBonusSummary] = useState([]);

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { bonus } = useParams();
    const theme = useTheme();
    const { addAlert } = useNotificationLoading();
    const history = useHistory();
    const isMountedRef = useRef(null);
    const { max_mrank } = useSelector(state => ({
        max_mrank: state.user.max_mrank
    }));

    useEffect(() => {
        isMountedRef.current = true;
        getUrl('default/get-summary').then(response => {
            if(response.code == 0) {
                setCommissionSummary(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        })
        return () => isMountedRef.current = false;
    }, []);

    useEffect(() => {
        setBonusList([]);
        setTotalPage(0);
        setPage(1);
        if(_.size(commissionSummary)){
            if(!bonus){
                history.replace(`/bonus/${commissionSummary.bonus[0]['bonus_type']}`);
            }else{
                let bonusInfo = _.find(commissionSummary.bonus, {bonus_type: bonus});
                let summary = _.find(commissionSummary.summary, {bonus_id: bonusInfo.id});
                setBonusSummary(summary);
            }
        }
    }, [bonus, commissionSummary]);

    useEffect(() => {
        if(_.size(bonusSummary)){
            getUrl(`default/get-details?page=${page}&per_page=10&bonus_type=${bonus}`).then(response => {
                if(response.code == 0 && isMountedRef.current) {
                    const { last_page, data } = response.data;
                    setTotalPage(last_page);
                    setBonusList(_.concat(bonusList, data));
                }
            }).catch(err => {
                addAlert(JSON.stringify(err.message));
            })
        }
    }, [bonusSummary, page]);

    const changeBonus = ({ target }) => {
        history.push(`/bonus/${target.value}`);
    }

    const loadMore = () => {
        setPage(page + 1);
    }

    return (
        <Box className={classes.root} style={{ background: theme.palette.white.mobileBkg, padding:'0 0 80px', minHeight: '100%'}}>
            <TitleBarWhite title={ t('title.bonus') } back>
                <Box display="flex" alignItems="center" justifyContent="center" height="56%">
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{background:theme.palette.secondary.main, borderRadius:10, width:'100%', height:150}} >
                        <Typography className={classes.balanceStyle}>{ currencyFormat(_.size(commissionSummary) ? ((bonusSummary ? bonusSummary.amount : 0)   * 100) / 100 : 0) }</Typography>
                    </Box>
                </Box>
            </TitleBarWhite>
            { _.size(commissionSummary) ?
            <div style={{padding:'0 20px'}}>
                <Paper elevation={3} classes={{ root: classes.paperContainer }}>
                <Box className={classes.boxContainer} paddingX={1}>
                <MuiThemeProvider theme={selectStyle}>
                    <FormControl className={classes.formControl}>
                        <Select
                            value={bonus}
                            name="bonus"
                            onChange={ changeBonus }
                            label={t('commission.bonus')}
                        >
                            { _.map(commissionSummary.bonus, bonusItem => {
                                if(!max_mrank?.code && bonusItem.id==5){
                                }else{
                                    return <MenuItem key={bonusItem.id} value={bonusItem.bonus_type}>{ stringToLang(bonusItem.name)}</MenuItem>
                                }
                            } ) }
                        </Select>
                    </FormControl>
                    </MuiThemeProvider>
                </Box>
            </Paper>
            </div>
            :null}
            <Box padding={2}>
                <List className={classes.listRoot}>
                    { _.map(bonusList, bonusItem => (
                        <ListingItem key={bonusItem.id} data={bonusItem} />
                    ))}
                </List>
                { page < totalPage ?
                    <Button onClick={ loadMore } fullWidth>{ t('transaction.loadMore') }</Button>
                    : <Typography style={{ textAlign: 'center' }}>{ t('transaction.endOfList') }</Typography>
                }
            </Box>
        </Box>
    )
}

const ListingItem = ({ data }) => {
    const { amount, status, bonus_date, wallets_amount, downline_id, downline_info,created_at,to_name } = data;

    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    let operator = status === "distributed" ? "+" : "";

    return (
        <ListItem divider classes={{ root: classes.listItemRoot }} display="flex" alignItems="center" style={{padding:'10px 0'}} >
            <Box className={classes.listItemTextRoot}>
                <ListItemText
                    style={{width:'100%'}}
                    classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
                    // primary={ downline_id ? t('commission.receivedAmountFrom', {amount: ((amount * 100) / 100), username: downline_info.username}): t('commission.totalAmountOf'/, {amount: ((amount * 100) / 100)}) }
                    primary={  t('commission.receivedAmountFrom', {amount: amount, username: to_name})}
                />
                <Typography
                    component="span"
                    variant="body2"
                    style={{ color: theme.palette.silver.text, fontSize: 11 }}
                >
                    {created_at}
                </Typography>
            </Box>
            <Box>
            { _.isObject(wallets_amount) && wallets_amount ?
                _.map(wallets_amount, (walletAmount, walletName) => {
                    return <Typography key={walletName} variant="body2" style={{ color: operator === '+' ? "green": 'grey', whiteSpace:'nowrap', width:85 }}>{operator} { `${((walletAmount * 100) / 100)} ${walletName}` }</Typography>
                })
                : amount
            }
            </Box>
            {/* <ListItemText
                primary={ `${operator} ${currencyFormat((amount * 100) / 100)} ${"\n"} ${operator} ${currencyFormat((amount * 100) / 100)}` }
                classes={{ root: classes.listItemAmountRoot, primary: classes.listItemAmountPrimary }}
                style={{ color: operator === '+' ? "green": 'grey', whiteSpace:'nowrap', width:85 }}
            /> */}
        </ListItem>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -38,
        padding: 10,
        borderRadius: 10,
        position:'relative',
        background:theme.palette.primary.main
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    balanceStyle: {
        fontSize: 30, 
        color: "white",//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    listRoot: {
        width: '100%',
    },
    listItemTextRoot: {
        flex: 4
    },
    listItemAmountRoot: {
        // flex: 1,
        // display: 'flex',
        // justifyContent: 'flex-end',
        //alignSelf: 'center',
        textAlign:'right',
    },
    listItemAmountPrimary: {
        fontWeight: 'bold',
        fontSize: 14
    },
    listItemTextPrimary: {
        fontSize: 14,
    },
    listItemTextSecondary: {
        fontSize: 10,
    },
    listItemRoot: {
        cursor: 'pointer'
    },
}));

const selectStyle = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                color: "#fff",
                "&$focused": {
                    color: '#fff'
                    },
                    '&.$Mui-disabled': {
                        color: '#fff'
                      }
            },
           
        },
        MuiInput: {
            underline: {
                '&::before': {
                    borderBottom: '1px solid #fff'
                },
                '&::after': {
                    borderBottom: '2px solid #fff'
                },
                "&:not(.Mui-disabled):hover::before": {
                    borderColor: "#fff"
                },
            },
        },
        MuiIconButton: {
            root: {
                color:'#c2c2c2',
                "&$focused": {
                    color: '#FF5532'
                    },
            }
        },
        MuiSelect: {
            icon: {
                color:'#fff'
            },
            select: {
                "&$focused": {
                    backgroundColor: 'transparent'
                    },
            }
        }
        
    },
});