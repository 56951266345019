import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Grid, Typography, Box, Card, CardContent, Divider, InputAdornment, Input, InputLabel, FormControl, IconButton, Link, Tooltip, Button } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { FiArrowLeftCircle } from 'react-icons/fi';

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { stringToLang } from '../helper/Tools';

export default function GenealogySponsor(props) {
    const { t } = useTranslation();
    const { id } = useSelector(state => state.user);
    const styles = useStyles();
    const [tree, setTree] = useState([]);
    const [state, setState] = useState({ totalDirectDownlines: 0, totalDownlines: 0, username: '', search: 0 });
    
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        setState({...state, username: props.username || "", search: state.search + 1});

        // getUrl(`genealogy/sponsor`, {username: props.username}).then(response => {
        //     if (isMountedRef.current) {
        //         if (response.status) {
        //             setTree(response.data.tree_data);
        //             // setState({ ...state, totalDirectDownlines: response.data.total_direct_downlines, totalDownlines: response.data.total_downlines });
        //         }
        //         setLoading(false);
        //     }
        // }).catch(error => {
        //     if (isMountedRef.current) {
        //         setLoading(false);
        //         addAlert(JSON.stringify(error.message));
        //     }
        // });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, props.username]);

    const searchUsername = () => {
        setLoading(true);
        getUrl(`default/get-sponsor`, {username: state.username}).then(response => {
            console.log('response.data',response.data)
            if (isMountedRef.current) {
                if (response.code == 0) {
                    setTree(response.data.tree_data);
                    // setState({ ...state, totalDirectDownlines: data.total_direct_downlines, totalDownlines: data.total_downlines });
                }else{
                    addAlert(response.msg);
                }
                setLoading(false);
            }
            
        }).catch(error => {
            console.log(error,'3333')
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });
    }

    useEffect(() => {
        if(state.search > 0){
            searchUsername()
        }
        // eslint-disable-next-line
    }, [state.search]);

    const viewMore = (value) => {
        history.push(`/community/genealogySponsor?u=${value}`, { from: 'sponsorGenealogy '+value });
        // setState({...state, username: value, search: state.search + 1});
    }

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={`${nodes.id}`} 
        // label={nodes.username}
        label={
            <div className={styles.labelRoot}>
                {
                    _.size(nodes.children) === 0 && nodes.user.total_downline > 0 ?
                    <Link style={{fontSize: "0.7rem"}} onClick={() => viewMore(nodes.username)}><Tooltip title={t('genealogy.loadMore')} style={{ display: "flex", alignItems: "center"}}><FindInPageIcon fontSize='small'/></Tooltip></Link>
                    : null
                }
                <PersonIcon />
                <Typography variant="body2" className={styles.labelText}>{nodes.username}</Typography>
                <Typography variant="caption" color="inherit" className={styles.labelSubText}>{new Date((nodes.user.created_at).replace(/-/g, "/")).toLocaleDateString()}</Typography>
                <Typography variant="caption" color="inherit" className={styles.labelSubText}>{nodes.user.max_rank_display ? '['+ stringToLang(nodes.user.max_rank_display) +']':''}</Typography>
                <Tooltip title={t('genealogy.downlines')} style={{ display: "flex", alignItems: "center"}}><Typography variant="caption" color="inherit" className={styles.labelSubText}><AccountTreeIcon fontSize="small"/>{nodes.user.total_downline}</Typography></Tooltip>
                <Tooltip title={t('stake.personalStake')} style={{ display: "flex", alignItems: "center"}}><Typography variant="caption" color="inherit" className={styles.labelSubText}><></><AttachMoneyIcon fontSize="small"/>{nodes.user.total_stakes}</Typography></Tooltip>
                <Tooltip title={t('stake.totalGroupStake')} style={{ display: "flex", alignItems: "center"}}><Typography variant="caption" color="inherit" className={styles.labelSubText}><></><LocalAtmIcon fontSize="small"/>{nodes.user.total_group_stakes}</Typography></Tooltip>
                {/* <Typography variant="caption" color="inherit" className={styles.labelSubText}>{nodes.user.mobile!==null && nodes.referral_id == id ? '['+ nodes.user.mobile +']':''}</Typography> */}
                {/* <Typography variant="caption" color="inherit" className={styles.labelSubText}>{nodes.user.email!==null && nodes.referral_id == id ? '['+ nodes.user.email +']':''}</Typography> */}
            </div>
          }
        >
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    );

    return (
        <Grid container spacing={1} justify="center" style={{ minHeight: 450, }}>
            <Grid item xs={12} className={styles.root} >
                {
                    _.size(tree) > 0 ?
                        <Card style={{border:'none', marginBottom: 10 }} variant="outlined">
                            <CardContent style={{ borderRadius: 0, padding:0 }}>
                                <Grid container>
                                    { _.size(history.location.state?.from) > 0 &&
                                        <Grid item xs={12} style={{ marginBottom: 20 }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<FiArrowLeftCircle />}
                                                size="small"
                                                onClick={() => history.goBack()}
                                            >
                                                {t('button.back')}
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <FormControl fullWidth >
                                            <InputLabel htmlFor="username-search">{t('genealogy.username')}</InputLabel>
                                            <Input
                                                id="username-search"
                                                type="text"
                                                value={state.username}
                                                onChange={(event) => setState({...state, username: event.target.value})}
                                                onKeyDown={(e) => (e.keyCode == 13 && viewMore(state.username))}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="search button"
                                                            onClick={() => viewMore(state.username)}
                                                        >
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12} container justify="center" alignItems="center" style={{ marginTop: 10 }}>
                                        <Grid item xs={6} style={{ textAlign: "center", flex: 1 }}>
                                            <Typography variant="caption" display="block">{t('genealogy.totalDirectDownlines')}</Typography>
                                            <Typography>{state.totalDirectDownlines}</Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem />
                                        <Grid item xs={6} style={{ textAlign: "center", flex: 1 }}>
                                            <Typography variant="caption" display="block">{t('genealogy.totalDownlines')}</Typography>
                                            <Typography>{state.totalDownlines}</Typography>
                                        </Grid>
                                    </Grid> */}
                                    <Grid item xs={12} container alignItems="center" style={{ marginTop: 10 }}>
                                        <Grid item xs={12} container alignItems="center" justify="flex-start"><AccountTreeIcon style={{ paddingRight: 5 }}/><Typography variant="caption">{t('genealogy.totalActiveDownlines')}</Typography></Grid>
                                        <Grid item xs={12} container alignItems="center" justify="flex-start"><AttachMoneyIcon style={{ paddingRight: 5 }}/><Typography variant="caption">{t('stake.personalStake')}</Typography></Grid>
                                        <Grid item xs={12} container alignItems="center" justify="flex-start"><LocalAtmIcon style={{ paddingRight: 5 }}/><Typography variant="caption">{t('stake.totalGroupStake')}</Typography></Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: 10, overflowX: "auto" }}>
                                        <TreeView
                                            className={styles.tree}
                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                            defaultExpanded={[`${id}`]}
                                            defaultExpandIcon={<ChevronRightIcon />}
                                        >
                                            {renderTree(tree)}
                                        </TreeView>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    :
                    <Card style={{ border:'none'}} variant="outlined">
                        <CardContent style={{ height: 400, borderRadius: 0 }}>
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <Typography variant="h5">...</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                }
                
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    tree: {
        // height: 110,
        flexGrow: 1,
        // maxWidth: 400,
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelText: {
        fontWeight: 'inherit',
        marginLeft: 10,
        // textTransform: "lowercase"
    },
    labelSubText: {
        marginLeft: 10, 
        fontStyle: 'italic'
    },
}));