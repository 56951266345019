import axios from 'axios';
import { API_URL, LOCAL_URL, FRONTEND_VERSION } from '../configs/Config';
import { store } from '../configs/Store';
import { resetCodeState } from '../actions/GeneralActions';
import { resetProfile } from '../actions/UserActions';
import { logoutUser } from '../helper/ApiAction';

const instance = axios.create({
    baseURL: API_URL,   
    timeout: 60000,
    headers: {},
    // withCredentials: true
});

export const removeLoginAccess = () => {
    console.log('退出登录')
    store.dispatch(resetCodeState());
    store.dispatch(resetProfile());
}

instance.interceptors.request.use(
    async (config) => {
        const token = await store.getState().general.accessToken;
        let uuid = '';
        if(!token){
            uuid = await store.getState().general.uuid;
        }
        // config.headers['Guest-Token'] = !token ? uuid : '';
        config.headers['x-csrf'] = "ag7x1Afy1r0q8j4y05vk3s4118hx7124";
        // config.headers.access_token = token ? token : '';
        if(undefined == config.data){
            // config.data = [];
        }
        if(config.method === 'post'){
            let data = config.data?config.data:{};
            config.data = JSON.stringify({
                access_token: token, //追加的参数
                ...data //拼接参数
            })
        }
        config.params = {
            access_token: token,
            ...config.params
        }
        const lang = await store.getState().general.language;
        config.headers.Language = lang ? ( lang === "cn" ? 'zh-CN' : lang) : "zh-CN";
        config.headers.Accept = "application/json";
        console.log("response for: ",config);
        return config;
    },
    (err) => {
        console.log("error request", err);
        return Promise.reject(err);
    },
)

instance.interceptors.response.use(
    (res) => {
        if(res.data.code == -1){
            removeLoginAccess()
        }
        if(res.data.code == -2){
            removeLoginAccess()
        }
        return res.data
    },
    (err) => {
        console.log(err.toJSON());
        if(err && err.response) {
            switch(err.response.status) {
                case 400:
                    console.log("Request error")
                    break;
                case 401:
                    console.log("Unauthorized access")
                    removeLoginAccess();
                    break;
                case 403:
                    console.log("No Permission");
                    return err.response.data;
                    break;
                default:
                    console.log('Other error messages');
            }
        }
        return Promise.reject(err);
    }
)

export default instance;