import React, { useState, useRef, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import _ from 'lodash';
import { postUrl } from '../helper/ApiAction';
import { APP_NAME } from '../configs/Config';
import useNotificationLoading from '../helper/useNotificationLoading';
import { useQuery } from '../helper/Tools';


import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { TextField, Button, Typography, Grid, Link, Box, FormHelperText } from '@material-ui/core';

import WebpImg from './Layouts/WebpImg';
import theme from '../Theme';

function Copyright() {
    return (
        <Typography variant="body2" align="center" style={{color:theme.palette.silver.text}}>
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/login">
                { APP_NAME }
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography>
    );
}

export default function SecurityPasswordReset() {

    const { t } = useTranslation();
    const classes = useStyles();
    const isMountedRef = useRef(null);
    // const dispatch = useDispatch();
    const { addAlert, setLoading } = useNotificationLoading();
    let { token } = useParams();
    const query = useQuery();
    const email = query.get("email");
    const username = query.get("username");

    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState({ security_password: '', security_password_confirmation: '', authentication_code: '' });
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    /* useEffect(() => {
        if(accessToken) {
            history.replace('/');
        }
    }, [accessToken, history]); */

    const resetPassword = () => {
        setLoading(true);
        const apiData = {
            security_password: state.security_password,
            security_password_confirmation: state.security_password_confirmation,
            tac:token,
            email,
            username,
            authentication_code: state.authentication_code,
            old_password:'',
            type:1
        }
        // console.log("apiData", apiData);
        postUrl(`user/edit-security-password`, apiData).then(result => {
            if (isMountedRef.current) {
                setLoading(false);
                let { code, msg, error } = result;
                if (code === 0) {
                    addAlert(msg, 'success', '', '');
                    setCompleted(true);
                } else {
                    // if (_.size(error) > 0) {
                    //     _.map(error, (value, key) => {
                    //         msg += "\n" + value[0];
                    //     })
                    //     setInputErrors(error);
                    // }
                    addAlert(msg, 'error', '', '');
                }
            }
        }).catch((error) => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
            }
        });
    }

    return (
        <Grid container component="main" className={classes.root} justify="center">
                <div className={classes.paper}>
                <center><img src="/images/logo.png" style={{ width:165, paddingLeft: 10, paddingRight: 10, }} alt="logo" /></center>
                    <div style={{color:'#ececec', textAlign:'center'}}>
                    <Typography component="h1" variant="h5" style={{ marginTop: '20px' }}>
                        <b>{t('general.resetSecurityPassword')}</b>
                    </Typography>
                    {!completed?<Typography variant="body2" style={{paddingTop:8}}>{t('general.resetSecurityPasswordMsg')}</Typography>:null}
                    <Typography variant="body2" style={{paddingTop:8}}>{username + ' - ' +email}</Typography>
                    </div>
                    {!completed ?
                        <> 
                        <MuiThemeProvider theme={formStyle}>
                            <form className={classes.form} noValidate onSubmit={ev => { ev.preventDefault(); resetPassword() }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="security_password"
                                    label={t('profile.newSecurityPassword')}
                                    name="security_password"
                                    value={state.security_password}
                                    onChange={({ target }) => setState({ ...state, security_password: target.value })}
                                    autoFocus
                                    type="password"
                                    error={inputErrors.security_password ? true : false}
                                    helperText={inputErrors.security_password ? inputErrors.security_password : ''}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="security_password_confirmation"
                                    label={t('profile.reenterNewSecurityPassword')}
                                    name="security_password_confirmation"
                                    value={state.security_password_confirmation}
                                    onChange={({ target }) => setState({ ...state, security_password_confirmation: target.value })}                                    
                                    type="password"
                                    error={inputErrors.security_password_confirmation ? true : false}
                                    helperText={inputErrors.security_password_confirmation ? inputErrors.security_password_confirmation : ''}
                                />
                                {/* <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="authentication_code"
                                    label={t('profile.authenticationCode')}
                                    name="authentication_code"
                                    value={state.authentication_code}
                                    onChange={({ target }) => setState({ ...state, authentication_code: target.value })}                                    
                                    type="text"
                                    error={inputErrors.authentication_code ? true : false}
                                    helperText={inputErrors.authentication_code ? inputErrors.authentication_code : ''}
                                />
                                <FormHelperText variant="outlined" style={{ color: "#fff" }}>{t('general.2FACaption')}</FormHelperText> */}
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >{t('profile.changePassword')}</Button>
                                </div>
                                <Box mt={5}>
                                    <Copyright/>
                                </Box>
                            </form>
                            </MuiThemeProvider>           
                        </>
                        :
                        <>
                        <Typography variant="body2" style={{ color:'#ececec', paddingTop:8 }}>{t('general.resetSecurityPasswordSuccess')}</Typography>
                        <Link underline='none' to={`/login`} component={RouterLink}>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                {t('button.proceedToLogin')}
                            </Button>
                        </Link>
                        </>
                    }
                </div>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        background:theme.palette.secondary.main
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background:theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        
    },
    logoImg: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
    },
}));

const formStyle = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                color: "#ececec",
                "&$focused": {
                    color:  theme.palette.primary.main,
                    },
            },
           
        },
        MuiInputBase: {
            root: {
                color: "#ececec",
                "&$focused": {
                    color:  theme.palette.primary.main,
                    },
            }
        },
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor:theme.palette.silver.text,
                },
                "&:hover $notchedOutline": {
                    borderColor:theme.palette.silver.text,
                },
                "&$focused $notchedOutline": {
                    borderColor:theme.palette.primary.main,
                }
              },
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor :theme.palette.primary.main,
                "&:hover": {
                    backgroundColor :theme.palette.primary.secondary,
                }
            }
        }
        
    },
});