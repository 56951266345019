import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, makeStyles, Box, Paper, Typography, FormControl, List, ListItem, ListItemText, useTheme, Select, MenuItem, TextField, InputAdornment, IconButton } from '@material-ui/core';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import TitleBarWhite from './Layouts/TitleBarWhite';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { currencyFormat } from '../helper/Tools';
import theme from '../Theme';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function WithdrawalHistory() {
    const [walletInfo, setWalletInfo] = useState([]);
    const [userWalletInfo, setUserWalletInfo] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogInfo, setDialogInfo] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [withdrawalHistory, setWithdrawalHistory] = useState([]);
    const [errorField, setErrorField] = useState({ security_password: "", general: "", authentication_code: "" });
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [state, setState] = useState({ security_password: "", authentication_code: "", showPassword: false });

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { wallet } = useParams();
    const { walletList, walletBalance, two_factor_auth } = useSelector(state => ({
        walletList: state.wallet.walletList,
        walletBalance: state.user.walletBalance,
        two_factor_auth: state.user.two_factor_auth,
    }));
    // console.log("walletList", walletList);
    const theme = useTheme();
    const { addAlert, setLoading, loading } = useNotificationLoading();
    const history = useHistory();

    const isMountedRef = useRef(null);

    const STATUS_COLORS = {
        default: theme.palette.primary.main, //'#FF5532',
        // 20: //Pending
        // 40: //Processing
        60: '#3F686B', //Completed
        80: theme.palette.gray.textTitle, //'#979797',//Cancelled
    }

    useEffect(() => {
        const existWallet = _.find(walletList, { code: wallet });
        const existWalletBalance = _.find(walletBalance, { code: wallet });
        setWalletInfo(existWallet);
        setUserWalletInfo(existWalletBalance);
        setWithdrawalHistory([]);
        setTotalPage(0);
        setPage(1);
    }, [wallet]);

    const getApi = (paging) => {
        getUrl(`default/transfer-list?page=${paging}&limit=10`).then(response => {
            if(response.code == 0) {
                const { last_page, data } = response.data;
                setTotalPage(last_page);
                if(paging === 1){
                    setWithdrawalHistory(data);
                }else{
                    setWithdrawalHistory(_.concat(withdrawalHistory, data));
                }
            }
        }).catch(err => {
            addAlert(JSON.stringify(err.message));
        })
    }

    useEffect(() => {
        isMountedRef.current = true;
        if(_.size(walletInfo) > 0) {
            getApi(page);
        }
        return () => isMountedRef.current = false;
    }, [walletInfo, page]);

    const changeWallet = ({ target }) => {
        history.push(`/transaction/${target.value}`);
    }

    const loadTransaction = () => {
        setPage(page + 1);
    }

    const openDialog = dialogInfo => {
        setDialogInfo(dialogInfo);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogInfo([]);
        setDialogOpen(false);
    }

    const cancelWithdrawal = () => {
        if(loading === false){
            setLoading(true);
            const apiData = {
                security_password: state.security_password,
                authentication_code: state.authentication_code,
            };
            setErrorField({});
            postUrl(`withdrawal/${dialogInfo.id}/cancel`, apiData).then(response => {
                if(response.status) {
                    addAlert(response.message, 'success');
                    setDialogInfo(response.data);
                    setCancelDialogOpen(false);
                    if(page !== 1){
                        setPage(1);
                    }else{
                        getApi(1);
                    }
                } else {
                    if(response.errors) {
                        let newErrorField = {};
                        _.map(response.errors, (errorMsg, errorField) => {
                            newErrorField[errorField] = errorMsg[0];
                        })
                        setErrorField(newErrorField);
                    }
                    if(response.message) {
                        addAlert(response.message);
                    }
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    return (
        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
            <TitleBarWhite title={ t('title.withdrawalHistory') } back displayInfo={false}/>

            <div className={classes.walletCard}>
                {/* <FiLayers style={{fontSize:39, color:theme.palette.primary.main}}/> */}
                <img src='/images/general/icon/usdt.png' alt="rank" className={classes.walletIconImg} />
                <div style={{ color: theme.palette.white.text, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                    <p className={classes.walletBalanceTitle}>{ _.size(walletInfo) > 0 ? walletInfo.wallet_name : '-' } {t('wallet.balance')}</p>
                    <p className={classes.walletAmount}><b>{ currencyFormat(_.size(userWalletInfo) ? (userWalletInfo.balance * 100) / 100 : 1) }</b></p>
                </div>
            </div>
            {
                _.size(walletList) >= 2 &&
                <Paper elevation={3} classes={{ root: classes.paperContainer }} style={{position:'relative'}}>
                    <Box className={classes.boxContainer} paddingX={1}>
                    <MuiThemeProvider theme={selectStyle}>
                        <FormControl className={classes.formControl}>
                            <Select
                                value={wallet}
                                name="wallet"
                                onChange={ changeWallet }
                                label={t('transaction.wallet')}
                            >
                                { _.map(walletList, walletItem => {
                                    return walletItem.allow_withdraw ? <MenuItem key={walletItem.code} value={walletItem.code}>{ walletItem.wallet_name }</MenuItem> : null
                                } ) }
                            </Select>
                        </FormControl>
                        </MuiThemeProvider>
                    </Box>
                </Paper>
            }
            
            <Box padding={2}>
                <List className={classes.listRoot}>
                    { _.map(withdrawalHistory, transactionItem => (
                        <TransactionItem key={transactionItem.id} data={transactionItem} openDialog={openDialog} />
                    ))}
                </List>
                { page < totalPage ?
                    <Button onClick={ loadTransaction } fullWidth>{ t('transaction.loadMore') }</Button>
                    : <Typography style={{ textAlign: 'center' }}>{ t('transaction.endOfList') }</Typography>
                }
            </Box>
            <MuiThemeProvider theme={DialogStyle}>
            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                onClose={closeDialog}
            >
                    <div style={{ background: '#fff' }}>
                        <div style={{ background: STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default'] }} className={classes.statusDialog}>
                            <Typography style={{ fontSize: 14, color: '#fff', letterSpacing: 1 }}><b>{ dialogInfo.status_display || '-' }</b></Typography>
                        </div>
                    </div>
                <DialogContent >
                <Box style={{textAlign:'center', paddingTop:15}}>
                        <Typography style={{ fontWeight: 'bold', lineHeight:1.0, fontSize: 30, color: STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default'], }}>{ `${currencyFormat((parseFloat(dialogInfo.amount) * 100) / 100 || 0)}` }</Typography>
                        <Typography style={{ textTransform: 'uppercase', fontSize: 14 }}>{ t('withdrawal.amount') }</Typography>
                    </Box>
                    {/* <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2}>
                        <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{ t('withdrawal.status') }</Typography>
                        <Typography style={{ fontSize: 14, color: STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default'] }}>{ dialogInfo.status_display || '-' }</Typography>
                    </Box> */}
                    <Box style={{ borderBottom: '2px dashed', borderColor: theme.palette.silver.bg, paddingBottom: 20 }}>
                        <Box display="flex" alignItems="flex-start" flexDirection="row" style={{ paddingTop: 5, fontWeight: 'bold' }}>
                            <p style={{ flex: 2 }}>{t('title.walletWithdrawal')}</p>
                            <p style={{ flex: 1, textAlign: 'right' }}>{currencyFormat(parseFloat(dialogInfo.request_amount))}</p>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="row" style={{ paddingTop: 5 }}>
                            <p style={{ flex: 2 }}>- {t('withdrawal.feeCharges')}</p>
                            <p style={{ flex: 1, textAlign: 'right' }}>{currencyFormat(parseFloat(dialogInfo.request_amount) - parseFloat(dialogInfo.amount))}</p>
                        </Box>
                        <Box display="flex" alignItems="flex-start" flexDirection="row" style={{ paddingTop: 5 }}>
                            <p style={{ flex: 2 }}>- {t('wallet.amountReceived')}</p>
                            <p style={{ flex: 1, textAlign: 'right' }}>{`${currencyFormat((parseFloat(dialogInfo.amount) * 100) / 100 || 0)}`}</p>
                        </Box>
                    </Box>
                    
                    {/* <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} >
                        <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{ t('withdrawal.feeCharges') }</Typography>
                        <Typography style={{ fontSize: 14 }}>{ currencyFormat(parseFloat(dialogInfo.request_amount) - parseFloat(dialogInfo.amount)) }</Typography>
                    </Box> */}
                        <div style={{paddingTop:10}}>
                            {dialogInfo.crypto_address ?
                                
                                 <>
                                 <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14, whiteSpace: "nowrap" }}>{t('withdrawal.crypto_address')}</Typography>
                                    <Typography style={{ fontSize: 14, wordBreak: "break-all" }}>{dialogInfo.crypto_address || '-'}</Typography>
                                </Box>
                             </>
                                :
                                <>
                                     <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                                     <Typography style={{ fontWeight: 'bold', fontSize: 14, }}>{t('withdrawal.bankName')} & {t('withdrawal.bankAccount')}</Typography>
                                     <Typography style={{ fontSize: 14, }}>{dialogInfo.bank_name || '-'}</Typography>
                                     <Typography style={{ fontSize: 14, }}>{dialogInfo.bank_account || '-'}</Typography>
                                 </Box>
                                 <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                                     <Typography style={{ fontWeight: 'bold', fontSize: 14, }}>{t('withdrawal.bankHolderName')}</Typography>
                                     <Typography style={{ fontSize: 14, }}>{dialogInfo.bank_holder_name || '-'}</Typography>
                                 </Box>
                                 {/* <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                                     <Typography style={{ fontWeight: 'bold', fontSize: 14, }}>{t('withdrawal.bankAccount')}</Typography>
                                     <Typography style={{ fontSize: 14, }}>{dialogInfo.bank_account || '-'}</Typography>
                                 </Box> */}
                                 <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                                     <Typography style={{ fontWeight: 'bold', fontSize: 14, }}>{t('withdrawal.bankSwift')}</Typography>
                                     <Typography style={{ fontSize: 14, }}>{dialogInfo.bank_swift || '-'}</Typography>
                                 </Box>
                                </>
                            }
                            <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 14, }}>{t('transaction.dateTime')}</Typography>
                                <Typography style={{ fontSize: 14, }}>{dialogInfo.created_at}</Typography>
                            </Box>
                            {dialogInfo.remark !== "" &&
                                <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={1}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('withdrawal.remark')}</Typography>
                                    <Typography style={{ fontSize: 14 }}>{dialogInfo.remark || '-'}</Typography>
                                </Box>
                            }
                        </div>
                    {/* <Box display="flex" alignItems="flex-start" flexDirection="column" paddingY={2} borderTop={.5} style={{ borderTopStyle: 'dashed', borderColor: theme.palette.silver.bg }}>
                        <Box display="flex" width="100%">
                            <Typography style={{ fontWeight: 'bold', fontSize: 14, flex: 1 }}>{ t('transaction.idNo') }</Typography>
                            <Typography style={{ fontSize: 14, flex: 2 }}>{ _.size(dialogInfo.transaction_details) > 0 ? dialogInfo.transaction_details[0].transaction_id : '-' }</Typography>
                        </Box>
                    </Box> */}
                        <Box classes={{ root: classes.cancelBox }} style={{flexDirection:'column'}}>
                            {
                                dialogInfo.status === 20 &&
                                <>
                                <Button variant="contained" classes={{ root: classes.button }} style={{backgroundColor:STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default']}} onClick={() => setCancelDialogOpen(true)}>
                                    {t('withdrawal.cancelWithdrawal')}
                                </Button>
                                <Button variant="contained" classes={{ root: classes.button }} style={{marginTop:15, backgroundColor:theme.palette.secondary.main}} onClick={closeDialog}>
                                {t('transaction.done')}
                            </Button>
                                </>
                            }
                            {
                                dialogInfo.status != 20 &&
                                <Button variant="contained" classes={{ root: classes.button }} style={{backgroundColor:STATUS_COLORS[STATUS_COLORS[dialogInfo.status] ? dialogInfo.status : 'default']}} onClick={closeDialog}>
                                    {t('transaction.done')}
                                </Button>
                                }
                           
                        </Box>
                </DialogContent>
                <div style={{lineHeight:0}}>
                        <img src="/images/general/receipt2.png" alt="receipt" style={{width:'100%', background:'transparent'}}/>
                    </div>
            </Dialog>
            <Dialog
                open={cancelDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                onClose={() => {setCancelDialogOpen(false);setErrorField({});}}
            >
                <MuiThemeProvider theme={fieldStyle}>
                <DialogContent style={{backgroundColor:'#fff', borderRadius:15, paddingBottom:25}}>
                    <Typography>{t('withdrawal.askCancelWithdrawal')}</Typography>
                    {
                        two_factor_auth === 1 ?
                            <>
                                <TextField
                                    type={state.showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    value={state.security_password}
                                    name="security_password"
                                    onChange={({ target }) => setState({ ...state, security_password: target.value })}
                                    classes={{
                                        root: classes.textFieldRoot,
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    label={t('withdrawal.security_password')}
                                    error={_.size(errorField.security_password) ? true : false}
                                    helperText={errorField.security_password}
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    fullWidth
                                />
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    value={state.authentication_code}
                                    name="authentication_code"
                                    onChange={({ target }) => setState({ ...state, authentication_code: target.value })}
                                    classes={{
                                        root: classes.textFieldRoot,
                                    }}
                                    label={t('profile.authenticationCode')}
                                    error={_.size(errorField.authentication_code) ? true : false}
                                    helperText={errorField.authentication_code}
                                    FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                                    fullWidth
                                />
                            </>
                        : 
                        <TextField 
                            type={state.showPassword ? 'text' : 'password'}
                            variant="outlined"
                            value={state.security_password}
                            name="security_password"          
                            onChange={({ target }) => setState({...state, security_password: target.value})}   
                            classes={{
                                root: classes.textFieldRoot,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            label={ t('withdrawal.security_password') }
                            error={ _.size(errorField.security_password) ? true : false }
                            helperText={ errorField.security_password }
                            FormHelperTextProps={{ color: 'red', fontSize: 12 }}
                            fullWidth
                        />
                    }
                            <Box classes={{ root: classes.cancelBox }}>
                                <Button variant="contained" classes={{ root: classes.noButton }} onClick={() => {setCancelDialogOpen(false);setErrorField({});}}>
                                    {t('button.close')}
                                </Button>
                                <Button variant="contained" classes={{ root: classes.yesButton }} onClick={cancelWithdrawal}>
                                    {t('button.submit')}
                                </Button>
                            </Box>
                </DialogContent>
                </MuiThemeProvider>
                
            </Dialog>
            </MuiThemeProvider>
        </Box>
    )
}

const TransactionItem = ({ data, openDialog }) => {
    const { amount, remark, status_display, created_at } = data;

    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    return (
        <ListItem divider onClick={() => openDialog(data)} classes={{ root: classes.listItemRoot }} display="flex" alignItems="center" style={{padding:'10px 0'}} >
            <Box className={classes.listItemTextRoot}>
                <ListItemText
                    style={{width:'100%'}}
                    classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
                    primary={ t('title.walletWithdrawal') }
                    secondary={
                        <Typography
                            component="p"
                            variant="body2"
                            className={classes.remarkStyle}
                        >
                            { `${t('withdrawal.status')}: ${status_display || '-'}`}
                        </Typography>
                    }
                />
                <Typography
                    component="span"
                    variant="body2"
                    style={{ color: theme.palette.silver.text, fontSize: 11 }}
                >
                    {created_at}
                </Typography>
            </Box>
            <ListItemText
                primary={ `${currencyFormat((amount * 100) / 100)}` }
                classes={{ root: classes.listItemAmountRoot, primary: classes.listItemAmountPrimary }}
                style={{ color: theme.palette.secondary.main, whiteSpace:'nowrap', width:85 }}
            />
        </ListItem>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 80,
    },
    paperContainer: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: -38,
        padding: 10,
        borderRadius: 10,
        background:theme.palette.primary.main
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    amountFieldRoot: {
        width: 200,
    },
    textFieldRoot: {
        marginTop: 15,
        marginBottom: 15,
    },
    cancelBox: {
        marginLeft: 20,
        marginRight: 20,
        display: "flex",
        justifyContent: "space-between",
    },
    button: {
        color: '#FFF',
        borderRadius: 10,
        padding:'8px 15px',
        width: "100% !important",
        "&:hover":{
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    yesButton: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 10,
        marginLeft: 10,
        marginRight: 10,
        width: "50% !important",
        "&:hover":{
            backgroundColor: theme.palette.primary.secondary,
        }
    },
    noButton: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        borderRadius: 10,
        marginLeft: 10,
        marginRight: 10,
        width: "50% !important",
        "&:hover":{
            backgroundColor: theme.palette.secondary.secondary,
        }
    },
    rankWrapperStyle: {
        display: 'flex',
        justifyContent: 'center',
        border: '3px solid',
        borderColor: theme.palette.secondary.main,
        height: 60,
        width: 60,
        borderRadius: '50%'
    },
    rankTitleStyle: {
        color: theme.palette.secondary.main,
        fontSize: 34,
        fontWeight:'bold',
        textTransform:'uppercase',
        padding:'0 10px',
    },
    remarkStyle:{
        color: theme.palette.silver.text,
        fontSize: 14,
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        width:'100%',
        overflow:'hidden'
    },
    balanceStyle: {
        fontSize: 30, 
        color: theme.palette.primary.main,//theme.palette.secondary.main, 
        fontWeight: 'bold',
        textAlign:'center',
        textTransform:'uppercase'
    },
    infoStyle: {
        color: theme.palette.secondary.white, 
        fontSize: 11, 
        letterSpacing: 1 
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    listRoot: {
        width: '100%',
    },
    listItemTextRoot: {
        flex: 4
    },
    listItemAmountRoot: {
        // flex: 1,
        // display: 'flex',
        // justifyContent: 'flex-end',
        //alignSelf: 'center',
        textAlign:'right',
    },
    listItemAmountPrimary: {
        fontWeight: 'bold',
        fontSize: 14
    },
    listItemTextPrimary: {
        fontSize: 14,
    },
    listItemTextSecondary: {
        fontSize: 10,
    },
    listItemRoot: {
        cursor: 'pointer'
    },
    walletCard: {
        height: 183, 
        background: theme.palette.secondary.main,
        borderRadius: 15,
        width: '100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        paddingBottom:14,
        position:'relative',
        [theme.breakpoints.down('sm')]: {
            height: 163, 
        },
    },
    walletIconImg: {
        width:63,
        [theme.breakpoints.down('sm')]: {
            width:43,
        },
    },
    walletBalanceTitle: {
        textTransform:'uppercase',
        fontSize:17,
        paddingBottom:2,
        [theme.breakpoints.down('sm')]: {
            fontSize:13,
        },
    },
    walletAmount: {
        fontSize:34,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize:25,
        },
    },
    statusDialog: {
        padding: ' 10px 15px',
        textTransform: 'uppercase',
        textAlign: 'center',
        width: 151,
        margin: '0 auto',
        borderRadius: '0 0 15px 15px',
    }
}));

const selectStyle = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                color: "#fff",
                "&$focused": {
                    color: '#fff'
                    },
                    '&.$Mui-disabled': {
                        color: '#fff'
                      }
            },
           
        },
        MuiInput: {
            underline: {
                '&::before': {
                    borderBottom: '1px solid #fff'
                },
                '&::after': {
                    borderBottom: '2px solid #fff'
                },
                "&:not(.Mui-disabled):hover::before": {
                    borderColor: "#fff"
                },
            },
        },
        MuiIconButton: {
            root: {
                color:'#c2c2c2',
                "&$focused": {
                    color: theme.palette.primary.main
                    },
            }
        },
        MuiSelect: {
            icon: {
                color:'#fff'
            },
            select: {
                "&$focused": {
                    backgroundColor: 'transparent'
                    },
            }
        }
        
    },
});

const fieldStyle = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                // color: "#c2c2c2",
                "&$focused": {
                    color: theme.palette.primary.main
                    },
                    '&.$Mui-disabled': {
                        color: '#ffffff54'
                      }
            },
           
        },
        MuiFormLabel: {
            root :{
                // color:'#c2c2c2',
                "&$focused": {
                    color: theme.palette.primary.main
                    },
            }
        },
        MuiIconButton: {
            root: {
                // color:'#c2c2c2',
                "&$focused": {
                    color: theme.palette.primary.main
                    },
            }
        },
        MuiOutlinedInput: {
            root: {
                borderColor:theme.palette.primary.main,
                "&$focused": {
                    borderColor:theme.palette.primary.main,
                    },
                "&$focused $notchedOutline": {
                    borderColor: theme.palette.primary.main
                    }
            },
            notchedOutline: {
                // borderColor:'#c2c2c2',
                "&$focused": {
                    borderColor:theme.palette.primary.main,
                    },
            }
        }
        
    },
});


const DialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh',
                padding: '15px 0 35px',
                borderRadius: '15px 15px 0 0',
            },
            paperFullWidth: {
                width: 400,
                maxWidth: '85%',
                margin: '0 auto',
                padding:0
            }
        },
        MuiPaper:{
            root:{
                backgroundColor:'transparent'
            },
            elevation24:{
                boxShadow:'none',
                WebkitFilter: 'drop-shadow(2px 7px 10px #00000070)',
                filter: 'drop-shadow(2px 7px 10px #00000070)',
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 30px',
                background:'#fff'
            }
        },
        MuiButton: {
            textPrimary: {
                color: '#fff',
                position: 'absolute',
                top: 10,
                right: 10,
                width: 25,
                height: 25,
                background: '#0008',
                borderRadius: 48,
                minWidth: 'unset',
                padding: 0,
            }
        }
        
    },
});