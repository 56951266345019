import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { makeStyles, useTheme, Typography, Box, Link, Grid, Drawer, Button, List, Divider, ListItem, ListItemIcon, ListItemText, } from '@material-ui/core';
import TitleBar from './Layouts/TitleBar';

import { currencyFormat, stringToLang } from '../helper/Tools';
import { getUrl } from '../helper/ApiAction';
import { storeWalletBalance } from '../actions/UserActions';
import useNotificationLoading from '../helper/useNotificationLoading';

import { TiInfoLarge } from 'react-icons/ti';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import clsx from 'clsx';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

export default function Wallet() {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { walletBalance, walletList, accessToken, max_mrank } = useSelector(state => ({
        walletBalance: state.user.walletBalance,
        walletList: state.wallet.walletList,
        accessToken: state.general.accessToken,
        max_mrank: state.user.max_mrank
    }));

    const isMountedRef = useRef(null);

    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const [commissionSummary, setCommissionSummary] = useState([]);

   
        
      
    useEffect(() => {
        isMountedRef.current = true;
        if (accessToken) {
            getUrl('default/balance').then(response => {
                // if (response.status && isMountedRef.current) {
                //     dispatch(storeWalletBalance(response.data));
                // }
                if(response.code == 0) {
                    console.log(response.data,'response.data')
                    dispatch(storeWalletBalance(response.data));
                }
                
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
            getUrl('default/get-summary').then(response => {
                if(response.code == 0 && isMountedRef.current) {
                    setCommissionSummary(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })
        }
        return () => isMountedRef.current = false;
    }, [dispatch, addAlert, accessToken])

    return (

        <Box>
            {/* className={styles.root} style={{ padding: '0 0 80px', }} */}
            {/* <TitleBar title={ t('title.wallet') } height={99} displayQr/> */}
            {/* <AutoplaySlider
                play={false}
                cancelOnInteraction={false}
                interval={3000}
                autoplay={false}
                bullets={true}
                organicArrows={true}
                className="wallet"
            >

                {_.map(walletBalance, walletItem => {
                    if (walletItem.code === 'MP' && walletItem.id === 4 && walletItem.balance == 0) { }
                    else {
                        return (
                            <div style={{ width: '100%' }} key={walletItem.id}>
                                <WalletComponent key={walletItem.id} data={walletItem} walletList={walletList} style={{ width: '100%' }} />
                            </div>
                        )
                    }
                })}
            </AutoplaySlider> */}

            <div>
                {_.map(walletBalance, walletItem => {
                    if (walletItem.code === 'MP' && walletItem.id === 4 && walletItem.balance == 0) { }
                    else {
                        return (
                            <div style={{ width: '100%' }} key={walletItem.id}>
                                <WalletComponent key={walletItem.id} data={walletItem} walletList={walletList} style={{ width: '100%' }} />
                            </div>
                        )
                    }
                })}
            </div>

            

            {/* <Box style={{ padding: '25px 15px' }}>
                <div >
                    {_.map(walletBalance, walletItem => {
                        if (walletItem.code === 'MP' && walletItem.id === 4 && walletItem.balance == 0) { }
                        else {
                            return (
                            <WalletComponent key={walletItem.id} data={walletItem} walletList={walletList} />
                            )
                        }
                    })}
                </div>
            </Box> */}


            {_.size(commissionSummary) ?
                <>
                    <div style={{ padding: '0 20px 10px' }}>
                        <Box justifyContent="space-between" display="flex" alignItems="center">
                            <Typography style={{ color: theme.palette.secondary.main, fontSize: 18, fontWeight: 'bold' }}>{t('commission.bonusSummary')}</Typography>
                            <Link underline="none" to={`/bonus`} component={RouterLink} style={{ marginRight: 5 }}>
                                <Typography style={{ textDecoration: "underline", fontSize: 14 }}>{t('commission.seeMore')}</Typography>
                            </Link>
                        </Box>
                    </div>
                    <Grid container spacing={1} style={{ padding: '0 20px 26px', width: '100%' }}>
                        {_.map(commissionSummary.bonus, bonusItem => {
                            if(!max_mrank?.code && bonusItem.id==5){
                            }
                            else{
                                let summary = _.find(commissionSummary.summary, { bonus_id: bonusItem.id });
                                let amount = "0.00";
                                if (summary) amount = Number(summary.amount).toFixed(2);
                                return (
                                    <Grid item xs={6} key={bonusItem.id}>
                                        <div className={classes.bonusBox} key={bonusItem.id}>
                                            <p style={{ fontSize: 20 }}><b>{amount}</b></p>
                                            <p className={classes.bonusTitle}>{stringToLang(bonusItem.name)}</p>
                                        </div>
                                    </Grid>
                                )
                            }
                        })
                        }
                    </Grid>
                </>

                : null
            }


        </Box>
    );
}

const WalletComponent = (props) => {
    const { data, walletList } = props;
    // console.log("data", data);

    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const existWallet = _.find(walletList, { id: data.id });
    console.log(walletList,'existWallet')
    let allowTransfer = false;
    let allowConvert = false;
    let allowWithdraw = false;
    let allowTopup = false;
    let usdtToLbvv = false;
    if (existWallet) {
        allowTransfer = existWallet.allow_transfer;
        allowConvert = existWallet.allow_convert;
        allowWithdraw = existWallet.allow_withdraw;
        allowTopup = existWallet.allow_topup;
        usdtToLbvv = existWallet.usdtToToken;
    }
    const balance = currencyFormat((data.balance * 100) / 100);
    const WALLET_COLORS = {
        usdt: 'linear-gradient(124deg, rgba(37,64,110,1) 0%, rgba(16,36,47,1) 100%)', 
        usdtr: 'linear-gradient(124deg, #37889e 0%, #2f6c88 100%)', 
        usdts: 'linear-gradient(124deg, #188fbe 0%, #2c439e 100%)', 
        lbpin: 'linear-gradient(124deg, #188fbe 0%, #2c439e 100%)', 
        lp:'linear-gradient(124deg, #ffc455 0%, #ffb858 100%)', 
        lbvv:'linear-gradient(124deg, rgba(37,64,110,1) 0%, rgba(16,36,47,1) 100%)', 
    }
    const BORDER_COLORS = {
        usdt: '#3a96c780', 
        usdtr: '#3699c0', 
        usdts: '#ffffff2e',
        lbpin:'#ffffff2e', 
        lp:'#fff9c96e', 
        lbvv: '#3a96c780',
    }

    return (
        <>
            <div style={{padding:'10px 20px 0'}}>
            <div style={{ background: 'rgb(37,64,110)', background:WALLET_COLORS[data.code], borderRadius:15, marginBottom:20, }}>
                <div className={classes.walletCard}>
                    {/* <FiLayers style={{fontSize:39, color:theme.palette.primary.main}}/> */}
                    <img src={`/images/general/icon/${data.code}.png`} alt="rank" className={classes.walletIconImg} />
                    <div style={{ color: theme.palette.white.main, textTransform: 'uppercase', paddingLeft: 15, width: 'fit-content' }}>
                        <p className={classes.walletBalanceTitle}>{data.wallet_name} {t('wallet.balance')}</p>
                       { data.code == 'lbvv'?
                       <p className={classes.walletAmount}><b>{ Number(data.balance).toFixed(0)}</b></p>
                       :
                       <p className={classes.walletAmount}><b>{balance}</b></p>}
                        {/* <p className={classes.walletAmount}><b>{balance}</b></p> */}
                    </div>

                </div>
                <div className={classes.walletMenuBarPosition}>
                    <div className={classes.walletMenuBar} >
                        {allowWithdraw ?
                            <Link underline='none' to={`/withdrawal/${data.code}`} component={RouterLink} className={classes.menuIconWidth} style={{borderColor:BORDER_COLORS[data.code]}}>
                                <div className={classes.menuConatiner} style={{background:WALLET_COLORS[data.code]}}>
                                    <center><img src='/images/general/icon/withdrawal.png' alt="withdrawal" className={classes.menuIcon} /></center>
                                    <p className={classes.menuTitle}>{t('title.walletWithdrawal')}</p>
                                </div>
                            </Link>
                            : null}

                        {allowTransfer ?
                            <Link underline='none' to={`/transfer/${data.code}`} component={RouterLink} className={classes.menuIconWidth} style={{borderColor:BORDER_COLORS[data.code]}}>
                                <div className={classes.menuConatiner} style={{background:WALLET_COLORS[data.code]}}>
                                    <center><img src='/images/general/icon/transfer.png' alt="transfer" className={classes.menuIcon} /></center>
                                    <p className={classes.menuTitle}>{t('wallet.transfer')}</p>
                                </div>
                            </Link>
                            : null}

                        {usdtToLbvv ?
                            <Link underline='none' to={`/to/LBvv`} component={RouterLink} className={classes.menuIconWidth} style={{borderColor:BORDER_COLORS[data.code]}}>
                                <div className={classes.menuConatiner} style={{background:WALLET_COLORS[data.code]}}>
                                    <center><img src='/images/general/icon/transfer.png' alt="usdttotoken" className={classes.menuIcon} /></center>
                                    <p className={classes.menuTitle}>{t('swap.usdtToLbvv')}</p>
                                </div>
                            </Link>
                            : null}

                        {allowConvert ?
                            <Link underline='none' to={`/convert/${data.code}`} component={RouterLink} className={classes.menuIconWidth} style={{borderColor:BORDER_COLORS[data.code]}}>
                                <div className={classes.menuConatiner} style={{background:WALLET_COLORS[data.code]}}>
                                    <center><img src='/images/general/icon/transfer.png' alt="transfer" className={classes.menuIcon} /></center>
                                    <p className={classes.menuTitle}>{t('wallet.convert')}</p>
                                </div>
                            </Link>
                            : null}
                        {allowTopup ?
                            <>
                                <Link underline='none' to={`/topup/${data.code}`} component={RouterLink} className={classes.menuIconWidth} style={{borderColor:BORDER_COLORS[data.code]}}>
                                    <div className={classes.menuConatiner} style={{background:WALLET_COLORS[data.code]}}>
                                        <center><img src='/images/general/icon/topup.png' alt="topup" className={classes.menuIcon} /></center>
                                        <p className={classes.menuTitle}>{t('topup.topup')}</p>
                                    </div>
                                </Link>
                            </>
                            : null
                        }
                        <React.Fragment key={'bottom'}>
                            <div onClick={toggleDrawer('bottom', true)} className={`head ${classes.menuConatiner} ${classes.menuIconWidth}`} style={{ display:'flex', justifyContent:'center', alignItems:'center', background:WALLET_COLORS[data.code], borderColor:BORDER_COLORS[data.code] }} >                               
                               <center><img src='/images/general/icon/transaction.png' alt="transfer" style={{ width: 30 }} /></center>
                                <p className={classes.menuTitle}>{t('wallet.transaction')}</p>                               
                            </div>
                            <MuiThemeProvider theme={drawerStyle}>
                                <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
                                    <div
                                        // className={clsx(classes.list, {
                                        //     [classes.fullList]: true,
                                        // })}
                                        role="presentation"
                                        onClick={toggleDrawer('bottom', false)}
                                        onKeyDown={toggleDrawer('bottom', false)}
                                    >
                                        <List>
                                            <ListItem component={RouterLink} underline='none' to={`/transaction/${data.code}`}>
                                                <div style={{ color: theme.palette.secondary.main, textAlign: 'center' }}>
                                                    {/* <TbReport/> */}
                                                    <p className={classes.menuTitle}>{t('wallet.transaction')}</p>
                                                </div>
                                            </ListItem>
                                            {allowTopup ?
                                                <ListItem underline='none' to={`/topup-history/${data.code}`} component={RouterLink}>
                                                    <div style={{ color: theme.palette.secondary.main, textAlign: 'center' }}>
                                                        {/* <center><img src='/images/general/icon/transaction.png' alt="transfer" style={{ width: 30 }} /></center> */}
                                                        <p className={classes.menuTitle}>{t('topup.topupHistory')}</p>
                                                    </div>
                                                </ListItem>
                                                : null
                                            }
                                            {allowWithdraw ?
                                                <ListItem underline='none' to={`/withdrawal-history/${data.code}`} component={RouterLink}>
                                                    <div style={{ color: theme.palette.secondary.main, textAlign: 'center' }}>
                                                        {/* <center><img src='/images/general/icon/withdrawal.png' alt="withdrawal" style={{ width: 30 }} /></center> */}
                                                        <p className={classes.menuTitle}>{t('title.withdrawalHistory')}</p>
                                                    </div>
                                                </ListItem>
                                                : null}
                                        </List>

                                    </div>
                                </Drawer>
                            </MuiThemeProvider>
                        </React.Fragment>
                        

                    </div>
                </div>

                
            </div>
            </div>

            


        </>

    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    menuIconWidth: {
        width:'100% !important',
        minHeight:77,
        borderTop:'3px solid',
        minWidth:'25%',
    },
    menuConatiner: {
        color: theme.palette.white.main,
        padding:15,
        background: 'rgb(37,64,110)',
        background: 'linear-gradient(124deg, rgba(37,64,110,1) 0%, rgba(16,36,47,1) 100%)',
        textAlign: 'center',
        display:'flex',
        alignItems:'center',
        flexDirection:'column',
        boxShadow:'-4px 4px 7px 0 #00000045',
        
    },
    menuIcon: {
        width: 30,
        minWidth:30
    },
    menuTitle: {
        fontSize: 12,
        whiteSpace:'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
        },
    },
    walletButtonStyle: {
        border: '1px solid #696969',
        borderRadius: 48,
        padding: '5px 10px',
        color: theme.palette.silver.text,
        fontSize: 14,
        minWidth: 75,
        textAlign: 'center'
    },
    bonusBox: {
        height: 70,
        // backgroundColor: '#EEBE7F',
        background: 'rgb(37,64,110)',
        background: 'linear-gradient(124deg, rgba(37,64,110,1) 0%, rgba(16,36,47,1) 100%)',
        borderRadius: 15,
        width: '100%',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 15
    },
    bonusTitle: {
        textTransform: 'uppercase',
        fontSize: 12,
        paddingTop: 5
    },
    walletCard: {
        height: 120,
        // background: theme.palette.secondary.main,
        borderRadius: 15,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 14,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 105,
        },
    },
    walletIconImg: {
        height: 51,
        [theme.breakpoints.down('sm')]: {
            height: 43,
        },
    },
    walletBalanceTitle: {
        textTransform: 'uppercase',
        fontSize: 15,
        paddingBottom: 2,
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },
    walletAmount: {
        fontSize: 30,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
        },
    },
    walletMenuBarPosition: {
        // padding: '0 20px',
        marginTop: -15,
        // position: 'relative',
        // [theme.breakpoints.down('sm')]: {
        //     marginTop: -29,
        // },
    },
    walletMenuBar: {
        // backgroundColor: theme.palette.primary.main,
        // background: 'rgb(37,64,110)',
        // background: 'linear-gradient(124deg, rgba(37,64,110,1) 0%, rgba(16,36,47,1) 100%)',
        // borderRadius: 15,
        // padding: '9px 10px !important',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        overflow: 'hidden',
        // [theme.breakpoints.down('sm')]: {
        //     padding: '10px !important',
        // },
    }
}));

const drawerStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto'
            },
           
        },
        MuiDrawer: {
            paper: {
                width: 450,
                margin: '0 auto',
                maxWidth: '100%',
            }
        },
        MuiListItem: {
            gutters: {
                padding:'20px 16px',
                borderBottom:'1px solid #efefef'
            }
        }
        // MuiInput: {
        //     underline: {
        //         '&::before': {
        //             borderBottom: '1px solid #fff'
        //         },
        //         '&::after': {
        //             borderBottom: '2px solid #fff'
        //         },
        //         "&:not(.Mui-disabled):hover::before": {
        //             borderColor: "#fff"
        //         },
        //     },
        // },
        // MuiIconButton: {
        //     root: {
        //         color:'#c2c2c2',
        //         "&$focused": {
        //             color: '#FF5532'
        //             },
        //     }
        // },
        // MuiSelect: {
        //     icon: {
        //         color:'#fff'
        //     },
        //     select: {
        //         "&$focused": {
        //             backgroundColor: 'transparent'
        //             },
        //     }
        // }
        
    },
});
