import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Typography, Box, Dialog, DialogContent, DialogTitle, DialogActions, Link } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { storeProfile } from '../actions/UserActions';
import _ from 'lodash';
import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';
import { createMuiTheme, MuiThemeProvider, useTheme } from '@material-ui/core/styles';

import TitleBarWhite from './Layouts/TitleBarWhite';

export default function ProfileTwoFactorAuthentication() {
    const { t } = useTranslation();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const dispatch = useDispatch();

    const [state, setState] = useState({
        secret: '',
        authentication_code: ''
    });
    const [enableAuth, setEnableAuth] = useState(false);
    const [disableAuth, setDisableAuth] = useState(false);
    const { two_factor_auth } = useSelector(state => ({
        two_factor_auth: state.user.two_factor_auth,
    }));
    const [errorFormState, setErrorFormState] = useState({
        authentication_code: ''
    })
    const [qrcode, setQrcode] = useState([]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        let newValue = value;
        setState({ ...state, [name]: newValue });
    }

    const getSecret = () => {
        setLoading(true);
        setErrorFormState({});
        getUrl(`members/get-authentication-secret`).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, secret: response.secret });
                setQrcode(response.qrcode);
                setEnableAuth(true);
            } else {
                if (response.error) {
                    let allErrorField = [];
                    _.map(response.errors, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                } else {
                    addAlert(JSON.stringify(response.message));
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
        setViewAnnouncement(true);
    }

    const verifyCode = () => {
        setLoading(true);
        let params = {
            secret: state.secret ? state.secret : '',
            authentication_code: state.authentication_code,
        }
        postUrl(`members/verify-authentication-code`, params).then(response => {
            setLoading(false);                    
            setErrorFormState("");
            setState({ ...state, authentication_code: "" });
            if (response.status) {
                dispatch(storeProfile(response.profile));
                addAlert(response.message, 'success');
            } else {
                if (response.errors) {
                    let allErrorField = [];
                    _.map(response.errors, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                    addAlert(JSON.stringify(_.map(response.errors).join(' ')));
                } else {
                    addAlert(JSON.stringify(response.message));
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const disableTwoFactorAuth = () => {
        setLoading(true);
        let params = {
            authentication_code: state.authentication_code,
        }
        postUrl(`members/disable-authentication-code`, params).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.profile));
                setDisableAuth(false);
                setEnableAuth(false);
                addAlert(response.message, 'success');
            } else {
                if (response.errors) {
                    let allErrorField = [];
                    _.map(response.errors, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setErrorFormState(allErrorField);
                    addAlert(JSON.stringify(_.map(response.errors).join(' ')));
                } else {
                    addAlert(response.message);
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const [viewAnnouncement, setViewAnnouncement] = useState(false);
    const closeAnnouncement = (event, reason) => {
        if (reason && (reason == "backdropClick" || reason == "escapeKeyDown")) 
        return;
        setViewAnnouncement(false);
    }
    // const [agree, setAgree] = useState(false);
    // const checkboxHandler = () => {
    //     setAgree(!agree);
    // }
    const btnHandler = () => {
        setViewAnnouncement(false);
    };

    return (
        <Box>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px' }}>
                <TitleBarWhite title={t('profile.2FA')} back displayInfo={false} />
                <Link underline='none' to="/TutorialAuthenticator" component={RouterLink} >
                    <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff' }}>
                        <p>{t('tutorial.tutorial')}</p>
                    </div>
                </Link>
            </div>

            <Box style={{ padding: '0 20px' }}>
                <Typography variant="h6">
                    {t('profile.2FA')}
                </Typography>
                <Typography style={{ fontSize: 15 }} color="textSecondary">
                    {
                        two_factor_auth === 1 ?
                            t('profile.enabled2FA')
                            : t('profile.2FASubtitle')
                    }
                </Typography>
            </Box>
            {
                two_factor_auth === 0 && !enableAuth ?
                    <div style={{ padding: '30px 20px 5px' }}>
                        <Typography variant="h6">{t('profile.enable2FA')}</Typography>
                        <div>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                                <Button variant="contained" onClick={getSecret} style={{ width: 500, maxWidth: '80%', color: '#fff' }} color="primary">{t('button.enable')}</Button>
                            </Box>
                        </div>
                    </div>
                    : null
            }
            {
                two_factor_auth === 1 && !disableAuth ?
                    <div style={{ padding: '30px 20px 5px' }}>
                        <Typography variant="h6">{t('profile.disable2FA')}</Typography>
                        <div>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                                <Button variant="contained" onClick={() => setDisableAuth(true)} style={{ width: 500, maxWidth: '80%' }} color="secondary">{t('button.disable')}</Button>
                            </Box>
                        </div>
                    </div>
                    : null
            }
            {
                // disable 2fa
                two_factor_auth === 1 && disableAuth ?
                    <div style={{ padding: '30px 20px 5px' }}>
                        <Typography variant="h6">{t('profile.disable2FA')}</Typography>
                        <Typography color="textSecondary" >{t('profile.disable2FACaption')}</Typography>
                        <div style={{ padding: '30px 20px 5px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: '10px 0' }}>
                                <TextField
                                    error={errorFormState.authentication_code ? true : false}
                                    helperText={errorFormState.authentication_code}
                                    label={t('profile.authenticationCode')}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ name: 'authentication_code' }}
                                    onChange={handleChange}
                                />
                                <Button variant="contained" onClick={disableTwoFactorAuth} style={{ width: 100, maxWidth: '50%', color: '#fff' }} color="primary">{t('button.submit')}</Button>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                // enable 2fa
                two_factor_auth === 0 && enableAuth ?
                    <div style={{ padding: '30px 20px 30px' }}>
                        <Typography variant="h6" >{t('profile.scan2FAQrcode')}</Typography>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
                            <img src={qrcode} alt="qrcode" />
                        </div>
                        <Typography variant="h6" style={{ padding: '10px 0' }}>{t('profile.insertSecret')}</Typography>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
                            <Typography color="textSecondary" style={{ fontSize: 15 }}><b>{t('profile.secretKey', { secret: state.secret })}</b></Typography>
                        </div>
                        <Typography variant="h6" style={{ padding: '10px 0' }}>{t('profile.verifyCode')}</Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: '10px 0' }}>
                            <TextField
                                error={errorFormState.authentication_code ? true : false}
                                helperText={errorFormState.authentication_code}
                                label={t('profile.authenticationCode')}
                                variant="outlined"
                                size="small"
                                inputProps={{ name: 'authentication_code' }}
                                onChange={handleChange}
                            />
                            <Button variant="contained" onClick={verifyCode} style={{ width: 100, maxWidth: '50%', color: '#fff' }} color="primary">{t('button.submit')}</Button>
                        </div>
                    </div>
                    : null
            }

            <MuiThemeProvider key='warn' theme={announceDialogStyle}>
                <Dialog
                    open={viewAnnouncement? true : false}
                    onClose={closeAnnouncement}
                    scroll="body"
                    aria-labelledby="view Announcement"
                    aria-describedby="view Announcement detail"
                    key='warn'
                    style={{width:'547px', maxWidth:'100%', margin:'0 auto'}}
                >
                    <DialogTitle id='warn'>{t('topup.dialog.title')}</DialogTitle>
                    <DialogContent dividers={false}>
                        <div
                            id="viewAnnouncement"
                            tabIndex={-1}
                        >
                            <p>{t('profile.dialog.2fa_content')}</p><br/>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={btnHandler}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        </Box>
    );
}

const announceDialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh'
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 24px'
            }
        },
    },
});