import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem, CardHeader, Typography, Box, Divider, TextField, Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, IconButton, Link } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import useNotificationLoading from '../helper/useNotificationLoading';
import _ from 'lodash';
import { postUrl, putUrl, getUrl } from '../helper/ApiAction';

import { FiChevronLeft } from 'react-icons/fi';

import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export default function BindSubAccount() {

    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useSelector(state => state.user);
    const { addAlert, setLoading } = useNotificationLoading();
    const [state, setState] = useState({
        unbindShow: false,
        unbindSuccess: false,
        bindSuccess: false,
        username: '',
        password: '',
        errorMsg: [],
        unbindUsername: '',
        unbindPassword: '',
    });
    const [bindAccounts, setBindAccounts] = useState([]);
    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`user`).then(result => {
            if (isMountedRef.current && result.status) {
                setBindAccounts(result.data.bind_accounts);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [addAlert, t, state.unbindSuccess, state.bindSuccess]);

    const handleBindChange = (event) => {
        const name = event.target.name;
        setState({ ...state, [name]: event.target.value, bindSuccess: false, unbindSuccess: false });
    }

    const handleUnbindChange = (event) => {
        const name = event.target.name;
        setState({ ...state, [name]: event.target.value, bindSuccess: false, unbindSuccess: false });
    }

    const bindAccount = () => {
        setLoading(true);
        let params = {
            username: state.username,
            security_password: state.password,
        }
        postUrl(`user/${id}/bind`, params).then(response => {
            setLoading(false);
            let { status, data, error, message } = response;
            if (status === 1) {
                addAlert('', 'success', t('bindSubAccount.bindSuccess'), '');
                setState({ ...state, bindSuccess: true, username: null, password: null, unbindUsername: null, unbindPassword: null, errorMsg: [] });
            } else {
                if (error) {
                    // addAlert('', 'error', message, '');
                    let allErrorField = [];
                    _.map(error, (errorItem, errorField) => {
                        allErrorField[errorField] = errorItem[0];
                    })
                    setState({ ...state, errorMsg: allErrorField });
                    
                }else{
                    addAlert('', 'error', message, '');
                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    const unbindAccount = () => {
        let params = {
            username: state.unbindUsername,
            security_password: state.unbindPassword,
        }
        putUrl(`user/${id}/unbind`, params).then(response => {
            setLoading(false);
            let { status, data, error, message } = response;
            if (status === 1) {
                setState({ ...state, unbindSuccess: true, username: null, password: null, unbindUsername: null, unbindPassword: null });
            } else {
                if (error) {
                    // addAlert('', 'error', message, '');
                    let allErrorField = [];
                    _.map(error, (errorItem, errorField) => {
                        allErrorField['unbind' + errorField] = errorItem[0];
                    })
                    setState({ ...state, errorMsg: allErrorField, username: null, password: null });

                }
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    return (
        <Box className={styles.root} style={{ padding: '0 0 80px'}}>
        <Box style={{ height: 50, backgroundColor:theme.palette.primary.main, display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', paddingTop:10}}>
                <Link underline='none' to="/setting" component={RouterLink} style={{zIndex:3}} >
                    <FiChevronLeft style={{color:theme.palette.white.main, marginLeft:15}}/>
                </Link>
                <Typography style={{ textAlign: 'center', color: theme.palette.white.main, width:'100%', marginLeft:-34 }}>{t('bindSubAccount.title')}</Typography>
            </Box>
        <Grid container spacing={1} justify="center" style={{ minHeight: 450,  }}>
            <Grid item xs={12} className={styles.root} style={{backgroundColor:'transparent'}}>
                <List className={styles.listStyle} style={{ borderRadius: 15, backgroundColor:'transparent' }}>
                   
                    <CardHeader
                            title={t('bindSubAccount.title')}
                            titleTypographyProps={{ variant: 'subtitle1', color: 'secondary' }}
                        />
                        <Divider />
                    <ListItem className={styles.listPaddingVertical}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{t('bindSubAccount.bindUsername')}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField
                                    error={state.errorMsg.username ? true : false}
                                    helperText={state.errorMsg.username}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ name: 'username' }}
                                    onChange={handleBindChange}
                                    value={state.username ?? ""}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem className={styles.listPaddingVertical}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3} sm={3}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Typography variant="caption" color="textSecondary">{t('bindSubAccount.bindPassword')}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={9} sm={8}>
                                <TextField
                                    error={state.errorMsg.security_password ? true : false}
                                    helperText={state.errorMsg.security_password}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ name: 'password' }}
                                    type="password"
                                    onChange={handleBindChange}
                                    value={state.password ?? ""}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                    {/* <ListItem className={styles.listPaddingVertical} style={{ display: 'flex', justifyContent: 'center', alignItem: 'center'  }}>
                            <Box>
                                <Button onClick={bindAccount} variant="contained" color="secondary">{t('bindSubAccount.bind')}</Button>
                            </Box>
                        </ListItem> */}
                     <Box style={{display:'flex', justifyContent:'center', alignItems:'center', paddingTop:30, paddingBottom:30}}>
                            <Button onClick={bindAccount} style={{width:500, maxWidth:'80%'}} variant="contained" color="secondary">{t('bindSubAccount.bind')}</Button>
                    </Box>


                    {
                        _.size(bindAccounts) > 0 && bindAccounts ?
                            <>
                                <Divider variant="middle" light />
                                <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
                                    <Box style={{paddingTop:20}}>
                                        <Typography style={{color:theme.palette.secondary.brown, fontSize:16}}>
                                            {t('bindSubAccount.listSubAccount')}
                                            
                                        </Typography>
                                    </Box>
                                </ListItem>
                                {
                                    _.map(bindAccounts, (acc_list, acc_key) => {
                                        return (
                                            <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }} key={acc_key}>
                                                <Box >
                                                    <Typography variant="body1" color="textPrimary">{`${acc_list.username}`}</Typography>
                                                </Box>
                                                <IconButton onClick={() => setState({ ...state, unbindUsername: acc_list.username, unbindShow: true })}>
                                                    <DeleteIcon color="primary" />
                                                </IconButton>
                                            </ListItem>


                                        )
                                    })
                                }

                            </>
                            : null

                    }     
                    
                </List>
                <Dialog
                    open={state.unbindShow}
                    fullWidth
                    onClose={() => setState({ ...state, unbindShow: false, unbindSuccess: false })}
                >
                    <DialogTitle>
                        {t('bindSubAccount.unbindTitle')}
                    </DialogTitle>
                    <DialogContent>
                        {
                            state.unbindSuccess ?
                                <>
                                    <Grid container spacing={2} justify="center" align="center">
                                        <Grid item xs={12}>
                                            <CheckCircleOutlineIcon style={{ color: 'green' }} fontSize="large" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DialogContentText>
                                                {t('bindSubAccount.unbindSuccess')}
                                            </DialogContentText>
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <DialogContentText>
                                        {t('bindSubAccount.unbindContent', { 'username': state.unbindUsername })}
                                    </DialogContentText>
                                    <TextField
                                        error={state.errorMsg.unbindsecurity_password ? true : false}
                                        helperText={state.errorMsg.unbindsecurity_password}
                                        fullWidth={true}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ name: 'unbindPassword' }}
                                        type="password"
                                        onChange={handleUnbindChange}
                                        value={state.unbindPassword ?? ""}
                                        autoFocus
                                    />
                                </>
                        }

                    </DialogContent>
                    <DialogActions>
                        {
                            state.unbindSuccess ?
                                <Button autoFocus variant="outlined" onClick={() => setState({ ...state, unbindShow: false, username: null, password: null, unbindSuccess: false, errorMsg: [], unbindUsername: null, unbindPassword: null })} color="default">
                                    <Typography variant="overline">{t('button.close')}</Typography>
                                </Button> :
                                <>
                                    <Button autoFocus variant="outlined" onClick={() => setState({ ...state, unbindShow: false, username: null, password: null, unbindSuccess: false, errorMsg: [], unbindUsername: null, unbindPassword: null })} color="default">
                                        <Typography variant="overline">{t('button.cancel')}</Typography>
                                    </Button>
                                    <Button onClick={unbindAccount} variant="contained" color="secondary">
                                        <Typography variant="overline">{t('button.confirm')}</Typography>
                                    </Button>
                                </>
                        }
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
        </Box>

    )
}

const useStyles = makeStyles(theme => ({
    root: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg, 
        padding:'0 0 80px',
        minHeight: '100%'
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
}));