import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, useTheme } from '@material-ui/core/styles';


import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { Box, Button, MobileStepper, Typography } from '@material-ui/core';

import TitleBarWhite from './Layouts/TitleBarWhite';

  
export default function TutorialRegister() {
    const { t, i18n } = useTranslation();

    const theme = useTheme();
    const classes = useStyles();
   

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const tutorialSteps = [
        {
            label: t('tutorial.registerTitle1'),
            description: t('tutorial.registerDesc1'),
            imgPath:
                `/images/tutorial/register/step1-${i18n.language}.png`,
        },
        {
            label: t('tutorial.registerTitle2'),
            description: t('tutorial.registerDesc2'),
            imgPath:
                `/images/tutorial/register/step2-${i18n.language}.png`,
        },
        {
            label: t('tutorial.registerTitle3'),
            description: t('tutorial.registerDesc3'),
            imgPath:
                `/images/tutorial/register/step3-${i18n.language}.png`,
        },
        {
            label: t('tutorial.registerTitle4'),
            description: t('tutorial.registerDesc4'),
            imgPath:
                `/images/tutorial/register/step4-${i18n.language}.png`,
        },
        // {
        //     label: t('tutorial.registerTitle5'),
        //     description: t('tutorial.registerDesc5'),
        //     imgPath:
        //         `/images/tutorial/register/step5-${i18n.language}.png`,
        // },
        // {
        //     label: t('tutorial.registerTitle6'),
        //     description: t('tutorial.registerDesc6'),
        //     imgPath:
        //         `/images/tutorial/register/step6-${i18n.language}.png`,
        // },
        // {
        //     label: t('tutorial.registerTitle7'),
        //     description: t('tutorial.registerDesc7'),
        //     imgPath:
        //         `/images/tutorial/register/step7-${i18n.language}.png`,
        // },
        // {
        //     label: t('tutorial.registerTitle8'),
        //     description: t('tutorial.registerDesc8'),
        //     imgPath:
        //         `/images/tutorial/register/step8-${i18n.language}.png`,
        // },
        {
            label: t('tutorial.registerTitle9'),
            description: t('tutorial.registerDesc9'),
            imgPath:
                `/images/tutorial/register/step9-${i18n.language}.png`,
        },
        {
            label: t('tutorial.registerTitle10'),
            description: t('tutorial.registerDesc10'),
            imgPath:
                `/images/tutorial/register/step10-${i18n.language}.png`,
        },
        {
            label: t('tutorial.registerTitle11'),
            description: t('tutorial.registerDesc11'),
            imgPath:
                `/images/tutorial/register/step11-${i18n.language}.png`,
        },
        {
            label: t('tutorial.registerTitle12'),
            description: t('tutorial.registerDesc12'),
            imgPath:
                `/images/tutorial/register/step12-${i18n.language}.png`,
        },
        {
            label: t('tutorial.registerTitle13'),
            description: t('tutorial.registerDesc13'),
            imgPath:
                `/images/tutorial/register/step13-${i18n.language}.png`,
        },
        // {
        //     label: t('tutorial.registerTitle14'),
        //     description: t('tutorial.registerDesc14'),
        //     imgPath:
        //         `/images/tutorial/register/step14-${i18n.language}.png`,
        // },
        // {
        //     label: t('tutorial.registerTitle15'),
        //     description: t('tutorial.registerDesc15'),
        //     imgPath:
        //         `/images/tutorial/register/step15-${i18n.language}.png`,
        // },
        // {
        //     label: t('tutorial.registerTitle16'),
        //     description: t('tutorial.registerDesc16'),
        //     imgPath:
        //         `/images/tutorial/register/step16-${i18n.language}.png`,
        // },
        // {
        //     label: t('tutorial.registerTitle17'),
        //     description: t('tutorial.registerDesc17'),
        //     imgPath:
        //         `/images/tutorial/register/step17-${i18n.language}.png`,
        // },
        {
            label: t('tutorial.registerTitle18'),
            description: t('tutorial.registerDesc18'),
            imgPath:
                `/images/tutorial/register/step18-${i18n.language}.png`,
        },
    ];
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (

        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
            <TitleBarWhite title={ t('title.bonus') } back displayInfo={false}/>

            <div style={{paddingTop:20}}>
                <p className={classes.pagetitle}>{t('tutorial.howTo')}{t('tutorial.register')}</p>

                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    autoplay={false}
                >
                    {tutorialSteps.map((step, index) => (
                        <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <img className={classes.img} src={step.imgPath} alt={step.label} />
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <div className={classes.textContainer}>
                    <Typography className={classes.title}>{tutorialSteps[activeStep].label}</Typography>
                    <Typography className={classes.description}>{tutorialSteps[activeStep].description}</Typography>
                </div>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            {t('button.next')}
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            {t('button.back')}
                        </Button>
                    }
                />
            </div>
        </Box>
    );
}


const useStyles = makeStyles(theme => ({
    pagetitle: {
        fontWeight:'bold',
        fontSize:18,
        textAlign:'center',
        color:theme.palette.primary.main,
        paddingBottom:10
    },
    tutorialImgBox: {
        width:'80%',
        margin:'0 auto'
    },
    title: {
        color:'#3F686B',
        fontFamily:'times-new-roman',
        fontSize:22,
        textAlign:'center',
        fontWeight:'bold',
    },
    description: {
        color:theme.palette.silver.text,
        fontSize:16,
        lineHeight:1.3,
        textAlign:'center',
    },
    textContainer: {
        paddingTop:20,
        minHeight:150,
    },
      img: {
        // height: 255,
        // display: 'block',
        // maxWidth: 400,
        // overflow: 'hidden',
        width: '100%',
      },
   

}));


