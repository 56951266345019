import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { Box, Button, MobileStepper, Typography } from '@material-ui/core';
import TitleBarWhite from './Layouts/TitleBarWhite';

  
export default function TutorialPassword() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();


    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const tutorialSteps = [
        {
            label: t('tutorial.authenticatorTitle1'),
            description: t('tutorial.authenticatorDesc1'),
            imgPath:
                `/images/tutorial/authenticator/step1-${i18n.language}.png`,
        },
        {
            label: t('tutorial.authenticatorTitle2'),
            description: t('tutorial.authenticatorDesc2'),
            imgPath:
                `/images/tutorial/authenticator/step2-${i18n.language}.png`,
        },
        {
            label: t('tutorial.authenticatorTitle3'),
            description: t('tutorial.authenticatorDesc3'),
            imgPath:
                `/images/tutorial/authenticator/step3-${i18n.language}.png`,
        },
        {
            label: t('tutorial.authenticatorTitle4'),
            description: t('tutorial.authenticatorDesc4'),
            imgPath:
                `/images/tutorial/authenticator/step4-${i18n.language}.png`,
        },
        {
            label: t('tutorial.authenticatorTitle5'),
            description: t('tutorial.authenticatorDesc5'),
            imgPath:
                `/images/tutorial/authenticator/step5-${i18n.language}.png`,
        },
        {
            label: t('tutorial.authenticatorTitle6'),
            description: t('tutorial.authenticatorDesc6'),
            imgPath:
                `/images/tutorial/authenticator/step6-${i18n.language}.png`,
        },
        {
            label: t('tutorial.authenticatorTitle7'),
            description: t('tutorial.authenticatorDesc7'),
            imgPath:
                `/images/tutorial/authenticator/step7-${i18n.language}.png`,
        },
        {
            label: t('tutorial.authenticatorTitle8'),
            description: t('tutorial.authenticatorDesc8'),
            imgPath:
                `/images/tutorial/authenticator/step8-${i18n.language}.png`,
        },
        {
            label: t('tutorial.authenticatorTitle9'),
            description: t('tutorial.authenticatorDesc9'),
            imgPath:
                `/images/tutorial/authenticator/step9-${i18n.language}.png`,
        },
    ];
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (

        <Box className={classes.root} style={{ padding:'0 20px 30px', minHeight: '100%'}}>
            <TitleBarWhite title={ t('title.bonus') } back displayInfo={false}/>

            <div style={{paddingTop:20}}>
                <p className={classes.pagetitle}>{t('tutorial.howToUse')}<br/>{t('tutorial.authenticator')}</p>

                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    autoplay={false}
                >
                    {tutorialSteps.map((step, index) => (
                        <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <img className={classes.img} src={step.imgPath} alt={step.label} />
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <div className={classes.textContainer}>
                    <Typography className={classes.title}>{tutorialSteps[activeStep].label}</Typography>
                    <Typography className={classes.description}>{tutorialSteps[activeStep].description}</Typography>
                </div>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            {t('button.next')}
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            {t('button.back')}
                        </Button>
                    }
                />
            </div>
        </Box>
    );
}


const useStyles = makeStyles(theme => ({
    pagetitle: {
        fontWeight:'bold',
        fontSize:18,
        lineHeight:1.3,
        textAlign:'center',
        color:theme.palette.primary.main,
        paddingBottom:10
    },
    tutorialImgBox: {
        width:'80%',
        margin:'0 auto'
    },
    title: {
        color:'#264671',
        fontFamily:'times-new-roman',
        fontSize:22,
        textAlign:'center',
        fontWeight:'bold',
    },
    description: {
        color:theme.palette.silver.text,
        fontSize:16,
        lineHeight:1.3,
        textAlign:'center',
    },
    textContainer: {
        paddingTop:20,
        minHeight:150,
    },
      img: {
        // height: 255,
        // display: 'block',
        // maxWidth: 400,
        // overflow: 'hidden',
        width: '100%',
      },
   

}));


