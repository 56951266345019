import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { BASE_URL,FRONTEND_VERSION } from './configs/Config';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    load: 'languageOnly',
    backend: {
      loadPath: `${BASE_URL}/locales/{{lng}}/{{ns}}.json?v=${FRONTEND_VERSION}`
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong'],
    }
  });

export default i18n;