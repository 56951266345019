import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, getUrl } from '../../helper/ApiAction';
import _ from 'lodash';
import moment from 'moment-timezone';

import { fade, makeStyles } from '@material-ui/core/styles';
import { Typography, Box, AppBar, Toolbar, IconButton, TextField, Badge, Menu, MenuItem, Container, Button, Link, InputAdornment, useTheme, useScrollTrigger, Slide } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BiUserCircle } from 'react-icons/bi';

import { useTranslation } from 'react-i18next';
import { changeLanguage as reduxChangeLanguage, storeUuid, removeUuid, updateCartTotal, availableEvent2 } from '../actions/GeneralActions'
import { storeProfile} from '../actions/UserActions'
import { storeWallets} from '../actions/WalletActions'
import { PROJECT_TYPE } from '../../configs/Config';

import { v4 as uuidv4 } from 'uuid';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Globe, ShoppingCart, User, Search } from 'react-feather';
import useNotificationLoading from '../../helper/useNotificationLoading';

import PropTypes from 'prop-types';

import SearchIcon from '@material-ui/icons/Search';
import WebpImg from './WebpImg';

const HIDE_COMPONENT_PAGE = ["login", "register", "checkout", "payment"];

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function Header(props) {
    const { uuid, cartTotal, accessToken } = useSelector(state => state.general);
    const { name, username, email, mobile, rank } = useSelector(state => state.user);
    const styles = useStyles();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    let history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const { setLoading, addAlert } = useNotificationLoading();

    const [state, setState] = useState({
        languageAnchorEl: null,
        profileAnchorEl: null,
        mobileAnchorEl: null,
    })

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;
    const [keyword, setKeyword] = useState('');
    const [currentPath, setCurrentPath] = useState();
    const [serverTime, setServerTime] = useState("");
    
    useEffect(() => {
        let active = true;

        if (active) {
            const path = _.split(location.pathname, '/');
            if (path) {
                setCurrentPath(path[1].toLowerCase());
            }
        }
        return () => { active = false };
    }, [location.pathname])

    useEffect(() => {
        let active = true;

        if (keyword) {
            getUrl(`keyword/${encodeURIComponent(keyword)}`)
                .then(response => {
                    if (active) {
                        let returnData = [];
                        _.map(response.data, (value, index) => {
                            returnData[index] = { name: value, id: index };
                        })
                        setOptions(returnData);
                    }
                    // setOptions(response.data);
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }

        return () => { active = false };
    }, [keyword, addAlert, t])

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    // useEffect(() => {
    //     let ipNotIncludesPath = '/comingSoon';
    //     let ipAllowArray = ['175.143.34.14'];

    //     axios.get('https://api64.ipify.org/').then((response) => {
    //         if(!ipAllowArray.includes(response.data)){
    //             if(location.pathname != ipNotIncludesPath){
    //                 setLoading(false);
    //                 history.push(ipNotIncludesPath);
    //             }
    //         }
    //     }).catch((error) => {
    //         console.log("ip address error", error);
    //     });

    //     // eslint-disable-next-line
    // }, [location.pathname]);

    useEffect(() => {
        if (accessToken) {
            getUrl('user/index')
                .then(userData => {
                    if (userData.code == 0) {
                        dispatch(storeProfile(userData.data));
                    }
                }).catch(error => {
                });
        }
        if (!uuid && !accessToken) {
            const createdUuid = uuidv4();
            dispatch(storeUuid(createdUuid));
        }

    }, [uuid, accessToken, dispatch, addAlert]);

    useEffect(() => {
        getUrl('wallets').then(response => {
            if(response.code == 0) {
                dispatch(storeWallets(response.data));
            }
        }).catch(error => {
            console.log("wallets error", error);
        })
    }, [accessToken])

    useEffect(() => {
        moment.tz.setDefault("Asia/Kuala_Lumpur");
        let secTimer = setInterval( () => {
            setServerTime(moment().format("YYYY-MM-DD HH:mm:ss"))
        },1000)
      
        return () => clearInterval(secTimer);
    }, [])
    

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
        handleMenuClose();
    };

    const handleMenuClose = () => {
        setState({ ...state, languageAnchorEl: null, mobileAnchorEl: null, profileAnchorEl: null });
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const languageId = 'primary-search-language-menu';
    const profileId = 'primary-search-account-menu';
    const mobileMenuId = 'primary-search-menu-mobile';

    const renderMenu = (
        <Menu
            anchorEl={state.languageAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={languageId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(state.languageAnchorEl)}
            onClose={handleMenuClose}

        >
            <MenuItem onClick={() => changeLanguage('cn')}>简</MenuItem>
            <MenuItem onClick={() => changeLanguage('tw')}>繁</MenuItem>
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            {/* <MenuItem onClick={() => changeLanguage('ms')}>Malay</MenuItem> */}
            {/* <MenuItem onClick={() => changeLanguage('id')}>Indonesia</MenuItem> */}
        </Menu>
    );

    const renderProfile = (
        <Menu
            anchorEl={state.profileAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={profileId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(state.profileAnchorEl)}
            onClose={handleMenuClose}
        >
            {
                accessToken ?
                    <Box>
                        <Link underline='none' color="inherit" to="/account/profile" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.account')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/account/order" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.order')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/account/vouchers" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.voucher')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/voucher_cart" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.voucherCart')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/contactus" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('footer.contactUs')}</MenuItem>
                        </Link>
                        <MenuItem
                            onClick={() => {
                                logoutUser();
                                handleMenuClose();
                                history.replace('/');
                            }}
                        >
                            {t('button.logout')}
                        </MenuItem>
                    </Box>
                    :
                    <Box>
                        <Link underline='none' color="inherit" to="/login" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('button.login')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/voucher_cart" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('title.voucherCart')}</MenuItem>
                        </Link>
                        <Link underline='none' color="inherit" to="/contactUs" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>{t('footer.contactUs')}</MenuItem>
                        </Link>
                    </Box>
            }
        </Menu>
    );

    const renderMobileMenu = (
        <Menu
            anchorEl={state.mobileAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(state.mobileAnchorEl)}
            onClose={handleMenuClose}
        >
            <Box >
                <Link underline='none' color="inherit" to={PROJECT_TYPE === "catalog" ? "/mystore" : "/cart"} component={RouterLink} onClick={() => handleMenuClose()}>
                    <MenuItem>
                        <IconButton color="inherit">
                            <Badge badgeContent={cartTotal} classes={{ badge: styles.badgeStyle }}>
                                {
                                    PROJECT_TYPE === "catalog" ?
                                        <StorefrontIcon style={{ color: '#000000' }} size={20} />
                                        :
                                        <ShoppingCart color="#000000" size={20} />
                                }
                            </Badge>
                        </IconButton>
                        <p>{PROJECT_TYPE === "catalog" ? t('title.myStore') : t('title.cart')}</p>
                    </MenuItem>
                </Link>
                <Link underline='none' color="inherit" to="/category/all" component={RouterLink} onClick={() => handleMenuClose()}>
                    <MenuItem>
                        <IconButton color="inherit">
                            <CategoryOutlinedIcon style={{ color: '#000000' }} size={20} />
                        </IconButton>
                        <p>{t('title.categories')}</p>
                    </MenuItem>
                </Link>
                <MenuItem onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget })} >
                    <IconButton
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <Globe color="#000000" size={20} />
                    </IconButton>
                    <p>{t('home.translate')}</p>
                </MenuItem>
                {
                    accessToken ?
                        <>
                            <Link underline='none' color="inherit" to="/account/profile" onClick={() => handleMenuClose()} component={RouterLink}>
                                <MenuItem>
                                    <IconButton
                                        aria-controls="primary-search-account-menu"
                                        aria-haspopup="true"
                                        color="inherit"
                                    >
                                        <User color="#000000" size={20} />
                                    </IconButton>
                                    <p>{t('title.account')}</p>
                                </MenuItem>
                            </Link>
                            <MenuItem onClick={() => { logoutUser(); handleMenuClose(); history.replace('/'); }} >
                                <IconButton
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <ExitToAppIcon />
                                </IconButton>
                                <p>{t('button.logout')}</p>
                            </MenuItem>
                        </>
                        :
                        <Link underline='none' color="inherit" to="/login" onClick={() => handleMenuClose()} component={RouterLink}>
                            <MenuItem>
                                <IconButton
                                    aria-controls="primary-search-account-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <User color="#000000" size={20} />
                                </IconButton>
                                <p>{t('button.login')}</p>
                            </MenuItem>
                        </Link>
                        
                }
            </Box>

        </Menu>
    );

    // CHATBOT
    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
        window.$crisp=[];window.CRISP_WEBSITE_ID="f5101ba2-f281-4c94-9262-84998266238b";
        script.setAttribute("src", "https://client.crisp.chat/l.js");
        head.appendChild(script);
        return () => {
            head.removeChild(script);
        };
    }, []);

    return (
        // _.includes(['comingsoon'], currentPath) ?
        // false?
        // null : 
        <div >
            {/* <HideOnScroll {...props}>
                <AppBar color="primary" style={{ backgroundColor: theme.palette.primary.main, width:600, maxWidth:'100%', margin:'0 auto', right:'unset' }}>
                    <Container>
                        <Toolbar className={styles.toolbarTest} style={{ padding: '0' }}>
                            <Link underline='none' to="/" component={RouterLink} onClick={() => handleMenuClose()} className={styles.iconStyle}>
                                <WebpImg src="/images/logo.png" style={{ height: 38, paddingLeft: 10, paddingRight: 10 }} alt="" />

                            </Link>
                            {
                                _.includes(HIDE_COMPONENT_PAGE, currentPath) === false ?
                                    <>
                                        <Hidden xsDown>
                                            <Autocomplete
                                                style={{ width: '700px', maxWidth: '50%' }}
                                                open={open}
                                                onOpen={() => setOpen(true)}
                                                onClose={() => setOpen(false)}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                getOptionLabel={(option) => option.name}
                                                options={options}
                                                loading={loading}
                                                loadingText={`${t('general.loading')}...`}
                                                onInputChange={(event, newInputValue) => {
                                                    setKeyword(newInputValue)
                                                }}
                                                onChange={(event, value) => {
                                                    if (value && value.id) {
                                                        setOpen(false);
                                                        history.push(`/item/${value.id}`);
                                                    }
                                                }}
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder={t('general.search')}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: (
                                                                    <InputAdornment position="start"><Search style={{ color: theme.palette.gray.main }} /></InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <Fragment>
                                                                        {params.InputProps.endAdornment}
                                                                    </Fragment>
                                                                ),
                                                                style: {
                                                                    height: 40,
                                                                    padding: '0 14px',
                                                                    backgroundColor: '#FFFFFF'
                                                                }
                                                            }}
                                                            onKeyPress={(ev) => {
                                                                if (ev.key === 'Enter') {
                                                                    ev.preventDefault();
                                                                    setOpen(false);
                                                                    history.push(`/search/${keyword}`);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }}
                                                renderOption={(option, state) => (
                                                    // <Box display="flex" style={{ flex: 1 }}>
                                                    <Link underline='none' to={`/item/${option.id}`} onClick={() => handleMenuClose()} component={RouterLink} style={{ flex: 1 }}>
                                                        {option.name}
                                                    </Link>
                                                    // </Box>
                                                )}
                                            />
                                        </Hidden>
                                        <div className={styles.sectionDesktop}>
                                            <Button onClick={() => { handleMenuClose(); history.push(PROJECT_TYPE === "catalog" ? "/mystore" : "/cart") }}>
                                                <Badge badgeContent={cartTotal} classes={{ badge: styles.badgeStyle }}>
                                                    {
                                                        PROJECT_TYPE === "catalog" ?
                                                            <StorefrontIcon style={{ color: '#FFFFFF' }} size={20} />
                                                            :
                                                            <ShoppingCart color="#FFFFFF" size={20} />
                                                    }
                                                </Badge>
                                            </Button>
                                            <IconButton
                                                aria-label="language"
                                                aria-controls={languageId}
                                                aria-haspopup="true"
                                                onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget })}
                                                color="inherit"
                                            >
                                                <Globe color="#FFFFFF" size={20} />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="account of current user"
                                                aria-controls={profileId}
                                                aria-haspopup="true"
                                                onClick={(event) => setState({ ...state, profileAnchorEl: event.currentTarget })}
                                                color="inherit"
                                            >
                                                <User color="#FFFFFF" size={20} />
                                            </IconButton>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <div className={styles.sectionMobile}>
                                                <Link underline='none' style={{ lineHeight: '1.0' }} color="inherit" to="/search" component={RouterLink}>
                                                    <SearchIcon
                                                        style={{ color: '#fff' }} />
                                                </Link>
                                            </div>

                                            <div className={styles.sectionMobile}>
                                                <IconButton
                                                    aria-label="show more"
                                                    aria-controls={mobileMenuId}
                                                    aria-haspopup="true"
                                                    onClick={(event) => setState({ ...state, mobileAnchorEl: event.currentTarget })}
                                                    style={{ color: '#fff' }}
                                                >
                                                    <MoreIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                       
                                    </>
                                        : null
                                    
                            }
                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
            <Box style={{ paddingBottom: 60, backgroundColor: 'rgba(208, 208, 208, 0.23)', height:'100%' }}>

            </Box> */}
            {/* {renderMobileMenu}
            {renderMenu}
            {renderProfile} */}

            <div style={{ backgroundColor: theme.palette.white.main, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '15px 15px 6px' }}>
                <Link underline='none' to="/" component={RouterLink} className={styles.iconStyle} style={{ paddingTop: 5 }}>
                    <WebpImg src="/images/logo.png" style={{ height: 36, }} alt=" logo" containerStyle={{ lineHeight: 0 }} />
                </Link>
                <div style={{display:'flex', alignItems:'center'}}>
                    <IconButton
                        aria-label="language"
                        aria-controls={languageId}
                        aria-haspopup="true"
                        onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget })}
                        color="inherit"
                        style={{ paddingRight:27 }}
                    >
                        <Globe color={theme.palette.primary.main} size={20} />
                    </IconButton>
                    {renderMenu}
                    {accessToken ?
                        <Typography aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}  style={{ fontSize: 15, fontWeight: 'bold', textTransform: 'uppercase', display: 'flex', alignItems: 'center', lineHeight: 0 }}><span style={{ paddingRight: 5 }}>{username}</span> <BiUserCircle style={{ fontSize: 24 }} /></Typography>
                        :
                        <Link underline='none' color="inherit" to="/login" component={RouterLink}>
                            <div style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.white.main, borderRadius: 48, padding: '8px 20px', textTransform: 'uppercase', letterSpacing: 1 }}>
                                <b>{t('button.login')}</b>
                            </div>
                        </Link>
                    }
                </div>
            </div>
            <div style={{ padding: '6px 15px 0', textAlign: "right", color:"#767373" }}>
                <span>{t('general.serverTime')}: {serverTime}</span>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        logoutUser(dispatch);
                        handleClose();
                        history.replace('/');
                    }}
                >
                    {t('button.logout')}
                </MenuItem>
            </Menu>
        </div>
    )};

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        color: '#FFFFFF'
    },
  
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 400,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbarTest: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    iconStyle: {
        display: 'flex',
        alignItems: 'center',
        color: 'black'
    },
    badgeStyle: {
        backgroundColor: theme.palette.background.default,
        color: "#000000"
    }
}));