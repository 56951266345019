import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, IconButton, Link, makeStyles, Menu, MenuItem, useTheme } from '@material-ui/core';
import { Globe } from 'react-feather';

import { changeLanguage as reduxChangeLanguage } from '../actions/GeneralActions';
import WebpImg from './Layouts/WebpImg';
  
export default function Migrated() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const styles = useStyles();
    const [state, setState] = useState({
        languageAnchorEl: null,
    })

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    const handleMenuClose = () => {
        setState({ ...state, languageAnchorEl: null });
    };

    const languageId = 'primary-search-language-menu';
    const renderMenu = (
        <Menu
            anchorEl={state.languageAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={languageId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(state.languageAnchorEl)}
            onClose={handleMenuClose}

        >
            <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
            <MenuItem onClick={() => changeLanguage('cn')}>中文</MenuItem>
            <MenuItem onClick={() => changeLanguage('ms')}>Malay</MenuItem>
            <MenuItem onClick={() => changeLanguage('id')}>Indonesia</MenuItem>
        </Menu>
    );

    return (
        <Box className={styles.root}>
            
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}>
                <Link underline='none' to="/" component={RouterLink}>
                    <WebpImg src="/images/migrate/logo.png" style={{ height: 55, }} alt=" logo" containerStyle={{ lineHeight: 0 }} />
                </Link>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        aria-label="language"
                        aria-controls={languageId}
                        aria-haspopup="true"
                        onClick={(event) => setState({ ...state, languageAnchorEl: event.currentTarget })}
                        color="inherit"
                        style={{ paddingRight: 27 }}
                    >
                        <Globe style={{ color: '#fff' }} size={20} />
                    </IconButton>
                    {renderMenu}
                </div>
            </Box>
            <div style={{ paddingTop: 15 }}>
                <div>
                    <center><img src={`/images/migrate/text-${i18n.language}.png`} alt="migrate" style={{ width: 350, maxWidth: '100%' }} /></center>
                </div>
                <div style={{ color: '#fff', padding: 20 }}>
                    <p style={{ lineHeight: 1.3, whiteSpace:'pre-line' }}>
                        {/* {t('migrate.message1')} */}
                        <Trans i18nKey="migrate.message1" components={{ visit: <Link underline='none' color="primary" href="https://www.ledgerblock.net" /> }} />
                    </p>
                    <p style={{ lineHeight: 1.3, paddingTop: 10 }}>
                        {/* {t('migrate.message2')} */}
                        <Trans i18nKey="migrate.message2" components={{ visit: <Link underline='none' color="primary" href="https://www.ledgerblock.net" /> }} />
                    </p>
                </div>
                <div style={{ textAlign: "center"}}>
                    <Link className={styles.button} underline='none' href="https://www.ledgerblock.net" color="inherit">{t('button.visitNow')}</Link>
                </div>
            </div>
            <div style={{ width: '100%', maxWidth: '100%', paddingTop:25 }}> {/* position:'absolute', bottom:20, padding:'0 52px 0 0' */}
                <center><img src="/images/migrate/migrate-graphic.png" alt="migrate" style={{ width: 319, maxWidth: '100%' }} /></center>
            </div>
            
                {/* <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                    <img src="/images/migrate/bottom-app.png" alt="wave" />
                </div> */}
            
            {/* <div style={{textAlign:'center', color:'#606060'}}>
                <p className={styles.desc}>Hello, this site will be discontinued as of 15th Jan 2023. Bye Bye~~~ <br/>Oh wait.. we had migrated to a new site! Check out the new app <a href="#" style={{fontWeight: "bold" }}>LedgerBlock</a>!</p>
            </div> */}
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 25,
        height: '100%',
        minHeight:'calc(100vh - 56px)',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // flexDirection: 'column',
        background:'#00255d',
        backgroundImage:'url("/images/migrate/bottom-app.png")',
        backgroundRepeat:'no-repeat',
        backgroundPosition:'bottom',
        backgroundSize:'contain'
      
    },
    button: {
        textAlign:'center',
        fontSize:18,
        color:'#fff',
        background:theme.palette.primary.main,
        boxShadow:theme.palette.secondary.shadow,
        borderRadius:8,
        padding:'5px 15px',
        width:'fit-content',
        minWidth:150,
        margin:'0 auto',
        display: 'block'
    },
    desc: {
        fontSize:14,
        lineHeight:1.4,
        padding:'8px 11% 0'
    }
}));
