import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';
import { TextField, Button, Typography, Grid, Box, Divider, List, ListItem, Link, Snackbar, Card, CardContent, FormHelperText, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import _ from 'lodash';
import { putUrl, postUrl, getUrl } from '../helper/ApiAction';
import { useSelector } from 'react-redux';
import useNotificationLoading from '../helper/useNotificationLoading';

import { FiChevronLeft } from 'react-icons/fi';
import TitleBarWhite from './Layouts/TitleBarWhite';

const INITIAL_SECURITY_PASSWORD_STATE = { old_password: '', security_password: '', security_password_confirmation: '', authentication_code: '', tac: '' };

export default function ProfileSecurityPassword() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { two_factor_auth, username, email } = useSelector(state => ({
        two_factor_auth: state.user.two_factor_auth,
        username: state.user.username,
        email: state.user.email,
    }));
    const [securityPasswordFormState, setSecurityPasswordFormState] = useState(INITIAL_SECURITY_PASSWORD_STATE);
    const [errorFormState, setErrorFormState] = useState({
        old_password: '', password: '', password_confirmation: '', authentication_code: '', tac: ''
    });
    const [state, setState] = useState({
        snackbarShow: false,
        error: false,
        message: '',
    });
    const { addAlert, setLoading } = useNotificationLoading();
    const [timer, setTimer] = useState();
    const [resendVerification, setResendVerification] = useState(false);

    const handleSecurityPasswordChange = (event) => {
        const name = event.target.name;
        setSecurityPasswordFormState({ ...securityPasswordFormState, [name]: event.target.value });
    }

    const updateSecurityPassword = () => {
        setLoading(true);
        const { old_password, security_password, security_password_confirmation, authentication_code, tac } = securityPasswordFormState;
        if(!old_password || !security_password || !security_password_confirmation) {
            setLoading(false);
            setState({ ...state, snackbarShow: true, error: true, message: t('profile.fillUpRequired') })
        } else {
            putUrl('user/edit-security-password', securityPasswordFormState).then(result => {
                if(result.code == 0) {
                    setLoading(false);
                    setState( state => ({ ...state, snackbarShow: true, error: false, message: result.msg }) );
                    let allErrorField = [];
                    // _.map(result.error, (errorItem, errorField) => {
                    //     allErrorField[errorField] = errorItem[0];
                    // })
                    setSecurityPasswordFormState(INITIAL_SECURITY_PASSWORD_STATE);
                    setErrorFormState(allErrorField);
                } else {
                    setLoading(false);
                    setState( state => ({ ...state, snackbarShow: true, error: true, message: result.msg }) );
                    // setSecurityPasswordFormState(INITIAL_SECURITY_PASSWORD_STATE);
                    setErrorFormState({});
                }
            })
        }
    }

    const [viewAnnouncement, setViewAnnouncement] = useState(true);
    const closeAnnouncement = (event, reason) => {
        if (reason && (reason == "backdropClick" || reason == "escapeKeyDown")) 
        return;
        setViewAnnouncement(false);
    }
    const [agree, setAgree] = useState(false);
    const checkboxHandler = () => {
        setAgree(!agree);
    }
    const btnHandler = () => {
        setViewAnnouncement(false);
    };
    const forgotPassword = () => {
        setLoading(true);
        getUrl(`default/send-mail`, { email: email, username: username,type:2 }).then(result => {
            setLoading(false);
            if (result.code === 0) {
                addAlert(result.msg, 'success', '', '');
            } else {
                addAlert(result.msg, 'error', '', '');
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(error + "\n" + t('error.contactSupport'), 'error', '', '');
        });
    }

    const requestTac = () => {
        setLoading(true);
        let postData = {
            username: username ? username : '',
            email: email ? email : '',
        }
        getUrl('default/send-mail', postData).then(result => {
            setErrorFormState({});
            if (result.code !== 0) {
                setLoading(false);
                addAlert(result.msg);
            } else {
                setLoading(false);
                addAlert(result.msg, 'success');
                setResendVerification(true);
                setTimer(60);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(t('general.checkMailBox'), 'success');
        });
    }

    useEffect(() => {
        if (timer === 0) {
            setResendVerification(false);
        }
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [timer])

    return (
        <Box>
            
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'0 10px'}}>
            <TitleBarWhite title={ t('profile.changeSecurityPassword') } back displayInfo={false}/>
                <Link underline='none' to="/TutorialSecurityPassword" component={RouterLink} >
                <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff' }}>
                    <p>{t('tutorial.tutorial')}</p>
                </div>
                </Link>
            </div>

            <Box style={{ padding:'0 20px' }}>

                <Typography variant="h6">
                    {t('profile.changeSecurityPassword')}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    {t('profile.securityPasswordSubtitle')}
                </Typography>
                <div style={{ padding: 1 }}>
                    <Typography variant="caption" color="textSecondary">
                        <b>{t('profile.forgotSecurityPassword')}</b>
                    </Typography>
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                        <Button variant="contained" onClick={forgotPassword} style={{ width: 500, maxWidth: '80%', color:'#fff' }} color="primary">{t('profile.buttonForgotSecurityPassword')}</Button>
                    </Box>
                </div>
            </Box>
            {
                two_factor_auth !== 1 ?
                <>
                    <div style={{ padding: '30px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.currentSecurityPassword')}</b></Typography>
                        <div>
                            <TextField
                                error={errorFormState.old_password ? true : false}
                                helperText={errorFormState.old_password}
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                type="password"
                                inputProps={{ name: 'old_password' }}
                                onChange={handleSecurityPasswordChange}
                                value={securityPasswordFormState.old_password}
                            />
                            <FormHelperText>{t('profile.currentSecurityPasswordInfo')}</FormHelperText>
                        </div>
                    </div>
                    <div style={{ padding: '5px 20px 5px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.newSecurityPassword')}</b></Typography>
                        <TextField
                            error={errorFormState.security_password ? true : false}
                            helperText={errorFormState.security_password}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            type="password"
                            inputProps={{ name: 'security_password' }}
                            onChange={handleSecurityPasswordChange}
                            value={securityPasswordFormState.security_password}
                        />
                    </div>
                    <div style={{ padding: '5px 20px 10px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.confirmSecurityPassword')}</b></Typography>
                        <TextField
                            error={errorFormState.security_password_confirmation ? true : false}
                            helperText={errorFormState.security_password_confirmation}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            type="password"
                            inputProps={{ name: 'security_password_confirmation' }}
                            onChange={handleSecurityPasswordChange}
                            value={securityPasswordFormState.security_password_confirmation}
                        />
                    </div>
                    <div style={{ padding: '5px 20px 10px' }}>
                    <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.tac')}</b></Typography>
                    <Grid container>
                        <Grid item xs={8}>
                            <TextField
                                error={errorFormState.tac ? true : false}
                                helperText={errorFormState.tac}
                                fullWidth={true}
                                variant="outlined"
                                size="small"
                                type="text"
                                inputProps={{ name: 'tac' }}
                                onChange={handleSecurityPasswordChange}
                                value={securityPasswordFormState.tac}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ paddingLeft: '20px' }}>
                            <Button onClick={requestTac} variant="contained" color="primary" disabled={resendVerification}>{timer > 0 ? timer : t('button.requestTac')}</Button>
                        </Grid>
                    </Grid>
                    <FormHelperText>{t('general.tacCaption')}</FormHelperText>
                    </div>
                    {/* <div style={{ padding: '5px 20px 10px' }}>
                        <Typography color="textSecondary" style={{fontSize:13,}}><b>{t('profile.authenticationCode')}</b></Typography>
                        <TextField
                            error={errorFormState.authentication_code ? true : false}
                            helperText={errorFormState.authentication_code}
                            fullWidth={true}
                            variant="outlined"
                            size="small"
                            type="text"
                            inputProps={{ name: 'authentication_code' }}
                            onChange={handleSecurityPasswordChange}
                            value={securityPasswordFormState.authentication_code}
                        />
                        <FormHelperText>{t('general.2FACaption')}</FormHelperText>
                    </div> */}
                    <List className={styles.listStyle} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 30 }}>
                            <Button variant="contained" onClick={updateSecurityPassword} style={{ width: 500, maxWidth: '80%', color:'#fff' }} color="primary">{t('button.confirm')}</Button>
                        </Box>
                    </List>
                </>
                :
                <Box style={{ padding:'20px 40px' }} display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                    <Typography variant="overline" color="textPrimary">{t('profile.set2FAFirst', { title: t('profile.securityPassword') })}</Typography>
                    <div align="center" style={{ paddingTop: 20 }}>
                        <Link underline='none' to="/profile-two-factor-authentication" component={RouterLink} >
                            <div style={{ background: theme.palette.primary.main, padding: '8px 15px', borderRadius: 8, color: '#fff', maxWidth: '170px' }}>
                                <p align="center" style={{ lineHeight: 1.4 }}>{t('profile.2FA')}</p>
                            </div>
                        </Link>
                    </div>
                </Box>
            }
            
            <Snackbar
                open={state.snackbarShow}
                autoHideDuration={8000}
                onClose={() => setState({ ...state, snackbarShow: false, error: false, message: '' })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Card style={{ backgroundColor: state.error ? red[600] : green[600] }}>
                    <CardContent style={{ padding: 10 }}>
                        <Typography style={{ color: 'white' }}>{state.message}</Typography>
                    </CardContent>
                </Card>
            </Snackbar>

            <MuiThemeProvider key='warn' theme={announceDialogStyle}>
                <Dialog
                    open={viewAnnouncement? true : false}
                    onClose={closeAnnouncement}
                    scroll="body"
                    aria-labelledby="view Announcement"
                    aria-describedby="view Announcement detail"
                    key='warn'
                    style={{width:'547px', maxWidth:'100%', margin:'0 auto'}}
                >
                    <DialogTitle id='warn'>{t('topup.dialog.title')}</DialogTitle>
                    <DialogContent dividers={false}>
                        <div
                            id="viewAnnouncement"
                            tabIndex={-1}
                        >
                            <p>{t('profile.dialog.content')}</p><br/>
                            <input type="checkbox" id="agree" onChange={checkboxHandler} />
                            <label htmlFor="agree">{t('topup.dialog.agree')}</label>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!agree} onClick={btnHandler}>
                            {t('button.proceed')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    listPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 15
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
    backIconStyle: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        cursor: 'pointer',
    },
}));

const announceDialogStyle = createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
               width:450,
               maxWidth:'100%',
               margin:'0 auto',
            },
           
        },
        MuiDialog: {
            paper: {
                width: 380,
                maxWidth:'90%',
                minHeight:'24vh'
            }
        },
        MuiDialogContent:{
            root: {
                padding:'8px 24px 24px'
            }
        },
    },
});