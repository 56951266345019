import React from 'react';
import {isWebpSupported} from 'react-image-webp/dist/utils';

export default function WebpImg({ src, className, containerStyle={}, style = {}, alt = "", disableFallback = false }) {
    src = src ? src : '/images/no-image.png';
    const file_mime = src.substr(src.length - 4);
    let file_name = src.slice(0, - 4); 
    // console.log({ src, file_mime, file_name });

    if(file_mime === 'webp') {
        if(isWebpSupported()) {
            return <img src={`${file_name}webp`} className={className} style={style} alt={alt} onError={(ev) => { return disableFallback ? ev.target.style.display='none' : ev.target.src = '/images/no-image.png' } }/>
        }
        return <img src={`${file_name}png`} className={className} style={style} alt={alt} onError={(ev) => { return disableFallback ? ev.target.style.display='none' : ev.target.src = '/images/no-image.png' } }/>
    } else {
        return <div style={containerStyle}><img src={src} className={className} style={style} alt={alt} onError={(ev) => { return disableFallback ? ev.target.style.display='none' : ev.target.src = '/images/no-image.png' } }/></div>
    }

    // if(file_mime === 'webp') {
    //     return (
    //         <picture >
    //             <source srcSet={`${file_name}webp`} type="image/webp"/>
    //             <source srcSet={`${file_name}png`} type="image/png" /> 
    //             <img src={src} className={className} style={style} alt={alt}/>
    //         </picture>
    //     )
    // } else {
    //     return <img src={src} className={className} style={style} alt={alt} onError={(ev) => { return ev.target.src = '/images/no-image.png' } }/>
    // }
}