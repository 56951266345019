import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../helper/Tools';
import { getUrl } from '../helper/ApiAction';
import { useHistory } from 'react-router-dom';
import useNotificationLoading from '../helper/useNotificationLoading';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, List, ListItem, Typography, Box, Button } from '@material-ui/core';

import TitleBarWhite from './Layouts/TitleBarWhite';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

export default function EmailVerification() {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const isMountedRef = useRef(null);
    const [status, setStatus] = useState('pending');
    const history = useHistory();

    const query = useQuery();
    const params = query.get('verify_url');

    useEffect(() => {
        setLoading(true);
        if (params) {
            let url = decodeURI(params);
            isMountedRef.current = true;
            getUrl(url).then(response => {
                setLoading(false);
                if (isMountedRef.current && response.status) {
                    setStatus('verified');
                    addAlert(response.message, 'success');
                } else {
                    addAlert(response.message);
                    setStatus('failed');
                }
            }).catch(error => {
                setLoading(false);
                setStatus('failed')
                addAlert(JSON.stringify(error.message));
            });
            return () => isMountedRef.current = false;
        }
        // eslint-disable-next-line
    }, [addAlert, params]);


    return (
        <Box className={styles.root} style={{ background: theme.palette.white.mobileBkg, padding: '0 0 80px', minHeight: '100%' }}>
            <Grid container spacing={1} justify="center" style={{ padding: '40px 15px 0' }}>
                <Grid item xs={12} className={styles.root} >
                    <List className={styles.listStyle} style={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
                        <ListItem style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>
                            <Box style={{ color: theme.palette.primary.main }}>
                                <Typography variant="h6">
                                    {t('emailVerification.title')}
                                </Typography>
                            </Box>
                        </ListItem>
                        {
                            status === 'pending' ? null
                                :
                                <>
                                    {
                                        status === 'verified' ?
                                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ color: theme.palette.primary.main, padding: 20 }}>
                                                <CheckCircleOutlinedIcon style={{ fontSize: "150px" }} />
                                                <Typography variant="h6" style={{ padding: 10 }}>
                                                    {t('emailVerification.status.' + status)}
                                                </Typography>
                                                <Button variant="contained" onClick={() => history.push("/")} color="primary" style={{ padding: 10, maxWidth: '40%' }}>{t("accountLink.goToHomePage")}</Button>
                                            </Box>
                                            :
                                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ color: theme.palette.primary.main, padding: 20 }}>
                                                <CancelOutlinedIcon style={{ fontSize: "150px" }} />
                                                <Typography variant="h6" style={{ padding: 10 }}>
                                                    {t('emailVerification.status.' + status)}
                                                </Typography>
                                                <Typography variant="h6" style={{ padding: 10 }}>
                                                    {t('emailVerification.failed_message')}
                                                </Typography>
                                            </Box>
                                    }
                                </>
                        }
                    </List>
                </Grid>
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
    },
    listStyle: {
        backgroundColor: '#fff',
        marginBottom: 20,
    },
}));